import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
// used to create fake backend
import { fakeBackendProvider } from './_helpers';

import { AppRoutingModule } from './app-routing.module';
import { JwtInterceptor, ErrorInterceptor, appInitializer } from './_helpers';
import { AccountService } from './_services';
import { AppComponent } from './app.component';
import { AlertComponent } from './_components';

import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatTimepickerModule } from 'mat-timepicker';
import { MatSliderModule } from '@angular/material/slider';

import { MatIconModule } from '@angular/material/icon';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { Step1Component } from './onboarding/step1/step1.component';
import { Step2Component } from './onboarding/step2/step2.component';
import { Step3Component } from './onboarding/step3/step3.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { DatePipe } from '@angular/common';
import { NgScrollbarModule } from 'ngx-scrollbar';;
import { ContactusComponent } from './contactus/contactus.component'
;
import { CbtiComponent } from './cbti/cbti.component'
import { DiaryModule } from './diary/diary.module';
@NgModule({
  imports: [
    BrowserModule,
    TourMatMenuModule.forRoot(),
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    RouterModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatTimepickerModule,
    MatIconModule,
    NgxSliderModule,
    MatExpansionModule,
    MatSliderModule,
    NgScrollbarModule,
    DiaryModule
  ],

  declarations: [
    AppComponent,
    AlertComponent,
    OnboardingComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    ContactusComponent
,
    CbtiComponent
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AccountService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // provider used to create fake backend
    fakeBackendProvider,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
