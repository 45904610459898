<div class="admin-dashboard container p-4">
  <!-- <form [formGroup]="form"> -->
  <h1 class="pb-4 pt-4">Page Details</h1>

  <div id="pdfContent">
    <!-- <form [formGroup]="form"> -->

    <div class="container pt-4 pb-2">
      <h4 style="color: #002e46; font-size: 18px">
        <table class="table table-hover patient-details-table">
          <thead>
            <tr>
              <th><strong>Week Number</strong></th>
              <th><strong>Page Number</strong></th>
              <th><strong>Page Name</strong></th>
            </tr>
          </thead>
          <tbody *ngIf="data?.length > 0; else elseBlock">
            <tr *ngFor="let item of data">
              <td>{{ item.week - 1 }}</td>
              <td>
                {{ item.page_number }}
              </td>
              <td>
                {{ item.content }}
              </td>
            </tr>
          </tbody>
          <ng-template #elseBlock>
            <tbody>
              <tr colspan="5">
                <td class="text-center" colspan="5">No data found</td>
              </tr>
            </tbody>
          </ng-template>
        </table>
      </h4>
      <!-- <div *ngIf="totalItems >= pageSize" class="pagination">
        <button
          class="btn btn-primary px-3 patient-controls page-btn"
          (click)="previousPage()"
          [disabled]="currentPage === 1"
        >
          Previous
        </button>
        <span style="margin: 5px 5px"
          >Page {{ currentPage }} of {{ totalPages }} (Total:
          {{ totalItems }})</span
        >
        <button
          class="btn btn-primary px-3 patient-controls page-btn"
          (click)="nextPage()"
          [disabled]="!hasMoreData"
        >
          Next
        </button>
      </div> -->
    </div>
  </div>
</div>

<div class="dialog-actions">
  <br />

  <div class="details-input">
    <button
      class="sleep-journal-button btn btn-primary mx-auto field col-3"
      style="min-width: 250px"
      (click)="cancel()"
    >
      Close
    </button>
  </div>
</div>
