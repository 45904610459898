<form [formGroup]="form" (ngSubmit)="onSubmit()">

    <!-- <choose-option formControlName="duration_falling_sleep"></choose-option> -->
    <choose-quantity formControlName="duration_falling_sleep"></choose-quantity>


  <button class="btn1 field btn btn-primary">
    Complete Your Entry
  </button>
</form>

<!-- <div [formGroup]="form">
    <choose-quantity [increment]="10" formControlName="totalQuantity"></choose-quantity>
    ...
</div> -->