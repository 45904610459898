<div class="admin-dashboard container p-4">
  <h1 class="pb-4 pt-4">Course Status Details</h1>
  <div id="pdfContent">
    <h4 style="color: #002e46; font-size: 18px">
      <div class="course-section">
        <div class="details-input">
          <label>Name</label>
          <input
            value="{{ courseDetails?.first_name }} {{
              courseDetails?.last_name
            }}"
            type="text"
            readonly
          />

          <label>email</label>
          <input value="{{ courseDetails?.email }}" type="text" readonly />
          <label>mobile</label>
          <input value="{{ courseDetails?.mobile }}" type="text" readonly />
        </div>
        <div class="details-input">
          <label>User Id</label>
          <input value="{{ courseDetails?.id }}" type="text" readonly />
          <label>Current Week</label>
          <input
            value="{{ progressData?.current_week }}"
            type="text"
            readonly
          />
          <label>Current Page</label>
          <input
            value="{{ progressData?.current_page }}"
            type="text"
            readonly
          />
        </div>
      </div>

      <div
        *ngIf="
          progressData &&
            progressData?.current_week &&
            progressData?.current_week >= 1;
          else elseBlock
        "
      >
        <div *ngIf="progressData?.current_week >= 1">
          <h2 class="week-font">
            Week 1
            {{ parseMomentStartYM(week1ExceptedTime) }}
            {{ addDayAndFormat(week1ExceptedTime, 6) }}
          </h2>
          <div class="course-section">
            <h3>Unlock Time</h3>
            <div class="details-input">
              <label>Expected</label>
              <input
                value="{{ parseMomentDateTime(week1ExceptedTime) }}"
                type="text"
                readonly
              />

              <label>Actual </label>
              <input
                value="{{
                  parseMomentDateTime(progressData?.week1_actual_unlock_time)
                }}"
                type="text"
                readonly
              />
              <label>Renew Times</label>
              <input
                value="{{ progressData?.week1_check_flg }}"
                type="text"
                readonly
              />
            </div>
            <h3>Update Excepted Time</h3>
            <div class="details-input">
              <label>Adjust Days</label>
              <input
                [(ngModel)]="adjustDays1"
                type="text"
                (blur)="calculateUpdatedTime(1, adjustDays1)"
              />

              <label>Updated Time</label>
              <input
                value="{{
                  parseMomentUpdatedDateTime(updatedWeek1ExceptedTime)
                }}"
                type="text"
                style="margin-right: 16%"
                readonly
              />
              <button
                class="btn btn-primary field mx-auto col-2"
                (click)="updateTime(1, updatedWeek1ExceptedTime)"
                [disabled]="updateSuccess1"
              >
                Update Time
              </button>
            </div>
            <p *ngIf="updateError1" class="alert alert-danger">
              {{ updateError }}
            </p>
            <p *ngIf="updateSuccess1" class="alert alert-success">
              Excepted time updated successfully!
            </p>
          </div>
        </div>
        <div *ngIf="progressData?.current_week >= 2">
          <h2 class="week-font">
            Week 2
            {{ parseMomentStartYM(week2ExceptedTime) }}
            {{ addDayAndFormat(week2ExceptedTime, 6) }}
          </h2>
          <div class="course-section">
            <h3>Unlock Time</h3>
            <div class="details-input">
              <label>expected</label>
              <input
                value="{{ parseMomentDateTime(week2ExceptedTime) }}"
                type="text"
                readonly
              />

              <label>actual </label>
              <input
                value="{{
                  parseMomentDateTime(progressData?.week2_actual_unlock_time)
                }}"
                type="text"
                readonly
              />
              <label>Renew Times</label>
              <input
                value="{{ progressData?.week2_check_flg }}"
                type="text"
                readonly
              />
            </div>
            <h3>Update Excepted Time</h3>
            <div class="details-input">
              <label>Adjust Days</label>
              <input
                [(ngModel)]="adjustDays2"
                type="text"
                (blur)="calculateUpdatedTime(2, adjustDays2)"
              />

              <label>Updated Time</label>
              <input
                value="{{
                  parseMomentUpdatedDateTime(updatedWeek2ExceptedTime)
                }}"
                type="text"
                style="margin-right: 16%"
                readonly
              />
              <button
                class="btn btn-primary field mx-auto col-2"
                (click)="updateTime(2, updatedWeek2ExceptedTime)"
                [disabled]="updateSuccess2"
              >
                Update Time
              </button>
            </div>
            <p *ngIf="updateError2" class="alert alert-danger">
              {{ updateError }}
            </p>
            <p *ngIf="updateSuccess2" class="alert alert-success">
              Excepted time updated successfully!
            </p>
          </div>
        </div>
        <div *ngIf="progressData?.current_week >= 3">
          <h2 class="week-font">
            Week 3
            {{ parseMomentStartYM(week3ExceptedTime) }}
            {{ addDayAndFormat(week3ExceptedTime, 6) }}
          </h2>
          <div class="course-section">
            <h3>Unlock Time</h3>
            <div class="details-input">
              <label>expected</label>
              <input
                value="{{ parseMomentDateTime(week3ExceptedTime) }}"
                type="text"
                readonly
              />

              <label>actual </label>
              <input
                value="{{
                  parseMomentDateTime(progressData?.week3_actual_unlock_time)
                }}"
                type="text"
                readonly
              />
              <label>Renew Times</label>
              <input
                value="{{ progressData?.week3_check_flg }}"
                type="text"
                readonly
              />
            </div>
            <h3>Update Excepted Time</h3>
            <div class="details-input">
              <label>Adjust Days</label>
              <input
                [(ngModel)]="adjustDays3"
                type="text"
                (blur)="calculateUpdatedTime(3, adjustDays3)"
              />

              <label>Updated Time</label>
              <input
                value="{{
                  parseMomentUpdatedDateTime(updatedWeek3ExceptedTime)
                }}"
                type="text"
                style="margin-right: 16%"
                readonly
              />
              <button
                class="btn btn-primary field mx-auto col-2"
                (click)="updateTime(3, updatedWeek3ExceptedTime)"
                [disabled]="updateSuccess3"
              >
                Update Time
              </button>
            </div>
            <p *ngIf="updateError3" class="alert alert-danger">
              {{ updateError }}
            </p>
            <p *ngIf="updateSuccess3" class="alert alert-success">
              Excepted time updated successfully!
            </p>
          </div>
        </div>
        <div *ngIf="progressData?.current_week >= 4">
          <h2 class="week-font">
            Week 4
            {{ parseMomentStartYM(week4ExceptedTime) }}
            {{ addDayAndFormat(week4ExceptedTime, 6) }}
          </h2>
          <div class="course-section">
            <h3>Unlock Time</h3>
            <div class="details-input">
              <label>expected</label>
              <input
                value="{{ parseMomentDateTime(week4ExceptedTime) }}"
                type="text"
                readonly
              />

              <label>actual </label>
              <input
                value="{{
                  parseMomentDateTime(progressData?.week4_actual_unlock_time)
                }}"
                type="text"
                readonly
              />
              <label>Renew Times</label>
              <input
                value="{{ progressData?.week4_check_flg }}"
                type="text"
                readonly
              />
            </div>
            <h3>Update Excepted Time</h3>
            <div class="details-input">
              <label>Adjust Days</label>
              <input
                [(ngModel)]="adjustDays4"
                type="text"
                (blur)="calculateUpdatedTime(4, adjustDays4)"
              />

              <label>Updated Time</label>
              <input
                value="{{
                  parseMomentUpdatedDateTime(updatedWeek4ExceptedTime)
                }}"
                type="text"
                style="margin-right: 16%"
                readonly
              />
              <button
                class="btn btn-primary field mx-auto col-2"
                (click)="updateTime(4, updatedWeek4ExceptedTime)"
                [disabled]="updateSuccess4"
              >
                Update Time
              </button>
            </div>
            <p *ngIf="updateError4" class="alert alert-danger">
              {{ updateError }}
            </p>
            <p *ngIf="updateSuccess4" class="alert alert-success">
              Excepted time updated successfully!
            </p>
          </div>
        </div>
        <div *ngIf="progressData?.current_week >= 5">
          <h2 class="week-font">
            Week 5
            {{ parseMomentStartYM(week5ExceptedTime) }}
            {{ addDayAndFormat(week5ExceptedTime, 6) }}
          </h2>
          <!-- <h2 class="week-font">
            Week 5
            {{ addDayAndFormat(sleepJournalData[3]?.end_date, 1) }}
            {{ addDayAndFormat(sleepJournalData[3]?.end_date, 7) }}
          </h2> -->
          <div class="course-section">
            <h3>Unlock Time</h3>
            <div class="details-input">
              <label>expected</label>
              <input
                value="{{ parseMomentDateTime(week5ExceptedTime) }}"
                type="text"
                readonly
              />

              <label>actual </label>
              <input
                value="{{
                  parseMomentDateTime(progressData?.week5_actual_unlock_time)
                }}"
                type="text"
                readonly
              />
              <label>Renew Times</label>
              <input
                value="{{ progressData?.week5_check_flg }}"
                type="text"
                readonly
              />
            </div>
            <h3>Update Excepted Time</h3>
            <div class="details-input">
              <label>Adjust Days</label>
              <input
                [(ngModel)]="adjustDays5"
                type="text"
                (blur)="calculateUpdatedTime(5, adjustDays5)"
              />

              <label>Updated Time</label>
              <input
                value="{{
                  parseMomentUpdatedDateTime(updatedWeek5ExceptedTime)
                }}"
                type="text"
                style="margin-right: 16%"
                readonly
              />
              <button
                class="btn btn-primary field mx-auto col-2"
                (click)="updateTime(5, updatedWeek5ExceptedTime)"
                [disabled]="updateSuccess5"
              >
                Update Time
              </button>
            </div>
            <p *ngIf="updateError5" class="alert alert-danger">
              {{ updateError }}
            </p>
            <p *ngIf="updateSuccess5" class="alert alert-success">
              Excepted time updated successfully!
            </p>
          </div>
        </div>
        <div *ngIf="progressData?.current_week == 6">
          <h2 class="week-font">Final</h2>
          <div class="course-section">
            <h3>Completion Time</h3>
            <div class="details-input">
              <label>expected</label>
              <input
                value="{{ parseMomentDateTime(week5CompletionTime) }}"
                type="text"
                readonly
              />

              <label>actual </label>
              <input
                value="{{
                  parseMomentDateTime(
                    progressData?.week5_actual_completion_time
                  )
                }}"
                type="text"
                readonly
              />
              <label>Renew Times</label>
              <input
                value="{{ progressData?.week5_check_flg }}"
                type="text"
                readonly
              />
            </div>
            <h3>Update Excepted Time</h3>
            <div class="details-input">
              <label>Adjust Days</label>
              <input
                [(ngModel)]="adjustDays6"
                type="text"
                (blur)="calculateUpdatedTime(6, adjustDays6)"
              />

              <label>Updated Time</label>
              <input
                value="{{
                  parseMomentUpdatedDateTime(updatedWeek5CompletionTime)
                }}"
                type="text"
                style="margin-right: 16%"
                readonly
              />
              <button
                class="btn btn-primary field mx-auto col-2"
                (click)="updateTime(6, updatedWeek5CompletionTime)"
                [disabled]="updateSuccess6"
              >
                Update Time
              </button>
            </div>
            <p *ngIf="updateError6" class="alert alert-danger">
              {{ updateError }}
            </p>
            <p *ngIf="updateSuccess6" class="alert alert-success">
              Excepted time updated successfully!
            </p>
          </div>
        </div>
      </div>
      <ng-template #elseBlock>
        <p class="text-center">
          No course has started yet, or the Week 1 course has not been
          completed.
        </p>
      </ng-template>
    </h4>
  </div>
</div>
<div class="dialog-actions">
  <br />

  <div class="details-input">
    <button
      class="sleep-journal-button btn btn-primary mx-auto field col-3"
      style="min-width: 250px"
      (click)="cancel()"
    >
      Close
    </button>
  </div>
</div>
