import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Role } from '@app/_models';
import { PatientService } from '@app/_services/patient.service';
import {
  ShowOnDirtyErrorStateMatcher,
  ErrorStateMatcher,
} from '@angular/material/core';
import { Options } from '@angular-slider/ngx-slider';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { first } from 'rxjs/operators';

import { AccountService, AlertService } from '@app/_services';
import { coerceNumberProperty } from '@angular/cdk/coercion';
import { DatePipe } from '@angular/common';
 
import { trigger, transition, query, style, animate, group, state } from '@angular/animations';
import { Journal } from '@app/_models/journal';
import { RangeSliderComponent } from '../range-slider/range-slider.component';
import { MatTimepickerModule } from 'mat-timepicker';
class CustomErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null) {
    return control.invalid;
  }
}

@Component({
  selector: 'app-day',
  templateUrl: './day.component.html',
  styleUrls: ['./day.component.less']
})
export class DayComponent implements OnInit {
  wake_up_time = "--";
  entry_date = "--";
  id = "--";
  rise_from_bed = "--";
  go_to_bed = "--";
  lights_off_time = "--";
  duration_falling_sleep = 0;
  number_awakening = 0;
  minutes_awake = 0;
  list_stimulants = "--";
  list_sleep_medications = "--";
  rate_rest = "--";
  rate_sleep_quality = "--";
  
  userRole = Role.User;
  adminRole = Role.Admin;
  Role: Role;
  form: FormGroup;
  submitted = false;
  formError = false
  loading = false;
  isVisible = false;
  isVisible_left = true;
  isVisible_right = true;
  
  @Input() showSpecificPatient: boolean;

  constructor(
    private patientService: PatientService,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService,
	private formBuilder: FormBuilder,
    private datePipe: DatePipe
  ) {

  }

  @ViewChild(RangeSliderComponent) rangeSliderComponent: RangeSliderComponent;

  currentDate: any = new Date().toJSON().slice(0, 10);
  allData;
  currentEntry: Journal = new Journal;
  Math = Math;
  efficiencyFromPrevious;
  initialValuesLoaded: boolean = true;
  ngOnInit() {
    
    this.Role = this.accountService.Role
    // this.currentDate = this.rangeSliderComponent.currentDate
    var curr = new Date;
    var firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()));
    this.accountService
      .getDairyData(firstday)
      .pipe(first())
      .subscribe({
        next: (data) => {
          // get return url from query parameters or default to home page
          this.allData = data;
          this.refreshCurrentEntry(this.currentDate);
		 
          // this.initialValuesLoaded=true
        },
        error: (error) => {
          this.alertService.error(error);
          // this.loading = false;
        },
      });
	 
  }
 
  public refreshCurrentEntry(date: any): void {
    
    this.currentDate = date;
    this.currentEntry = this.allData.filter(data => data.entry_date == date)[0];
	let journalData = this.currentEntry;
    // this.efficiencyFromPrevious = this.compareWithYesterday();
	 
	 
	this.wake_up_time = this.currentEntry.wake_up_time;
	this.rise_from_bed = this.currentEntry.rise_from_bed;
	this.go_to_bed = this.currentEntry.go_to_bed;
	this.lights_off_time = this.currentEntry.lights_off_time;
	this.duration_falling_sleep = this.currentEntry.duration_falling_sleep;
	this.number_awakening = this.currentEntry.number_awakening;
	this.minutes_awake = this.currentEntry.minutes_awake;
	this.list_stimulants = this.currentEntry.list_stimulants;
	this.list_sleep_medications = this.currentEntry.list_sleep_medications;
	this.rate_rest = this.currentEntry.rate_rest;
	this.rate_sleep_quality = this.currentEntry.rate_sleep_quality;
	this.entry_date = this.currentEntry.entry_date;
	
	this.id = this.currentEntry['id'];
    this.form = this.formBuilder.group(
      {
        wake_up_time: ['', Validators.required],
		id: ['', Validators.required],
		rise_from_bed: ['', Validators.required],
		go_to_bed: ['', Validators.required],
		lights_off_time: ['', Validators.required],
		duration_falling_sleep: ['', Validators.required],
		number_awakening: ['', Validators.required],
		minutes_awake: ['', Validators.required],
		list_stimulants: ['', Validators.required],
		list_sleep_medications: ['', Validators.required],
		rate_rest: ['', Validators.required],
		rate_sleep_quality: ['', Validators.required],
		entry_date: ['', Validators.required],
      },
    );
    this.form.patchValue({
      wake_up_time: this.wake_up_time,
	  id: this.id,
	  rise_from_bed: this.rise_from_bed,
	  go_to_bed: this.go_to_bed,
	  lights_off_time: this.lights_off_time,
	  duration_falling_sleep: this.duration_falling_sleep,
	  number_awakening: this.number_awakening,
	  minutes_awake: this.minutes_awake,
	  list_stimulants: this.list_stimulants,
	  list_sleep_medications: this.list_sleep_medications,
	  rate_rest: this.rate_rest,
	  rate_sleep_quality: this.rate_sleep_quality,
	  entry_date: this.entry_date,
    });
	console.log(this.currentEntry['id']);
	this.form.get('wake_up_time').disable();
	this.form.get('rise_from_bed').disable();
	this.form.get('go_to_bed').disable();
	this.form.get('lights_off_time').disable();
	this.form.get('duration_falling_sleep').disable();
	this.form.get('number_awakening').disable();
	this.form.get('minutes_awake').disable();
	this.form.get('list_stimulants').disable();
	this.form.get('list_sleep_medications').disable();
	this.form.get('rate_rest').disable();
	this.form.get('rate_sleep_quality').disable();
   
  }
    
  

  public refreshWeekData(date: any): void {
    
    this.currentDate = date;
    this.accountService
      .getDairyData(this.currentDate)
      .pipe(first())
      .subscribe({
	    
        next: (data) => {
		  console.log("week");
          this.allData = data;
          // this.currentEntry = data[0];
		  
          this.refreshCurrentEntry(this.currentDate.toJSON().slice(0, 10));
          this.initialValuesLoaded = true

        },
        error: (error) => {
          this.alertService.error(error);
        },
      });
  }

  public getMinuteFromTime(t1, t2) {
    let time1 = "07/29/2019 " + t1
    let time2 = "07/30/2019 " + t2

    let date1 = new Date(time1);
    let date2 = new Date(time2);

    let Time = date2.getTime() - date1.getTime();
    let Days = Time / (1000 * 60); //Diference in Days
    return Days
  }

  public compareWithYesterday() {
    this.currentEntry = this.allData.filter(data => data.entry_date == this.currentDate)[0];
    // let previous:any = new Date( new Date().getTime() + Math.abs(new Date().getTimezoneOffset()*60000) );
    let previous: any = new Date();
    previous.setHours(0, 0, 0, 0);
    let current = new Date(this.currentDate).getTime() + Math.abs(new Date(this.currentDate).getTimezoneOffset() * 60000);
    previous.setDate((new Date(current)).getDate() - 1);
    // previous = new Date( previous.getTime() + Math.abs(previous.getTimezoneOffset()*60000) )
    previous = previous.toJSON().slice(0, 10)
    let previousEntry = this.allData.filter(data => data.entry_date == previous)[0];
    try {
      return Math.round(100 * (this.currentEntry.sleep_efficiency - previousEntry.sleep_efficiency) / (this.currentEntry.sleep_efficiency));
    }
    catch (error) {
      console.warn('Here is the error message', error);
    }
  }
  // Ref:https://www.c-sharpcorner.com/article/how-to-get-start-and-end-date-of-week-using-angular-10ngular/
  // startOfWeek(date) {  
  //   var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);  
  //   return new Date(date.setDate(diff));  
  // }  
  // endofweek(date) {  
  //   var lastday = date.getDate() - (date.getDay() - 1) + 6;  
  //   return new Date(date.setDate(lastday));  
  // } 

  onSubmit(): void {
    this.submitted = true;
    // // reset alerts on submit
    this.alertService.clear();

    // // stop here if form is invalid
    if (this.form.invalid) {
      this.formError = true
      return;
    }

    this.formError = false

    this.loading = true;

    let data = {
      ...this.form.value
    }
    console.log(data);
          this.accountService
		    .updateJournal(this.form.value)
            .pipe(first())
            .subscribe({
              next: () => {
                 console.log(this.form.value);
                // get return url from query parameters or default to home page
                //this.router.navigate(['/patient-profile/'])
                this.alertService.success('Your entry has been successfully saved');
				 window.location.reload();
				console.log("ss");
              },
              error: (error) => {
                this.alertService.error(error);
				console.log(error);
                this.loading = false;
              },
            });

    console.log("sss");
  }

  onEdit(): void {
    this.submitted = false;
	this.form.get('wake_up_time').enable();
	this.form.get('rise_from_bed').enable();
	this.form.get('go_to_bed').enable();
	this.form.get('lights_off_time').enable();
	this.form.get('duration_falling_sleep').enable();
	this.form.get('number_awakening').enable();
	this.form.get('minutes_awake').enable();
	this.form.get('list_stimulants').enable();
	this.form.get('list_sleep_medications').enable();
	this.form.get('rate_rest').enable();
	this.form.get('rate_sleep_quality').enable();
	this.isVisible = true;
	this.isVisible_left = false;
	this.isVisible_right = false;
	
  }
    
 
}