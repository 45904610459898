import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['../onboarding.component.less']
})
export class Step2Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
