<div class="container bg-light min-vh-100 p-4" style="margin-bottom: 3rem;">
    <h1 class="pt-4 pb-4">What is CBTI</h1>
    <div class="container vh-80 container-inner p-4">
        <div class="row p-4">
		    <div class="col-md-2"></div>
            <div class="col-md-8"><video width="100%" height="auto" controls autoplay>
			  <source src="/assets/videos/what_is_cbti.mp4" type="video/mp4">
			Your browser does not support the video tag.
			</video>
			</div>
			<div class="col-md-2"></div>
        </div>
        <div class="row p-4">
            <div class="col-md-6 content" style="font-family : Karla; font-size : 14px;">
                <p style="color : #002E46 !important;">
                    Behavioral therapy is a term that describes a broad range of techniques used to change maladaptive
                    behaviors. The goal is to reinforce desirable behaviors and eliminate unwanted ones. Behavioral
                    therapy
                    is
                    rooted in the principle of thought that we learn from our environment.
                </p>

                <p style="color : #002E46 !important;">
                    Unlike the types of therapy that are rooted in insight (such as psychoanalytic therapy), behavioral
                    therapy
                    is action-based. Because of this, behavioral therapy tends to be highly focused. The behavior itself
                    is
                    the
                    problem and the goal is to teach people new behaviors to minimize or eliminate the issue.
                </p>

                <p style="color : #002E46 !important;">
                    There are a number of different types of behavioral therapy. The type of therapy used can depend on
                    a
                    variety of factors, including the condition being treated and the severity of the person's symptoms.
                </p>

                <p style="color : #002E46 !important;">
                    The one that we will focus on is called Brief Behavioral Therapy for insomnia (BBT), which relies on
                    behavioral techniques without a cognitive element.
                </p>
            </div>
            <div class="col-md-6">
                <img src="/assets/cbti-1.png" alt="" width="100%">

            </div>
        </div>
    </div>
</div>