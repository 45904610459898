import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService, AlertService } from '@app/_services';
import { Role } from '@app/_models';
//import { AlertService } from '@app/_services';
import { PatientService } from '@app/_services/patient.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'patient-profile',
  templateUrl: './patient-profile.component.html',
  styleUrls: ['./patient-profile.component.less']
})
export class PatientProfileComponent implements OnInit {

  patientId: Number;
  patientDetails: Object;
  showSpecificPatient: boolean = true;
  patientProfileDetails: Object;
  userRole = Role.User;
  adminRole = Role.Admin;
  Role: Role;

  constructor(
    private alertService: AlertService,
    private patientService: PatientService,
	private accountService: AccountService,
    private actRoute: ActivatedRoute
    ) {
    this.patientId = this.actRoute.snapshot.params['id'];
  }

  ngOnInit(): void {
	this.Role = this.accountService.Role
	//alert(this.Role);
    this.patientService.getPatientDetailsById(this.patientId)
      .pipe(first())
      .subscribe({
        next: (data) => {
          data.initial_letter = data.first_name.charAt(0)
          this.patientDetails = data
		  if(data.profile_details == null){
			let profile_details = {
				"sex": null,
				"dob": null,
				"height": null,
				"weight": null,
				"sex_details": null,
				"notes": null,
				"notes_written_by": null,
				"age": null
			}
			console.log("empty");
			this.patientProfileDetails = profile_details
		  }else{
          this.patientProfileDetails = data.profile_details
		  }
        },
        error: (error) => {
          this.alertService.error(error);
          // this.loading = false;
        },
      });
  }
  onEdit_Profile(): void {
 this.patientService.getPatientDetailsById(this.patientId)
      .pipe(first())
      .subscribe({
        next: (data) => {
          data.initial_letter = data.first_name.charAt(0)
          this.patientDetails = data
		  if(data.profile_details == null){
			let profile_details = {
				"sex": null,
				"dob": null,
				"height": null,
				"weight": null,
				"sex_details": null,
				"notes": null,
				"notes_written_by": null,
				"age": null
			}
			console.log("empty2");
			this.patientProfileDetails = profile_details
			alert(JSON.stringify(this.patientProfileDetails, null, 2));
		  }else{
          this.patientProfileDetails = data.profile_details
		  alert(JSON.stringify(this.patientProfileDetails, null, 2));
		  }
        },
        error: (error) => {
          this.alertService.error(error);
          // this.loading = false;
        },
      });   
  }


}