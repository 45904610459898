<h1>Select Duration (in Minutes)</h1>
<div class="time-picker">
  <div class="time-section">
    <label for="hour">{{ "DIALOG_HOUR" | translate }}</label>
    <select
      id="hour"
      [(ngModel)]="selectedHour"
      (ngModelChange)="calculateTotalMinutes()"
    >
      <option *ngFor="let hour of hours" [value]="hour">{{ hour }}</option>
    </select>
  </div>

  <div class="time-section">
    <label for="minute">{{ "DIALOG_MINUTE" | translate }}</label>
    <select
      id="minute"
      [(ngModel)]="selectedMinute"
      (ngModelChange)="calculateTotalMinutes()"
    >
      <option *ngFor="let minute of minutes" [value]="minute">
        {{ minute }}
      </option>
    </select>
  </div>
  <div class="time-section">
    <label for="totalMinutes">{{ "DIALOG_TOTAL_MINUTES" | translate }}</label>
    <input
      class="input-field"
      type="text"
      id="totalMinutes"
      [value]="totalMinutes"
      readonly
    />
  </div>
</div>

<div class="dialog-actions">
  <button
    class="btn btn-primary field mx-auto col-3 ms-2"
    (click)="confirmTime()"
    style="min-width: 100px; margin-right: 20px"
  >
    {{ "DIALOG_CONFIRM" | translate }}
  </button>
  <button
    class="btn btn-primary field mx-auto col-3 ms-2"
    (click)="cancel()"
    style="min-width: 100px"
  >
    {{ "DIALOG_CANCEL" | translate }}
  </button>
</div>
