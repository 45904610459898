<div class="custom-dialog">
  <h2>
    <!-- Course Access Reminder -->
    {{ "DIALOG_COURSE_ACCESS_REMINDER" | translate }}
  </h2>

  <h3>
    <p>
      <!-- It has been a while since you last accessed this course. Please choose an
      option to continue: -->
      {{ "DIALOG_LONG_TIME_REMINDER" | translate }}
    </p>
    <!-- 
    <p>
      <b>Continue:</b> Your previous data will be kept. Log your sleep data
      starting tonight. Once you complete 7 consecutive days, the next week's
      course will unlock. 
      {{ "DIALOG_CONTINUE_REMINDER" | translate }} 
    </p>-->
    <div [innerHTML]="safeHtmlContentContinue"></div>
    <!-- 
    <p>
      <b>Reset:</b> Restart the course to provide more complete and accurate
      data for our sleep experts to analyze.
      {{ "DIALOG_RESET_REMINDER" | translate }}
    </p>
    -->
    <div [innerHTML]="safeHtmlContentReset"></div>
  </h3>
  <div class="form-group text-center">
    <button
      class="btn btn-primary field mx-auto col-3 me-2"
      (click)="onContinue()"
      style="min-width: 160px"
    >
      <span>{{ "DIALOG_CONTINUE" | translate }}</span
      ><br /><span>{{ "DIALOG_KEEP_DATA" | translate }}</span>
    </button>
    <button
      class="btn btn-primary field mx-auto col-3 ms-2"
      (click)="onReset()"
      style="min-width: 180px"
    >
      <span>{{ "DIALOG_RESET" | translate }}</span
      ><br />
      <span>{{ "DIALOG_RESTART_COURSE" | translate }}</span>
    </button>
  </div>
</div>
