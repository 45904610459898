import { Component, OnInit } from '@angular/core';
import { AlertService } from '@app/_services';
import { PatientService } from '@app/_services/patient.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { CourseService } from '@app/_services/course.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { HttpClient } from '@angular/common/http';
import { EmailVerificationService } from '@app/_services/email-verification.service';
import { ContactService } from '@app/_services/contact.service';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-journal-details',
  templateUrl: './journal-details.component.html',
  styleUrls: ['./journal-details.style.scss'],
})
export class JournalDetailsComponent implements OnInit {
  journals: any[] = [];
  totalItems = 0;
  totalPages = 0;
  pageSize = 20;
  currentPage = 1;
  hasMoreData: boolean = false;
  sortOrder: string = 'asc';
  sortField: string = 'entry_date';
  data: any = {};
  profile: any = {};
  activeSort = '';

  constructor(
    public patientService: PatientService,
    private activatedRoute: ActivatedRoute,
    private courseService: CourseService,
    private emailService: EmailVerificationService,
    private contactService: ContactService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.fetchJournals();
  }

  fetchJournals() {
    const userId = Number(this.activatedRoute.snapshot.paramMap.get('id'));

    this.patientService
      .getPatientJournal(
        Number(userId),
        this.currentPage,
        this.pageSize,
        this.sortOrder
      )
      .subscribe((response) => {
        this.data = response;
        this.profile = response.profile;
        this.journals = this.data.journal;
        this.totalItems = response.journal_count;
        this.totalPages = Math.ceil(this.totalItems / this.pageSize);
        this.hasMoreData = this.currentPage < this.totalPages;
      });

    //   this.patientService.getUserJournals(userId).subscribe({
    //     next: (response) => {
    //       this.data = response;
    //       console.log('response:', response);
    //       this.profile = response.profile;
    //       this.journals = this.data.journals;
    //       console.log('this.journals:', this.journals);
    //       this.totalItems = response.total;
    //     },
    //   });
    // }
  }
  parseMomentDateTime(modifiedDate: string) {
    if (modifiedDate == null) {
      return '';
    }

    try {
      return moment(modifiedDate).format('M-D h:mm A');
    } catch (error) {
      return '';
    }
  }

  parseMomentDate(modifiedDate: string) {
    if (modifiedDate == null) {
      return '';
    }

    try {
      return moment(modifiedDate).format('M-D');
    } catch (error) {
      return '';
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.fetchJournals();
    }
  }

  nextPage() {
    if (this.hasMoreData) {
      this.currentPage++;
      this.fetchJournals();
    }
  }

  sortData(field: string) {
    if (this.sortField === field) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = field;
      this.sortOrder = 'asc';
    }
    this.fetchJournals();

    // this.journals.sort((a, b) => {
    //   const valueA = a[field];
    //   const valueB = b[field];

    //   if (!valueA || !valueB) return 0;

    //   if (typeof valueA === 'string' && typeof valueB === 'string') {
    //     return this.sortOrder === 'asc'
    //       ? valueA.localeCompare(valueB)
    //       : valueB.localeCompare(valueA);
    //   } else {
    //     return this.sortOrder === 'asc' ? valueA - valueB : valueB - valueA;
    //   }
    // });
  }

  getSortIcon(field: string): string {
    if (this.sortField !== field) return 'fa-sort';
    return this.sortOrder === 'asc' ? 'fa-sort-up' : 'fa-sort-down';
  }
}
