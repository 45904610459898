import { Component, OnInit } from '@angular/core';
import { PatientService } from '@app/_services/patient.service';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { EmailVerificationService } from '@app/_services/email-verification.service';
import { ContactService } from '@app/_services/contact.service';
import { MatDialog } from '@angular/material/dialog';
import { JournalDetailsDialogComponent } from '@app/dialog/journal/journal-details-dialog.component';
import { CourseDetailsDialogComponent } from '@app/dialog/course/course-details-dialog.component';
import { CourseService } from '@app/_services/course.service';
import { PageDetailsDialogComponent } from '@app/dialog/page/page-details-dialog.component';

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.style.scss'],
})
export class ContactDetailsComponent implements OnInit {
  contactId: number;
  contact: any = {};
  response: string;
  userId: number;
  courseDetails: any;
  profileData: any;
  progressData: any;
  selfReflectData: Object;
  sleepJournalData: Object;
  desiredSleepData: Object;
  weeklyCheckInData: Object;

  showComponent = true;
  form: FormGroup;
  suggestion1: string = '';
  suggestion2: string = '';
  suggestion3: string = '';
  suggestion4: string = '';
  suggestion5: string = '';

  finalSuggestion: string = '';
  message: string = '';
  isEmailSent: boolean = false;
  currentWeek;
  currentPage;
  updatedWeek;
  chkMessage = '';
  noUserId = false;
  updateSuccess = false;
  updateError = '';

  constructor(
    public patientService: PatientService,
    private activatedRoute: ActivatedRoute,
    private emailService: EmailVerificationService,
    private contactService: ContactService,
    private courseService: CourseService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.paramMap.get('id');

    this.contactId = Number(id);
    if (this.contactId) {
      this.contactService.getContactDetailsById(this.contactId).subscribe(
        (response) => {
          this.contact = response;
          this.userId = response.user;
          if (this.userId) {
            this.courseService
              .getUserProgressById(this.userId)
              .subscribe((data) => {
                console.log('data:', data);
                if (data) {
                  this.currentWeek = data?.current_week;
                  this.currentPage = data?.current_page;
                } else {
                  this.chkMessage =
                    'No User ID found in Course Progress data. Cannot update week number.';
                  this.noUserId = true;
                }
              });
          } else {
            this.chkMessage =
              'User ID is missing. Cannot retrieve progress data.';
            this.noUserId = true;
          }
        },
        (error) => {
          console.error('Error fetching course details data.', error);
        }
      );
    }
  }

  parseMomentDateTime(modifiedDate: string) {
    if (modifiedDate == null) {
      return '';
    }

    try {
      return moment(modifiedDate).format('M-D-YYYY h:mm A');
    } catch (error) {
      return '';
    }
  }

  openJournalDetails() {
    const dialogRef = this.dialog.open(JournalDetailsDialogComponent, {
      width: '1200px',
      height: '800px',
      data: { userId: this.userId },
    });
  }

  openCourseDetails() {
    const dialogRef = this.dialog.open(CourseDetailsDialogComponent, {
      width: '1200px',
      height: '800px',
      data: { userId: this.userId },
    });
  }

  openPageDetails() {
    const dialogRef = this.dialog.open(PageDetailsDialogComponent, {
      width: '1000px',
      height: '800px',
    });
  }

  modifyCourseInfo() {
    if (!this.updatedWeek || isNaN(this.updatedWeek)) {
      this.updateError = 'Please enter a valid week number.';
      this.updateSuccess = false;
      return;
    }

    if (this.updatedWeek <= this.currentWeek) {
      this.updateError = `Please enter a week number greater than Current Week (${this.currentWeek}).`;
      this.updateSuccess = false;
      return;
    }

    if (this.updatedWeek < 1 || this.updatedWeek > 6) {
      this.updateError = 'Week number must be between 1 and 6.';
      this.updateSuccess = false;
      return;
    }
    const data = {
      userId: this.userId,
      updatedWeek: this.updatedWeek,
    };
    this.courseService.updateWeekInfo(data).subscribe({
      next: () => {
        this.currentWeek = this.updatedWeek;
        this.currentPage = 1;
        this.updateSuccess = true;
      },
      error: (error) => {
        this.updateError = 'Failed to save progress.';
        this.updateSuccess = false;
      },
    });
  }

  sendResponse() {
    const data = {
      id: this.contact.id,
      first_name: this.contact.first_name,
      last_name: this.contact.last_name,
      email: this.contact.email,
      phone: this.contact.phone,
      message: this.contact.message,
      is_processed: this.contact.is_processed,
      response_text: this.response,
    };
    this.emailService.sendResponseEmail(data).subscribe({
      next: () => {
        this.message = 'Your email has been sent successfully!';
        this.isEmailSent = true;
      },
      error: (err) => {
        this.message = `Error sending code: ${
          err.error.detail || 'Unexpected error occurred.'
        }`;
      },
    });
  }
}
