import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '@app/_services';
import { CourseService } from '@app/_services/course.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-week5-page3',
  templateUrl: './week5-page3.component.html',
  styleUrls: ['./week5-page3.style.scss'],
})
export class Week5Page3Component implements OnInit {
  videoSrc: string = '';
  isVideoEnded: boolean = false;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private courseService: CourseService
  ) {}

  ngOnInit(): void {
    const savedLang = localStorage.getItem('selectedLanguage');
    if (savedLang) {
      this.translate.use(savedLang);
    } else {
      this.translate.use(this.translate.defaultLang);
    }

    // Paremeter(5): Indicates the number of the current week
    // Paremeter(3): Indicates the number of the current page
    this.courseService.isStudied(5, 3).then((result) => {
      if (result) {
        this.isVideoEnded = true;
      } else {
        const isVideoWatched =
          localStorage.getItem('isW5P3VideoWatched') === 'true';
        this.isVideoEnded = isVideoWatched;
      }
    });
    this.setVideoSource();

    this.translate.onLangChange.subscribe((event) => {
      localStorage.setItem('selectedLanguage', event.lang);
      this.setVideoSource();
    });
  }

  setVideoSource(): void {
    const currentLang = this.translate.currentLang;

    if (currentLang === 'sch' || currentLang === 'tch') {
      this.videoSrc = '/assets/videos/week5_1_what_perpetuates_insomnia_ch.mp4';
    } else {
      this.videoSrc = '/assets/videos/week5_1_what_perpetuates_insomnia_en.mp4';
    }
    this.cdr.detectChanges();
  }

  onVideoEnded(): void {
    this.isVideoEnded = true;
    localStorage.setItem('isW5P3VideoWatched', 'true');
  }

  onNextClick() {
    // this.courseService.unlockWeek(2);
    //  Parameter (3): The index starts from 0, unlock the third page of the current week
    this.courseService.unlockWeek5Content(3);

    //  Parameter (5): Indicates the number of the current week.
    //  Parameter (4): Indicates the fourth page of the current week.
    this.courseService.updateProgress(5, 4);

    this.router.navigate(['week5/self_assessment_and_reflection']);
  }
}
