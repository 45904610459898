import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { plotAverageGraph } from '@app/_helpers';
import { Journal } from '@app/_models/journal';
import { AccountService, AlertService } from '@app/_services';
import * as moment from 'moment';
import { first } from 'rxjs/operators';
import { initData, sleepEfficiencyInit } from './month';

@Component({
  selector: 'app-month',
  templateUrl: './month.component.html',
  styleUrls: ['./month.component.less']
})
export class MonthComponent implements OnInit {

  @Input() showSpecificPatient: boolean;

  constructor(private router: Router,
    private accountService: AccountService,
    private alertService: AlertService,
    private datePipe: DatePipe) {
    // Object.assign(this, { single })
    Object.assign(this, { initData });
    Object.assign(this, { sleepEfficiencyInit });

    this.refData = (JSON.stringify(this.initData));
    this.sleepEfficiencyRef = (JSON.stringify(this.sleepEfficiencyInit));
  }

  sleep = [{ "name": "Day 1", "value": 0 }, { "name": "Day 2", "value": 69 }, { "name": "Day 3", "value": 27 }, { "name": "Day 4", "value": 92 }, { "name": "Day 5", "value": 60 }, { "name": "Day 6", "value": 0 }, { "name": "Day 7", "value": 0 }];
  // options
  showXAxis = false;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  xAxisLabel = 'Country';
  showYAxisLabel = false;
  yAxisLabel = 'Sleep Efficiency';

  colorScheme = {
    domain: ['#58a3ca']
  };

  sleepEfficiencyInit;
  sleepEfficiencyRef;
  allData;
  currentDate: any = new Date().toJSON().slice(0, 10);
  averageSleepTime = 0;
  averageTimeInBed = 0;
  averageRating = 0;
  averageSleepEfficiency = 0;
  currentEntry: Journal = new Journal;
  selectedDate: any = new Date().toJSON().slice(0, 10);
  originalData;
  ngOnInit(): void {
    this.accountService
      .getDairyData(null, 'monthly')
      .pipe(first())
      .subscribe({
        next: (data) => {
          // get return url from query parameters or default to home page
          // this.allData = data;
          // this.averageSleepTime = 0;
          // this.averageTimeInBed = 0;
          this.createData(data);
          // this.refreshCurrentEntry(this.currentDate);
          // this.initialValuesLoaded=true
        },
        error: (error) => {
          this.alertService.error(error);
          // this.loading = false;
        },
      });
    // this.allData = initData
  }

  public resetBarValue() {
    document.getElementById("clonedElementForBed")?.remove();
    document.getElementById("clonedElementForSleep")?.remove();
  }

  public createData(data) {
    this.originalData = data;
    // Object.assign(this, initData);
    // Object.assign(this, {initData});
    this.initData = Object.assign([], this.generatingInitialDataStructure());
    this.sleepEfficiencyInit = Object.assign([], JSON.parse(this.sleepEfficiencyRef));
    this.allData = this.initData;
    this.averageSleepTime = 0;
    this.averageTimeInBed = 0;
    this.averageRating = 0;
    const ln = data.length;
    this.selectedDate = this.currentDate;
    data.forEach(element => {
      // let date1 = new Date(this.currentDate);
      // date1 = new Date(date1.getFullYear(), date1.getMonth(), 1);
      let year = element.entry_date.slice(0, 4);
      let month = element.entry_date.slice(5, 7);
      let day = element.entry_date.slice(8, 10);
      let date2 = new Date(year, Number(month) - 1, day);
      // let diffTime = date1.getTime() - date2.getTime();
      // const diffTime = Math.abs(date2 - date1);
      // if(diffTime == 0){
      //   this.currentEntry = element;
      // }

      // const diffDays = Math.ceil(Math.abs(diffTime) / (1000 * 60 * 60 * 24));
      const diffDays = date2.getDate() - 1;
      // this.allData[diffDays].name = Math.round(element.rate_rest + element.rate_sleep_quality / 2);
      this.allData[diffDays].series[0].value = Math.abs(element.total_asleep);

      this.allData[diffDays]['series'][0].tooltipText = String(Math.abs(element.total_asleep));

      this.averageSleepTime += element.total_asleep;
      this.averageTimeInBed += element.total_time_in_bed;
      this.averageRating += element.rate_sleep_quality + element.rate_rest;
      this.allData[diffDays].series[1].value = Math.abs(Math.abs(element.total_time_in_bed) - Math.abs(element.total_asleep));
      this.allData[diffDays].series[1].tooltipText = String(Math.abs(element.total_time_in_bed));

      this.sleepEfficiencyInit[diffDays].value = Math.round(element.sleep_efficiency);
    });

    this.averageSleepTime = Math.round(this.averageSleepTime / ln);
    this.averageTimeInBed = Math.round(this.averageTimeInBed / ln);
    this.averageRating = Math.round(this.averageRating / (2 * ln));
    this.averageSleepEfficiency = Math.round(this.averageSleepTime / this.averageTimeInBed * 100);


    let maximumTimeInbed = 0
    let maximumSleepTime = 0

    // let maximumTimeSortData = data.sort(function(a, b){return b.total_time_in_bed - a.total_time_in_bed});
    // if(maximumTimeSortData.length>0){
    //   maximumTimeInbed = maximumTimeSortData[0].total_time_in_bed
    //   if(maximumTimeInbed < 840){
    //     maximumTimeInbed = 840
    //   }
    // }
    maximumTimeInbed = 840

    let averageTimeInBed: any = this.averageTimeInBed

    let percentageInBed = (averageTimeInBed / maximumTimeInbed) * 100

    console.log("Average time in bed is ", averageTimeInBed)
    console.log("Maximum time in bed is ", maximumTimeInbed)

    let miniumCalculationData = [...data]
    // let totalSleepSortData = miniumCalculationData.sort(function(a, b){return b.total_asleep - a.total_asleep});

    // if(totalSleepSortData.length > 0 ){
    //   maximumSleepTime  = totalSleepSortData[0].total_time_in_bed
    //   if(maximumSleepTime < 840){
    //     maximumSleepTime = 840
    //   }
    // }
    maximumSleepTime = 840

    let percentageInSleep = (this.averageSleepTime / maximumSleepTime) * 100

    plotAverageGraph(percentageInBed, "#6FC8F6", percentageInSleep, "#055D9D", this.resetBarValue)
  }

  initData: any[];
  refData;
  addDays(date, days) {
    const find = new Date(Number(date))
    find.setDate(date.getDate() + days)
    return find
  }

  public refreshCurrentEntry(date: any): void {
    let momentDate = moment(date)
    let year = momentDate.year()
    let month = momentDate.month() + 1;
    let currentDate: any = new Date(year, month, 0).toJSON().slice(0, 10);

    this.currentDate = currentDate;
    this.accountService
      .getDairyData((new Date(year, month, 0)), 'monthly')

      .pipe(first())
      .subscribe({
        next: (data) => {

          this.createData(data);
          // this.currentEntry = data[0];
          // this.refreshCurrentEntry(this.currentDate.toJSON().slice(0,10));
          // this.initialValuesLoaded=true

        },
        error: (error) => {
          this.alertService.error(error);
        },
      });
  }

  formatDataLabel(value) {
    return value + '%';
  }

  public refreshWeekData(date: any): void {
    this.currentDate = date;
    this.accountService
      .getDairyData(this.currentDate, 'monthly')

      .pipe(first())
      .subscribe({
        next: (data) => {

          this.createData(data);
          // this.currentEntry = data[0];
          // this.refreshCurrentEntry(this.currentDate.toJSON().slice(0,10));
          // this.initialValuesLoaded=true

        },
        error: (error) => {
          this.alertService.error(error);
        },
      });
  }

  yAxisFormat = (value) => {
    // Must be in this component. See: https://github.com/swimlane/ngx-charts/issues/261
    return String(value) + "%";
  }

  generatingInitialDataStructure() {

    let momentDate = moment(this.currentDate);
    let endDateOfMonth:number = parseInt(momentDate.clone().endOf('month').format('D'));
    var initData = [];
    for (let i = 0; i < endDateOfMonth; i++) {
      let dayLabel:any = momentDate.clone().startOf('month').add(i, 'days').format("MMMM DD");
      initData.push({
        name: dayLabel,
        series: [
          {
            name: 'Sleep Time',
            value: 0,
            tooltipText: ''
          },
          {
            name: 'Time in Bed',
            value: 0,
            tooltipText: ''
          },
        ],
      });
    };

    return initData

  }

}