<div class="p-4">
  <h1>
    {{ "W3_WEEKLY_CHECK_IN" | translate }}
  </h1>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <h4 style="color: #002e46; font-size: 18px">
      <br />
      <p>
        {{ "W2_GAUGE_PROGRESS" | translate }}
      </p>
      <div>
        <p>
          {{ "W2_NOTICE_CHANGES_QUESTION" | translate }}
        </p>
        <div class="rating-options">
          <label *ngFor="let option of ratingOptions">
            <input
              type="radio"
              formControlName="sleep_change"
              [value]="option.value"
            />
            <div>{{ option.label }}</div>
          </label>
        </div>
        <div class="rating-labels">
          <span>
            {{ "W2_NOT_AT_ALL" | translate }}
          </span>
          <span>
            {{ "W2_LOTS_CHANGES" | translate }}
          </span>
        </div>
        <div
          class="text-danger"
          *ngIf="
            form.get('sleep_change')?.invalid &&
            form.get('sleep_change')?.touched
          "
        >
          {{ "FIELD_REQUIRED" | translate }}
        </div>
      </div>
      <br />
      <div>
        <p>
          {{ "W2_RATE_SLEEP_QUALITY_QUESTION" | translate }}
        </p>
        <div class="rating-options">
          <label *ngFor="let option of ratingOptions">
            <input
              type="radio"
              formControlName="sleep_quality"
              [value]="option.value"
            />
            <div>{{ option.label }}</div>
          </label>
        </div>
        <div class="rating-labels">
          <span>
            {{ "W2_REALLY_BAD" | translate }}
          </span>
          <span>
            {{ "W2_REALLY_GOOD" | translate }}
          </span>
        </div>
        <div
          class="text-danger"
          *ngIf="
            form.get('sleep_quality')?.invalid &&
            form.get('sleep_quality')?.touched
          "
        >
          {{ "FIELD_REQUIRED" | translate }}
        </div>
      </div>
      <br />
      <div>
        <p>
          {{ "W2_RATE_DAYTIME_FUNCTION" | translate }}
        </p>
        <div class="rating-options">
          <label *ngFor="let option of ratingOptions">
            <input
              type="radio"
              formControlName="daytime_functioning"
              [value]="option.value"
            />
            <div>{{ option.label }}</div>
          </label>
        </div>
        <div class="rating-labels">
          <span>
            {{ "W2_REALLY_BAD" | translate }}
          </span>
          <span>
            {{ "W2_REALLY_GOOD" | translate }}
          </span>
        </div>
        <div
          class="text-danger"
          *ngIf="
            form.get('daytime_functioning')?.invalid &&
            form.get('daytime_functioning')?.touched
          "
        >
          {{ "FIELD_REQUIRED" | translate }}
        </div>
      </div>
      <br />
      <p>{{ "W2_COMMENTS_OBSERVATIONS" | translate }}</p>
      <angular-editor
        [config]="editorConfig"
        formControlName="comments_text"
      ></angular-editor>
      <div
        class="text-danger"
        *ngIf="
          form.get('comments_text')?.invalid &&
          form.get('comments_text')?.touched
        "
      >
        {{ "FIELD_REQUIRED" | translate }}
      </div>

      <!-- <div
          *ngIf="f.comments_text.errors?.required && f.comments_text.touched"
          class="text-danger"
        >
          This field is required.
        </div> -->
      <br />
      <div class="form-group text-center">
        <button
          class="btn btn-primary field mx-auto col-3"
          type="submit"
          [disabled]="form.invalid"
        >
          <!-- <button
            class="btn btn-primary field mx-auto col-3"
            type="submit"
          > -->
          {{ "NEXT" | translate }}
        </button>
      </div>
    </h4>
  </form>
</div>
