<div class="custom-dialog">
  <h2 *ngIf="data.flg == 1 || data.flg == 3">
    {{ "DIALOG_INCOMPLETE_JOURNAL" | translate }}
  </h2>
  <h2 *ngIf="data.flg == 2">{{ "DIALOG_COURSE_UNLOCK" | translate }}</h2>
  <h3>
    <div *ngIf="data.flg == 1 || data.flg == 3">
      <p>
        <!-- You haven't logged your Sleep Journal for the following dates: -->
        {{ "DIALOG_NOT_LOG_JOURNAL" | translate }}
      </p>
      <b>
        <ul>
          <li *ngFor="let date of data.missingDates">
            <!-- <a href="#" (click)="selectDate($event, date)">{{
              date | date : "yyyy-MM-dd"
            }}</a> -->
            <a href="#" (click)="selectDate($event, date)">{{ date }}</a>
          </li>
        </ul>
      </b>

      <p>
        <!-- Please complete your logs by clicking on the dates above or using the
        Log Journal button. -->
        {{ "DIALOG_COMPLETE_LOG_JOURNAL" | translate }}
      </p>
    </div>
    <div *ngIf="data.flg == 1">
      <!-- <p>
        The next week's course will unlock on <b>{{ data.date }}</b> after
        you've logged <b>all 7 days</b> of this week's Sleep Journal.
        {{
          "DIALOG_NEXT_WEEK_UNLOCK_REMINDER" | translate : { date: data.date }
        }}
      </p> -->
      <div [innerHTML]="safeHtmlContentNext"></div>
    </div>
    <div *ngIf="data.flg == 2">
      <!-- <p>
        Your next course is set to unlock on <b>{{ data.date }}</b> after you've
        completed <b>all 7 days</b> of this week's Sleep Journal. You're doing
        great so far—keep logging your entries, and the next course will be
        ready soon!
        {{ "DIALOG_COURSE_UNLOCK_REMINDER" | translate : { date: data.date } }}
      </p> -->
      <div [innerHTML]="saftHtmlContent"></div>
      <p>
        <b>
          <!-- Stay consistent, you're almost there! -->
          {{ "DIALOG_STAY_CONSISTENT" | translate }}
        </b>
      </p>
    </div>
  </h3>
  <div class="form-group text-center">
    <!-- <button
      class="btn btn-primary field mx-auto col-3 me-2"
      (click)="onConfirm()"
      [ngStyle]="{
        'min-width': data.flg == 1 || data.flg == 3 ? '130px' : 'auto',
        width: data.flg == 2 ? '160px' : 'auto'
      }"
    > -->
    <button
      *ngIf="data.flg == 1 || data.flg == 3"
      class="btn btn-primary field mx-auto col-3 me-2"
      (click)="logJournal()"
      style="min-width: 145px"
    >
      {{ "DIALOG_LOG_JOURNAL" | translate }}
    </button>
    <button
      *ngIf="data.flg == 2"
      class="btn btn-primary field mx-auto col-3 me-2"
      (click)="viewProgress()"
      style="min-width: 145px"
    >
      {{ "DIALOG_VIEW_PROGRESS" | translate }}
    </button>

    <button
      class="btn btn-primary field mx-auto col-3 ms-2"
      (click)="onCancel()"
      style="min-width: 145px"
    >
      {{ "DIALOG_CANCEL" | translate }}
    </button>
  </div>
</div>
