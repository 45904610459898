<div class="administrator container p-4">

    <h1 class="py-4">Administrator</h1>
    <button *ngIf="administratorService.isPreviousPageDisable != true" class="btn btn-primary mb-4" (click)="navigateToAdminPage()">Add Administrator</button>

    <div class="container pt-4 pb-2">
        <div class="row">
            <div *ngIf="administratorService.isPreviousPageDisable" class="col-6 my-2">
                <div class="card add-btn-container cursor-pointer" (click)="navigateToAdminPage()">
                    <div class="card-body text-black">
                        <div class="d-flex align-items-center d-flex justify-content-around">
                            <img src="https://via.placeholder.com/50" alt="Generic placeholder image"
                                class="img-fluid rounded-circle border border-dark border-3 invisible">
                            <div class="doctor-details">
                                NEW ADMIN
                            </div>
                            <div class="doctor-details invisible">

                                <p class="mb-0 me-2 m-auto">@sheisme</p>
                                <span>
                                    test@test.com
                                </span>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6 my-2" *ngFor="let admin of adminList;">
                <div class="card">
                    <div class="card-body text-black">
                        <div class="d-flex align-items-center d-flex justify-content-around">
                            <img src="https://via.placeholder.com/50" alt="Generic placeholder image"
                                class="img-fluid rounded-circle border border-dark border-3">
                            <div class="doctor-details">

                                <p class="mb-0 me-2 m-auto text-center">{{admin.first_name}} {{admin.last_name}}</p>
                                <span>
                                    {{admin.email}}
                                </span>

                            </div>
                            <div class="action">
                                <div class="dot">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-around">
            <div class="col-5 offset-6">
                <ul *ngIf="administratorService.printPageList.length>0" class="pagination pagination-sm mt-4 justify-content-end">
                    <li class="page-item" [ngClass]="[administratorService.isPreviousPageDisable? 'disabled': '']">
                        <a class="page-link" href="#" (click)="moveToPreviousPage($event)">Previous</a>
                    </li>
                    <li class="page-item" [ngClass]="[administratorService.startPage == item ? 'active' : '']" *ngFor="let item of administratorService.printPageList;">
                            <a class="page-link" href="#" (click)="goToPage($event,item)">{{item}}</a>
                    </li>
                    <li class="page-item" [ngClass]="[administratorService.isNextPageDisable? 'disabled': '']"><a class="page-link"
                            href="#" (click)="moveToNextPage($event)">Next</a></li>
                </ul>
            </div>
        </div>

        <div class="row justify-content-around">
            <div class="col-5"></div>

        </div>
    </div>
</div>