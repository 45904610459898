import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cbti',
  templateUrl: './cbti.component.html',
  styleUrls: ['./cbti.component.less'],
})
export class CbtiComponent implements OnInit {
  videoSrc: string = '';
  isVideoEnded: boolean = false;

  constructor(
    private translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const savedLang = localStorage.getItem('selectedLanguage');

    if (savedLang) {
      this.translate.use(savedLang);
    } else {
      this.translate.use(this.translate.defaultLang);
    }
    this.setVideoSource();

    this.translate.onLangChange.subscribe((event) => {
      localStorage.setItem('selectedLanguage', event.lang);
      this.setVideoSource();
    });
  }

  setVideoSource(): void {
    const currentLang = this.translate.currentLang;

    if (currentLang === 'sch' || currentLang === 'tch') {
      this.videoSrc = '/assets/videos/what_is_cbti_ch.mp4';
    } else {
      this.videoSrc = '/assets/videos/what_is_cbti_en.mp4';
    }
    this.cdr.detectChanges();
  }
}
