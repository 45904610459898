import { Component, OnInit } from '@angular/core';
import { Contactus } from '../_models/contactus';
import {
  FormsModule,
  FormGroup,
  FormBuilder,
  FormArray,
  FormControl,
  Validators,
} from '@angular/forms';
import { AccountService, AlertService } from '@app/_services';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ContactService } from '@app/_services/contact.service';
import { PatientService } from '@app/_services/patient.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.less'],
})
export class ContactusComponent implements OnInit {
  // model = new Contactus();
  submitted = false;
  contactUsForm: FormGroup;
  userInfo: any = {};

  constructor(
    private fb: FormBuilder,
    private contactService: ContactService,
    private alertService: AlertService,
    private router: Router,
    private patientService: PatientService
  ) {}

  ngOnInit(): void {
    this.intializeForm();
  }

  intializeForm(): void {
    this.contactUsForm = this.fb.group({
      first_name: new FormControl('', [Validators.required]),
      last_name: new FormControl('', [Validators.required]),
      email: new FormControl('', [
        Validators.required,
        Validators.email,

        // Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
      ]),
      phone: new FormControl('', [Validators.required]),
      message: new FormControl('', [
        Validators.required,
        Validators.maxLength(365),
      ]),
    });
    // const id = JSON.parse(localStorage.getItem('token')).user.id;
    // if (id) {
    //   this.patientService.getPatientDetailsById(id).subscribe((data) => {
    //     console.log('data:', data);
    //     this.userInfo = data;
    //   });
    // }
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.contactUsForm.invalid) {
      return;
    }
    const user_id = JSON.parse(localStorage.getItem('token')).user.id;
    const contacts = {
      ...this.contactUsForm.value,
      user_id: user_id,
    };
    // this.loading = true;
    this.contactService
      .contact(contacts)
      .pipe(first())
      .subscribe({
        next: () => {
          // get return url from query parameters or default to home page
          this.alertService.info(
            'Thank You! You entry has been saved, we will contact you shortly'
          );
          // const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
          this.contactUsForm.reset();
          this.submitted = false;
          // this.router.navigate(['/contact']);
        },
        error: (error) => {
          this.alertService.error(error);

          // this.loading = false;
        },
      });
  }

  // get reference(): FormArray{

  // }
}
