import { Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-donutchart',
  templateUrl: './donutchart.component.html',
  styleUrls: ['./donutchart.component.less']
})
export class DonutchartComponent implements OnInit {

  constructor(private elementRef: ElementRef) { }

  @Input()
  firstValue: any;

  @Input()
  secondValue: any;


  @Input()
  dailyLabel: boolean = false;

  
  @Input()
  value: any = null;



  @Input()
  comparedEfficiency: boolean = false;

  up: boolean = false;
  down: boolean = false;

  arr: Array<number>;

  ngOnInit(): void {
    if (this.comparedEfficiency) {
      if (this.firstValue < 0) {
        this.down = true;
        this.up = false;
      } else if (this.firstValue > 0) {
        this.up = true;
        this.down = false;
      }
    }
    if(this.value == 'calculate'){
      this.value = Math.round(100 * this.firstValue / (this.secondValue));
    }
    this.firstValue = Math.abs(this.firstValue);
    let first = Math.floor(100 * this.firstValue / (this.firstValue + this.secondValue));
    let second = 100 - first;
    this.arr = [first, second];


    var circle = this.elementRef.nativeElement.querySelector('circle');
    var radius = Number(circle.attributes.r.value);
    // debugger;
    var circumreference = radius * 2 * Math.PI;

  }

  ngAfterContentInit() {
    // debugger;
    // console.log("Hello ", this.myValue.nativeElement);
  }

  ngOnChanges() {
    // create header using child_id
    if (this.comparedEfficiency) {
      if (this.firstValue < 0) {
        this.down = true;
        this.up = false;
      } else if (this.firstValue > 0) {
        this.up = true;
        this.down = false;
      }
    }
    if(this.value == 'calculate'){
      this.value = Math.round(100 * this.firstValue / (this.secondValue));
    }
    this.firstValue = Math.abs(this.firstValue);
    let first = Math.floor(100 * this.firstValue / (this.firstValue + this.secondValue));
    let second = 100 - first;
    this.arr = [first, second];

  }
}

