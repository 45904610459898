<div class="container p-4">
    <h1 class="pl-4 pt-4">Contact Us</h1>
    <p class="p-4">Please give us time to respond to query</p>
    <form [formGroup]="contactUsForm" (ngSubmit)="onSubmit()" class="p-4 m-4">
        <div class="row">
            <div class="col">
                <div class="form-group">
                    <label for="firstName"
                        [ngClass]="{'text-danger' : submitted && contactUsForm.get('firstName').invalid}">First
                        Name</label>
                    <input formControlName="firstName" id="firstName" #firstName type="text" class="form-control"
                        [ngClass]="{'is-invalid' : submitted && contactUsForm.get('firstName').invalid}">
                    <ng-container *ngIf="submitted && contactUsForm.get('firstName').invalid">
                        <small id="firstName" class="text-danger">
                            First name is required.
                        </small>
                    </ng-container>


                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <label for="lastName"
                        [ngClass]="{'text-danger' : submitted && contactUsForm.get('lastName').invalid}">Last
                        Name</label>
                    <input id="lastName" formControlName="lastName" cols="30" rows="10"
                        [ngClass]="{'is-invalid' : submitted && contactUsForm.get('lastName').invalid}"
                        class="form-control">
                        <ng-container *ngIf="submitted">
                            <small *ngIf="contactUsForm.get('lastName').errors?.required" class="text-danger">Last Name is required </small>
                        </ng-container>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="form-group">
                    <label for="email"
                        [ngClass]="{'text-danger' : submitted && contactUsForm.get('email').invalid}">Email</label>
                    <input formControlName="email" type="email"
                        [ngClass]="{'is-invalid' : submitted && contactUsForm.get('email').invalid}"
                        class="form-control">
                        <ng-container *ngIf="submitted">
                            <small *ngIf="contactUsForm.get('email').errors?.required" class="text-danger">Email is required </small>
                            <small *ngIf="contactUsForm.get('email').errors?.email" class="text-danger">Please enter a valid email address</small>
                        </ng-container>
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <label for="phoneNumber"
                        [ngClass]="{'text-danger' : submitted && contactUsForm.get('phoneNumber').invalid}">Contact
                        No.</label>
                    <input id="phoneNumber" formControlName="phoneNumber" type="tel" id="phoneNumber" name="phoneNumber"
                        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" required cols="30" rows="10"
                        [ngClass]="{'is-invalid' : submitted && contactUsForm.get('phoneNumber').invalid}"
                        class="form-control">
                    <ng-container *ngIf="submitted">
                        <small *ngIf="contactUsForm.get('phoneNumber').errors?.required" class="text-danger">Please enter
                            your phone number in correct format e.g. 123-456-7891 </small>
                        <small *ngIf="contactUsForm.get('phoneNumber').errors?.pattern" class="text-danger">Please provide
                            a valid phone number e.g. 123-456-7891</small>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label for="message"
                [ngClass]="{'text-danger' : submitted && contactUsForm.get('message').invalid}">Message</label>
            <textarea name="message" id="message" formControlName="message" class="form-control" rows="10"
                [ngClass]="{'is-invalid' : submitted && contactUsForm.get('message').invalid}"></textarea>
                <ng-container *ngIf="submitted">
                    <small *ngIf="contactUsForm.get('message').errors?.maxlength" class="text-danger">You message exceed the maximum limit of 365 characters.</small>
                </ng-container>
        </div>
        <button class="btn btn-primary">Send</button>
    </form>
</div>