import { Component, OnInit, Inject } from '@angular/core';
import { PatientService } from '@app/_services/patient.service';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { CourseService } from '@app/_services/course.service';
import { FormGroup } from '@angular/forms';
import { EmailVerificationService } from '@app/_services/email-verification.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { format } from 'date-fns';

@Component({
  selector: 'app-course-details-dialog',
  templateUrl: './course-details-dialog.component.html',
  styleUrls: ['./course-details-dialog.style.scss'],
})
export class CourseDetailsDialogComponent implements OnInit {
  patientId: number;
  courseDetails: any;
  profileData: any;
  progressData: any;
  selfReflectData: Object;
  sleepJournalData: Object;
  desiredSleepData: Object;
  weeklyCheckInData: Object;
  adjustDays1: number;
  adjustDays2: number;
  adjustDays3: number;
  adjustDays4: number;
  adjustDays5: number;
  adjustDays6: number;
  updatedWeek1ExceptedTime;
  updatedWeek2ExceptedTime;
  updatedWeek3ExceptedTime;
  updatedWeek4ExceptedTime;
  updatedWeek5ExceptedTime;
  updatedWeek5CompletionTime;

  showComponent = true;
  form: FormGroup;
  suggestion1: string = '';
  suggestion2: string = '';
  suggestion3: string = '';
  suggestion4: string = '';
  suggestion5: string = '';

  finalSuggestion: string = '';
  message: string = '';
  isSending: boolean = false;

  // chkMessage = '';
  updateSuccess1 = false;
  updateError1 = '';
  updateSuccess2 = false;
  updateError2 = '';
  updateSuccess3 = false;
  updateError3 = '';
  updateSuccess4 = false;
  updateError4 = '';
  updateSuccess5 = false;
  updateError5 = '';
  updateSuccess6 = false;
  updateError6 = '';

  week1ExceptedTime: string = '';
  week2ExceptedTime: string = '';
  week3ExceptedTime: string = '';
  week4ExceptedTime: string = '';
  week5ExceptedTime: string = '';
  week5CompletionTime: string = '';

  constructor(
    public patientService: PatientService,
    private activatedRoute: ActivatedRoute,
    private courseService: CourseService,
    private emailService: EmailVerificationService,
    public dialogRef: MatDialogRef<CourseDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public receivedData: any
  ) {
    this.patientId = receivedData.userId;
  }

  ngOnInit(): void {
    // const userId = this.activatedRoute.snapshot.paramMap.get('id');

    // this.patientId = Number(userId);
    if (this.patientId) {
      this.patientService.getPatientCourseDetailsById(this.patientId).subscribe(
        (courseDetails) => {
          console.log('courseDetails:', courseDetails);
          if (courseDetails) {
            this.courseDetails = courseDetails;

            if (courseDetails?.profile) {
              this.profileData = courseDetails.profile;
            }

            if (courseDetails?.progress) {
              this.progressData = courseDetails.progress;
              this.week1ExceptedTime =
                this.progressData.week1_expected_unlock_time;
              this.week2ExceptedTime =
                this.progressData.week2_expected_unlock_time;
              this.week3ExceptedTime =
                this.progressData.week3_expected_unlock_time;
              this.week4ExceptedTime =
                this.progressData.week4_expected_unlock_time;
              this.week5ExceptedTime =
                this.progressData.week5_expected_unlock_time;
              this.week5CompletionTime =
                this.progressData.week5_expected_completion_time;
            }

            if (courseDetails?.reflectinfo) {
              this.selfReflectData = courseDetails.reflectinfo;
              this.suggestion1 = this.selfReflectData[0]?.doctor_suggestion;
              this.suggestion2 = this.selfReflectData[1]?.doctor_suggestion;
              this.suggestion3 = this.selfReflectData[2]?.doctor_suggestion;
              this.suggestion4 = this.selfReflectData[3]?.doctor_suggestion;
              this.suggestion5 = this.selfReflectData[4]?.doctor_suggestion;
              this.finalSuggestion = this.selfReflectData[5]?.doctor_suggestion;
            }

            if (courseDetails?.journalinfo) {
              this.sleepJournalData = courseDetails.journalinfo;
            }

            if (courseDetails?.desiredinfo) {
              this.desiredSleepData = courseDetails.desiredinfo;
            }

            if (courseDetails?.weeklyinfo) {
              this.weeklyCheckInData = courseDetails.weeklyinfo;
            }
          }
        },
        (error) => {
          console.error('Error fetching course details data.', error);
        }
      );
    }
  }

  calculateUpdatedTime(week_number: number, adjustDays: number) {
    const daysToAdd = Number(adjustDays) || 0;
    if (week_number === 1) {
      const newDate = new Date(this.week1ExceptedTime);
      newDate.setDate(newDate.getDate() + daysToAdd);
      this.updatedWeek1ExceptedTime = newDate;
    } else if (week_number === 2) {
      const newDate = new Date(this.week2ExceptedTime);
      newDate.setDate(newDate.getDate() + daysToAdd);
      this.updatedWeek2ExceptedTime = newDate;
    } else if (week_number === 3) {
      const newDate = new Date(this.week3ExceptedTime);
      newDate.setDate(newDate.getDate() + daysToAdd);
      this.updatedWeek3ExceptedTime = newDate;
    } else if (week_number === 4) {
      const newDate = new Date(this.week4ExceptedTime);
      newDate.setDate(newDate.getDate() + daysToAdd);
      this.updatedWeek4ExceptedTime = newDate;
    } else if (week_number === 5) {
      const newDate = new Date(this.week5ExceptedTime);
      newDate.setDate(newDate.getDate() + daysToAdd);
      this.updatedWeek5ExceptedTime = newDate;
    } else if (week_number === 6) {
      const newDate = new Date(this.week5CompletionTime);
      newDate.setDate(newDate.getDate() + daysToAdd);
      this.updatedWeek5CompletionTime = newDate;
    }
  }

  updateTime(weekNumber: number, updatedTime) {
    if (!updatedTime) return;

    const formattedTime = this.formatDateTime(updatedTime);
    const progress = {
      user_id: this.patientId,
      week_number: weekNumber,
      updated_time: formattedTime,
    };

    this.courseService.updateExceptTime(progress).subscribe({
      next: () => {
        if (weekNumber === 1) {
          this.week1ExceptedTime = updatedTime;
          this.updateSuccess1 = true;
        } else if (weekNumber === 2) {
          this.week2ExceptedTime = updatedTime;
          this.updateSuccess2 = true;
        } else if (weekNumber === 3) {
          this.week3ExceptedTime = updatedTime;
          this.updateSuccess3 = true;
        } else if (weekNumber === 4) {
          this.week4ExceptedTime = updatedTime;
          this.updateSuccess4 = true;
        } else if (weekNumber === 5) {
          this.week5ExceptedTime = updatedTime;
          this.updateSuccess5 = true;
        } else if (weekNumber === 6) {
          this.week5CompletionTime = updatedTime;
          this.updateSuccess6 = true;
        }
      },
      error: (error) => {
        if (weekNumber === 1) {
          this.updateError1 = 'Failed to update excepted time.';
          this.updateSuccess1 = false;
        } else if (weekNumber === 2) {
          this.updateError2 = 'Failed to update excepted time.';
          this.updateSuccess2 = false;
        } else if (weekNumber === 3) {
          this.updateError3 = 'Failed to update excepted time.';
          this.updateSuccess3 = false;
        } else if (weekNumber === 4) {
          this.updateError4 = 'Failed to update excepted time.';
          this.updateSuccess4 = false;
        } else if (weekNumber === 5) {
          this.updateError5 = 'Failed to update excepted time.';
          this.updateSuccess5 = false;
        } else if (weekNumber === 6) {
          this.updateError6 = 'Failed to update excepted time.';
          this.updateSuccess6 = false;
        }
      },
    });
  }

  formatDateTime(date: Date): string {
    return format(date, 'yyyy-MM-dd HH:mm:ss'); // Format for Django backend
  }

  getDailySleepTime(totalSleepTime): string {
    const totalSleepInMinutes = Number(totalSleepTime);

    const dailyHours = Math.floor(totalSleepInMinutes / 7 / 60);
    const dailyMinutes = Math.round((totalSleepInMinutes / 7) % 60);

    return dailyMinutes === 0 && dailyHours === 0
      ? ''
      : `${dailyHours}h ${dailyMinutes}min`;
  }

  getDesiredSleepTime(totalSleepTime): string {
    const totalSleepInMinutes = Number(totalSleepTime);

    const dailyHours = Math.floor(totalSleepInMinutes / 60);
    const dailyMinutes = Math.round(totalSleepInMinutes % 60);

    return dailyMinutes === 0 && dailyHours === 0
      ? ''
      : `${dailyHours}h ${dailyMinutes}min`;
  }

  parseMomentDate(modifiedDate: string) {
    if (modifiedDate == null) {
      return '-';
    }

    try {
      return moment(modifiedDate).format('M-D-YYYY');
    } catch (error) {
      return '-';
    }
  }

  parseMomentDateTime(modifiedDate: string) {
    if (modifiedDate == null) {
      return '-';
    }

    try {
      return moment(modifiedDate).format('M-D-YYYY h:mm A');
    } catch (error) {
      return '-';
    }
  }

  parseMomentUpdatedDateTime(modifiedDate: string) {
    if (modifiedDate == null) {
      return '';
    }

    try {
      return moment(modifiedDate).format('M-D-YYYY h:mm A');
    } catch (error) {
      return '';
    }
  }

  formatTimeWithMoment(time: string): string {
    if (!time) return '-';

    const timeString = `1970-01-01T${time}`;
    return moment(timeString).format('h:mm A');
  }

  parseMomentStartYM(modifiedDate: string) {
    if (modifiedDate == null) {
      return '';
    }

    try {
      return `${moment(modifiedDate).format('M-D')} -`;
    } catch (error) {
      return '';
    }
  }

  parseMomentEndYM(modifiedDate: string) {
    if (modifiedDate == null) {
      return '';
    }

    try {
      return moment(modifiedDate).format('M-D');
    } catch (error) {
      return '';
    }
  }

  addDayAndFormat(dateString: string, dayNumber: number): string {
    if (dateString == null) {
      return '';
    }

    try {
      if (dayNumber === 1) {
        return `${moment(dateString).add(dayNumber, 'day').format('M-D')} -`;
      }
      return moment(dateString).add(dayNumber, 'day').format('M-D');
    } catch (error) {
      return '';
    }
  }

  // saveSuggestion(week_number: number, suggestion: string) {
  //   const data = {
  //     week_number: week_number,
  //     suggestion: suggestion || '',
  //     id: this.patientId,
  //   };

  //   this.courseService.updateDoctorSuggestion(data).subscribe();
  // }

  sendSuggestion() {
    const newReflectData = [
      { week_number: 1, doctor_suggestion: this.suggestion1 },
      { week_number: 2, doctor_suggestion: this.suggestion2 },
      { week_number: 3, doctor_suggestion: this.suggestion3 },
      { week_number: 4, doctor_suggestion: this.suggestion4 },
      { week_number: 5, doctor_suggestion: this.suggestion5 },
      { week_number: 6, doctor_suggestion: this.finalSuggestion },
    ].filter((item) => item.doctor_suggestion);
    const data = {
      first_name: this.courseDetails.first_name,
      last_name: this.courseDetails.last_name,
      email: this.courseDetails.email,
      reflect_info: newReflectData,
    };
    this.emailService.sendSuggestionNotificattion(data).subscribe({
      next: () => {
        this.message = 'Your email has been sent successfully!';
      },
      error: (err) => {
        this.message = `Error sending code: ${
          err.error.detail || 'Unexpected error occurred.'
        }`;
      },
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
