<div class="container p-4 container-custom outer">
  <h1
    style="
      color: #012233;
      margin-bottom: 10px !important;
      font-size: 35px !important;
    "
  >
    <b
      >{{ "INTRO_P1_DEAR" | translate }} {{ account["user"]["first_name"] }},
    </b>
  </h1>
  <h4 style="color: #002e46; font-size: 18px">
    <br />
    <br />
    <!-- <b>Welcome to our CBT-I Program!</b> -->
    <p>
      {{ "INTRO_P1_WELCOME" | translate }}
    </p>
    <br />
    <br />
    <p>
      {{ "INTRO_P1_INTRODUCTION" | translate }}
    </p>

    <br />
    <br />
  </h4>
  <!-- <button class="next-button" (click)="onNextClick()">Next &rarr;</button> -->
  <div class="form-group text-center">
    <button class="btn btn-primary field mx-auto col-3" (click)="onNextClick()">
      {{ "NEXT" | translate }}
    </button>
  </div>
</div>
