
    <input matInput type="number"  readonly class="valueInput" placeholder="Value"
      [(value)]="hours*60 + mins"  (click)="showSlider()" >

      <div class="duration-container"  *ngIf="!hidden">

          <div class="row d-flex flex-row mt-3">
              <div class="col"></div>
            <div class="col clock-div">
              {{hours | number:'2.0'}}
            </div>
            <div class="col colon-div">:</div>
            <div class="col clock-div">
              {{mins}}
            </div>
            <div class="col"></div>

          </div>
          <div class="row d-flex flex-column pr-4 pl-4">
            <mat-slider min="0" max="12" step="1" [(value)]="hours"></mat-slider>
            <mat-label class="clock-label">HOURS</mat-label>
            <mat-slider min="0" max="60" step="1" [(value)]="mins"></mat-slider>
            <mat-label class="clock-label">MINUTES</mat-label>
          </div>

        </div>
