<div class="app-container min-vh-100" [ngClass]="{'bg-light': hasRoute('onboarding')}">
    <!-- main nav -->
    <!-- <nav class="navbar navbar-expand navbar-dark bg-dark" *ngIf="account">
        <div class="navbar-nav">
            <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                class="nav-item nav-link">Home</a>
            <a routerLink="/profile" routerLinkActive="active" class="nav-item nav-link">Profile</a>
            <a *ngIf="account.role === Role.Admin" routerLink="/admin" routerLinkActive="active"
                class="nav-item nav-link">Admin</a>
            <a (click)="logout()" class="nav-item nav-link">Logout</a>
        </div>
    </nav> -->
	<nav id="mobile-menu" class="navbar navbar-expand-lg navbar-dark bg-dark" style="background-color : #002e46 !important;">
  <a class="navbar-brand" href="#"><img class="menu-logo d-flex" src="../assets/logo2.png" style="width : auto;" /></a>
  <button class="navbar-toggler pull-right" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
                             <li class="nav-item">
                                <i class="f-icons f-home"></i>
                                <a class="nav-link active" aria-current="page" routerLink="/"
                                    routerLinkActive="active">Dashboard</a>
                            </li>
                            <li class="nav-item">
                                <i class="f-icons f-pencil"></i>
                                <a class="nav-link" href="#" routerLink="/journal" routerLinkActive="active">Sleep
                                    Journal</a>
                            </li>
                            <li class="nav-item">
                                <i class="f-icons f-list"></i>
                                <a class="nav-link" routerLink="/educate">Sleep Education</a>
                            </li>
                            <li class="nav-item">
                                <i class="f-icons f-action"></i>
                                <a class="nav-link" href="#" routerLink="/plan">Action Plan</a>
                            </li>
                            <li class="nav-item">
                                <i class="f-icons f-moon"></i>
                                <a class="nav-link" href="#" routerLink="/calculator">Sleep Calculator</a>
                            </li>
                            <li class="nav-item">
                                <i class="f-icons f-calculator"></i>
                                <a class="nav-link" href="#" routerLink="/diary">My Progress</a>
                            </li>
							<li class="nav-item" style="">
                                <i class="f-icons f-cbti"></i>
                                <a class="nav-link" href="#" routerLink="/cbti">What is CBT-I?</a>
                            </li>

                            <li class="nav-item">
                                <i class="f-icons f-contact-us"></i>
                                <a class="nav-link" href="#" routerLink="/contact">Contact us</a>
                            </li>

                            <li class="nav-item">
                                <i class="f-icons f-logout"></i>
                                <a class="nav-link" (click)="logout()" href="#">Logout</a>
                            </li>
    </ul>
  </div>
</nav>
    <div class="row ml-0 mr-0">
        <div id="deskstop-menu" class="col-3" *ngIf="(account || accountsbs) && !hasRoute('onboarding')">

            <nav class="nav flex-column" *ngIf="account || accountsbs">
                <div class="row menu-header">
                    <div class="col d-flex justify-content-center pr-0">
                        <img class="menu-logo d-flex" src="../assets/logo2.png" style="width : 250px; height : auto;" />
                    </div>
             

                </div>
                <div class="row d-flex justify-content-center">
                    <ul *ngIf="this.accountService.Role===userEnum; else elseBlock" class="nav flex-column p-4 mt-4 justify-content-between" style="min-height: 70vh;">
                        <div class="upper">
                            <li class="nav-item">
                                <i class="f-icons f-home"></i>
                                <a class="nav-link active" aria-current="page" routerLink="/"
                                    routerLinkActive="active">Dashboard</a>
                            </li>
                            <li class="nav-item">
                                <i class="f-icons f-book"></i>
                                <a class="nav-link" href="assets/work_book.pdf" download>Sleep
                                    Workbook</a>
                            </li>							
                            <li class="nav-item">
                                <i class="f-icons f-pencil"></i>
                                <a class="nav-link" href="#" routerLink="/journal" routerLinkActive="active">Sleep
                                    Journal</a>
                            </li>
                            <li class="nav-item">
                                <i class="f-icons f-list"></i>
                                <a class="nav-link" routerLink="/educate">Sleep Education</a>
                            </li>

                            <li class="nav-item">
                                <i class="f-icons f-moon"></i>
                                <a class="nav-link" href="#" routerLink="/calculator">Sleep Calculator</a>
                            </li>
                            <li class="nav-item">
                                <i class="f-icons f-calculator"></i>
                                <a class="nav-link" href="#" routerLink="/diary">My Progress</a>
                            </li>
                            <li class="nav-item">
                                <i class="f-icons f-action"></i>
                                <a class="nav-link" href="#" routerLink="/plan">Self Management Tips</a>
                            </li>							
                        </div>
                        <!--  -->
                        <div class="bottom">
                            <li class="nav-item" style="">
                                <i class="f-icons f-cbti"></i>
                                <a class="nav-link" href="#" routerLink="/cbti">What is CBT-I?</a>
                            </li>

                            <li class="nav-item">
                                <i class="f-icons f-contact-us"></i>
                                <a class="nav-link" href="#" routerLink="/contact">Contact us</a>
                            </li>

                            <li class="nav-item">
                                <i class="f-icons f-logout"></i>
                                <a class="nav-link" (click)="logout()" href="#">Logout</a>
                            </li>
                        </div>
                    </ul>
                    <ng-template #elseBlock>
                        <ul class="nav flex-column p-4 mt-4">
                            <li class="nav-item">
                                <i class="f-icons f-home"></i>
                                <a class="nav-link active" aria-current="page" routerLink="/dashboard"
                                    routerLinkActive="active">Dashboard</a>
                            </li>
                            <li class="nav-item">
                                <i class="f-icons f-pencil"></i>
                                <a class="nav-link" href="#" routerLink="/add-patient" routerLinkActive="active">Add
                                    Patient</a>
                            </li>
                            <li class="nav-item">
                                <i class="f-icons f-pencil"></i>
                                <a class="nav-link" href="#" routerLink="/administrator"
                                    routerLinkActive="active">Administrator</a>
                            </li>
                            <li class="nav-item">
                                <i class="f-icons f-action"></i>
                                <a class="nav-link" (click)="logout()" href="#">Logout</a>
                            </li>
                        </ul>
                    </ng-template>
                </div>
            </nav>
        </div>
        <!-- subnav router outlet -->
        <router-outlet name="subnav"></router-outlet>

        <!-- global alert -->
        <alert style="position: fixed; right: 0; left: 0; z-index: 9999;"></alert>

        <!--Tour Template-->
        <tour-step-template>



        </tour-step-template>

        <!-- main router outlet -->
        <div class="col small_devices"
            [ngClass]="{'mt-5': !hasRoute('onboarding') && !startsWithRoute('account/login') && !startsWithRoute('account/register')}">
            <router-outlet></router-outlet>
        </div>
        <div id="right-nav" class="col-2" *ngIf="(account || accountsbs) && !hasRoute('onboarding')">
		   <div class="row" *ngIf="hasRoute('educate')">test</div>
		
		
		</div>
    </div>

</div>