import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '@app/_services';
import { CourseService } from '@app/_services/course.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { MissingJournalDialogComponent } from '@app/dialog/missing-journal-dialog/missing-journal-dialog.component';
import { utcToZonedTime } from 'date-fns-tz';
import { LongTimeConfirmDialogComponent } from '@app/dialog/long-time-confirm-dialog/long-time-confirm-dialog.component';
import { AppComponent } from '@app/app.component';

@Component({
  selector: 'app-week1-page5',
  templateUrl: 'week1-page5.component.html',
  styleUrls: ['week1-page5.style.scss'],
})
export class Week1Page5Component implements OnInit {
  videoSrc: string = '';
  isVideoEnded: boolean = false;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private courseService: CourseService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private appComponent: AppComponent
  ) {}

  ngOnInit(): void {
    const savedLang = localStorage.getItem('selectedLanguage');
    if (savedLang) {
      this.translate.use(savedLang);
    } else {
      this.translate.use(this.translate.defaultLang);
    }

    this.translate.onLangChange.subscribe((event) => {
      localStorage.setItem('selectedLanguage', event.lang);
      // this.setVideoSource();
    });
  }

  // setVideoSource(): void {
  //   const currentLang = this.translate.currentLang;

  //   if (currentLang === 'sch' || currentLang === 'tch') {
  //     this.videoSrc = 'assets/videos/Sleep_Environment_Chi.mp4';
  //   } else {
  //     this.videoSrc = 'assets/videos/Sleep_Environment_En.mp4';
  //   }
  //   this.cdr.detectChanges();
  // }

  // onVideoEnded(): void {
  //   this.isVideoEnded = true;
  //   localStorage.setItem('isW1P3VideoWatched', 'true');
  // }

  onNextClick() {
    // this.courseService.getCourseTimes().then(
    //   (response) => {
    //     const timeZone = 'America/Vancouver';
    //     const lastUpdateTimeVancouver = utcToZonedTime(
    //       response.lastUpdateTime,
    //       timeZone
    //     );
    //     const dayDifference = this.courseService.getDayDuration(
    //       new Date(),
    //       lastUpdateTimeVancouver,
    //       timeZone
    //     );

    //     // The difference between current_date and last_update_date is at least 7 days
    //     if (dayDifference >= 7) {
    //       const dialogRef = this.dialog.open(LongTimeConfirmDialogComponent, {
    //         disableClose: true,
    //       });
    //       dialogRef.afterClosed().subscribe((response) => {
    //         if (response == 'continue') {
    //           this.router.navigate(['/journal']);
    //         } else if (response == 'reset') {
    //           this.router.navigate(['/week1/track_your_sleep']);
    //         }
    //       });
    //     } else {
    //       // The difference between current_date and last_update_date is less than 7 days
    //       this.courseService.checkJournalEntries().subscribe((result) => {
    //         let missingDates: string[] = [];
    //         let filledDates: string[] = [];

    //         switch (result.success) {
    //           case 1:
    //             // The difference between current_date and week1_expected_unlock_time is less than 7 days and
    //             // there are incomplete Sleep Journals
    //             missingDates = result.missingDates;
    //             filledDates = result.filledDates;
    //             const dialogRef1 = this.dialog.open(
    //               MissingJournalDialogComponent,
    //               {
    //                 data: {
    //                   missingDates: missingDates,
    //                   flg: 1,
    //                   date: result.endDate,
    //                 },
    //               }
    //             );
    //             dialogRef1.afterClosed().subscribe((result) => {
    //               if (result === 'confirm') {
    //                 this.router.navigate(['/journal']);
    //               }
    //             });
    //             break;
    //           case 2:
    //             // The difference between current_date and week1_expected_unlock_time is less than 7 days and
    //             // there are no incomplete Sleep Journals
    //             filledDates = result.filledDates;
    //             const dialogRef2 = this.dialog.open(
    //               MissingJournalDialogComponent,
    //               {
    //                 data: {
    //                   missingDates: missingDates,
    //                   flg: 2,
    //                   date: result.endDate,
    //                 },
    //               }
    //             );
    //             dialogRef2.afterClosed().subscribe((result) => {
    //               if (result === 'confirm') {
    //                 this.router.navigate(['/diary']);
    //               }
    //             });
    //             break;
    //           case 3:
    //             // The difference between current_date and week1_expected_unlock_time is at lease 7 days and
    //             // there are incomplete Sleep Journals
    //             missingDates = result.missingDates;
    //             filledDates = result.filledDates;

    //             const dialogRef3 = this.dialog.open(
    //               MissingJournalDialogComponent,
    //               {
    //                 data: {
    //                   missingDates: missingDates,
    //                   flg: 3,
    //                 },
    //               }
    //             );
    //             dialogRef3.afterClosed().subscribe((result) => {
    //               if (result === 'confirm') {
    //                 this.router.navigate(['/journal']); // navigate to week1
    //               }
    //             });

    //             break;

    //           case 4:
    //             // The difference between current_date and week1_expected_unlock_time is at lease 7 days and
    //             // there are not incomplete Sleep Journals
    //             this.courseService.unlockWeek(1);
    //             this.courseService.unlockWeek2Content(0);
    //             this.courseService.updateProgress(2, 1);

    //             this.router.navigate(['week2/weekly_check_in_2']);
    //             break;
    //         }
    //       });
    //     }
    //   },
    //   (error) => {
    //     console.log('Userprogress not found!');
    //   }
    // );

    this.courseService.getCourseTimes().then(
      (response) => {
        if (response.currentWeek === 1) {
          const timeZone = 'America/Vancouver';
          const lastUpdateTimeVancouver = utcToZonedTime(
            response.lastUpdateTime,
            timeZone
          );
          const dayDifference = this.courseService.getDayDuration(
            new Date(),
            lastUpdateTimeVancouver,
            timeZone
          );
          this.courseService.checkUserProgress(1, dayDifference, true);
        } else {
          this.router.navigate(['week2/weekly_check_in']);
        }
      },
      (error) => {
        console.log('Userprogress not found!');
      }
    );
    // this.courseService.checkUserProgress(1);
  }
}
