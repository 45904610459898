import { Component, OnInit } from '@angular/core';
import { PatientService } from '@app/_services/patient.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ContactService } from '@app/_services/contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.style.scss'],
})
export class ContactComponent implements OnInit {
  contacts: any[] = [];
  searchName: string = '';
  filterProcessed: string = '';

  searchString = '';
  activeSort = '';
  sortOrder: 'asc' | 'desc' = 'asc';

  limit: number = 20;
  offset: number = 0;
  totalCount: number = 0;
  totalPages: number = 0;
  hasMoreData: boolean = false;
  currentPage: number = 1;

  constructor(
    public patientService: PatientService,
    private contactService: ContactService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.patientService.resetPaginationDetails();
    this.loadContacts();
  }

  loadContacts() {
    this.contactService
      .getAllContacts(
        this.searchName,
        this.filterProcessed,
        this.limit,
        this.offset,
        this.activeSort || 'first_name',
        this.sortOrder
      )
      .subscribe((response) => {
        console.log('response:', response);
        this.contacts = response.results;
        this.totalCount = response.count;
        this.totalPages = Math.ceil(this.totalCount / this.limit);
        this.hasMoreData = response.next !== null;
        this.currentPage = Math.floor(this.offset / this.limit) + 1;
      });
  }

  onSearch(): void {
    this.offset = 0;
    this.loadContacts();
  }

  sortData(field: string) {
    if (this.activeSort === field) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.activeSort = field;
      this.sortOrder = 'asc';
    }

    this.contactService
      .getAllContacts(
        this.searchName,
        this.filterProcessed,
        this.limit,
        this.offset,
        this.activeSort || 'first_name',
        this.sortOrder
      )
      .subscribe((response) => {
        this.contacts = response.results;
      });
  }

  getSortIcon(field: string) {
    if (this.activeSort === field) {
      return this.sortOrder === 'asc' ? 'fa-sort-up' : 'fa-sort-down';
    }
    return 'fa-sort';
  }

  previousPage() {
    if (this.offset > 0) {
      this.offset -= this.limit;
      this.loadContacts();
    }
  }

  nextPage() {
    this.offset += this.limit;
    this.loadContacts();
  }

  parseMomentDate(modifiedDate: string) {
    if (modifiedDate == null) {
      return '-';
    }

    try {
      return moment(modifiedDate).format('M-D-YYYY h:mm A');
    } catch (error) {
      return '-';
    }
  }

  goToContactDetails(id: number) {
    this.router.navigate([`/contactadmin-details/${id}`]);
  }
}
