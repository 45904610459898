import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from '@app/_services';
import { CourseService } from '@app/_services/course.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-week1-page4',
  templateUrl: 'week1-page4.component.html',
  styleUrls: ['week1-page4.style.scss'],
})
export class Week1Page4Component implements OnInit {
  form: FormGroup;
  formError = false;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '100px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    placeholder: 'Enter text here ...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
        'strikeThrough',
        'subscript',
        'superscript',
        'heading',
        'fontName',
        'backgroundColor',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'insertImage',
        'insertVideo',
        'insertLink',
        'toggleEditorMode',
      ],
    ],
  };

  constructor(
    private router: Router,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private courseService: CourseService,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      week_number: ['1'],
      self_assessment_text: ['', Validators.required],
      reflection_text: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    const savedLang = localStorage.getItem('selectedLanguage');
    if (savedLang) {
      this.translate.use(savedLang);
    } else {
      this.translate.use(this.translate.defaultLang);
    }

    this.courseService.isStudied(1, 4).then((result) => {
      if (result) {
        const id: number = JSON.parse(localStorage.getItem('token')).user.id;

        this.courseService.getSelfAssessmentReflection(1).subscribe(
          (data) => {
            if (data) {
              this.form.patchValue({
                self_assessment_text: data.self_assessment_text,
                reflection_text: data.reflection_text,
              });
            }
          },
          (error) => {
            console.error(
              'Error fetching self-assessment and reflection data.',
              error
            );
          }
        );
      }
    });

    this.translate.onLangChange.subscribe((event) => {
      localStorage.setItem('selectedLanguage', event.lang);
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }

  onSubmit() {
    this.courseService.saveSelfAssessmentReflection(this.form.value).subscribe(
      (response) => {
        console.log(
          'Self-Assessment and reflection saved sucessfully',
          response
        );
      },
      (error) => {
        console.log('Self-Assessment and reflection saved failure', error);
      }
    );
    this.courseService.unlockWeek(0);
    this.courseService.unlockWeek1Content(4);
    this.courseService.updateProgress(1, 5);

    this.router.navigate(['week1/recommended_practices']);
  }
}
