import { Component, OnInit } from '@angular/core';
import { AlertService } from '@app/_services';
import { PatientService } from '@app/_services/patient.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { CourseService } from '@app/_services/course.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { HttpClient } from '@angular/common/http';
import { EmailVerificationService } from '@app/_services/email-verification.service';
import { MatDialog } from '@angular/material/dialog';
import { JournalDetailsDialogComponent } from '@app/dialog/journal/journal-details-dialog.component';
import { CourseDetailsDialogComponent } from '@app/dialog/course/course-details-dialog.component';
import { PageDetailsDialogComponent } from '@app/dialog/page/page-details-dialog.component';

@Component({
  selector: 'app-course-edit',
  templateUrl: './course-edit.component.html',
  styleUrls: ['./course-edit.style.scss'],
})
export class CourseEditComponent implements OnInit {
  patientId: number;
  courseDetails: any;
  profileData: any;
  progressData: any;

  currentWeek;
  currentPage;
  updatedWeek;
  chkMessage = '';
  updateSuccess = false;
  updateError = '';

  constructor(
    public patientService: PatientService,
    private activatedRoute: ActivatedRoute,
    private courseService: CourseService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    const userId = this.activatedRoute.snapshot.paramMap.get('id');

    this.patientId = Number(userId);
    if (userId) {
      this.patientService.getPatientCourseDetailsById(this.patientId).subscribe(
        (courseDetails) => {
          if (courseDetails) {
            this.courseDetails = courseDetails;
            console.log(this.courseDetails);

            if (courseDetails?.profile) {
              this.profileData = courseDetails.profile;
            }

            if (courseDetails?.progress) {
              this.progressData = courseDetails.progress;
              this.currentWeek = this.progressData.current_week;
              this.currentPage = this.progressData.current_page;
            }
          }
        },
        (error) => {
          console.error('Error fetching course details data.', error);
        }
      );
    }
  }

  parseMomentDateTime(modifiedDate: string) {
    if (modifiedDate == null) {
      return '-';
    }

    try {
      return moment(modifiedDate).format('M-D-YYYY h:mm A');
    } catch (error) {
      return '-';
    }
  }

  openJournalDetails() {
    const dialogRef = this.dialog.open(JournalDetailsDialogComponent, {
      width: '1200px',
      height: '800px',
      data: { userId: this.patientId },
    });
  }

  openCourseDetails() {
    const dialogRef = this.dialog.open(CourseDetailsDialogComponent, {
      width: '1200px',
      height: '800px',
      data: { userId: this.patientId },
    });
  }

  openPageDetails() {
    const dialogRef = this.dialog.open(PageDetailsDialogComponent, {
      width: '1000px',
      height: '800px',
    });
  }

  modifyCourseInfo() {
    if (!this.updatedWeek || isNaN(this.updatedWeek)) {
      this.updateError = 'Please enter a valid week number.';
      this.updateSuccess = false;
      return;
    }

    if (this.updatedWeek <= this.currentWeek) {
      this.updateError = `Please enter a week number greater than Current Week (${this.currentWeek}).`;
      this.updateSuccess = false;
      return;
    }

    if (this.updatedWeek < 1 || this.updatedWeek > 6) {
      this.updateError = 'Week number must be between 1 and 6.';
      this.updateSuccess = false;
      return;
    }
    const data = {
      userId: this.patientId,
      updatedWeek: this.updatedWeek,
    };
    this.courseService.updateWeekInfo(data).subscribe({
      next: () => {
        this.currentWeek = this.updatedWeek;
        this.currentPage = 1;
        this.updateSuccess = true;
      },
      error: (error) => {
        this.updateError = 'Failed to save progress.';
        this.updateSuccess = false;
      },
    });
  }
}
