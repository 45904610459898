<div class="container p-4 container-custom outer">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <h1
      style="
        color: #012233;
        margin-bottom: 10px !important;
        font-size: 28px !important;
      "
    >
      <b>{{ "W1_P5_ASSESSMENT_REFLECTION" | translate }}</b>
    </h1>
    <h4 style="color: #002e46; font-size: 18px">
      <br />
      <p>
        {{ "W4_VARIOUS_FACTORS" | translate }}
      </p>
      <p>
        {{ "W4_IDENTIFY_FACTORS" | translate }}
      </p>
      <br />
      <p>
        {{ "W4_LIST_NAGATIVE_FACTORS" | translate }}
      </p>
      <!-- <div [innerHTML]="safeHtmlContentNegative"></div> -->
      <!-- <br /> -->

      <angular-editor
        [config]="editorConfig"
        formControlName="self_assessment_text"
      ></angular-editor>

      <!-- <div
              *ngIf="
                f.self_assessment_text.errors.required &&
                form.get('self_assessment_text')?.touched
              "
              class="text-danger"
            >
              This field is required.
            </div> -->
      <div
        *ngIf="
          f.self_assessment_text.errors?.required &&
          f.self_assessment_text.touched
        "
        class="text-danger"
      >
        This field is required.
      </div>

      <br />
      <br />
      <p>
        {{ "W4_POSSIBLE_SOLUTIONS" | translate }}
      </p>
      <!-- <div [innerHTML]="safeHtmlContentSolutions"></div> -->

      <!-- <br /> -->
      <angular-editor
        [config]="editorConfig"
        formControlName="reflection_text"
      ></angular-editor>
      <div
        *ngIf="f.reflection_text.errors?.required && f.reflection_text.touched"
        class="text-danger"
      >
        {{ "FIELD_REQUIRED" | translate }}
      </div>
      <br />
      <div class="form-group text-center">
        <button
          class="btn btn-primary field mx-auto col-3"
          type="submit"
          [disabled]="form.invalid"
        >
          {{ "NEXT" | translate }}
        </button>
      </div>
    </h4>
  </form>
</div>
