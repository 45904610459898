import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-step3',
  templateUrl: './step3.component.html',
  styleUrls: ['../onboarding.component.less']
})
export class Step3Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
