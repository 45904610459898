import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CourseService } from '@app/_services/course.service';
import { TranslateService } from '@ngx-translate/core';
import { utcToZonedTime } from 'date-fns-tz';

@Component({
  selector: 'app-week5-page5',
  templateUrl: './week5-page5.component.html',
  styleUrls: ['./week5-page5.style.scss'],
})
export class Week5Page5Component implements OnInit {
  constructor(
    private router: Router,
    private translate: TranslateService,
    private courseService: CourseService
  ) {}

  ngOnInit(): void {
    const savedLang = localStorage.getItem('selectedLanguage');
    if (savedLang) {
      this.translate.use(savedLang);
    } else {
      this.translate.use(this.translate.defaultLang);
    }

    // Paremeter(5): Indicates the number of the current week
    // Paremeter(4): Indicates the number of the current page
    this.courseService.isStudied(5, 5).then((result) => {
      if (result) {
        const id: number = JSON.parse(localStorage.getItem('token')).user.id;
      }
    });

    this.translate.onLangChange.subscribe((event) => {
      localStorage.setItem('selectedLanguage', event.lang);
    });
  }

  onNextClick() {
    this.courseService.getCourseTimes().then(
      (response) => {
        // 4: Indicates the number of the current week
        if (response.currentWeek === 5) {
          const timeZone = 'America/Vancouver';
          const lastUpdateTimeVancouver = utcToZonedTime(
            response.lastUpdateTime,
            timeZone
          );
          const dayDifference = this.courseService.getDayDuration(
            new Date(),
            lastUpdateTimeVancouver,
            timeZone
          );
          // Paremeter(5): Indicates the number of the current week
          this.courseService.checkUserProgress(5, dayDifference, true);
        } else {
          this.router.navigate(['week5/review_course']);
        }
      },
      (error) => {
        console.log('Userprogress not found!');
      }
    );
    // this.courseService.unlockWeek(4);

    // //  Parameter (5): The index starts from 0, unlock the third page of the current week
    // this.courseService.unlockWeek5Content(5);

    // //  Parameter (5): Indicates the number of the current week.
    // //  Parameter (6): Indicates the fifth page of the current week.
    // this.courseService.updateProgress(5, 6);

    // this.router.navigate(['week5/review_course']);
  }
}
