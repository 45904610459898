import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { CbtiComponent } from './cbti/cbti.component';
import { ContactusComponent } from './contactus/contactus.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AddPatientComponent } from './dashboard/patient/patient.component';
import { PatientProfileComponent } from './dashboard/patient-profile/patient-profile.component';
import { PatientProfileUpdateComponent } from './dashboard/patient-profile-update/patient-profile-update.component';
import { AdministratorComponent } from './dashboard/administrator/administrator.component';

import { HomeComponent } from './home';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { AuthGuard } from './_helpers';
import { Role } from './_models';
import { AddAdminComponent } from './dashboard/add-admin/add-admin.component';
import { DiaryComponent } from './diary/diary.component';

import { IntroComponent } from './intro/intro.component';
import { IntroPage1Component } from './intro/intro-page1/intro-page1.component';
import { IntroPage2Component } from './intro/intro-page2/intro-page2.component';
import { IntroPage3Component } from './intro/intro-page3/intro-page3.component';

import { Week1Component } from './week1/week1.component';
import { Week1Page1Component } from './week1/week1-page1/week1-page1.component';
import { Week1Page2Component } from './week1/week1-page2/week1-page2.component';
import { Week1Page3Component } from './week1/week1-page3/week1-page3.component';
import { Week1Page4Component } from './week1/week1-page4/week1-page4.component';
import { Week1Page5Component } from './week1/week1-page5/week1-page5.component';

import { Week2Component } from './week2/week2.component';
import { Week2Page1Component } from './week2/week2-page1/week2-page1.component';
import { Week2Page2Component } from './week2/week2-page2/week2-page2.component';
import { Week2Page3Component } from './week2/week2-page3/week2-page3.component';
import { Week2Page4Component } from './week2/week2-page4/week2-page4.component';
import { Week2Page5Component } from './week2/week2-page5/week2-page5.component';
import { Week2Page6Component } from './week2/week2-page6/week2-page6.component';
import { Week2Page7Component } from './week2/week2-page7/week2-page7.component';
import { Week2Page8Component } from './week2/week2-page8/week2-page8.component';

import { Week3Component } from './week3/week3.component';
import { Week3Page1Component } from './week3/week3-page1/week3-page1.component';
import { Week3Page2Component } from './week3/week3-page2/week3-page2.component';
import { Week3Page3Component } from './week3/week3-page3/week3-page3.component';
import { Week3Page4Component } from './week3/week3-page4/week3-page4.component';
import { Week3Page5Component } from './week3/week3-page5/week3-page5.component';

import { Week4Component } from './week4/week4.component';
import { Week4Page1Component } from './week4/week4-page1/week4-page1.component';
import { Week4Page2Component } from './week4/week4-page2/week4-page2.component';
import { Week4Page3Component } from './week4/week4-page3/week4-page3.component';
import { Week4Page4Component } from './week4/week4-page4/week4-page4.component';
import { Week4Page5Component } from './week4/week4-page5/week4-page5.component';

import { Week5Component } from './week5/week5.component';
import { Week5Page1Component } from './week5/week5-page1/week5-page1.component';
import { Week5Page2Component } from './week5/week5-page2/week5-page2.component';
import { Week5Page3Component } from './week5/week5-page3/week5-page3.component';
import { Week5Page4Component } from './week5/week5-page4/week5-page4.component';
import { Week5Page5Component } from './week5/week5-page5/week5-page5.component';
import { Week5Page6Component } from './week5/week5-page6/week5-page6.component';

const accountModule = () =>
  import('./account/account.module').then((x) => x.AccountModule);
const adminModule = () =>
  import('./admin/admin.module').then((x) => x.AdminModule);
const profileModule = () =>
  import('./profile/profile.module').then((x) => x.ProfileModule);
const journalModule = () =>
  import('./journal/journal.module').then((x) => x.JournalModule);

const diaryModule = () =>
  import('./diary/diary.module').then((x) => x.DiaryModule);

const educateModule = () =>
  import('./educate/educate.module').then((x) => x.EducateModule);

const calculatorModule = () =>
  import('./calculator/calculator.module').then((x) => x.CalculatorModule);

const homeModule = () => import('./home/home.module').then((x) => x.HomeModule);

const landingModule = () =>
  import('./landing/landing.module').then((x) => x.LandingModule);
const planModule = () => import('./plan/plan.module').then((x) => x.PlanModule);

// const addPatientModule = () =>
//   import('./dashboard/dashboard.module').then((x) => x.DashboardModule);

const routes: Routes = [
  // { path: '', loadChildren: homeModule, canActivate: [AuthGuard] },
  {
    path: '',
    component: IntroComponent,
    children: [
      {
        path: '',
        redirectTo: 'welcome',
        pathMatch: 'full',
        canActivate: [AuthGuard],
      },
      {
        path: 'welcome',
        component: IntroPage1Component,
        canActivate: [AuthGuard],
      },
      {
        path: 'introduction',
        component: IntroPage2Component,
        canActivate: [AuthGuard],
      },
      {
        path: 'recommended_time_line',
        component: IntroPage3Component,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'week1',
    component: Week1Component,
    children: [
      {
        path: 'track_your_sleep',
        component: Week1Page1Component,
        canActivate: [AuthGuard],
      },
      // {
      //   path: 'journal',
      //   component: Week1Page2Component,
      //   canActivate: [AuthGuard],
      // },
      {
        path: 'prepare_for_optimal_sleep',
        component: Week1Page2Component,
        canActivate: [AuthGuard],
      },
      {
        path: 'take_action',
        component: Week1Page3Component,
        canActivate: [AuthGuard],
      },
      {
        path: 'self_assessment_and_reflection',
        component: Week1Page4Component,
        canActivate: [AuthGuard],
      },
      {
        path: 'recommended_practices',
        component: Week1Page5Component,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'week2',
    component: Week2Component,
    children: [
      {
        path: 'weekly_check_in',
        component: Week2Page1Component,
        canActivate: [AuthGuard],
      },
      {
        path: 'review_sleep_journal',
        component: Week2Page2Component,
        canActivate: [AuthGuard],
      },
      {
        path: 'time_in_bed_restriction_calculator',
        component: Week2Page3Component,
        canActivate: [AuthGuard],
      },
      {
        path: 'understanding_sleep',
        component: Week2Page4Component,
        canActivate: [AuthGuard],
      },
      {
        path: 'facts_about_sleep',
        component: Week2Page5Component,
        canActivate: [AuthGuard],
      },
      {
        path: 'sleep_as_we_age',
        component: Week2Page6Component,
        canActivate: [AuthGuard],
      },
      {
        path: 'self_assessment_and_reflection',
        component: Week2Page7Component,
        canActivate: [AuthGuard],
      },
      {
        path: 'plan_for_the_week',
        component: Week2Page8Component,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'week3',
    component: Week3Component,
    children: [
      {
        path: 'weekly_check_in',
        component: Week3Page1Component,
        canActivate: [AuthGuard],
      },
      {
        path: 'review_sleep_journal',
        component: Week3Page2Component,
        canActivate: [AuthGuard],
      },
      {
        path: 'what_controls_sleep',
        component: Week3Page3Component,
        canActivate: [AuthGuard],
      },
      {
        path: 'self_assessment_and_reflection',
        component: Week3Page4Component,
        canActivate: [AuthGuard],
      },
      {
        path: 'plan_for_the_week',
        component: Week3Page5Component,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'week4',
    component: Week4Component,
    children: [
      {
        path: 'weekly_check_in',
        component: Week4Page1Component,
        canActivate: [AuthGuard],
      },
      {
        path: 'review_sleep_journal',
        component: Week4Page2Component,
        canActivate: [AuthGuard],
      },
      {
        path: 'emotions_and_physical_health',
        component: Week4Page3Component,
        canActivate: [AuthGuard],
      },
      {
        path: 'self_assessment_and_reflection',
        component: Week4Page4Component,
        canActivate: [AuthGuard],
      },
      {
        path: 'plan_for_the_week',
        component: Week4Page5Component,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'week5',
    component: Week5Component,
    children: [
      {
        path: 'weekly_check_in',
        component: Week5Page1Component,
        canActivate: [AuthGuard],
      },
      {
        path: 'review_sleep_journal',
        component: Week5Page2Component,
        canActivate: [AuthGuard],
      },
      {
        path: 'what_perpetuates_insomnia',
        component: Week5Page3Component,
        canActivate: [AuthGuard],
      },
      {
        path: 'self_assessment_and_reflection',
        component: Week5Page4Component,
        canActivate: [AuthGuard],
      },
      {
        path: 'self_management_and_relapse_prevention',
        component: Week5Page5Component,
        canActivate: [AuthGuard],
      },
      {
        path: 'review_course',
        component: Week5Page6Component,
        canActivate: [AuthGuard],
      },
    ],
  },

  { path: 'account', loadChildren: accountModule },
  { path: 'account/login', loadChildren: accountModule },
  { path: 'profile', loadChildren: profileModule, canActivate: [AuthGuard] },
  {
    path: 'calculator',
    loadChildren: calculatorModule,
    canActivate: [AuthGuard],
  },
  { path: 'journal', loadChildren: journalModule, canActivate: [AuthGuard] },
  { path: 'diary', loadChildren: diaryModule, canActivate: [AuthGuard] },
  { path: 'contact', component: ContactusComponent },
  { path: 'cbti', component: CbtiComponent, canActivate: [AuthGuard] },

  {
    path: 'onboarding',
    component: OnboardingComponent,
    canActivate: [AuthGuard],
  },
  { path: 'educate', loadChildren: educateModule, canActivate: [AuthGuard] },
  { path: 'plan', loadChildren: planModule, canActivate: [AuthGuard] },
  {
    path: 'admin',
    loadChildren: adminModule,
    canActivate: [AuthGuard],
    data: { roles: [Role.User] },
  },
  {
    path: 'dashboard',
    // loadChildren: diaryModule,
    // children: [
    //   {
    //       path: 'api-dashboard',
    //       component: DiaryComponent
    //   }] ,
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] },
  },
  {
    path: 'add-patient',
    // loadChildren: diaryModule,
    //  children: [
    //   {
    //       path: 'api-dashboard',
    //       component: DiaryComponent
    //   }] ,
    component: AddPatientComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] },
  },
  {
    path: 'patient-profile/:id',
    // loadChildren: diaryModule,
    //  children: [
    //   {
    //       path: 'api-dashboard',
    //       component: DiaryComponent
    //   }] ,
    component: PatientProfileComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] },
  },
  {
    path: 'patient-profile-update/:id',
    // loadChildren: diaryModule,
    //  children: [
    //   {
    //       path: 'api-dashboard',
    //       component: DiaryComponent
    //   }] ,
    component: PatientProfileUpdateComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] },
  },
  {
    path: 'administrator',
    // loadChildren: diaryModule,
    //  children: [
    //   {
    //       path: 'api-dashboard',
    //       component: DiaryComponent
    //   }] ,
    component: AdministratorComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] },
  },
  {
    path: 'add-admin',
    // loadChildren: diaryModule,
    //  children: [
    //   {
    //       path: 'api-dashboard',
    //       component: DiaryComponent
    //   }] ,
    component: AddAdminComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] },
  },
  // otherwise redirect to home
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      { preloadingStrategy: PreloadAllModules }
      // { relativeLinkResolution: 'legacy' }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
