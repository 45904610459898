import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CourseComponent } from './course.component';
import { CourseDetailsComponent } from './details/course-details.component';
import { CourseEditComponent } from './edit/course-edit.component';

import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { Week2Module } from '@app/week2/week2.module';
import { Week3Module } from '@app/week3/week3.module';
import { Week4Module } from '@app/week4/week4.module';
import { Week5Module } from '@app/week5/week5.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    TranslateModule.forChild(),
    Week2Module,
    Week3Module,
    Week4Module,
    Week5Module,
  ],
  declarations: [CourseComponent, CourseDetailsComponent, CourseEditComponent],
})
export class CourseModule {}
