import { Component, OnInit } from '@angular/core';
import { Steps } from './steps';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.less']
})
export class OnboardingComponent implements OnInit {

  public stateEnum = Steps;
  public state:Steps = Steps.step1;
  constructor() { }

  ngOnInit(): void {
  }

  next(): void {
    this.state += 1;
  }

  previous(): void {
    this.state -=1;
  }
}
