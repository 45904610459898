import { Component } from '@angular/core';

import { AccountService } from './_services';
import { Account, Role } from './_models';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.style.scss'],
})
export class AppComponent {
  Role: String;
  account: Account;
  userEnum = Role.User;
  adminEnum = Role.Admin;
  accountsbs;
  router;
  constructor(private accountService: AccountService, private _router: Router) {
    this.accountService.account.subscribe((x) => (this.account = x));
    this.accountsbs = this.accountService.accountValue;

    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.router = event.url;
      }
    });
  }

  ngOnInit() {
    this.accountService.fetchAccountDataFromStorage()
    this.Role = this.accountService.Role
  }

  logout() {
    this.accountService.logout();
  }
  
  /**
 * Check if the router url contains the specified route
 *
 * @param {string} route
 * @returns
 * @memberof MyComponent
 */
  hasRoute(route: string) {
    return this.router == ('/' + route);
  }

/**
* Check if the router url starts wtih the specified route
*
* @param {string} route
* @returns
* @memberof MyComponent
*/
  startsWithRoute(route: string) {
    return this.router && this.router.startsWith('/' + route);
  }
}