import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CourseService } from '@app/_services/course.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-week3-page1',
  templateUrl: './week3-page1.component.html',
  styleUrls: ['./week3-page1.style.scss'],
})
export class Week3Page1Component implements OnInit {
  form: FormGroup;
  fromError = false;
  constructor(
    private router: Router,
    private translate: TranslateService,
    private courseService: CourseService,
    private fb: FormBuilder,
    private http: HttpClient,
    public dialog: MatDialog
  ) {
    this.form = this.fb.group({
      week_number: ['3'],
      sleep_change: ['', Validators.required],
      sleep_quality: ['', Validators.required],
      daytime_functioning: ['', Validators.required],
      comments_text: ['', Validators.required],
    });
  }

  ratingOptions = [
    {
      label: '1',
      value: 1,
    },
    {
      label: '2',
      value: 2,
    },
    {
      label: '3',
      value: 3,
    },
    {
      label: '4',
      value: 4,
    },
    {
      label: '5',
      value: 5,
    },
  ];
  formError = false;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '100px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    placeholder: 'Enter text here ...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
        'strikeThrough',
        'subscript',
        'superscript',
        'heading',
        'fontName',
        'backgroundColor',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'insertImage',
        'insertVideo',
        'insertLink',
        'toggleEditorMode',
      ],
    ],
  };

  ngOnInit(): void {
    const savedLang = localStorage.getItem('selectedLanguage');
    if (savedLang) {
      this.translate.use(savedLang);
    } else {
      this.translate.use(this.translate.defaultLang);
    }

    // Paremeter(3): Indicates the number of the current week
    // Paremeter(1): Indicates the number of the current page
    this.courseService.isStudied(3, 1).then((result) => {
      if (result) {
        const id: number = JSON.parse(localStorage.getItem('token')).user.id;

        // Paremeter(4): Indicates the number of the current week
        this.courseService.getWeeklyCheckIn(3).subscribe(
          (data) => {
            if (data) {
              this.form.patchValue({
                sleep_change: data.sleep_change,
                sleep_quality: data.sleep_quality,
                daytime_functioning: data.daytime_functioning,
                comments_text: data.comments_text,
              });
            }
          },
          (error) => {
            console.error('Error fetching Weekly CheckIn Data', error);
          }
        );
      }
    });

    this.translate.onLangChange.subscribe((event) => {
      localStorage.setItem('selectedLanguage', event.lang);
    });
  }
  get f() {
    return this.form.controls;
  }

  onSubmit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.formError = true;
      return;
    }

    this.formError = false;

    this.courseService.saveWeeklyCheckIn(this.form.value).subscribe(
      (response) => {
        console.log('Weekly check in saved sucessfully', response);
      },
      (error) => {
        console.log('Weekly check in saved failure', error);
      }
    );

    this.courseService.unlockWeek(2);

    //  Parameter (1): The index starts from 0, unlock the third page of the current week
    this.courseService.unlockWeek3Content(1);

    //  Parameter (3): Indicates the number of the current week.
    //  Parameter (2): Indicates the second page of the current week.
    this.courseService.updateProgress(3, 2);

    this.router.navigate(['week3/review_sleep_journal']);
  }
}
