<h1>{{ "DIALOG_SELECT_TIME" | translate }}</h1>
<div class="time-picker">
  <div class="time-section">
    <label for="hour">Hour:</label>
    <select id="hour" [(ngModel)]="selectedHour">
      <option *ngFor="let hour of hours" [value]="hour">{{ hour }}</option>
    </select>
  </div>

  <div class="time-section">
    <label for="minute">Minute:</label>
    <select id="minute" [(ngModel)]="selectedMinute">
      <option *ngFor="let minute of minutes" [value]="minute">
        {{ minute }}
      </option>
    </select>
  </div>

  <div class="time-section">
    <label for="period">AM/PM:</label>
    <select id="period" [(ngModel)]="selectedPeriod">
      <option *ngFor="let period of periods" [value]="period">
        {{ period }}
      </option>
    </select>
  </div>
</div>
<!-- <button
      class="btn btn-primary field mx-auto col-3 ms-2"
      (click)="omReset()"
      style="min-width: 160px"
        mat-raised-button
    > -->
<div class="dialog-actions">
  <button
    class="btn btn-primary field mx-auto col-3 ms-2"
    (click)="confirmTime()"
    style="min-width: 100px"
  >
    {{ "DIALOG_OK" | translate }}
  </button>
  <button
    class="btn btn-primary field mx-auto col-3 ms-2"
    (click)="cancel()"
    style="min-width: 100px"
  >
    {{ "DIALOG_CANCEL" | translate }}
  </button>
</div>
