<div
  class="app-container min-vh-100"
  [ngClass]="{ 'bg-light': hasRoute('onboarding') }"
>
  <!-- main nav -->
  <!-- <nav class="navbar navbar-expand navbar-dark bg-dark" *ngIf="account">
        <div class="navbar-nav">
            <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                class="nav-item nav-link">Home</a>
            <a routerLink="/profile" routerLinkActive="active" class="nav-item nav-link">Profile</a>
            <a *ngIf="account.role === Role.Admin" routerLink="/admin" routerLinkActive="active"
                class="nav-item nav-link">Admin</a>
            <a (click)="logout()" class="nav-item nav-link">Logout</a>
        </div>
    </nav> -->
  <!-- <div class="language-switcher">
        <button class="btn btn-light" (click)="switchLanguage('en')">English</button>
        <button class="btn btn-light" (click)="switchLanguage('sch')">简体中文</button>
        <button class="btn btn-light" (click)="switchLanguage('tch')">繁體中文</button>
    </div> -->
  <div class="language-switcher">
    <select
      class="form-select"
      [(ngModel)]="selectedLanguage"
      (change)="switchLanguage($event.target.value)"
    >
      <option value="en" selected>English</option>
      <option value="sch">简体中文</option>
      <option value="tch">繁體中文</option>
    </select>
  </div>

  <nav
    id="mobile-menu"
    class="navbar navbar-expand-lg navbar-dark bg-dark"
    style="background-color: #002e46 !important"
  >
    <a class="navbar-brand" href="#"
      ><img
        class="menu-logo d-flex"
        src="../assets/logo2.png"
        style="width: auto"
    /></a>
    <button
      class="navbar-toggler pull-right"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <i class="f-icons f-home"></i>
          <!-- <a class="nav-link active" aria-current="page" routerLink="/"
                        routerLinkActive="active">Dashboard</a> -->
          <a
            class="nav-link active"
            aria-current="page"
            routerLink="/"
            routerLinkActive="active"
            >{{ "DASHBOARD" | translate }}</a
          >
        </li>
        <li class="nav-item">
          <i class="f-icons f-pencil"></i>
          <!-- <a class="nav-link" href="#" routerLink="/journal" routerLinkActive="active">Sleep
                        Journal</a> -->
          <a
            class="nav-link"
            href="#"
            routerLink="/journal"
            routerLinkActive="active"
            >{{ "JOURNAL" | translate }}</a
          >
        </li>
        <li class="nav-item">
          <i class="f-icons f-list"></i>
          <!-- <a class="nav-link" routerLink="/educate">Sleep Education</a> -->
          <a class="nav-link" routerLink="/educate">{{
            "EDUCATION" | translate
          }}</a>
        </li>
        <li class="nav-item">
          <i class="f-icons f-action"></i>
          <!-- <a class="nav-link" href="#" routerLink="/plan">Action Plan</a> -->
          <a class="nav-link" href="#" routerLink="/plan">{{
            "ACTIONPLAN" | translate
          }}</a>
        </li>
        <li class="nav-item">
          <i class="f-icons f-moon"></i>
          <!-- <a class="nav-link" href="#" routerLink="/calculator">Sleep Calculator</a> -->
          <a class="nav-link" href="#" routerLink="/calculator">{{
            "CALCULATOR" | translate
          }}</a>
        </li>
        <li class="nav-item">
          <i class="f-icons f-calculator"></i>
          <!-- <a class="nav-link" href="#" routerLink="/diary">My Progress</a> -->
          <a class="nav-link" href="#" routerLink="/diary">{{
            "PROGRESS" | translate
          }}</a>
        </li>
        <li class="nav-item" style="">
          <i class="f-icons f-cbti"></i>
          <!-- <a class="nav-link" href="#" routerLink="/cbti">What is CBT-I?</a> -->
          <a class="nav-link" href="#" routerLink="/cbti">{{
            "WHAT" | translate
          }}</a>
        </li>

        <li class="nav-item">
          <i class="f-icons f-contact-us"></i>
          <!-- <a class="nav-link" href="#" routerLink="/contact">Contact us</a> -->
          <a class="nav-link" href="#" routerLink="/contact">{{
            "CONTACT" | translate
          }}</a>
        </li>

        <li class="nav-item">
          <i class="f-icons f-logout"></i>
          <!-- <a class="nav-link" (click)="logout()" href="#">Logout</a> -->
          <a class="nav-link" (click)="logout()" href="#">{{
            "LOGOUT" | translate
          }}</a>
        </li>
      </ul>
    </div>
  </nav>

  <div class="row ml-0 mr-0">
    <div
      id="deskstop-menu"
      class="col-3"
      *ngIf="(account || accountsbs) && !hasRoute('onboarding')"
    >
      <nav class="nav flex-column" *ngIf="account || accountsbs">
        <div class="row menu-header">
          <div class="col d-flex justify-content-center pr-0">
            <img
              class="menu-logo d-flex"
              src="../assets/logo2.png"
              style="width: 250px; height: auto"
            />
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <ul
            *ngIf="this.accountService.Role === userEnum; else elseBlock"
            class="nav flex-column p-4 mt-4 justify-content-between"
            style="min-height: 70vh"
          >
            <div class="upper">
              <!-- <li class="nav-item">
                                <i class="f-icons f-home"></i>
                                <a class="nav-link active" aria-current="page" routerLink="/"
                                    routerLinkActive="active">Dashboard</a>
                                <a class="nav-link active" aria-current="page" routerLink="/"
                                    routerLinkActive="active">{{ 'DASHBOARD' | translate }}</a>
                            </li>
                            <li class="nav-item">
                                <i class="f-icons f-book"></i>
                                <a class="nav-link" href="assets/work_book.pdf" download>Sleep
                                    Workbook</a>
                                    <a class="nav-link" href="assets/work_book.pdf" download>{{ 'WORKBOOK' | translate }}</a>
                            </li>							  -->
              <li class="nav-item">
                <i class="f-icons f-pencil"></i>
                <!-- <a class="nav-link" href="#" routerLink="/journal" routerLinkActive="active">Sleep
                                    Journal</a>  -->
                <a
                  class="nav-link"
                  href="#"
                  routerLink="/journal"
                  routerLinkActive="active"
                  >{{ "JOURNAL" | translate }}</a
                >
              </li>
              <!-- <li class="nav-item">
                                <i class="f-icons f-list"></i>
                                <a class="nav-link" routerLink="/educate">Sleep Education</a>
                                <a class="nav-link" routerLink="/educate">{{ 'EDUCATION' | translate }}</a>
                            </li>

                            <li class="nav-item">
                                <i class="f-icons f-moon"></i>
                                <a class="nav-link" href="#" routerLink="/calculator">Sleep Calculator</a>
                                <a class="nav-link" href="#" routerLink="/calculator">{{ 'CALCULATOR' | translate }}</a>
                            </li>  -->
              <li class="nav-item">
                <i class="f-icons f-calculator"></i>
                <!-- <a class="nav-link" href="#" routerLink="/diary">My Progress</a>  -->
                <a class="nav-link" href="#" routerLink="/diary">{{
                  "PROGRESS" | translate
                }}</a>
              </li>
              <!-- My CBT-I Program -->
              <li>
                <h3 class="program-title" style="margin-bottom: 10px">
                  {{ "PROGRAM" | translate }}
                </h3>
              </li>
              <li>
                <div class="week-list-container">
                  <ul class="week-list">
                    <!-- Week 1 -->
                    <li class="week-item">
                      <div
                        (click)="toggleWeek(0)"
                        class="week-header"
                        [ngClass]="{
                          'active-week': selectedWeek === 0,
                          'locked-week': courseService.getWeekLocked(0)
                        }"
                      >
                        <div
                          class="circle"
                          [ngClass]="{
                            completed: !courseService.getWeekLocked(0)
                          }"
                        ></div>
                        <span class="week-title">{{ "W1" | translate }}</span>
                        <span class="toggle-icon">{{
                          isExpanded(0) ? "▲" : "▼"
                        }}</span>
                        <span
                          *ngIf="courseService.getWeekLocked(0)"
                          class="lock-icon"
                          >🔒</span
                        >
                      </div>
                      <ul *ngIf="isExpanded(0)" class="week-tasks">
                        <li
                          class="task-item"
                          [ngClass]="{
                            'locked-task':
                              courseService.getWeek1ContentLocked(0)
                          }"
                        >
                          <div
                            class="task-header"
                            (click)="
                              !!courseService.getWeek1ContentLocked(0)
                                ? openTask(0)
                                : null
                            "
                          >
                            <div class="circle small-circle completed"></div>

                            <a
                              class="task-item-link"
                              href="#"
                              routerLink="/week1/track_your_sleep"
                              routerLinkActive="active"
                              [ngClass]="{
                                'disabled-link':
                                  courseService.getWeek1ContentLocked(0)
                              }"
                              >{{ "MENU_TRACK" | translate }}</a
                            >
                            <span
                              *ngif="courseService.getWeek1ContentLocked(0)"
                              class="lock-icon"
                              >🔒</span
                            >
                          </div>
                          <div class="vertical-line"></div>
                          <!--  <ul class="subtask-list">
                            <li
                              [ngClass]="{
                                'locked-task':
                                  courseService.getWeek1ContentLocked(1)
                              }"
                            >
                              <span class="icon">📄</span>
                              <a
                                class="subtask-item-link"
                                href="#"
                                routerLink="/week1/journal"
                                routerLinkActive="active"
                                [ngClass]="{
                                  'disabled-link':
                                    courseService.getWeek1ContentLocked(1)
                                }"
                                >{{ "JOURNAL" | translate }}</a
                              >
                              <span
                                *ngIf="courseService.getWeek1ContentLocked(1)"
                                class="lock-icon"
                                >🔒</span
                              >
                            </li>
                          </ul> -->
                        </li>
                        <li
                          class="task-item"
                          [ngClass]="{
                            'locked-task':
                              courseService.getWeek1ContentLocked(1)
                          }"
                        >
                          <div class="task-header">
                            <div
                              class="circle small-circle"
                              [ngClass]="{
                                completed:
                                  !courseService.getWeek1ContentLocked(1)
                              }"
                            ></div>
                            <a
                              class="task-item-link"
                              href="#"
                              routerLink="/week1/prepare_for_optimal_sleep"
                              routerLinkActive="active"
                              [ngClass]="{
                                'disabled-link':
                                  courseService.getWeek1ContentLocked(1)
                              }"
                              >{{ "MENU_PREPARE" | translate }}</a
                            >
                            <span
                              *ngIf="courseService.getWeek1ContentLocked(1)"
                              class="lock-icon"
                              >🔒</span
                            >
                          </div>
                          <div class="vertical-line"></div>
                          <ul class="subtask-list">
                            <li
                              class="subtask-item"
                              [ngClass]="{
                                'locked-task':
                                  courseService.getWeek1ContentLocked(1)
                              }"
                            >
                              <span class="icon">🎥</span>
                              <a
                                class="subtask-item-link"
                                href="#"
                                routerLink="/week1/prepare_for_optimal_sleep"
                                routerLinkActive="active"
                                [ngClass]="{
                                  'disabled-link':
                                    courseService.getWeek1ContentLocked(1)
                                }"
                                >{{ "VIDEO" | translate }}</a
                              >

                              <span
                                *ngIf="courseService.getWeek1ContentLocked(1)"
                                class="lock-icon"
                                >🔒</span
                              >
                            </li>
                          </ul>
                        </li>
                        <li
                          class="task-item"
                          [ngClass]="{
                            'locked-task':
                              courseService.getWeek1ContentLocked(2)
                          }"
                        >
                          <div class="task-header">
                            <div
                              class="circle small-circle"
                              [ngClass]="{
                                completed:
                                  !courseService.getWeek1ContentLocked(2)
                              }"
                            ></div>
                            <a
                              class="task-item-link"
                              href="#"
                              routerLink="/week1/take_action"
                              routerLinkActive="active"
                              [ngClass]="{
                                'disabled-link':
                                  courseService.getWeek1ContentLocked(2)
                              }"
                              >{{ "MENU_ACTION" | translate }}</a
                            >

                            <span
                              *ngIf="courseService.getWeek1ContentLocked(2)"
                              class="lock-icon"
                              >🔒</span
                            >
                          </div>
                          <div class="vertical-line"></div>
                          <ul class="subtask-list">
                            <li
                              class="subtask-item"
                              [ngClass]="{
                                'locked-task':
                                  courseService.getWeek1ContentLocked(3)
                              }"
                            >
                              <span class="icon">📄</span
                              ><a
                                class="subtask-item-link"
                                href="#"
                                routerLink="/week1/self_assessment_and_reflection"
                                routerLinkActive="active"
                                [ngClass]="{
                                  'disabled-link':
                                    courseService.getWeek1ContentLocked(3)
                                }"
                                >{{ "MENU_SELF_ASSESSMENT" | translate }}</a
                              >
                              <span
                                *ngIf="courseService.getWeek1ContentLocked(3)"
                                class="lock-icon"
                                >🔒</span
                              >
                            </li>
                            <li
                              class="subtask-item"
                              class="subtask-item"
                              [ngClass]="{
                                'locked-task':
                                  courseService.getWeek1ContentLocked(3)
                              }"
                            >
                              <span class="icon">📄</span
                              ><a
                                class="subtask-item-link"
                                href="#"
                                routerLink="/week1/self_assessment_and_reflection"
                                routerLinkActive="active"
                                [ngClass]="{
                                  'disabled-link':
                                    courseService.getWeek1ContentLocked(3)
                                }"
                                >{{ "MENU_REFLCETIONS" | translate }}</a
                              >

                              <span
                                *ngIf="courseService.getWeek1ContentLocked(3)"
                                class="lock-icon"
                                >🔒</span
                              >
                            </li>
                          </ul>
                        </li>
                        <li
                          class="task-item"
                          [ngClass]="{
                            'locked-task':
                              courseService.getWeek1ContentLocked(4)
                          }"
                        >
                          <div class="task-header">
                            <div
                              class="circle small-circle"
                              [ngClass]="{
                                completed:
                                  !courseService.getWeek1ContentLocked(4)
                              }"
                            ></div>
                            <a
                              class="subtask-item-link"
                              href="#"
                              routerLink="/week1/recommended_practices"
                              routerLinkActive="active"
                              [ngClass]="{
                                'disabled-link':
                                  courseService.getWeek1ContentLocked(4)
                              }"
                              >{{ "MENU_PEACTICES" | translate }}</a
                            >

                            <span
                              *ngIf="courseService.getWeek1ContentLocked(4)"
                              class="lock-icon"
                              >🔒</span
                            >
                          </div>
                        </li>
                      </ul>
                    </li>
                    <!-- Week 2 -->

                    <li class="week-item">
                      <div
                        (click)="toggleWeek(1)"
                        class="week-header"
                        [ngClass]="{
                          'active-week': selectedWeek === 1,
                          'locked-week': courseService.getWeekLocked(1)
                        }"
                      >
                        <div
                          class="circle"
                          [ngClass]="{
                            completed: !courseService.getWeekLocked(1)
                          }"
                        ></div>
                        <span class="week-title">{{ "W2" | translate }}</span>
                        <span class="toggle-icon">{{
                          isExpanded(1) ? "▲" : "▼"
                        }}</span>
                        <span
                          *ngIf="courseService.getWeekLocked(1)"
                          class="lock-icon"
                          >🔒</span
                        >
                      </div>
                      <ul *ngIf="isExpanded(1)" class="week-tasks">
                        <li
                          class="task-item"
                          [ngClass]="{
                            'locked-task':
                              courseService.getWeek2ContentLocked(0)
                          }"
                        >
                          <div
                            class="task-header"
                            (click)="
                              !!courseService.getWeek2ContentLocked(0)
                                ? openTask(0)
                                : null
                            "
                          >
                            <div class="circle small-circle completed"></div>

                            <a
                              class="task-item-link"
                              href="#"
                              routerLink="/week2/weekly_check_in"
                              routerLinkActive="active"
                              [ngClass]="{
                                'disabled-link':
                                  courseService.getWeek2ContentLocked(0)
                              }"
                              >{{ "MENU_WEEKLY_CHECK_IN" | translate }}</a
                            >
                            <span
                              *ngif="courseService.getWeek2ContentLocked(0)"
                              class="lock-icon"
                              >🔒</span
                            >
                          </div>
                          <div class="vertical-line"></div>
                        </li>

                        <li
                          class="task-item"
                          [ngClass]="{
                            'locked-task':
                              courseService.getWeek2ContentLocked(1)
                          }"
                        >
                          <div class="task-header">
                            <div
                              class="circle small-circle"
                              [ngClass]="{
                                completed:
                                  !courseService.getWeek2ContentLocked(1)
                              }"
                            ></div>
                            <a
                              class="task-item-link"
                              href="#"
                              routerLink="/week2/review_sleep_journal"
                              routerLinkActive="active"
                              [ngClass]="{
                                'disabled-link':
                                  courseService.getWeek2ContentLocked(1)
                              }"
                              >{{ "MENU_REVIEW_JOURNAL" | translate }}</a
                            >
                            <span
                              *ngIf="courseService.getWeek2ContentLocked(1)"
                              class="lock-icon"
                              >🔒</span
                            >
                          </div>
                          <div class="vertical-line"></div>
                          <ul class="subtask-list">
                            <li
                              class="subtask-item"
                              [ngClass]="{
                                'locked-task':
                                  courseService.getWeek2ContentLocked(2)
                              }"
                            >
                              <span class="icon">🎥</span>
                              <a
                                class="subtask-item-link"
                                href="#"
                                routerLink="/week2/time_in_bed_restriction_calculator"
                                routerLinkActive="active"
                                [ngClass]="{
                                  'disabled-link':
                                    courseService.getWeek2ContentLocked(2)
                                }"
                                >{{ "MENU_HOW_CALCULATE" | translate }}</a
                              >

                              <span
                                *ngIf="courseService.getWeek2ContentLocked(2)"
                                class="lock-icon"
                                >🔒</span
                              >
                            </li>
                          </ul>
                        </li>
                        <li
                          class="task-item"
                          [ngClass]="{
                            'locked-task':
                              courseService.getWeek2ContentLocked(3)
                          }"
                        >
                          <div class="task-header">
                            <div
                              class="circle small-circle"
                              [ngClass]="{
                                completed:
                                  !courseService.getWeek2ContentLocked(3)
                              }"
                            ></div>
                            <a
                              class="task-item-link"
                              href="#"
                              routerLink="/week2/understanding_sleep"
                              routerLinkActive="active"
                              [ngClass]="{
                                'disabled-link':
                                  courseService.getWeek2ContentLocked(3)
                              }"
                              >{{ "MENU_UNDERSTAND_SLEEP" | translate }}</a
                            >
                            <span
                              *ngIf="courseService.getWeek2ContentLocked(3)"
                              class="lock-icon"
                              >🔒</span
                            >
                          </div>
                          <div class="vertical-line"></div>
                          <ul class="subtask-list">
                            <li
                              class="subtask-item"
                              [ngClass]="{
                                'locked-task':
                                  courseService.getWeek2ContentLocked(4)
                              }"
                            >
                              <span class="icon">🎥</span>
                              <a
                                class="subtask-item-link"
                                href="#"
                                routerLink="/week2/facts_about_sleep"
                                routerLinkActive="active"
                                [ngClass]="{
                                  'disabled-link':
                                    courseService.getWeek2ContentLocked(4)
                                }"
                                >{{ "MENU_FACT_SLEEP" | translate }}</a
                              >

                              <span
                                *ngIf="courseService.getWeek2ContentLocked(4)"
                                class="lock-icon"
                                >🔒</span
                              >
                            </li>
                          </ul>
                          <ul class="subtask-list">
                            <li
                              class="subtask-item"
                              [ngClass]="{
                                'locked-task':
                                  courseService.getWeek2ContentLocked(5)
                              }"
                            >
                              <span class="icon">🎥</span>
                              <a
                                class="subtask-item-link"
                                href="#"
                                routerLink="/week2/sleep_as_we_age"
                                routerLinkActive="active"
                                [ngClass]="{
                                  'disabled-link':
                                    courseService.getWeek2ContentLocked(5)
                                }"
                                >{{ "MENU_SLEEP_WE_AGE" | translate }}</a
                              >

                              <span
                                *ngIf="courseService.getWeek2ContentLocked(5)"
                                class="lock-icon"
                                >🔒</span
                              >
                            </li>
                          </ul>
                        </li>

                        <li
                          class="task-item"
                          [ngClass]="{
                            'locked-task':
                              courseService.getWeek2ContentLocked(6)
                          }"
                        >
                          <div class="task-header">
                            <div
                              class="circle small-circle"
                              [ngClass]="{
                                completed:
                                  !courseService.getWeek2ContentLocked(6)
                              }"
                            ></div>
                            <a
                              class="task-item-link"
                              href="#"
                              routerLink="/week2/self_assessment_and_reflection"
                              routerLinkActive="active"
                              [ngClass]="{
                                'disabled-link':
                                  courseService.getWeek2ContentLocked(6)
                              }"
                              >{{ "MENU_ACTION" | translate }}</a
                            >

                            <span
                              *ngIf="courseService.getWeek2ContentLocked(6)"
                              class="lock-icon"
                              >🔒</span
                            >
                          </div>
                          <div class="vertical-line"></div>
                          <ul class="subtask-list">
                            <li
                              class="subtask-item"
                              [ngClass]="{
                                'locked-task':
                                  courseService.getWeek2ContentLocked(6)
                              }"
                            >
                              <span class="icon">📄</span
                              ><a
                                class="subtask-item-link"
                                href="#"
                                routerLink="/week2/self_assessment_and_reflection"
                                routerLinkActive="active"
                                [ngClass]="{
                                  'disabled-link':
                                    courseService.getWeek2ContentLocked(6)
                                }"
                                >{{ "MENU_SELF_EVALUATION" | translate }}</a
                              >
                              <span
                                *ngIf="courseService.getWeek2ContentLocked(6)"
                                class="lock-icon"
                                >🔒</span
                              >
                            </li>
                          </ul>
                        </li>
                        <li
                          class="task-item"
                          [ngClass]="{
                            'locked-task':
                              courseService.getWeek2ContentLocked(7)
                          }"
                        >
                          <div class="task-header">
                            <div
                              class="circle small-circle"
                              [ngClass]="{
                                completed:
                                  !courseService.getWeek2ContentLocked(7)
                              }"
                            ></div>
                            <a
                              class="task-item-link"
                              href="#"
                              routerLink="/week2/plan_for_the_week"
                              routerLinkActive="active"
                              [ngClass]="{
                                'disabled-link':
                                  courseService.getWeek2ContentLocked(7)
                              }"
                              >{{ "MENU_PLAN_WEEK" | translate }}</a
                            >

                            <span
                              *ngIf="courseService.getWeek2ContentLocked(7)"
                              class="lock-icon"
                              >🔒</span
                            >
                          </div>

                          <ul class="subtask-list">
                            <li
                              class="subtask-item"
                              [ngClass]="{
                                'locked-task':
                                  courseService.getWeek2ContentLocked(7)
                              }"
                            >
                              <span class="icon">📄</span
                              ><a
                                class="subtask-item-link"
                                href="#"
                                routerLink="/week2/plan_for_the_week"
                                routerLinkActive="active"
                                [ngClass]="{
                                  'disabled-link':
                                    courseService.getWeek2ContentLocked(7)
                                }"
                                >{{ "MENU_NEW_TIB" | translate }}</a
                              >
                              <span
                                *ngIf="courseService.getWeek2ContentLocked(7)"
                                class="lock-icon"
                                >🔒</span
                              >
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <!-- </div> -->
                    </li>
                    <!-- Week 3 -->
                    <li class="week-item">
                      <div
                        (click)="toggleWeek(2)"
                        class="week-header"
                        [ngClass]="{
                          'active-week': selectedWeek === 2,
                          'locked-week': courseService.getWeekLocked(2)
                        }"
                      >
                        <div
                          class="circle"
                          [ngClass]="{
                            completed: !courseService.getWeekLocked(2)
                          }"
                        ></div>
                        <span class="week-title">{{ "W3" | translate }}</span>
                        <span class="toggle-icon">{{
                          isExpanded(2) ? "▲" : "▼"
                        }}</span>
                        <span
                          *ngIf="courseService.getWeekLocked(2)"
                          class="lock-icon"
                          >🔒</span
                        >
                      </div>
                      <ul *ngIf="isExpanded(2)" class="week-tasks">
                        <li
                          class="task-item"
                          [ngClass]="{
                            'locked-task':
                              courseService.getWeek3ContentLocked(0)
                          }"
                        >
                          <div
                            class="task-header"
                            (click)="
                              !!courseService.getWeek3ContentLocked(0)
                                ? openTask(0)
                                : null
                            "
                          >
                            <div class="circle small-circle completed"></div>

                            <a
                              class="task-item-link"
                              href="#"
                              routerLink="/week3/weekly_check_in"
                              routerLinkActive="active"
                              [ngClass]="{
                                'disabled-link':
                                  courseService.getWeek3ContentLocked(0)
                              }"
                              >{{ "MENU_WEEKLY_CHECK_IN" | translate }}</a
                            >
                            <span
                              *ngif="courseService.getWeek3ContentLocked(0)"
                              class="lock-icon"
                              >🔒</span
                            >
                          </div>
                          <div class="vertical-line"></div>
                        </li>

                        <li
                          class="task-item"
                          [ngClass]="{
                            'locked-task':
                              courseService.getWeek3ContentLocked(1)
                          }"
                        >
                          <div class="task-header">
                            <div
                              class="circle small-circle"
                              [ngClass]="{
                                completed:
                                  !courseService.getWeek3ContentLocked(1)
                              }"
                            ></div>
                            <a
                              class="task-item-link"
                              href="#"
                              routerLink="/week3/review_sleep_journal"
                              routerLinkActive="active"
                              [ngClass]="{
                                'disabled-link':
                                  courseService.getWeek3ContentLocked(1)
                              }"
                              >{{ "MENU_REVIEW_JOURNAL" | translate }}</a
                            >
                            <span
                              *ngIf="courseService.getWeek3ContentLocked(1)"
                              class="lock-icon"
                              >🔒</span
                            >
                          </div>
                          <div class="vertical-line"></div>
                          <!-- <ul class="subtask-list">
                            <li
                              class="subtask-item"
                              [ngClass]="{
                                'locked-task':
                                  courseService.getWeek2ContentLocked(2)
                              }"
                            >
                              <span class="icon">🎥</span>
                              <a
                                class="subtask-item-link"
                                href="#"
                                routerLink="/week2/time_in_bed_restriction_calculator"
                                routerLinkActive="active"
                                [ngClass]="{
                                  'disabled-link':
                                    courseService.getWeek2ContentLocked(2)
                                }"
                                >{{ "MENU_HOW_CALCULATE" | translate }}</a
                              >

                              <span
                                *ngIf="courseService.getWeek2ContentLocked(2)"
                                class="lock-icon"
                                >🔒</span
                              >
                            </li>
                          </ul> -->
                        </li>
                        <li
                          class="task-item"
                          [ngClass]="{
                            'locked-task':
                              courseService.getWeek3ContentLocked(2)
                          }"
                        >
                          <div class="task-header">
                            <div
                              class="circle small-circle"
                              [ngClass]="{
                                completed:
                                  !courseService.getWeek3ContentLocked(2)
                              }"
                            ></div>
                            <a
                              class="task-item-link"
                              href="#"
                              routerLink="/week3/what_controls_sleep"
                              routerLinkActive="active"
                              [ngClass]="{
                                'disabled-link':
                                  courseService.getWeek3ContentLocked(2)
                              }"
                              >{{ "MENU_WHAT_CONTROLS_SLEEP" | translate }}</a
                            >
                            <span
                              *ngIf="courseService.getWeek3ContentLocked(2)"
                              class="lock-icon"
                              >🔒</span
                            >
                          </div>
                          <div class="vertical-line"></div>
                          <!-- <ul class="subtask-list">
                            <li
                              class="subtask-item"
                              [ngClass]="{
                                'locked-task':
                                  courseService.getWeek3ContentLocked(2)
                              }"
                            >
                              <span class="icon">🎥</span>
                              <a
                                class="subtask-item-link"
                                href="#"
                                routerLink="/week3/what_controls_sleep"
                                routerLinkActive="active"
                                [ngClass]="{
                                  'disabled-link':
                                    courseService.getWeek3ContentLocked(2)
                                }"
                                >{{ "MENU_WHAT_CONTROLS_SLEEP" | translate }}</a
                              >

                              <span
                                *ngIf="courseService.getWeek3ContentLocked(2)"
                                class="lock-icon"
                                >🔒</span
                              >
                            </li>
                          </ul> -->
                          <!-- <ul class="subtask-list">
                            <li
                              class="subtask-item"
                              [ngClass]="{
                                'locked-task':
                                  courseService.getWeek2ContentLocked(5)
                              }"
                            >
                              <span class="icon">🎥</span>
                              <a
                                class="subtask-item-link"
                                href="#"
                                routerLink="/week2/sleep_as_we_age"
                                routerLinkActive="active"
                                [ngClass]="{
                                  'disabled-link':
                                    courseService.getWeek2ContentLocked(5)
                                }"
                                >{{ "MENU_SLEEP_WE_AGE" | translate }}</a
                              >

                              <span
                                *ngIf="courseService.getWeek2ContentLocked(5)"
                                class="lock-icon"
                                >🔒</span
                              >
                            </li>
                          </ul> -->
                        </li>

                        <li
                          class="task-item"
                          [ngClass]="{
                            'locked-task':
                              courseService.getWeek3ContentLocked(3)
                          }"
                        >
                          <div class="task-header">
                            <div
                              class="circle small-circle"
                              [ngClass]="{
                                completed:
                                  !courseService.getWeek3ContentLocked(3)
                              }"
                            ></div>
                            <a
                              class="task-item-link"
                              href="#"
                              routerLink="/week3/self_assessment_and_reflection"
                              routerLinkActive="active"
                              [ngClass]="{
                                'disabled-link':
                                  courseService.getWeek3ContentLocked(3)
                              }"
                              >{{ "MENU_ACTION" | translate }}</a
                            >

                            <span
                              *ngIf="courseService.getWeek3ContentLocked(3)"
                              class="lock-icon"
                              >🔒</span
                            >
                          </div>
                          <div class="vertical-line"></div>
                          <ul class="subtask-list">
                            <li
                              class="subtask-item"
                              [ngClass]="{
                                'locked-task':
                                  courseService.getWeek3ContentLocked(3)
                              }"
                            >
                              <span class="icon">📄</span
                              ><a
                                class="subtask-item-link"
                                href="#"
                                routerLink="/week3/self_assessment_and_reflection"
                                routerLinkActive="active"
                                [ngClass]="{
                                  'disabled-link':
                                    courseService.getWeek3ContentLocked(3)
                                }"
                                >{{ "MENU_SELF_EVALUATION" | translate }}</a
                              >
                              <span
                                *ngIf="courseService.getWeek3ContentLocked(3)"
                                class="lock-icon"
                                >🔒</span
                              >
                            </li>
                          </ul>
                        </li>
                        <li
                          class="task-item"
                          [ngClass]="{
                            'locked-task':
                              courseService.getWeek3ContentLocked(4)
                          }"
                        >
                          <div class="task-header">
                            <div
                              class="circle small-circle"
                              [ngClass]="{
                                completed:
                                  !courseService.getWeek3ContentLocked(4)
                              }"
                            ></div>
                            <a
                              class="task-item-link"
                              href="#"
                              routerLink="/week3/plan_for_the_week"
                              routerLinkActive="active"
                              [ngClass]="{
                                'disabled-link':
                                  courseService.getWeek3ContentLocked(4)
                              }"
                              >{{ "MENU_PLAN_WEEK" | translate }}</a
                            >

                            <span
                              *ngIf="courseService.getWeek3ContentLocked(4)"
                              class="lock-icon"
                              >🔒</span
                            >
                          </div>

                          <ul class="subtask-list">
                            <li
                              class="subtask-item"
                              [ngClass]="{
                                'locked-task':
                                  courseService.getWeek3ContentLocked(4)
                              }"
                            >
                              <span class="icon">📄</span
                              ><a
                                class="subtask-item-link"
                                href="#"
                                routerLink="/week3/plan_for_the_week"
                                routerLinkActive="active"
                                [ngClass]="{
                                  'disabled-link':
                                    courseService.getWeek3ContentLocked(4)
                                }"
                                >{{ "MENU_NEW_TIB" | translate }}</a
                              >
                              <span
                                *ngIf="courseService.getWeek3ContentLocked(4)"
                                class="lock-icon"
                                >🔒</span
                              >
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <!-- Week 4 -->
                    <li class="week-item">
                      <div
                        (click)="toggleWeek(3)"
                        class="week-header"
                        [ngClass]="{
                          'active-week': selectedWeek === 3,
                          'locked-week': courseService.getWeekLocked(3)
                        }"
                      >
                        <div
                          class="circle"
                          [ngClass]="{
                            completed: !courseService.getWeekLocked(3)
                          }"
                        ></div>
                        <span class="week-title">{{ "W4" | translate }}</span>
                        <span class="toggle-icon">{{
                          isExpanded(3) ? "▲" : "▼"
                        }}</span>
                        <span
                          *ngIf="courseService.getWeekLocked(3)"
                          class="lock-icon"
                          >🔒</span
                        >
                      </div>
                      <ul *ngIf="isExpanded(3)" class="week-tasks">
                        <li
                          class="task-item"
                          [ngClass]="{
                            'locked-task':
                              courseService.getWeek4ContentLocked(0)
                          }"
                        >
                          <div
                            class="task-header"
                            (click)="
                              !!courseService.getWeek4ContentLocked(0)
                                ? openTask(0)
                                : null
                            "
                          >
                            <div class="circle small-circle completed"></div>

                            <a
                              class="task-item-link"
                              href="#"
                              routerLink="/week4/weekly_check_in"
                              routerLinkActive="active"
                              [ngClass]="{
                                'disabled-link':
                                  courseService.getWeek4ContentLocked(0)
                              }"
                              >{{ "MENU_WEEKLY_CHECK_IN" | translate }}</a
                            >
                            <span
                              *ngif="courseService.getWeek4ContentLocked(0)"
                              class="lock-icon"
                              >🔒</span
                            >
                          </div>
                          <div class="vertical-line"></div>
                        </li>

                        <li
                          class="task-item"
                          [ngClass]="{
                            'locked-task':
                              courseService.getWeek4ContentLocked(1)
                          }"
                        >
                          <div class="task-header">
                            <div
                              class="circle small-circle"
                              [ngClass]="{
                                completed:
                                  !courseService.getWeek4ContentLocked(1)
                              }"
                            ></div>
                            <a
                              class="task-item-link"
                              href="#"
                              routerLink="/week4/review_sleep_journal"
                              routerLinkActive="active"
                              [ngClass]="{
                                'disabled-link':
                                  courseService.getWeek4ContentLocked(1)
                              }"
                              >{{ "MENU_REVIEW_JOURNAL" | translate }}</a
                            >
                            <span
                              *ngIf="courseService.getWeek4ContentLocked(1)"
                              class="lock-icon"
                              >🔒</span
                            >
                          </div>
                          <div class="vertical-line"></div>
                          <!-- <ul class="subtask-list">
                            <li
                              class="subtask-item"
                              [ngClass]="{
                                'locked-task':
                                  courseService.getWeek2ContentLocked(2)
                              }"
                            >
                              <span class="icon">🎥</span>
                              <a
                                class="subtask-item-link"
                                href="#"
                                routerLink="/week2/time_in_bed_restriction_calculator"
                                routerLinkActive="active"
                                [ngClass]="{
                                  'disabled-link':
                                    courseService.getWeek2ContentLocked(2)
                                }"
                                >{{ "MENU_HOW_CALCULATE" | translate }}</a
                              >

                              <span
                                *ngIf="courseService.getWeek2ContentLocked(2)"
                                class="lock-icon"
                                >🔒</span
                              >
                            </li>
                          </ul> -->
                        </li>
                        <li
                          class="task-item"
                          [ngClass]="{
                            'locked-task':
                              courseService.getWeek4ContentLocked(2)
                          }"
                        >
                          <div class="task-header">
                            <div
                              class="circle small-circle"
                              [ngClass]="{
                                completed:
                                  !courseService.getWeek4ContentLocked(2)
                              }"
                            ></div>
                            <a
                              class="task-item-link"
                              href="#"
                              routerLink="/week4/emotions_and_physical_health"
                              routerLinkActive="active"
                              [ngClass]="{
                                'disabled-link':
                                  courseService.getWeek4ContentLocked(2)
                              }"
                              >{{ "W4_MENU_EMOTION_PHYSICAL" | translate }}</a
                            >
                            <span
                              *ngIf="courseService.getWeek4ContentLocked(2)"
                              class="lock-icon"
                              >🔒</span
                            >
                          </div>
                          <div class="vertical-line"></div>
                          <!-- <ul class="subtask-list">
                            <li
                              class="subtask-item"
                              [ngClass]="{
                                'locked-task':
                                  courseService.getWeek3ContentLocked(2)
                              }"
                            >
                              <span class="icon">🎥</span>
                              <a
                                class="subtask-item-link"
                                href="#"
                                routerLink="/week3/what_controls_sleep"
                                routerLinkActive="active"
                                [ngClass]="{
                                  'disabled-link':
                                    courseService.getWeek3ContentLocked(2)
                                }"
                                >{{ "MENU_WHAT_CONTROLS_SLEEP" | translate }}</a
                              >

                              <span
                                *ngIf="courseService.getWeek3ContentLocked(2)"
                                class="lock-icon"
                                >🔒</span
                              >
                            </li>
                          </ul> -->
                          <!-- <ul class="subtask-list">
                            <li
                              class="subtask-item"
                              [ngClass]="{
                                'locked-task':
                                  courseService.getWeek2ContentLocked(5)
                              }"
                            >
                              <span class="icon">🎥</span>
                              <a
                                class="subtask-item-link"
                                href="#"
                                routerLink="/week2/sleep_as_we_age"
                                routerLinkActive="active"
                                [ngClass]="{
                                  'disabled-link':
                                    courseService.getWeek2ContentLocked(5)
                                }"
                                >{{ "MENU_SLEEP_WE_AGE" | translate }}</a
                              >

                              <span
                                *ngIf="courseService.getWeek2ContentLocked(5)"
                                class="lock-icon"
                                >🔒</span
                              >
                            </li>
                          </ul> -->
                        </li>

                        <li
                          class="task-item"
                          [ngClass]="{
                            'locked-task':
                              courseService.getWeek4ContentLocked(3)
                          }"
                        >
                          <div class="task-header">
                            <div
                              class="circle small-circle"
                              [ngClass]="{
                                completed:
                                  !courseService.getWeek4ContentLocked(3)
                              }"
                            ></div>
                            <a
                              class="task-item-link"
                              href="#"
                              routerLink="/week4/self_assessment_and_reflection"
                              routerLinkActive="active"
                              [ngClass]="{
                                'disabled-link':
                                  courseService.getWeek4ContentLocked(3)
                              }"
                              >{{ "MENU_ACTION" | translate }}</a
                            >

                            <span
                              *ngIf="courseService.getWeek4ContentLocked(3)"
                              class="lock-icon"
                              >🔒</span
                            >
                          </div>
                          <div class="vertical-line"></div>
                          <ul class="subtask-list">
                            <li
                              class="subtask-item"
                              [ngClass]="{
                                'locked-task':
                                  courseService.getWeek4ContentLocked(3)
                              }"
                            >
                              <span class="icon">📄</span
                              ><a
                                class="subtask-item-link"
                                href="#"
                                routerLink="/week4/self_assessment_and_reflection"
                                routerLinkActive="active"
                                [ngClass]="{
                                  'disabled-link':
                                    courseService.getWeek4ContentLocked(3)
                                }"
                                >{{ "MENU_SELF_EVALUATION" | translate }}</a
                              >
                              <span
                                *ngIf="courseService.getWeek4ContentLocked(3)"
                                class="lock-icon"
                                >🔒</span
                              >
                            </li>
                          </ul>
                        </li>
                        <li
                          class="task-item"
                          [ngClass]="{
                            'locked-task':
                              courseService.getWeek4ContentLocked(4)
                          }"
                        >
                          <div class="task-header">
                            <div
                              class="circle small-circle"
                              [ngClass]="{
                                completed:
                                  !courseService.getWeek4ContentLocked(4)
                              }"
                            ></div>
                            <a
                              class="task-item-link"
                              href="#"
                              routerLink="/week4/plan_for_the_week"
                              routerLinkActive="active"
                              [ngClass]="{
                                'disabled-link':
                                  courseService.getWeek4ContentLocked(4)
                              }"
                              >{{ "MENU_PLAN_WEEK" | translate }}</a
                            >

                            <span
                              *ngIf="courseService.getWeek4ContentLocked(4)"
                              class="lock-icon"
                              >🔒</span
                            >
                          </div>

                          <ul class="subtask-list">
                            <li
                              class="subtask-item"
                              [ngClass]="{
                                'locked-task':
                                  courseService.getWeek4ContentLocked(4)
                              }"
                            >
                              <span class="icon">📄</span
                              ><a
                                class="subtask-item-link"
                                href="#"
                                routerLink="/week4/plan_for_the_week"
                                routerLinkActive="active"
                                [ngClass]="{
                                  'disabled-link':
                                    courseService.getWeek4ContentLocked(4)
                                }"
                                >{{ "MENU_NEW_TIB" | translate }}</a
                              >
                              <span
                                *ngIf="courseService.getWeek4ContentLocked(4)"
                                class="lock-icon"
                                >🔒</span
                              >
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <!-- Week 5 -->
                    <li class="week-item">
                      <div
                        (click)="toggleWeek(4)"
                        class="week-header"
                        [ngClass]="{
                          'active-week': selectedWeek === 4,
                          'locked-week': courseService.getWeekLocked(4)
                        }"
                      >
                        <div
                          class="circle"
                          [ngClass]="{
                            completed: !courseService.getWeekLocked(4)
                          }"
                        ></div>
                        <span class="week-title">{{ "W5" | translate }}</span>
                        <span class="toggle-icon">{{
                          isExpanded(4) ? "▲" : "▼"
                        }}</span>
                        <span
                          *ngIf="courseService.getWeekLocked(4)"
                          class="lock-icon"
                          >🔒</span
                        >
                      </div>
                      <ul *ngIf="isExpanded(4)" class="week-tasks">
                        <li
                          class="task-item"
                          [ngClass]="{
                            'locked-task':
                              courseService.getWeek5ContentLocked(0)
                          }"
                        >
                          <div
                            class="task-header"
                            (click)="
                              !!courseService.getWeek5ContentLocked(0)
                                ? openTask(0)
                                : null
                            "
                          >
                            <div class="circle small-circle completed"></div>

                            <a
                              class="task-item-link"
                              href="#"
                              routerLink="/week5/weekly_check_in"
                              routerLinkActive="active"
                              [ngClass]="{
                                'disabled-link':
                                  courseService.getWeek5ContentLocked(0)
                              }"
                              >{{ "MENU_WEEKLY_CHECK_IN" | translate }}</a
                            >
                            <span
                              *ngif="courseService.getWeek5ContentLocked(0)"
                              class="lock-icon"
                              >🔒</span
                            >
                          </div>
                          <div class="vertical-line"></div>
                        </li>

                        <li
                          class="task-item"
                          [ngClass]="{
                            'locked-task':
                              courseService.getWeek5ContentLocked(1)
                          }"
                        >
                          <div class="task-header">
                            <div
                              class="circle small-circle"
                              [ngClass]="{
                                completed:
                                  !courseService.getWeek5ContentLocked(1)
                              }"
                            ></div>
                            <a
                              class="task-item-link"
                              href="#"
                              routerLink="/week5/review_sleep_journal"
                              routerLinkActive="active"
                              [ngClass]="{
                                'disabled-link':
                                  courseService.getWeek5ContentLocked(1)
                              }"
                              >{{ "MENU_REVIEW_JOURNAL" | translate }}</a
                            >
                            <span
                              *ngIf="courseService.getWeek5ContentLocked(1)"
                              class="lock-icon"
                              >🔒</span
                            >
                          </div>
                          <div class="vertical-line"></div>
                          <!-- <ul class="subtask-list">
                            <li
                              class="subtask-item"
                              [ngClass]="{
                                'locked-task':
                                  courseService.getWeek2ContentLocked(2)
                              }"
                            >
                              <span class="icon">🎥</span>
                              <a
                                class="subtask-item-link"
                                href="#"
                                routerLink="/week2/time_in_bed_restriction_calculator"
                                routerLinkActive="active"
                                [ngClass]="{
                                  'disabled-link':
                                    courseService.getWeek2ContentLocked(2)
                                }"
                                >{{ "MENU_HOW_CALCULATE" | translate }}</a
                              >

                              <span
                                *ngIf="courseService.getWeek2ContentLocked(2)"
                                class="lock-icon"
                                >🔒</span
                              >
                            </li>
                          </ul> -->
                        </li>
                        <li
                          class="task-item"
                          [ngClass]="{
                            'locked-task':
                              courseService.getWeek5ContentLocked(2)
                          }"
                        >
                          <div class="task-header">
                            <div
                              class="circle small-circle"
                              [ngClass]="{
                                completed:
                                  !courseService.getWeek5ContentLocked(2)
                              }"
                            ></div>
                            <a
                              class="task-item-link"
                              href="#"
                              routerLink="/week5/what_perpetuates_insomnia"
                              routerLinkActive="active"
                              [ngClass]="{
                                'disabled-link':
                                  courseService.getWeek5ContentLocked(2)
                              }"
                              >{{ "W5_MENU_INSOMNIA" | translate }}</a
                            >
                            <span
                              *ngIf="courseService.getWeek5ContentLocked(2)"
                              class="lock-icon"
                              >🔒</span
                            >
                          </div>
                          <div class="vertical-line"></div>
                          <!-- <ul class="subtask-list">
                            <li
                              class="subtask-item"
                              [ngClass]="{
                                'locked-task':
                                  courseService.getWeek3ContentLocked(2)
                              }"
                            >
                              <span class="icon">🎥</span>
                              <a
                                class="subtask-item-link"
                                href="#"
                                routerLink="/week3/what_controls_sleep"
                                routerLinkActive="active"
                                [ngClass]="{
                                  'disabled-link':
                                    courseService.getWeek3ContentLocked(2)
                                }"
                                >{{ "MENU_WHAT_CONTROLS_SLEEP" | translate }}</a
                              >

                              <span
                                *ngIf="courseService.getWeek3ContentLocked(2)"
                                class="lock-icon"
                                >🔒</span
                              >
                            </li>
                          </ul> -->
                          <!-- <ul class="subtask-list">
                            <li
                              class="subtask-item"
                              [ngClass]="{
                                'locked-task':
                                  courseService.getWeek2ContentLocked(5)
                              }"
                            >
                              <span class="icon">🎥</span>
                              <a
                                class="subtask-item-link"
                                href="#"
                                routerLink="/week2/sleep_as_we_age"
                                routerLinkActive="active"
                                [ngClass]="{
                                  'disabled-link':
                                    courseService.getWeek2ContentLocked(5)
                                }"
                                >{{ "MENU_SLEEP_WE_AGE" | translate }}</a
                              >

                              <span
                                *ngIf="courseService.getWeek2ContentLocked(5)"
                                class="lock-icon"
                                >🔒</span
                              >
                            </li>
                          </ul> -->
                        </li>

                        <li
                          class="task-item"
                          [ngClass]="{
                            'locked-task':
                              courseService.getWeek5ContentLocked(3)
                          }"
                        >
                          <div class="task-header">
                            <div
                              class="circle small-circle"
                              [ngClass]="{
                                completed:
                                  !courseService.getWeek5ContentLocked(3)
                              }"
                            ></div>
                            <a
                              class="task-item-link"
                              href="#"
                              routerLink="/week5/self_assessment_and_reflection"
                              routerLinkActive="active"
                              [ngClass]="{
                                'disabled-link':
                                  courseService.getWeek5ContentLocked(3)
                              }"
                              >{{ "MENU_ACTION" | translate }}</a
                            >

                            <span
                              *ngIf="courseService.getWeek5ContentLocked(3)"
                              class="lock-icon"
                              >🔒</span
                            >
                          </div>
                          <div class="vertical-line"></div>
                          <ul class="subtask-list">
                            <li
                              class="subtask-item"
                              [ngClass]="{
                                'locked-task':
                                  courseService.getWeek5ContentLocked(3)
                              }"
                            >
                              <span class="icon">📄</span
                              ><a
                                class="subtask-item-link"
                                href="#"
                                routerLink="/week5/self_assessment_and_reflection"
                                routerLinkActive="active"
                                [ngClass]="{
                                  'disabled-link':
                                    courseService.getWeek5ContentLocked(3)
                                }"
                                >{{ "MENU_SELF_EVALUATION" | translate }}</a
                              >
                              <span
                                *ngIf="courseService.getWeek5ContentLocked(3)"
                                class="lock-icon"
                                >🔒</span
                              >
                            </li>
                          </ul>
                        </li>
                        <li
                          class="task-item"
                          [ngClass]="{
                            'locked-task':
                              courseService.getWeek5ContentLocked(4)
                          }"
                        >
                          <div class="task-header">
                            <div
                              class="circle small-circle"
                              [ngClass]="{
                                completed:
                                  !courseService.getWeek5ContentLocked(4)
                              }"
                            ></div>
                            <a
                              class="task-item-link"
                              href="#"
                              routerLink="/week5/self_management_and_relapse_prevention"
                              routerLinkActive="active"
                              [ngClass]="{
                                'disabled-link':
                                  courseService.getWeek5ContentLocked(4)
                              }"
                              >{{ "W5_MENU_PREVENTION" | translate }}</a
                            >

                            <span
                              *ngIf="courseService.getWeek5ContentLocked(4)"
                              class="lock-icon"
                              >🔒</span
                            >
                          </div>
                          <div class="vertical-line"></div>

                          <!-- <ul class="subtask-list">
                            <li
                              class="subtask-item"
                              [ngClass]="{
                                'locked-task':
                                  courseService.getWeek4ContentLocked(4)
                              }"
                            >
                              <span class="icon">📄</span
                              ><a
                                class="subtask-item-link"
                                href="#"
                                routerLink="/week4/plan_for_the_week"
                                routerLinkActive="active"
                                [ngClass]="{
                                  'disabled-link':
                                    courseService.getWeek4ContentLocked(4)
                                }"
                                >{{ "MENU_NEW_TIB" | translate }}</a
                              >
                              <span
                                *ngIf="courseService.getWeek4ContentLocked(4)"
                                class="lock-icon"
                                >🔒</span
                              >
                            </li>
                          </ul> -->
                        </li>
                        <li
                          class="task-item"
                          [ngClass]="{
                            'locked-task':
                              courseService.getWeek5ContentLocked(5)
                          }"
                        >
                          <div class="task-header">
                            <div
                              class="circle small-circle"
                              [ngClass]="{
                                completed:
                                  !courseService.getWeek5ContentLocked(5)
                              }"
                            ></div>
                            <a
                              class="task-item-link"
                              href="#"
                              routerLink="/week5/review_course"
                              routerLinkActive="active"
                              [ngClass]="{
                                'disabled-link':
                                  courseService.getWeek5ContentLocked(5)
                              }"
                              >{{ "MENU_REVIEW_COURSE" | translate }}</a
                            >
                            <span
                              *ngIf="courseService.getWeek5ContentLocked(5)"
                              class="lock-icon"
                              >🔒</span
                            >
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </li>

              <!-- <li class="nav-item">
                                <i class="f-icons f-action"></i>
                                <a class="nav-link" href="#" routerLink="/plan">Self Management Tips</a>
                                <a class="nav-link" href="#" routerLink="/plan">{{ 'TIPS' | translate }}</a>
                            </li>	-->
            </div>

            <div class="bottom">
              <li class="nav-item" style="">
                <i class="f-icons f-cbti"></i>
                <!-- <a class="nav-link" href="#" routerLink="/cbti">What is CBT-I?</a>  -->
                <a class="nav-link" href="#" routerLink="/cbti">{{
                  "WHAT" | translate
                }}</a>
              </li>

              <li class="nav-item">
                <i class="f-icons f-contact-us"></i>
                <!-- <a class="nav-link" href="#" routerLink="/contact">Contact us</a>  -->
                <a class="nav-link" href="#" routerLink="/contact">{{
                  "CONTACT" | translate
                }}</a>
              </li>

              <li class="nav-item">
                <i class="f-icons f-logout"></i>
                <!-- <a class="nav-link" (click)="logout()" href="#">Logout</a>  -->
                <a class="nav-link" (click)="logout()" href="#">{{
                  "LOGOUT" | translate
                }}</a>
              </li>
            </div>
          </ul>
          <ng-template #elseBlock>
            <ul class="nav flex-column p-4 mt-4">
              <li class="nav-item">
                <i class="f-icons f-home"></i>
                <!-- <a class="nav-link active" aria-current="page" routerLink="/dashboard"
                                    routerLinkActive="active">Dashboard</a>  -->
                <a
                  class="nav-link active"
                  aria-current="page"
                  routerLink="/dashboard"
                  routerLinkActive="active"
                  >{{ "DASHBOARD" | translate }}</a
                >
              </li>
              <li class="nav-item">
                <i class="f-icons f-pencil"></i>
                <!-- <a class="nav-link" href="#" routerLink="/add-patient" routerLinkActive="active">Add
                                    Patient</a>  -->
                <a
                  class="nav-link"
                  href="#"
                  routerLink="/add-patient"
                  routerLinkActive="active"
                  >{{ "ADDPATIENT" | translate }}</a
                >
              </li>
              <li class="nav-item">
                <i class="f-icons f-pencil"></i>
                <a
                  class="nav-link"
                  href="#"
                  routerLink="/administrator"
                  routerLinkActive="active"
                  >Administrator</a
                >
              </li>
              <li class="nav-item">
                <i class="f-icons f-action"></i>
                <!-- <a class="nav-link" (click)="logout()" href="#">Logout</a>  -->
                <a class="nav-link" (click)="logout()" href="#">{{
                  "LOGOUT" | translate
                }}</a>
              </li>
            </ul>
          </ng-template>
        </div>
      </nav>
    </div>
    <!-- subnav router outlet -->
    <router-outlet name="subnav"></router-outlet>

    <!-- global alert -->
    <alert style="position: fixed; right: 0; left: 0; z-index: 9999"></alert>

    <!--Tour Template-->
    <tour-step-template> </tour-step-template>

    <!-- main router outlet -->
    <div
      class="col small_devices"
      [ngClass]="{
        'mt-5':
          !hasRoute('onboarding') &&
          !startsWithRoute('account/login') &&
          !startsWithRoute('account/register')
      }"
    >
      <router-outlet></router-outlet>
    </div>
    <div
      id="right-nav"
      class="col-2"
      *ngIf="(account || accountsbs) && !hasRoute('onboarding')"
    >
      <div class="row" *ngIf="hasRoute('educate')">test</div>
    </div>
  </div>
</div>
