import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { Contactus } from '@app/_models/contactus';

const hostUrl = `${environment.apiUrl}/contact`;

const baseUrl = `${environment.apiUrl}/api`;
const baseUrlJournal = `${environment.apiUrl}/journal`;

@Injectable({ providedIn: 'root' })
export class ContactService {
  constructor(private http: HttpClient) {}

  contact(contact: Contactus) {
    return this.http.post(`${hostUrl}/contact/`, contact);
  }

  getAllContacts(
    name: string,
    isProcessed: string,
    limit: number,
    offset: number,
    activeSort: string,
    sortOrder: string
  ): Observable<any> {
    let params = new HttpParams();
    let orderState = sortOrder === 'desc' ? '-' : '';
    const ordering = orderState + activeSort;
    if (name) {
      params = params.set('name', name);
    }

    if (isProcessed !== undefined && isProcessed !== '') {
      params = params.set('is_processed', isProcessed);
    }

    params = params.set('offset', offset.toString());
    params = params.set('limit', limit.toString());
    params = params.set('ordering', ordering);

    return this.http.get<any>(`${hostUrl}/list/`, { params });
  }

  getContactDetailsById(id: number): Observable<any> {
    return this.http.get<any>(`${hostUrl}/contact-details/${id}/`);
  }
}
