<div class="custom-dialog">
  <h2>{{ "DIALOG_CONFIRMATION" | translate }}</h2>
  <h3>
    <p>{{ "INTRO_DIALOG_CONFIRM_INFO" | translate }}</p>
  </h3>
  <div class="form-group text-center">
    <button
      class="btn btn-primary field mx-auto col-3 me-2"
      (click)="onConfirm()"
    >
      {{ "DIALOG_CONFIRM" | translate }}
    </button>
    <button
      class="btn btn-primary field mx-auto col-3 ms-2"
      (click)="onCancel()"
    >
      {{ "DIALOG_CANCEL" | translate }}
    </button>
  </div>
</div>
