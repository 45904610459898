<app-header *ngIf="!showSpecificPatient"></app-header>
  <div class="card-body" [ngClass]="showSpecificPatient ? 'p-0 mt-4' : ''">
  
    <h3 class="card-header-date content-box-tsi">
        <app-filter></app-filter>
        <app-range-slider [selectedDate]="currentDate" (onWeekShift)="refreshWeekData($event)"  (onDatePicked)="refreshCurrentEntry($event)"></app-range-slider>
        <div class="row " style="margin-top : 20px;">
          <!-- <div class="col p-0">
            <app-donutchart [firstValue]="efficiencyFromPrevious" [secondValue]="100-Math.abs(efficiencyFromPrevious)" [comparedEfficiency]="true"></app-donutchart>
          </div> -->
		  <div class="col-md-2"></div>
          <div class="col-md-3">
            <app-donutchart [firstValue]="currentEntry?.total_asleep" [secondValue]="currentEntry?.total_time_in_bed -currentEntry?.total_asleep" [comparedEfficiency]="true" [dailyLabel]="true"></app-donutchart>
          </div>
          <div class="col-md-5 mt-auto mb-auto mar">
            <div class="row mb-3" style="margin-left: 1em">
              <i class="f-icons f-half-moon"></i>
              <div class="col-8">
                <div class="icon-heading">Total Asleep</div>
                <div style="font-size: 1.5rem; font-weight: 600;">{{currentEntry?.total_asleep ? (currentEntry?.total_asleep|formatTime) : ''}}</div>
              </div>
            </div>
            <div class="row" style="margin-left: 1em">
              <i class="f-icons f-clipboard"></i>
              <div class="col-8">
                <div class="icon-heading">Total Time in Bed</div>
                <div style="font-size: 1.5rem; font-weight: 600;">{{currentEntry?.total_time_in_bed ? (currentEntry?.total_time_in_bed|formatTime): ''}}</div>
              </div>
            </div>
            <div class="row" style="margin-left: 1em;margin-top: 1rem">
              <i class="f-icons f-sleep" style="background-size: 30px;"></i>
              <div class="col-8">
                <div class="icon-heading">Sleep Efficiency</div>
                <div style="font-size: 1.5rem; font-weight: 600;">{{Math.round(100 * currentEntry?.total_asleep / (currentEntry?.total_time_in_bed))}}%</div>
              </div>
            </div>
          </div>
		  <div class="col-md-2"></div>
        </div>
        <!-- <piechart></piechart> -->
    </h3>
  
		<h3 class="card-header-date content-box-tsi">
		  <div id="header-text">Sleep Journal
		  </div>
		<div class="row" *ngIf="this.Role==adminRole">
		  <div class="column-left" id="admin_column_left" [style.display]="isVisible_left ? 'block' : 'none'">
			<p class="question">
			  What time did you wake up this morning?
			</p>
			<p class="user-inputs">
			  {{currentEntry?.wake_up_time ? (currentEntry.wake_up_time | strToTime | date:'h:mm a'): ''}}
			  <!-- {{entryForDay?.wake_up_time | strToTime | date:'HH:mm a' ?? 'no'}} -->
			</p>
			<p class="question">
			  What time did you rise from bed this morning?
			</p>
			<p class="user-inputs">
			  {{currentEntry?.rise_from_bed ? (currentEntry.rise_from_bed | strToTime | date:'h:mm a'): ''}}

			</p>

			<p class="question">
			  What time did you go to bed last night?
			</p>
			<p class="user-inputs">
			  <!--TODO: CHECK FOR AM/PM-->
			  {{currentEntry?.go_to_bed ? (currentEntry.go_to_bed | strToTime | date:'h:mm a'): ''}}
			</p>
			<p class="question">
			  What time did you turn the light out?
			</p>
			<p class="user-inputs">
			  {{currentEntry?.lights_off_time ? (currentEntry.lights_off_time | strToTime | date:'h:mm a'): ''}}
			</p>

			<p class="question">

			  How long did it take you to fall asleep?
			</p>
			<p class="user-inputs">
			  {{currentEntry?.duration_falling_sleep ? (currentEntry.duration_falling_sleep | formatTime): ''}}
			</p>
			<p class="question">

			  Number of awakenings throughout the night
			</p>
			<p class="user-inputs">
			  {{currentEntry?.number_awakening}}
			</p>
			<p class="question">

			  Time Awake during Awakenings
			</p>
			<p class="user-inputs">
			  {{currentEntry?.minutes_awake ? (currentEntry.minutes_awake | formatTime): ''}}
			</p>
                        <button type="button" (click)="onEdit()"
                            class="btn btn-secondary rounded-pill w-25 my-2 reset-button mx-4">
                            Edit
                        </button>			
		  </div>
		  <div class="column-right"  id="admin_column_right" [style.display]="isVisible_right ? 'block' : 'none'">
			<p class="question">
			  List of stimulants you took
			</p>
			<p class="user-inputs">
			  {{currentEntry?.list_stimulants}}
			</p>
			<p class="question">
			  List any sleep medications
			</p>
			<p class="user-inputs">
			  {{currentEntry?.list_sleep_medications}}
			</p>
			<p class="question">
			  How well rested are you?
			</p>
			<p class="user-inputs">
			  {{currentEntry?.rate_rest}}{{currentEntry?.rate_rest ? "/5":""}}
			</p>
			<p class="question">
			  Was your sleep of good quality?
			</p>
			<p class="user-inputs">
			  {{currentEntry?.rate_sleep_quality}}{{currentEntry?.rate_sleep_quality ? "/5":""}}
			</p>
		  </div>
          		  
		<form class="p-4"  id="edit_form" [style.display]="isVisible ? 'block' : 'none'" *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label class="question"></label>
                        <input type="hidden" disabled id="id" formControlName="id" class="form-control" />
                    </div>
                </div>
                <div class="col-6">
				         <label class="question">What time did you wake up this morning?</label>
                    <div class="form-group">
                        <input type="text" id="wake_up_time" formControlName="wake_up_time" class="form-control" />
                    </div>
                </div>
                <div class="col-6">
                        <label class="question">List of stimulants you took</label>
                    <div class="form-group">
                        <input type="text" id="list_stimulants" formControlName="list_stimulants" class="form-control" />
                    </div>
                </div>

				
                <div class="col-6">
						<label class="question">What time did you rise from bed this morning?</label>
                    <div class="form-group">
                        <input type="text" id="rise_from_bed" formControlName="rise_from_bed" class="form-control" />
                    </div>
                </div>				
                <div class="col-6">
                        <label class="question">List any sleep medications</label>
                    <div class="form-group">
                        <input type="text" id="list_sleep_medications" formControlName="list_sleep_medications" class="form-control" />
                    </div>
                </div>


                <div class="col-6">
				        <label class="question">What time did you go to bed last night?</label>
                    <div class="form-group">   
                        <input type="text" id="go_to_bed" formControlName="go_to_bed" class="form-control" />
                    </div>
                </div>
                <div class="col-6">
                        <label class="question">How well rested are you?</label>
                    <div class="form-group">
                        <input type="text" id="rate_rest" formControlName="rate_rest" class="form-control" />
                    </div>
                </div>	

                <div class="col-6">
				        <label class="question">What time did you turn the light out?</label>
                    <div class="form-group">
                        <input type="text" id="lights_off_time" formControlName="lights_off_time" class="form-control" />
                    </div>
                </div>
                <div class="col-6">
                        <label class="question">Was your sleep of good quality?</label>
                    <div class="form-group">
                        <input type="text" id="rate_sleep_quality" formControlName="rate_sleep_quality" class="form-control" />
                    </div>
                </div>


                <div class="col-6">
				        <label class="question">How long did it take you to fall asleep?</label>
                    <div class="form-group">
                        <input type="text" id="duration_falling_sleep" formControlName="duration_falling_sleep" class="form-control" />
                    </div>
                </div>
                <div class="col-6"></div>		

                <div class="col-6">
                        <label class="question">Number of awakenings throughout the night</label>
                    <div class="form-group">
                        <input type="text" id="number_awakening" formControlName="number_awakening" class="form-control" />
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label class="question"></label>
                        <input type="hidden" disabled id="entry_date" formControlName="entry_date" class="form-control" />
                    </div>
                </div>
				
                <div class="col-6">
                        <label class="question">Time Awake during Awakenings</label>				
                    <div class="form-group">
                        <input type="text" id="minutes_awake" formControlName="minutes_awake" class="form-control" />
                    </div>
                </div>
                <div class="col-6"></div>
				
                <div class="col-6">
                    <div class="form-group form-action-container">
                        <button type="submit" class="btn btn-primary rounded-pill w-25 my-2">Submit</button>

                    </div>
                </div>
        </div>

        </form>
							
		</div>
      <diaryjournal [entryForDay]="currentEntry"></diaryjournal>
  
    </h3>
 
    <diarynotes></diarynotes>
  
  