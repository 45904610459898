
// custom function to plot the average values in the graph
export function  plotAverageGraph(timeInBed: number, timeInBedColor: string, timeInSleep: number, timeInSleepColor: string, resetBarValue: Function){
    
    if(isNaN(timeInBed)){
      timeInBed = 0
    }

    if(isNaN(timeInSleep)){
      timeInSleep = 0
    }
    
    setTimeout(()=>{
      resetBarValue()
      let chartElement = document.querySelector("barchart .ngx-charts-outer .y.axis g g")
      let chartElementContainer = document.querySelector("barchart .ngx-charts-outer .y.axis g")

      let firstELem = chartElement.firstElementChild

      let lastElem = chartElement.lastElementChild

      let minimumValue:any = firstELem.getAttribute("transform").split(",")[1].split(")")[0]

      minimumValue = parseInt(minimumValue)

      let maximumValue:any =lastElem.getAttribute("transform").split(",")[1].split(")")[0]

      maximumValue = parseInt(maximumValue)

      let plotValue = Math.abs((maximumValue - minimumValue)*(timeInBed/100) + minimumValue)

      let plotValueForSleep = Math.abs((maximumValue - minimumValue)*(timeInSleep/100) + minimumValue)

      let axisElement = document.querySelector("barchart .ngx-charts-outer .y.axis g ").children

      let clonedElementForBed:any = axisElement[1].cloneNode(true)
      let clonedElementForSleep:any = axisElement[1].cloneNode(true)

      clonedElementForBed.id = "clonedElementForBed"
      clonedElementForSleep.id = "clonedElementForSleep"

      clonedElementForBed.setAttribute("transform", "translate(0,"+ plotValue +")")
      clonedElementForSleep.setAttribute("transform", "translate(0,"+ plotValueForSleep +")")

      clonedElementForBed.querySelector(".gridline-path").style.stroke = timeInBedColor
      clonedElementForSleep.querySelector(".gridline-path").style.stroke = timeInSleepColor

      clonedElementForBed.querySelector(".gridline-path").style.strokeWidth = 1
      clonedElementForBed.querySelector(".gridline-path").style.strokeDasharray = "5,5";
      clonedElementForSleep.querySelector(".gridline-path").style.strokeWidth = 1

      chartElementContainer.append(clonedElementForBed)
      chartElementContainer.append(clonedElementForSleep)
    }, 500)


  }
