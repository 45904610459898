import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

export default class Validation {
  static match(controlName: string, checkControlName: string): ValidatorFn {
    return (controls: AbstractControl) => {
      const control = controls.get(controlName);
      const checkControl = controls.get(checkControlName);
      if (checkControl.errors && !checkControl.errors.matching) {
        return null;
      }
      if (control.value !== checkControl.value) {
        controls.get(checkControlName).setErrors({ matching: true });
        return { matching: true };
      } else {
        return null;
      }
    };
  }

  static dateLessThan(to: string) {
    return (controls: AbstractControl) => {
      const fromDate = moment.now()
      const toDate = controls.get(to);
      const momentToDate = moment(toDate.value)
      if (momentToDate.isAfter(fromDate)) {
        controls.get(to).setErrors({ dateLessThan: true });
        return { matching: true };
      }
      return null;
    }
  }
}