import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CourseService } from '@app/_services/course.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-week3-page4',
  templateUrl: './week3-page4.component.html',
  styleUrls: ['./week3-page4.style.scss'],
})
export class Week3Page4Component implements OnInit {
  form: FormGroup;
  formError = false;
  safeHtmlContentNegative: SafeHtml;
  safeHtmlContentSolutions: SafeHtml;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '100px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    placeholder: 'Enter text here ...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
        'strikeThrough',
        'subscript',
        'superscript',
        'heading',
        'fontName',
        'backgroundColor',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'insertImage',
        'insertVideo',
        'insertLink',
        'toggleEditorMode',
      ],
    ],
  };

  constructor(
    private router: Router,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private courseService: CourseService,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer
  ) {
    this.form = this.fb.group({
      week_number: ['3'],
      self_assessment_text: ['', Validators.required],
      reflection_text: ['', Validators.required],
    });
    this.translate.get('W2_NEGATIVE_FACTORS').subscribe((res: string) => {
      this.safeHtmlContentNegative =
        this.sanitizer.bypassSecurityTrustHtml(res);
    });

    this.translate.get('W2_SOLUTIONS').subscribe((res: string) => {
      this.safeHtmlContentSolutions =
        this.sanitizer.bypassSecurityTrustHtml(res);
    });
  }

  ngOnInit(): void {
    const savedLang = localStorage.getItem('selectedLanguage');
    if (savedLang) {
      this.translate.use(savedLang);
    } else {
      this.translate.use(this.translate.defaultLang);
    }

    // Paremeter(3): Indicates the number of the current week
    // Paremeter(4): Indicates the number of the current page
    this.courseService.isStudied(3, 4).then((result) => {
      if (result) {
        const id: number = JSON.parse(localStorage.getItem('token')).user.id;

        this.courseService.getSelfAssessmentReflection(3).subscribe(
          (data) => {
            if (data) {
              this.form.patchValue({
                self_assessment_text: data.self_assessment_text,
                reflection_text: data.reflection_text,
              });
            }
          },
          (error) => {
            console.error(
              'Error fetching self-assessment and reflection data.',
              error
            );
          }
        );
      }
    });

    this.translate.onLangChange.subscribe((event) => {
      localStorage.setItem('selectedLanguage', event.lang);
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }

  onSubmit() {
    this.courseService.saveSelfAssessmentReflection(this.form.value).subscribe(
      (response) => {
        console.log(
          'Self-Assessment and reflection saved sucessfully',
          response
        );
      },
      (error) => {
        console.log('Self-Assessment and reflection saved failure', error);
      }
    );
    // this.courseService.unlockWeek(2);

    //  Parameter (4): The index starts from 0, unlock the third page of the current week
    this.courseService.unlockWeek3Content(4);

    //  Parameter (3): Indicates the number of the current week.
    //  Parameter (5): Indicates the fifth page of the current week.
    this.courseService.updateProgress(3, 5);

    this.router.navigate(['week3/plan_for_the_week']);
  }
}
