<div class="admin-dashboard container p-4">
  <!-- <form [formGroup]="form"> -->
  <h1 class="pb-4 pt-4">Contact Details</h1>

  <div id="pdfContent">
    <!-- <form [formGroup]="form"> -->
    <h4 style="color: #002e46; font-size: 18px">
      <div class="course-section">
        <div class="details-input">
          <label>Name</label>
          <input
            value="{{ contact?.first_name }} {{ contact?.last_name }}"
            type="text"
            readonly
          />
          <label>Email</label>
          <input value="{{ contact?.email }}" type="text" readonly />
          <label>Phone</label>
          <input value="{{ contact?.phone }}" type="text" readonly />
        </div>
        <div class="details-input">
          <label>Created Time</label>
          <input
            value="{{ parseMomentDateTime(contact?.created) }}"
            type="text"
            readonly
          />
          <label>Processed</label>
          <input
            value="{{ contact?.is_processed ? 'Processed' : 'Not Processed' }}"
            type="text"
            readonly
          />

          <label>Updated Time</label>
          <input
            value="{{ parseMomentDateTime(contact?.updated) }}"
            type="text"
            readonly
          />
        </div>

        <h3>Customers' Messages</h3>

        <div class="details-input">
          <label class="label-size">Message</label>
          <textarea [ngModel]="contact?.message" readonly></textarea>
        </div>

        <div *ngIf="chkMessage" class="alert alert-info">
          {{ chkMessage }}
        </div>
        <h3>Our Responses</h3>

        <div class="d-flex justify-content-between">
          <button
            class="btn btn-primary field mx-auto col-3"
            style="min-width: 140px"
            (click)="openJournalDetails()"
            [disabled]="noUserId"
          >
            Journal Details
          </button>
          <button
            class="btn btn-primary field mx-auto col-3"
            style="min-width: 140px"
            (click)="openCourseDetails()"
            [disabled]="noUserId"
          >
            Course Details
          </button>
          <button
            class="btn btn-primary field mx-auto col-3"
            style="min-width: 140px"
            (click)="openPageDetails()"
            [disabled]="noUserId"
          >
            Page Details
          </button>
        </div>

        <div class="course-section">
          <div class="details-input">
            <label>Current Week</label>
            <input
              value="{{ currentWeek }}"
              type="text"
              readonly
              [disabled]="noUserId"
            />

            <label>Current Page</label>
            <input
              value="{{ currentPage }}"
              type="text"
              readonly
              [disabled]="noUserId"
            />
            <label>Updated Week</label>
            <input
              [(ngModel)]="updatedWeek"
              type="text"
              [disabled]="noUserId"
              style="padding-right: 2%"
            />
          </div>
          <div class="details-input">
            <label></label>
            <input type="text" readonly style="visibility: hidden" />
            <label></label>
            <input type="text" readonly style="visibility: hidden" />

            <button
              class="sleep-journal-button btn btn-primary mx-auto field col-3"
              (click)="modifyCourseInfo()"
              [disabled]="noUserId || updateSuccess || contact?.is_processed"
            >
              Change Week Number
            </button>
          </div>
          <p *ngIf="updateError" class="alert alert-danger">
            {{ updateError }}
          </p>
          <p *ngIf="updateSuccess" class="alert alert-success">
            Week number updated successfully!
          </p>
        </div>

        <div class="details-input">
          <label class="label-size">Response</label>
          <textarea
            [(ngModel)]="response"
            [value]="contact?.response_text"
          ></textarea>
        </div>

        <div class="details-input">
          <button
            class="sleep-journal-button btn btn-primary mx-auto field col-3"
            style="min-width: 250px"
            (click)="sendResponse()"
            [disabled]="isEmailSent || contact?.is_processed"
          >
            Send Response Email
          </button>
          <div
            *ngIf="message"
            class="alert alert-info mt-2"
            style="text-align: center"
            [innerHTML]="message"
          ></div>
        </div>
      </div>
    </h4>
  </div>
</div>
