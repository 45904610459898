import { Component, ElementRef, forwardRef, HostListener, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Observable } from 'rxjs';

// https://blog.angular-university.io/angular-custom-form-controls/
export interface FormFieldValue{
  hours: number
  minutes: number 
}
@Component({
  selector: 'app-duration',
  templateUrl: './duration.component.html',
  styleUrls: ['./duration.component.less'],
  providers: [
    {
    provide: MatFormFieldControl,
    useExisting: DurationComponent
  },
  {
    provide: NG_VALUE_ACCESSOR,
    multi:true,
    useExisting: forwardRef(() => DurationComponent),
  },
  {
    provide: NG_VALIDATORS,
    multi: true,
    useExisting: DurationComponent
  }
  ]
})
export class DurationComponent implements OnInit, MatFormFieldControl<Number>,ControlValueAccessor {

  _value: any;
  hours: number = 0;
  mins: number = 0;
  @Input()
  hidden: boolean = true;

  @Output()
  getanynumber(){
    return 2;
  }
//   @Input()
// formControlName;
onChange = (value) => {};

  onTouched = () => {};
  touched = false;

  disabled = false;
  value = 10;
  // @Input()
  // get value(): number {
  //   return 10;
  //   // this.onChange(this._value);
  //   // return this.hours*60 + this.mins;

  // }
  // set value(value) {
  //   this._value = 20;
  //   // this._value = this.hours*60 + this.mins;
  //   //  this.editor.setContents(this._value);
  //   //  this.onChange(value);
  //   // this.stateChanges.next();
  // }
  // value: Number = this.hours*60 + this.mins;
  @HostListener('document:click', ['$event'])
  clickout(event) {
    debugger;
    if(this.eRef.nativeElement.contains(event.target)) {
      this.hidden = false;
    } else {
      this.hidden = true;
    }
  }
  
  constructor(private eRef: ElementRef) { 
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  updatevalue() {
    this.markAsTouched();
    if (!this.disabled) {
      this.value= this.hours*60+this.mins;
      this.onChange(this.value);
    }
  }
  writeValue(value: any): void {
    this.value = value;
    // throw new Error('Method not implemented.');
  }
  registerOnChange(onChange: any): void {
    this.onChange = onChange;

    // throw new Error('Method not implemented.');
  }
  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;

    // throw new Error('Method not implemented.');
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  // validate(control: AbstractControl): ValidationErrors {
  //   throw new Error('Method not implemented.');
  // }
  // registerOnValidatorChange?(fn: () => void): void {
  //   throw new Error('Method not implemented.');
  // }
  // value: number;
  stateChanges: Observable<void>;
  id: string;
  placeholder: string;
  ngControl: NgControl;
  focused: boolean;
  empty: boolean;
  shouldLabelFloat: boolean = true;
  required: boolean;
  // disabled: boolean;
  errorState: boolean;
  controlType?: string;
  autofilled?: boolean;
  userAriaDescribedBy?: string;
  setDescribedByIds(ids: string[]): void {
    // throw new Error('Method not implemented.');
  }
  onContainerClick(event: MouseEvent): void {
    // throw new Error('Method not implemented.');
  }

  ngOnInit(): void {
  }

  showSlider() {
    this.hidden = !this.hidden;
  }
  hideSlider() {
    this.hidden = true;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const quantity = control.value;
    if (quantity <= 0) {
      return {
        mustBePositive: {
          quantity
        }
      };
    }
  }

}
