let w = new Date;
let days = new Date(w.getFullYear(), w.getMonth(),0).getDate()

export var initData = [];

for(let i = 1; i <= days; i++){
 initData.push({
    name: "Day " + i,
    series: [
        {
            name: 'Sleep Time',
            value: 0,
            tooltipText: ''
        },
        {
            name: 'Time in Bed',
            value: 0,
            tooltipText: ''
        },
    ],
});
};


export var sleepEfficiencyInit = [];

for(let i = 1; i <= days; i++){
    sleepEfficiencyInit.push({
        "name": "Day "+i,
        "value": 0
      });
   };
