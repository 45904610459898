<div class="admin-dashboard container p-4">
  <h1 class="pb-4 pt-4">Course Status</h1>
  <div class="py-4 d-flex justify-content-between align-items-center">
    <div class="d-inline-flex">
      <div class="has-search mr-2">
        <span class="fa fa-search form-control-feedback"></span>
        <input
          class="form-control mr-2 search-patient"
          type="text"
          placeholder="Search by name"
          [(ngModel)]="searchString"
          (input)="onSearch()"
        />
      </div>
    </div>
  </div>

  <div>
    <div class="container pt-4 pb-2">
      <h4 style="color: #002e46; font-size: 18px">
        <table class="table table-hover patient-details-table">
          <thead>
            <tr>
              <th (click)="sortData('first_name')">
                Name
                <i class="fa" [ngClass]="getSortIcon('first_name')"></i>
              </th>
              <th (click)="sortData('week1_actual_unlock_time')">
                Unlock Time<i
                  class="fa"
                  [ngClass]="getSortIcon('week1_actual_unlock_time')"
                ></i>
              </th>
              <th (click)="sortData('current_week')">
                Course Status
                <i class="fa" [ngClass]="getSortIcon('current_week')"></i>
              </th>
              <th (click)="sortData('last_update_time')">
                Last Update Time<i
                  class="fa"
                  [ngClass]="getSortIcon('last_update_time')"
                ></i>
              </th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody *ngIf="patientList?.length > 0; else elseBlock">
            <tr *ngFor="let item of patientList">
              <td>{{ item.first_name }} {{ item.last_name }}</td>
              <td>
                {{ parseMomentDate(item.progress?.week1_actual_unlock_time) }}
              </td>
              <td>
                {{
                  item.progress?.current_week === 6
                    ? "Complete"
                    : item.progress?.current_week
                    ? "Week" + item.progress?.current_week
                    : "-"
                }}
              </td>
              <td>{{ parseMomentDate(item.progress?.last_update_time) }}</td>

              <td>
                <i
                  class="fa fa-file-text"
                  aria-hidden="true"
                  (click)="goToCourseDetails(item.id)"
                ></i>
                &nbsp;
                <i
                  class="fa fa-pencil"
                  aria-hidden="true"
                  (click)="goToCourseEdit(item.id)"
                ></i>
              </td>
            </tr>
          </tbody>
          <ng-template #elseBlock>
            <tbody>
              <tr colspan="5">
                <p class="text-center">No data found</p>
              </tr>
            </tbody>
          </ng-template>
        </table>
        <div *ngIf="totalCount >= limit" class="pagination">
          <button
            class="btn btn-primary px-3 patient-controls page-btn"
            (click)="previousPage()"
            [disabled]="offset === 0"
          >
            Previous
          </button>
          <span style="margin-top: 2px"
            >Page {{ currentPage }} of {{ totalPages }} (Total:{{
              totalCount
            }})</span
          >
          <button
            class="btn btn-primary px-3 patient-controls page-btn"
            (click)="nextPage()"
            [disabled]="!hasMoreData"
          >
            Next
          </button>
        </div>
      </h4>
    </div>
  </div>
</div>
