
<div class="charts stack-bar" style="min-height: 300px;" #ContainerRef>
  <ngx-charts-bar-vertical-stacked  [view]="view" [scheme]="colorScheme" [results]="data" [gradient]="gradient"
    [xAxis]="false" [yAxis]="true" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel" yScaleMax="1200"
    [yAxisTicks]= [0,120,240,360,480,600,720,840]
    [yAxisLabel]="yAxisLabel" [animations]="animations" [maxYAxisTickLength]="500000" yScale="_update" [roundDomains]="true" [yAxisTickFormatting]="yAxisFormat" [xAxisTickFormatting]='axisFormat'  [showGridLines]="true"  (window:resize)="onResize($event)">
    <!--Ref: https://stackblitz.com/edit/angular-wgy1tg?file=src/app/app.component.html -->
       <ng-template #tooltipTemplate let-model="model">
        <h2>{{model.tooltipText}}</h2>
      </ng-template>
  </ngx-charts-bar-vertical-stacked>
</div>
<!-- [view]="view" -->