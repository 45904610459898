<div class="admin-dashboard container p-4">
  <!-- <form [formGroup]="form"> -->
  <h1 class="pb-4 pt-4">Course Status Details</h1>

  <div id="pdfContent">
    <!-- <form [formGroup]="form"> -->
    <h4 style="color: #002e46; font-size: 18px">
      <div class="course-section">
        <div class="details-input">
          <label>Name</label>
          <input
            value="{{ courseDetails?.first_name }} {{
              courseDetails?.last_name
            }}"
            type="text"
            readonly
          />

          <label>email</label>
          <input value="{{ courseDetails?.email }}" type="text" readonly />
          <label>mobile</label>
          <input value="{{ courseDetails?.mobile }}" type="text" readonly />
        </div>
        <div class="details-input">
          <label>Sex</label>
          <input
            value="{{
              profileData?.sex === undefined || profileData?.sex === null
                ? ''
                : profileData?.sex === 1
                ? 'Male'
                : 'Female'
            }}"
            type="text"
            readonly
          />
          <label>Age</label>
          <input value="{{ profileData?.age }}" type="text" readonly />

          <label>Last Login Time</label>
          <input
            value="{{ parseMomentDateTime(progressData?.last_update_time) }}"
            type="text"
            readonly
          />
        </div>
        <div class="details-input">
          <label>Referral Source</label>
          <input
            value="{{ profileData?.referral_source }}"
            type="text"
            style="padding-right: 1%"
            readonly
          />
          <label>Other Details</label>
          <input
            value="{{ profileData?.other_details }}"
            type="text"
            style="margin-left: 0.5%; margin-right: 10%"
            readonly
          />

          <label></label>
          <input
            style="visibility: hidden"
            value="{{ parseMomentDateTime(progressData?.last_update_time) }}"
            type="text"
            readonly
          />
          <!-- <button
            class="btn btn-primary field mx-auto col-3"
            (click)="openJournalDetails()"
            sytle=""
          >
            Journal Details
          </button> -->
        </div>
      </div>

      <div
        *ngIf="
          progressData &&
            progressData?.current_week &&
            progressData?.current_week >= 1;
          else elseBlock
        "
      >
        <div *ngIf="progressData?.current_week >= 1">
          <h2 class="week-font">
            Week 1
            {{ parseMomentStartYM(progressData?.week1_expected_unlock_time) }}
            {{ addDayAndFormat(progressData?.week1_expected_unlock_time, 6) }}
          </h2>
          <div class="course-section">
            <h3>Unlock Time</h3>
            <div class="details-input">
              <label>Expected</label>
              <input
                value="{{
                  parseMomentDateTime(progressData?.week1_expected_unlock_time)
                }}"
                type="text"
                readonly
              />

              <label>Actual </label>
              <input
                value="{{
                  parseMomentDateTime(progressData?.week1_actual_unlock_time)
                }}"
                type="text"
                readonly
              />
              <label>Renew Times</label>
              <input
                value="{{ progressData?.week1_check_flg }}"
                type="text"
                readonly
              />
            </div>

            <div *ngIf="weeklyCheckInData[0]">
              <h3>Weekly “Check in” (0:Bad - 5:Good)</h3>
              <div class="details-input">
                <label>Sleep Change </label>
                <input
                  value="{{ weeklyCheckInData[0]?.sleep_change }}"
                  type="text"
                  readonly
                />

                <label>Sleep Quality</label>
                <input
                  value="{{ weeklyCheckInData[0]?.sleep_quality }}"
                  type="text"
                  readonly
                />
                <label>Daytime Function</label>
                <input
                  value="{{ weeklyCheckInData[0]?.daytime_functioning }}"
                  type="text"
                  readonly
                />
              </div>

              <div class="details-input" style="margin-top: 1%">
                <label class="label-size">Comments or Observations</label>
                <textarea
                  [ngModel]="weeklyCheckInData[0]?.comments_text"
                  readonly
                ></textarea>
              </div>
            </div>

            <h3>Daily Sleep Efficiency Average (Target: 80%)</h3>
            <div class="details-input">
              <label>Sleep Time</label>
              <input
                value="{{
                  getDailySleepTime(sleepJournalData[0]?.total_sleep_time)
                }}"
                type="text"
                readonly
              />
              <label>Time In Bed</label>
              <input
                value="{{
                  getDailySleepTime(sleepJournalData[0]?.total_time_in_bed)
                }}"
                type="text"
                readonly
              />

              <label>Efficiency</label>
              <input
                value="{{ sleepJournalData[0]?.sleep_efficiency }}"
                type="text"
                readonly
              />
            </div>

            <div *ngIf="desiredSleepData[0]?.desired_sleep_time">
              <h3>Desired Sleep Time</h3>
              <div class="details-input">
                <label>Go to Bed </label>
                <input
                  value="{{
                    formatTimeWithMoment(
                      desiredSleepData[0]?.desired_go_to_bed_time
                    )
                  }}"
                  type="text"
                  readonly
                />

                <label>Wake Up</label>
                <input
                  value="{{
                    formatTimeWithMoment(
                      desiredSleepData[0]?.desired_wake_up_time
                    )
                  }}"
                  type="text"
                  readonly
                />
                <label>Sleep Time</label>
                <input
                  value="{{
                    getDesiredSleepTime(desiredSleepData[0]?.desired_sleep_time)
                  }}"
                  type="text"
                  readonly
                />
              </div>
            </div>

            <h3>Self-Assessment and Reflection</h3>

            <div class="details-input">
              <label class="label-size">Self_assessment</label>
              <textarea
                [ngModel]="selfReflectData[0]?.self_assessment_text"
                readonly
              ></textarea>
            </div>
            <div class="details-input">
              <label class="label-size">Reflection</label>
              <textarea
                class="speech-bubble-container"
                [ngModel]="selfReflectData[0]?.reflection_text"
                readonly
              ></textarea>
            </div>

            <h3>Patient's Summary and Suggestions</h3>
            <div class="details-input">
              <label class="label-size">Suggestion</label>
              <textarea
                [(ngModel)]="suggestion1"
                (blur)="saveSuggestion(1, suggestion1)"
              ></textarea>
            </div>
          </div>
          <div *ngIf="progressData?.current_week > 1">
            <app-week2-page2 [id]="patientId" [role]="1"></app-week2-page2>
          </div>
        </div>
        <div *ngIf="progressData?.current_week >= 2">
          <h2 class="week-font">
            Week 2
            {{ parseMomentStartYM(progressData?.week2_expected_unlock_time) }}
            {{ addDayAndFormat(progressData?.week2_expected_unlock_time, 6) }}
          </h2>
          <div class="course-section">
            <h3>Unlock Time</h3>
            <div class="details-input">
              <label>expected</label>
              <input
                value="{{
                  parseMomentDateTime(progressData?.week2_expected_unlock_time)
                }}"
                type="text"
                readonly
              />

              <label>actual </label>
              <input
                value="{{
                  parseMomentDateTime(progressData?.week2_actual_unlock_time)
                }}"
                type="text"
                readonly
              />
              <label>Renew Times</label>
              <input
                value="{{ progressData?.week2_check_flg }}"
                type="text"
                readonly
              />
            </div>

            <div *ngIf="weeklyCheckInData[1]">
              <h3>Weekly “Check in” (0:Bad - 5:Good)</h3>
              <div class="details-input">
                <label>Sleep Change </label>
                <input
                  value="{{ weeklyCheckInData[1]?.sleep_change }}"
                  type="text"
                  readonly
                />

                <label>Sleep Quality</label>
                <input
                  value="{{ weeklyCheckInData[1]?.sleep_quality }}"
                  type="text"
                  readonly
                />
                <label>Daytime Function</label>
                <input
                  value="{{ weeklyCheckInData[1]?.daytime_functioning }}"
                  type="text"
                  readonly
                />
              </div>

              <div class="details-input" style="margin-top: 1%">
                <label class="label-size">Comments or Observations</label>
                <textarea
                  [ngModel]="weeklyCheckInData[1]?.comments_text"
                  readonly
                ></textarea>
              </div>
            </div>
            <h3>Daily Sleep Efficiency Average (Target: 80%)</h3>
            <div class="details-input">
              <label>Sleep Time</label>
              <input
                value="{{
                  getDailySleepTime(sleepJournalData[1]?.total_sleep_time)
                }}"
                type="text"
                readonly
              />
              <label>Time In Bed</label>
              <input
                value="{{
                  getDailySleepTime(sleepJournalData[1]?.total_time_in_bed)
                }}"
                type="text"
                readonly
              />

              <label>Efficiency</label>
              <input
                value="{{ sleepJournalData[1]?.sleep_efficiency }}"
                type="text"
                readonly
              />
            </div>
            <div *ngIf="desiredSleepData[1]?.desired_sleep_time">
              <h3>Desired Sleep Time</h3>
              <div class="details-input">
                <label>Go to Bed </label>
                <input
                  value="{{
                    formatTimeWithMoment(
                      desiredSleepData[1]?.desired_go_to_bed_time
                    )
                  }}"
                  type="text"
                  readonly
                />

                <label>Wake Up</label>
                <input
                  value="{{
                    formatTimeWithMoment(
                      desiredSleepData[1]?.desired_wake_up_time
                    )
                  }}"
                  type="text"
                  readonly
                />
                <label>Sleep Time</label>
                <input
                  value="{{
                    getDesiredSleepTime(desiredSleepData[1]?.desired_sleep_time)
                  }}"
                  type="text"
                  readonly
                />
              </div>
            </div>

            <h3>Self-Assessment and Reflection</h3>
            <div class="details-input">
              <label class="label-size">Self_assessment</label>
              <textarea
                [ngModel]="selfReflectData[1]?.self_assessment_text"
                readonly
              ></textarea>
            </div>
            <div class="details-input">
              <label class="label-size">Reflection</label>
              <textarea
                [ngModel]="selfReflectData[1]?.reflection_text"
                readonly
              ></textarea>
            </div>

            <h3>Patient's Summary and Suggestions</h3>
            <div class="details-input">
              <label class="label-size">Suggestion</label>
              <textarea
                [(ngModel)]="suggestion2"
                (blur)="saveSuggestion(2, suggestion2)"
              ></textarea>
            </div>
          </div>
          <div *ngIf="progressData?.current_week > 2">
            <app-week3-page2 [id]="patientId" [role]="1"></app-week3-page2>
          </div>
        </div>
        <div *ngIf="progressData?.current_week >= 3">
          <h2 class="week-font">
            Week 3
            {{ parseMomentStartYM(progressData?.week3_expected_unlock_time) }}
            {{ addDayAndFormat(progressData?.week3_expected_unlock_time, 6) }}
          </h2>
          <div class="course-section">
            <h3>Unlock Time</h3>
            <div class="details-input">
              <label>expected</label>
              <input
                value="{{
                  parseMomentDateTime(progressData?.week3_expected_unlock_time)
                }}"
                type="text"
                readonly
              />

              <label>actual </label>
              <input
                value="{{
                  parseMomentDateTime(progressData?.week3_actual_unlock_time)
                }}"
                type="text"
                readonly
              />
              <label>Renew Times</label>
              <input
                value="{{ progressData?.week3_check_flg }}"
                type="text"
                readonly
              />
            </div>

            <div *ngIf="weeklyCheckInData[2]">
              <h3>Weekly “Check in” (0:Bad - 5:Good)</h3>
              <div class="details-input">
                <label>Sleep Change </label>
                <input
                  value="{{ weeklyCheckInData[2]?.sleep_change }}"
                  type="text"
                  readonly
                />

                <label>Sleep Quality</label>
                <input
                  value="{{ weeklyCheckInData[2]?.sleep_quality }}"
                  type="text"
                  readonly
                />
                <label>Daytime Function</label>
                <input
                  value="{{ weeklyCheckInData[2]?.daytime_functioning }}"
                  type="text"
                  readonly
                />
              </div>
              <!-- <div class="details-input">
                <label>(0:Not - 5:Lots)</label>
                <input
                  value="{{ weeklyCheckInData[2]?.sleep_change }}"
                  type="text"
                  readonly
                  style="visibility: hidden"
                />

                <label>(0:Bad - 5:Good)</label>
                <input
                  value="{{ weeklyCheckInData[2]?.sleep_quality }}"
                  type="text"
                  readonly
                  style="visibility: hidden"
                />
                <label>(0:Bad - 5:Good)</label>
                <input
                  value="{{ weeklyCheckInData[2]?.daytime_functioning }}"
                  type="text"
                  readonly
                  style="visibility: hidden"
                />
              </div> -->
              <div class="details-input" style="margin-top: 1%">
                <label class="label-size">Comments or Observations</label>
                <textarea
                  [ngModel]="weeklyCheckInData[2]?.comments_text"
                  readonly
                ></textarea>
              </div>
            </div>

            <h3>Daily Sleep Efficiency Average (Target: 80%)</h3>
            <div class="details-input">
              <label>Sleep Time</label>
              <input
                value="{{
                  getDailySleepTime(sleepJournalData[2]?.total_sleep_time)
                }}"
                type="text"
                readonly
              />
              <label>Time In Bed</label>
              <input
                value="{{
                  getDailySleepTime(sleepJournalData[2]?.total_time_in_bed)
                }}"
                type="text"
                readonly
              />

              <label>Efficiency</label>
              <input
                value="{{ sleepJournalData[2]?.sleep_efficiency }}"
                type="text"
                readonly
              />
            </div>
            <div *ngIf="desiredSleepData[2]?.desired_sleep_time">
              <h3>Desired Sleep Time</h3>
              <div class="details-input">
                <label>Go to Bed </label>
                <input
                  value="{{
                    formatTimeWithMoment(
                      desiredSleepData[2].desired_go_to_bed_time
                    )
                  }}"
                  type="text"
                  readonly
                />

                <label>Wake Up</label>
                <input
                  value="{{
                    formatTimeWithMoment(
                      desiredSleepData[2]?.desired_wake_up_time
                    )
                  }}"
                  type="text"
                  readonly
                />
                <label>Sleep Time</label>
                <input
                  value="{{
                    getDesiredSleepTime(desiredSleepData[2]?.desired_sleep_time)
                  }}"
                  type="text"
                  readonly
                />
              </div>
            </div>

            <h3>Self-Assessment and Reflection</h3>
            <div class="details-input">
              <label class="label-size">Self_assessment</label>
              <textarea
                [ngModel]="selfReflectData[2]?.self_assessment_text"
                readonly
              ></textarea>
            </div>
            <div class="details-input">
              <label class="label-size">Reflection</label>
              <textarea
                [ngModel]="selfReflectData[2]?.reflection_text"
                readonly
              ></textarea>
            </div>

            <h3>Patient's Summary and Suggestions</h3>
            <div class="details-input">
              <label class="label-size">Suggestion</label>
              <textarea
                [(ngModel)]="suggestion3"
                (blur)="saveSuggestion(3, suggestion3)"
              ></textarea>
            </div>
          </div>
          <div *ngIf="progressData?.current_week > 3">
            <app-week4-page2 [id]="patientId" [role]="1"></app-week4-page2>
          </div>
        </div>
        <div *ngIf="progressData?.current_week >= 4">
          <h2 class="week-font">
            Week 4
            {{ parseMomentStartYM(progressData?.week4_expected_unlock_time) }}
            {{ addDayAndFormat(progressData?.week4_expected_unlock_time, 6) }}
          </h2>
          <div class="course-section">
            <h3>Unlock Time</h3>
            <div class="details-input">
              <label>expected</label>
              <input
                value="{{
                  parseMomentDateTime(progressData?.week4_expected_unlock_time)
                }}"
                type="text"
                readonly
              />

              <label>actual </label>
              <input
                value="{{
                  parseMomentDateTime(progressData?.week4_actual_unlock_time)
                }}"
                type="text"
                readonly
              />
              <label>Renew Times</label>
              <input
                value="{{ progressData?.week4_check_flg }}"
                type="text"
                readonly
              />
            </div>

            <div *ngIf="weeklyCheckInData[3]">
              <h3>Weekly “Check in” (0:Bad - 5:Good)</h3>
              <div class="details-input">
                <label>Sleep Change </label>
                <input
                  value="{{ weeklyCheckInData[3]?.sleep_change }}"
                  type="text"
                  readonly
                />

                <label>Sleep Quality</label>
                <input
                  value="{{ weeklyCheckInData[3]?.sleep_quality }}"
                  type="text"
                  readonly
                />
                <label>Daytime Function</label>
                <input
                  value="{{ weeklyCheckInData[3]?.daytime_functioning }}"
                  type="text"
                  readonly
                />
              </div>
              <!-- <div class="details-input">
                <label>(0:Not - 5:Lots)</label>
                <input
                  value="{{ weeklyCheckInData[3]?.sleep_change }}"
                  type="text"
                  readonly
                  style="visibility: hidden"
                />

                <label>(0:Bad - 5:Good)</label>
                <input
                  value="{{ weeklyCheckInData[3]?.sleep_quality }}"
                  type="text"
                  readonly
                  style="visibility: hidden"
                />
                <label>(0:Bad - 5:Good)</label>
                <input
                  value="{{ weeklyCheckInData[2]?.daytime_functioning }}"
                  type="text"
                  readonly
                  style="visibility: hidden"
                />
              </div> -->
              <div class="details-input" style="margin-top: 1%">
                <label class="label-size">Comments or Observations</label>
                <textarea
                  [ngModel]="weeklyCheckInData[3]?.comments_text"
                  readonly
                ></textarea>
              </div>
            </div>

            <h3>Daily Sleep Efficiency Average (Target: 80%)</h3>
            <div class="details-input">
              <label>Sleep Time</label>
              <input
                value="{{
                  getDailySleepTime(sleepJournalData[3]?.total_sleep_time)
                }}"
                type="text"
                readonly
              />
              <label>Time In Bed</label>
              <input
                value="{{
                  getDailySleepTime(sleepJournalData[3]?.total_time_in_bed)
                }}"
                type="text"
                readonly
              />

              <label>Efficiency</label>
              <input
                value="{{ sleepJournalData[3]?.sleep_efficiency }}"
                type="text"
                readonly
              />
            </div>
            <div *ngIf="desiredSleepData[3]?.desired_sleep_time">
              <h3>Desired Sleep Time</h3>
              <div class="details-input">
                <label>Go to Bed </label>
                <input
                  value="{{
                    formatTimeWithMoment(
                      desiredSleepData[3]?.desired_go_to_bed_time
                    )
                  }}"
                  type="text"
                  readonly
                />

                <label>Wake Up</label>
                <input
                  value="{{
                    formatTimeWithMoment(
                      desiredSleepData[3]?.desired_wake_up_time
                    )
                  }}"
                  type="text"
                  readonly
                />
                <label>Sleep Time</label>
                <input
                  value="{{
                    getDesiredSleepTime(desiredSleepData[3]?.desired_sleep_time)
                  }}"
                  type="text"
                  readonly
                />
              </div>
            </div>

            <h3>Self-Assessment and Reflection</h3>
            <div class="details-input">
              <label class="label-size">Self_assessment</label>
              <textarea
                [ngModel]="selfReflectData[3]?.self_assessment_text"
                readonly
              ></textarea>
            </div>
            <div class="details-input">
              <label class="label-size">Reflection</label>
              <textarea
                [ngModel]="selfReflectData[3]?.reflection_text"
                readonly
              ></textarea>
            </div>

            <h3>Patient's Summary and Suggestions</h3>
            <div class="details-input">
              <label class="label-size">Suggestion</label>
              <textarea
                [(ngModel)]="suggestion4"
                (blur)="saveSuggestion(4, suggestion4)"
              ></textarea>
            </div>
          </div>
          <div *ngIf="progressData?.current_week > 4">
            <app-week5-page2 [id]="patientId" [role]="1"></app-week5-page2>
          </div>
        </div>
        <div *ngIf="progressData?.current_week >= 5">
          <h2 class="week-font">
            Week 5
            {{ parseMomentStartYM(progressData?.week5_expected_unlock_time) }}
            {{ addDayAndFormat(progressData?.week5_expected_unlock_time, 6) }}
          </h2>
          <div class="course-section">
            <h3>Unlock Time</h3>
            <div class="details-input">
              <label>expected</label>
              <input
                value="{{
                  parseMomentDateTime(progressData?.week5_expected_unlock_time)
                }}"
                type="text"
                readonly
              />

              <label>actual </label>
              <input
                value="{{
                  parseMomentDateTime(progressData?.week5_actual_unlock_time)
                }}"
                type="text"
                readonly
              />
              <label>Renew Times</label>
              <input
                value="{{ progressData?.week5_check_flg }}"
                type="text"
                readonly
              />
            </div>
            <div *ngIf="sleepJournalData[4]?.sleep_efficiency">
              <h3>Daily Sleep Efficiency Average (Target: 80%)</h3>
              <div class="details-input">
                <label>Sleep Time</label>
                <input
                  value="{{
                    getDailySleepTime(sleepJournalData[4]?.total_sleep_time)
                  }}"
                  type="text"
                  readonly
                />
                <label>Time In Bed</label>
                <input
                  value="{{
                    getDailySleepTime(sleepJournalData[4]?.total_time_in_bed)
                  }}"
                  type="text"
                  readonly
                />

                <label>Efficiency</label>
                <input
                  value="{{ sleepJournalData[4]?.sleep_efficiency }}"
                  type="text"
                  readonly
                />
              </div>
            </div>
            <div *ngIf="desiredSleepData[4]?.desired_sleep_time">
              <h3>Desired Sleep Time</h3>
              <div class="details-input">
                <label>Go to Bed </label>
                <input
                  value="{{
                    formatTimeWithMoment(
                      desiredSleepData[4]?.desired_go_to_bed_time
                    )
                  }}"
                  type="text"
                  readonly
                />

                <label>Wake Up</label>
                <input
                  value="{{
                    formatTimeWithMoment(
                      desiredSleepData[4]?.desired_wake_up_time
                    )
                  }}"
                  type="text"
                  readonly
                />
                <label>Sleep Time</label>
                <input
                  value="{{
                    getDesiredSleepTime(desiredSleepData[4]?.desired_sleep_time)
                  }}"
                  type="text"
                  readonly
                />
              </div>
            </div>

            <h3>Self-Assessment and Reflection</h3>
            <div class="details-input">
              <label class="label-size">Self_assessment</label>
              <textarea
                [ngModel]="selfReflectData[4]?.self_assessment_text"
                readonly
              ></textarea>
            </div>
            <div class="details-input">
              <label class="label-size">Reflection</label>
              <textarea
                [ngModel]="selfReflectData[4]?.reflection_text"
                readonly
              ></textarea>
            </div>

            <h3>Patient's Summary and Suggestions</h3>
            <div class="details-input">
              <label class="label-size">Suggestion</label>
              <textarea
                [(ngModel)]="suggestion5"
                (blur)="saveSuggestion(5, suggestion5)"
              ></textarea>
            </div>
          </div>
        </div>
        <div *ngIf="progressData?.current_week == 6">
          <h2 class="week-font">Final</h2>
          <div class="course-section">
            <h3>Completion Time</h3>
            <div class="details-input">
              <label>expected</label>
              <input
                value="{{
                  parseMomentDateTime(
                    progressData?.week5_expected_completion_time
                  )
                }}"
                type="text"
                readonly
              />

              <label>actual </label>
              <input
                value="{{
                  parseMomentDateTime(
                    progressData?.week5_actual_completion_time
                  )
                }}"
                type="text"
                readonly
              />
              <label>Renew Times</label>
              <input
                value="{{ progressData?.week5_check_flg }}"
                type="text"
                readonly
              />
            </div>
            <div class="details-input">
              <label class="label-size">Self_assessment</label>
              <textarea
                [ngModel]="selfReflectData[5]?.self_assessment_text"
                readonly
              ></textarea>
            </div>
            <div class="details-input">
              <label class="label-size">Reflection</label>
              <textarea
                [ngModel]="selfReflectData[5]?.reflection_text"
                readonly
              ></textarea>
            </div>

            <h3>Patient's Summary and Suggestions</h3>
            <div class="details-input">
              <label class="label-size">Suggestion</label>
              <textarea
                [(ngModel)]="finalSuggestion"
                (blur)="saveSuggestion(6, finalSuggestion)"
              ></textarea>
            </div>
          </div>
        </div>
        <div *ngIf="progressData?.current_week > 1">
          <h2 class="week-font">Introduction</h2>
          <div class="course-section">
            <h3>
              If you believe it is necessary to provide suggestions after
              reviewing the patient's records, please input your recommendations
              in the corresponding week's "Doctor's Suggestions" section. Then,
              click the "Send Notification Email" button below. The patient will
              receive an email notification regarding your suggestions, and
              these recommendations will also appear on the patient's weekly
              course pages under "Self-Assessment and Reflection."
            </h3>
            <!-- <h3>If needed, enter your suggestions in the week's "Doctor's Suggestions" section and click "Send Notification Email." The patient will be notified by email and see your suggestions on their "Self-Assessment and Reflection" pages.</h3> -->
            <div class="details-input">
              <button
                class="sleep-journal-button btn btn-primary mx-auto field col-3"
                style="min-width: 250px"
                (click)="sendSuggestion()"
              >
                Send Notification Email
              </button>
              <div
                *ngIf="message"
                class="alert alert-info mt-2"
                style="text-align: center"
                [innerHTML]="message"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <ng-template #elseBlock>
        <p class="text-center">
          No course has started yet, or the Week 1 course has not been
          completed.
        </p>
      </ng-template>
    </h4>
    <!-- </form> -->
  </div>
</div>
