import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CourseService } from '@app/_services/course.service';
import { AccountService, AlertService } from '@app/_services';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: 'confirm-dialog.component.html',
  styleUrls: ['confirm-dialog.style.scss'],
})
export class ConfirmDialogComponent {
  private currentWeek: number;
  private currentPage: number;
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    private courseService: CourseService,
    private alertService: AlertService,
    private accountSerivice: AccountService
  ) {}

  onConfirm() {
    this.courseService.updateProgress(1, 1);

    this.dialogRef.close('confirm');
  }

  onCancel() {
    this.dialogRef.close();
  }
}
