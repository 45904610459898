<div class="admin-dashboard container p-4">
  <!-- <form [formGroup]="form"> -->
  <h1 class="pb-4 pt-4">Course Week Editor</h1>

  <div id="pdfContent">
    <!-- <form [formGroup]="form"> -->
    <h4 style="color: #002e46; font-size: 18px">
      <div class="course-section">
        <div class="details-input">
          <label>Name</label>
          <input
            value="{{ courseDetails?.first_name }} {{
              courseDetails?.last_name
            }}"
            type="text"
            readonly
          />

          <label>email</label>
          <input value="{{ courseDetails?.email }}" type="text" readonly />
          <label>mobile</label>
          <input value="{{ courseDetails?.mobile }}" type="text" readonly />
        </div>
        <div class="details-input">
          <label>Sex</label>
          <input
            value="{{
              profileData?.sex === undefined || profileData?.sex === null
                ? ''
                : profileData?.sex === 1
                ? 'Male'
                : 'Female'
            }}"
            type="text"
            readonly
          />
          <label>Age</label>
          <input value="{{ profileData?.age }}" type="text" readonly />

          <label>Last Login Time</label>
          <input
            value="{{ parseMomentDateTime(progressData?.last_update_time) }}"
            type="text"
            readonly
          />
        </div>
      </div>

      <h2 class="week-font">Reference Materials</h2>

      <div class="course-section">
        <div class="details-input">
          <button
            class="btn btn-primary field mx-auto col-3"
            (click)="openJournalDetails()"
          >
            Journal Details
          </button>
          <button
            class="btn btn-primary field mx-auto col-3"
            (click)="openCourseDetails()"
            style="margin-left: 5%"
          >
            Course Details
          </button>
          <button
            class="btn btn-primary field mx-auto col-3"
            (click)="openPageDetails()"
          >
            Page Details
          </button>
        </div>
      </div>

      <h2 class="week-font">Edit Week Number</h2>
      <div class="course-section">
        <div class="details-input">
          <label>Current Week</label>
          <input value="{{ currentWeek }}" type="text" readonly />

          <label>Current Page</label>
          <input value="{{ currentPage }}" type="text" />
          <label>Updated Week</label>
          <input [(ngModel)]="updatedWeek" type="text" />
        </div>
        <div class="details-input">
          <label></label>
          <input
            value="{{ currentPage }}"
            type="text"
            style="visibility: hidden"
          />
          <label></label>
          <input
            value="{{ currentPage }}"
            type="text"
            style="visibility: hidden"
          />
          <button
            class="btn btn-primary field mx-auto col-3"
            (click)="modifyCourseInfo()"
            [disabled]="updateSuccess"
            style="margin-right: 10%"
          >
            Change Week Number
          </button>
        </div>
        <p *ngIf="updateError" class="alert alert-danger">
          {{ updateError }}
        </p>
        <p *ngIf="updateSuccess" class="alert alert-success">
          Week number updated successfully!
        </p>
      </div>
    </h4>
    <!-- </form> -->
  </div>
</div>
