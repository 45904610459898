import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '@app/_services';
import { CourseService } from '@app/_services/course.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-week2-page3',
  templateUrl: './week2-page3.component.html',
  styleUrls: ['./week2-page3.style.scss'],
})
export class Week2Page3Component implements OnInit {
  @ViewChild('videoPlayer') videoElement!: ElementRef<HTMLVideoElement>;
  videoSrc: string = '';
  isVideoEnded: boolean = false;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private courseService: CourseService
  ) {}

  ngOnInit(): void {
    const savedLang = localStorage.getItem('selectedLanguage');
    if (savedLang) {
      this.translate.use(savedLang);
    } else {
      this.translate.use(this.translate.defaultLang);
    }

    this.courseService.isStudied(2, 3).then((result) => {
      if (result) {
        this.isVideoEnded = true;
      } else {
        const isVideoWatched =
          localStorage.getItem('isW2P3VideoWatched') === 'true';
        this.isVideoEnded = isVideoWatched;
      }
    });
    this.setVideoSource();

    this.translate.onLangChange.subscribe((event) => {
      localStorage.setItem('selectedLanguage', event.lang);
      this.setVideoSource();
    });
  }

  setVideoSource(): void {
    const currentLang = this.translate.currentLang;

    if (currentLang === 'sch' || currentLang === 'tch') {
      this.videoSrc = '/assets/videos/week2_1_how%20to%20calculate_ch.mp4';
    } else {
      this.videoSrc = '/assets/videos/week2_1_how%20to%20calculate_en.mp4';
    }
    this.cdr.detectChanges();
  }

  onVideoEnded(): void {
    this.isVideoEnded = true;
    localStorage.setItem('isW2P3VideoWatched', 'true');
  }

  ngAfterViewInit(): void {
    this.tryPlayVideo();
  }

  tryPlayVideo(): void {
    const video: HTMLVideoElement = this.videoElement.nativeElement;

    // Try to play the video
    video
      .play()
      .then(() => {
        console.log('Video playing successfully');
      })
      .catch((error) => {
        console.warn('Autoplay was prevented:', error);
        // If autoplay is prevented, prompt the user to play the video
        this.addPlayButtonOverlay();
      });
  }

  addPlayButtonOverlay(): void {
    const playButton = document.createElement('button');
    playButton.textContent = 'Play Video';
    playButton.style.position = 'absolute';
    playButton.style.top = '60%';
    playButton.style.left = '50%';
    playButton.style.transform = 'translate(-50%, -50%)';
    playButton.style.zIndex = '1000';
    playButton.style.padding = '10px 20px';
    playButton.style.fontSize = '16px';
    playButton.style.backgroundColor = '#007bff';
    playButton.style.color = '#fff';
    playButton.style.border = 'none';
    playButton.style.cursor = 'pointer';

    playButton.addEventListener('click', () => {
      const video: HTMLVideoElement = this.videoElement.nativeElement;
      video.play();
      playButton.remove();
    });

    this.videoElement.nativeElement.parentElement?.appendChild(playButton);
  }

  onNextClick() {
    this.courseService.unlockWeek(1);
    this.courseService.unlockWeek2Content(3);
    this.courseService.updateProgress(2, 4);

    this.router.navigate(['week2/understanding_sleep']); // navigate to intropage2
  }
}
