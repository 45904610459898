<div class="patient-profile container p-4">
    <h1 class="pb-4 pt-4">Patient Profile</h1>

    <div *ngIf="patientDetails!==undefined" class="container patient-profile pt-4 pb-2">
        <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex">
                <div class="m-auto position-relative">
                    <div class="circular-div"> </div>
                    <span class="username-initial text-capitalize">{{patientDetails.initial_letter}}</span>
                </div>
                <div class="d-flex flex-column justify-content-around ml-3 ">
                    <div class='user-name pb-2'>
                        {{ patientDetails.first_name }} {{ patientDetails.last_name }}
                    </div>
                    <div class="additonal-info pt-2">
                        <div class="mr-2">
                            <span class="label">
                                Age:
                            </span> {{patientProfileDetails.age}}
                        </div>
                        <div class="mr-2">
                            <span class="label">
                                Height:
                            </span>
                            {{patientProfileDetails.height}}
                        </div>
                        <div class="mr-2">
                            <span class="label">
                                Weight:
                            </span>
                            {{patientProfileDetails.weight}}
                        </div>
                    </div>
                </div>
            </div>
           
            <div class="report-button">
               <!--<button class="btn btn-secondary" style="margin-right : 20px;" (click)="onEdit_Profile()">
        
                    Edit Profile
                </button>-->
                <button class="btn btn-secondary">
                    <span class="pr-2">
                        <i class="fa fa-download" aria-hidden="true"></i>
                    </span>
                    Download Report
                </button>
            </div>
        </div>
    </div>

    <dairy-component *ngIf="patientDetails!==undefined" [showSpecificPatient]="showSpecificPatient"></dairy-component>
</div>