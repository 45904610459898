<div class="admin-dashboard container p-4">
  <!-- <form [formGroup]="form"> -->
  <h1 class="pb-4 pt-4">Journal Details</h1>

  <div id="pdfContent">
    <!-- <form [formGroup]="form"> -->
    <h4 style="color: #002e46; font-size: 18px">
      <div class="course-section">
        <div class="details-input">
          <label>Name</label>
          <input
            value="{{ data?.first_name }} {{ data?.last_name }}"
            type="text"
            readonly
          />
          <label>Email</label>
          <input value="{{ data?.email }}" type="text" readonly />
          <label>Phone</label>
          <input value="{{ data?.mobile }}" type="text" readonly />
        </div>
      </div>
    </h4>
    <div class="container pt-4 pb-2">
      <table class="table table-hover patient-details-table">
        <thead>
          <tr>
            <th (click)="sortData('entry_date')">
              Date
              <i class="fa" [ngClass]="getSortIcon('entry_date')"></i>
            </th>
            <th><strong>Go to Bed</strong></th>
            <th><strong>Lights Off</strong></th>
            <th><strong>Time to sleep</strong></th>
            <th><strong>Wake count</strong></th>
            <th><strong>Awake minutes</strong></th>
            <th><strong>Wake Up</strong></th>
            <th><strong>Rise Bed</strong></th>
            <th><strong>Sleep Aids</strong></th>
          </tr>
        </thead>
        <tbody *ngIf="journals?.length > 0; else elseBlock">
          <tr *ngFor="let item of journals">
            <td>{{ parseMomentDate(item?.entry_date) }}</td>
            <td>
              {{ parseMomentDateTime(item?.go_to_bed_date_time) }}
            </td>
            <td>
              {{ parseMomentDateTime(item?.lights_off_date_time) }}
            </td>
            <td>
              {{ item?.duration_falling_sleep }}
            </td>
            <td>
              {{ item?.number_awakening }}
            </td>
            <td>
              {{ item?.minutes_awake }}
            </td>
            <td>
              {{ parseMomentDateTime(item?.wake_up_date_time) }}
            </td>
            <td>{{ parseMomentDateTime(item?.rise_from_bed_date_time) }}</td>
            <td>
              <span class="tooltip-container">
                {{
                  item?.sleep_aid_details?.length > 20
                    ? (item.sleep_aid_details | slice : 0 : 20) + "..."
                    : item.sleep_aid_details
                }}
                <span class="tooltip-text">{{ item?.sleep_aid_details }}</span>
              </span>
            </td>
          </tr>
        </tbody>
        <ng-template #elseBlock>
          <tbody>
            <tr colspan="5">
              <td class="text-center" colspan="5">No data found</td>
            </tr>
          </tbody>
        </ng-template>
      </table>
      <div *ngIf="totalItems >= pageSize" class="pagination">
        <button
          class="btn btn-primary px-3 patient-controls page-btn"
          (click)="previousPage()"
          [disabled]="currentPage === 1"
        >
          Previous
        </button>
        <span style="margin: 5px 5px"
          >Page {{ currentPage }} of {{ totalPages }} (Total:
          {{ totalItems }})</span
        >
        <button
          class="btn btn-primary px-3 patient-controls page-btn"
          (click)="nextPage()"
          [disabled]="!hasMoreData"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</div>

<div class="dialog-actions">
  <br />

  <div class="details-input">
    <button
      class="sleep-journal-button btn btn-primary mx-auto field col-3"
      style="min-width: 250px"
      (click)="cancel()"
    >
      Close
    </button>
  </div>
</div>
