<div class="container p-4 container-custom outer">
  <h1
    style="
      color: #012233;
      margin-bottom: 10px !important;
      font-size: 28px !important;
    "
  >
    <b>{{ "W1_P6_PRACTICES" | translate }}</b>
  </h1>
  <h4 style="color: #002e46; font-size: 18px">
    <br />
    <p>
      <b>{{ "W1_P6_IMPROVE_ENVIRONMENT_H" | translate }}</b>
      {{ "W1_P6_IMPROVE_ENVIRONMENT_B" | translate }}
    </p>
    <p>
      <b>{{ "W1_P6_MAINTAIN_LIFESTYLE_H" | translate }}</b>
      {{ "W1_P6_MAINTAIN_LIFESTYLE_B" | translate }}
    </p>
    <p>
      <b>{{ "W1_P6_GET_SUNLIGHT_H" | translate }}</b>
      {{ "W1_P6_GET_SUNLIGHT_B" | translate }}
    </p>
    <p>
      <b> {{ "W1_P6_PREPARE_BODY_H" | translate }}</b>
      {{ "W1_P6_PREPARE_BODY_B" | translate }}
    </p>
    <p>
      <b>{{ "W1_P6_MINIMIZE_ANXIETY_H" | translate }}</b>
      {{ "W1_P6_MINIMIZE_ANXIETY_B" | translate }}
    </p>
    <p>
      <b> {{ "W1_P6_HAVE_ROUTINE_H" | translate }}</b>
      {{ "W1_P6_HAVE_ROUTINE_B" | translate }}
    </p>
    <p>
      <b>{{ "W1_P6_FORGET_NUMBER_H" | translate }}</b>
      {{ "W1_P6_FORGET_NUMBER_B" | translate }}
    </p>
    <p>
      <b> {{ "W1_P6_SIMULANTS_H" | translate }}</b>
      {{ "W1_P6_SIMULANTS_B" | translate }}
    </p>
    <p>
      <b> {{ "W1_P6_CONSISTENT_H" | translate }}</b>
      {{ "W1_P6_CONSISTENT_B" | translate }}
    </p>

    <br />
    <div class="form-group text-center">
      <button
        class="btn btn-primary field mx-auto col-3"
        (click)="onNextClick()"
      >
        {{ "NEXT" | translate }}
      </button>
    </div>
  </h4>
</div>
