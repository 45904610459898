<h1>{{ "DIALOG_SELECT_DATE_TIME" | translate }}</h1>
<!-- Date Selection -->
<div class="date-picker">
  <div class="date-section">
    <label for="year">{{ "YEAR" | translate }}</label>
    <select id="year" [(ngModel)]="selectedYear" (change)="updateDays()">
      <option *ngFor="let year of years" [value]="year">{{ year }}</option>
    </select>
  </div>

  <div class="date-section">
    <label for="month">{{ "MONTH" | translate }}</label>
    <select id="month" [(ngModel)]="selectedMonth" (change)="updateDays()">
      <option *ngFor="let month of months" [value]="month">{{ month }}</option>
    </select>
  </div>

  <div class="date-section">
    <label for="day">{{ "DAY" | translate }}</label>
    <select id="day" [(ngModel)]="selectedDay">
      <option *ngFor="let day of days" [value]="day">{{ day }}</option>
    </select>
  </div>
</div>

<!-- Time Selection -->
<div class="time-picker">
  <div class="time-section">
    <label for="hour">{{ "DIALOG_TIME_HOUR" | translate }}</label>
    <select id="hour" [(ngModel)]="selectedHour">
      <option *ngFor="let hour of hours" [value]="hour">{{ hour }}</option>
    </select>
  </div>

  <div class="time-section">
    <label for="minute">{{ "DIALOG_TIME_MINUTE" | translate }}</label>
    <select id="minute" [(ngModel)]="selectedMinute">
      <option *ngFor="let minute of minutes" [value]="minute">
        {{ minute }}
      </option>
    </select>
  </div>

  <div class="time-section">
    <label for="period"
      >{{ "DIALOG_TIME_AM" | translate }}/{{
        "DIALOG_TIME_PM" | translate
      }}:</label
    >
    <select id="period" [(ngModel)]="selectedPeriod">
      <option *ngFor="let period of periods" [value]="period">
        {{ period }}
      </option>
    </select>
  </div>
</div>
<!-- <button
      class="btn btn-primary field mx-auto col-3 ms-2"
      (click)="omReset()"
      style="min-width: 160px"
        mat-raised-button
    > -->
<div class="dialog-actions">
  <button
    class="btn btn-primary field mx-auto col-3 ms-2"
    (click)="confirmTime()"
    style="min-width: 100px"
  >
    {{ "DIALOG_OK" | translate }}
  </button>
  <button
    class="btn btn-primary field mx-auto col-3 ms-2"
    (click)="cancel()"
    style="min-width: 100px"
  >
    {{ "DIALOG_CANCEL" | translate }}
  </button>
</div>
