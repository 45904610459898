import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CourseService } from '@app/_services/course.service';
import { AccountService, AlertService } from '@app/_services';
import { Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-missing-journal-dialog',
  templateUrl: './missing-journal-dialog.component.html',
  styleUrls: ['./mssing-journal-dialog.style.scss'],
})
export class MissingJournalDialogComponent {
  safeHtmlContentNext: SafeHtml;
  saftHtmlContent: SafeHtml;
  constructor(
    public dialogRef: MatDialogRef<MissingJournalDialogComponent>,
    private sanitizer: DomSanitizer,
    private translate: TranslateService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.translate
      .get('DIALOG_NEXT_WEEK_UNLOCK_REMINDER', { date: data.date })
      .subscribe((res: string) => {
        this.safeHtmlContentNext = this.sanitizer.bypassSecurityTrustHtml(res);
      });

    this.translate
      .get('DIALOG_COURSE_UNLOCK_REMINDER', { date: data.date })
      .subscribe((res: string) => {
        this.saftHtmlContent = this.sanitizer.bypassSecurityTrustHtml(res);
      });
  }
  selectDate(event: Event, date: Date): void {
    event.preventDefault();
    this.dialogRef.close(date);
    // this.router.navigate(['/journal'], {
    //   queryParams: { date: date },
    // });
    this.dialogRef.afterClosed().subscribe(() => {
      const localDateString = this.formatDateToVancouverTime(date); // Display Vancouver date
      const utcDateString = this.formatDateToUTC(date); // Convert to UTC for backend
      this.router.navigate(['/journal'], {
        queryParams: { date: utcDateString },
      });
    });
  }

  // For displaying the date in Vancouver time
  private formatDateToVancouverTime(date: any): string {
    const vancouverFormatter = new Intl.DateTimeFormat('en-CA', {
      timeZone: 'America/Vancouver',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    return vancouverFormatter.format(new Date(date));
  }

  // For converting the date to UTC (to send to backend)
  private formatDateToUTC(date: any): string {
    // First parse the date as if it's in Vancouver time
    const vancouverDate = new Date(date);

    // Manually adjusting the time to UTC
    const utcDate = new Date(
      vancouverDate.getTime() + vancouverDate.getTimezoneOffset() * 60000
    );

    return utcDate.toISOString();
  }

  logJournal() {
    const firstMissingDate = this.data.missingDates[0];
    this.dialogRef.close();
    this.dialogRef.afterClosed().subscribe(() => {
      const localDateString = this.formatDateToUTC(firstMissingDate);

      this.router.navigate(['/journal'], {
        queryParams: { date: localDateString },
      });
    });
  }

  viewProgress() {
    this.dialogRef.close('progress');
  }

  onCancel() {
    this.dialogRef.close();
  }
}
