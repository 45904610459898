import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  ShowOnDirtyErrorStateMatcher,
  ErrorStateMatcher,
} from '@angular/material/core';
import { Options } from '@angular-slider/ngx-slider';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { finalize, first } from 'rxjs/operators';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import { DatePipe } from '@angular/common';

import { AccountService, AlertService } from '@app/_services';
import { coerceNumberProperty } from '@angular/cdk/coercion';
import Validation from './validation';
import * as moment from 'moment';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CourseService } from '@app/_services/course.service';
import { MatDialog } from '@angular/material/dialog';
import { TimePickerDialogComponent } from '@app/dialog/time-picker-dialog/time-picker-dialog.component';
import { HourMinuterPickerDialogComponent } from '@app/dialog/hour-minuter-picker-dialog/hour-minuter-picker-dialog.component';
import { JournalConfirmDialogComponent } from './journal-confirm-dialog.component';
import { DepFlags } from '@angular/compiler/src/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { addDays, format, subDays } from 'date-fns';
import { DateTimePickerDialogComponent } from '@app/dialog/date-time-picker-dialog/date-time-picker-dialog.component';

class CustomErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null) {
    return control.invalid;
  }
}

@Component({
  selector: 'app-journal',
  templateUrl: 'journal.component.html',
  styleUrls: ['journal.component.scss'],
})
export class JournalComponent implements OnInit {
  page = 1;

  serializedDate = new FormControl(new Date().toISOString());
  form: FormGroup;
  loading = false;
  submitted = false;
  gridsize_hour = 0;
  gridsize_min = 0;
  //value for slider
  autoTicks = false;
  disabled = false;
  invert = false;
  //ideally max is dependent on input times
  max = 240;
  min = 0;
  showTicks = true;
  step = 5;
  thumbLabel = true;
  value = 0;
  vertical = false;
  hidden = true;
  formError = false;
  firstForm: FormGroup;
  secondForm: FormGroup;

  //value for timepicker
  title = 'angular-material-timepicker';
  minValue: Date;
  maxValue: Date;
  defaultValue: Date;
  defaultValuePM: Date = new Date(0, 0, 0, 23, 0, 0);

  selectedTime: string = '';

  showOnDirtyErrorStateMatcher = new ShowOnDirtyErrorStateMatcher();
  customErrorStateMatcher = new CustomErrorStateMatcher();
  private timeZone = 'America/Vancouver';
  sleepAidUsed: boolean = false;

  // safeHtmlContentNext: SafeHtml;
  // saftHtmlContent: SafeHtml;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService,
    private translate: TranslateService,
    private courseService: CourseService,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer
  ) {
    const minValue = new Date();
    minValue.setHours(6);
    minValue.setMinutes(10);
    this.minValue = minValue;

    const maxValue = new Date();
    maxValue.setHours(18);
    maxValue.setMinutes(10);
    this.maxValue = maxValue;

    const d = new Date();
    d.setDate(1);
    d.setMonth(2);
    d.setHours(7);
    d.setMinutes(0);
    d.setSeconds(1);
    d.setMilliseconds(10);
    this.defaultValue = d;

    this.form = this.formBuilder.group({
      time: [this.defaultValue, Validators.required],
    });
  }
  switchLanguage(language: string) {
    this.translate.use(language);
  }

  ngOnInit() {
    this.submitted = false;
    this.form = this.formBuilder.group(
      // this.firstForm = this.formBuilder.group(
      {
        entry_date: ['', Validators.required],
        wake_up_date_time: ['', Validators.required],
        rise_from_bed_date_time: ['', Validators.required],
        go_to_bed_date_time: ['', Validators.required],
        lights_off_date_time: ['', Validators.required],
        duration_falling_sleep: ['', Validators.required],
        // number_awakening: [0, Validators.required],
        // minutes_awake: [0, Validators.required],
        number_awakening: [, Validators.required],
        minutes_awake: [, Validators.required],
        // list_stimulants: [''],
        // list_sleep_medications: [''],
        rate_rest: ['', Validators.required],
        rate_sleep_quality: ['', Validators.required],
        sleep_aid_used: [false],
        sleep_aid_details: [''],
      },
      {
        validators: [
          Validation.dateShouldNotBeGreaterThanToday('entry_date'),
          Validation.wakeUpTimeShouldEarlyNow('wake_up_date_time'),
          Validation.wakeUpTimeCompEntry('entry_date', 'wake_up_date_time'),
          Validation.riseTimeShouldEarlyNow('rise_from_bed_date_time'),
          Validation.riseTimeCompEntry('entry_date', 'rise_from_bed_date_time'),
          Validation.dateShouldBeGreater(
            'rise_from_bed_date_time',
            'wake_up_date_time'
          ),
          Validation.bedTimeShouldEarlyNow('go_to_bed_date_time'),
          Validation.bedTimeCompEntry('entry_date', 'go_to_bed_date_time'),
          Validation.lightsOffTimeShouldEarlyNow('lights_off_date_time'),
          Validation.lightsOffTimeCompEntry(
            'entry_date',
            'lights_off_date_time'
          ),
          Validation.dateShouldBeLessThan(
            'lights_off_date_time',
            'go_to_bed_date_time'
          ),
          Validation.checkBedTime(
            'rise_from_bed_date_time',
            'go_to_bed_date_time'
          ),
          Validation.checkBedTimeGreaterThanYesterday(
            'entry_date',
            'go_to_bed_date_time',
            this.courseService
          ),
          Validation.valueShouldNotBeEmpty('number_awakening', 'minutes_awake'),
          Validation.lightsOffEarlyWake(
            'wake_up_date_time',
            'lights_off_date_time'
          ),
          Validation.checkSleepTime(
            'wake_up_date_time',
            'lights_off_date_time',
            'duration_falling_sleep',
            'minutes_awake'
          ),
        ],
      }
    );

    this.form.valueChanges.subscribe(() => {
      if (this.form.valid) {
        this.formError = false; // Reset the global error if the form becomes valid
      }
    });

    this.translate.onTranslationChange.subscribe((event: LangChangeEvent) => {
      this.form.updateValueAndValidity();
    });

    this.route.queryParams.subscribe((params) => {
      if (params['date']) {
        const dateString = new Date(params['date']);
        this.form.get('entry_date')?.setValue(dateString);

        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {},
        });
      }
    });
  }

  openWakeUpTimePicker(event: Event): void {
    event.preventDefault();

    const entryDate = this.form.get('entry_date')?.value;
    const wakeUpTime = this.form.get('wake_up_date_time')?.value;
    const riseFromBedTime = this.form.get('rise_from_bed_date_time')?.value;

    let initalDate;

    if (
      moment(addDays(entryDate, 1), 'MM/dd/yyyy').isAfter(
        moment(new Date(), 'MM/dd/yyyy')
      )
    ) {
      initalDate = entryDate
        ? format(entryDate, 'MM/dd/yyyy')
        : format(new Date(), 'MM/dd/yyyy');
    } else {
      initalDate = entryDate
        ? format(addDays(entryDate, 1), 'MM/dd/yyyy')
        : format(new Date(), 'MM/dd/yyyy');
    }

    const initalDateTime = wakeUpTime
      ? wakeUpTime
      : riseFromBedTime
      ? riseFromBedTime
      : initalDate + ' 7:00 AM';

    const dialogRef = this.dialog.open(DateTimePickerDialogComponent, {
      width: 'auto',
      data: { time: initalDateTime },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const [date, time, period] = result.split(' ');
        const [month, day, year] = date.split('/');
        const formattedDateTime = `${parseInt(month)}/${parseInt(
          day
        )}/${year} ${time} ${period}`;
        this.form.get('wake_up_date_time')?.setValue(formattedDateTime);
        this.form.get('wake_up_date_time')?.markAsTouched();
        this.form.get('wake_up_date_time')?.updateValueAndValidity();
      }
    });
  }

  openRiseFromBedTimePicker(event: Event): void {
    event.preventDefault();

    const entryDate = this.form.get('entry_date')?.value;
    const wakeUpTime = this.form.get('wake_up_date_time')?.value;
    const riseFromBedTime = this.form.get('rise_from_bed_date_time')?.value;

    let initalDate;

    if (
      moment(addDays(entryDate, 1), 'MM/dd/yyyy').isAfter(
        moment(new Date(), 'MM/dd/yyyy')
      )
    ) {
      initalDate = entryDate
        ? format(entryDate, 'MM/dd/yyyy')
        : format(new Date(), 'MM/dd/yyyy');
    } else {
      initalDate = entryDate
        ? format(addDays(entryDate, 1), 'MM/dd/yyyy')
        : format(new Date(), 'MM/dd/yyyy');
    }

    const initalDateTime = riseFromBedTime
      ? riseFromBedTime
      : wakeUpTime
      ? wakeUpTime
      : initalDate + ' 7:00 AM';

    const dialogRef = this.dialog.open(DateTimePickerDialogComponent, {
      width: 'auto',
      data: { time: initalDateTime },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const [date, time, period] = result.split(' ');
        const [month, day, year] = date.split('/');
        const formattedDateTime = `${parseInt(month)}/${parseInt(
          day
        )}/${year} ${time} ${period}`;
        this.form.get('rise_from_bed_date_time')?.setValue(formattedDateTime);
        this.form.get('rise_from_bed_date_time')?.markAsTouched();
        this.form.get('rise_from_bed_date_time')?.updateValueAndValidity();
      }
    });
  }
  openGoToBedTimePicker(event: Event): void {
    event.preventDefault();

    const entryDate = this.form.get('entry_date')?.value;
    const goToBedTime = this.form.get('go_to_bed_date_time')?.value;
    const lightsOffTime = this.form.get('lights_off_date_time')?.value;

    const initalDate = entryDate
      ? format(entryDate, 'MM/dd/yyyy')
      : format(subDays(new Date(), 1), 'MM/dd/yyyy');

    const initalDateTime = goToBedTime
      ? goToBedTime
      : lightsOffTime
      ? lightsOffTime
      : initalDate + ' 10:00 PM';

    const dialogRef = this.dialog.open(DateTimePickerDialogComponent, {
      width: 'auto',
      data: { date: initalDate, time: initalDateTime },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const [date, time, period] = result.split(' ');
        const [month, day, year] = date.split('/');
        const formattedDateTime = `${parseInt(month)}/${parseInt(
          day
        )}/${year} ${time} ${period}`;
        this.form.get('go_to_bed_date_time')?.setValue(formattedDateTime);
        this.form.get('go_to_bed_date_time')?.markAsTouched();
        this.form.get('go_to_bed_date_time')?.updateValueAndValidity();
      }
    });
  }

  openLightsOffTimePicker(event: Event): void {
    event.preventDefault();
    const entryDate = this.form.get('entry_date')?.value;
    const goToBedTime = this.form.get('go_to_bed_date_time')?.value;
    const lightsOffTime = this.form.get('lights_off_date_time')?.value;

    const initalDate = entryDate
      ? format(entryDate, 'MM/dd/yyyy')
      : format(subDays(new Date(), 1), 'MM/dd/yyyy');

    const initalDateTime = lightsOffTime
      ? lightsOffTime
      : goToBedTime
      ? goToBedTime
      : initalDate + ' 10:00 PM';

    const dialogRef = this.dialog.open(DateTimePickerDialogComponent, {
      width: 'auto',
      data: { date: initalDate, time: initalDateTime },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const [date, time, period] = result.split(' ');
        const [month, day, year] = date.split('/');
        const formattedDateTime = `${parseInt(month)}/${parseInt(
          day
        )}/${year} ${time} ${period}`;
        this.form.get('lights_off_date_time')?.setValue(formattedDateTime);
        this.form.get('lights_off_date_time')?.markAsTouched();
        this.form.get('lights_off_date_time')?.updateValueAndValidity();
      }
    });
  }

  openDurationTimePicker(event: Event): void {
    event.preventDefault();
    const durationFallingSleep = this.form.get('duration_falling_sleep')?.value;

    const dialogRef = this.dialog.open(HourMinuterPickerDialogComponent, {
      width: 'auto',
      data: { durationMinutes: durationFallingSleep || 0 },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.form.get('duration_falling_sleep')?.setValue(result);
        this.form.get('duration_falling_sleep')?.markAsTouched();
        this.form.get('duration_falling_sleep')?.updateValueAndValidity();
      }
    });
  }

  openMinutesAwakePicker(event: Event): void {
    event.preventDefault();
    const minutesAwake = this.form.get('minutes_awake')?.value;

    const dialogRef = this.dialog.open(HourMinuterPickerDialogComponent, {
      width: 'auto',
      data: { durationMinutes: minutesAwake || 0 },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.form.get('minutes_awake')?.setValue(result);
        this.form.get('minutes_awake')?.markAsTouched();
        this.form.get('minutes_awake')?.updateValueAndValidity();
      }
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }

  showSlider() {
    this.hidden = !this.hidden;
  }
  hideSlider() {
    this.hidden = true;
  }

  convertTimeStringToDate(timeString: string): Date | null {
    const [date, time, period] = timeString.split(' ');
    const [months, days, years] = date.split('/').map(Number);
    const [hours, minutes] = time.split(':').map(Number);

    if (
      !date ||
      !time ||
      !period ||
      isNaN(years) ||
      isNaN(months) ||
      isNaN(days) ||
      isNaN(hours) ||
      isNaN(minutes)
    ) {
      return null; // Return null if the time string is invalid
    }

    // Create a new Date object using the year, month (0-indexed), and day
    const newDate = new Date(years, months - 1, days);
    let hour = period === 'PM' && hours < 12 ? hours + 12 : hours;
    if (period === 'AM' && hour === 12) {
      hour = 0; // Adjust for midnight
    }

    newDate.setHours(hour);
    newDate.setMinutes(minutes);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);

    return newDate;
  }

  onSubmit() {
    if (this.submitted) {
      return;
    }
    this.submitted = true;
    // reset alerts on submit
    this.alertService.clear();

    // // stop here if form is invalid
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.formError = true;
      return;
    }

    this.formError = false;

    this.loading = true;

    let data = {
      ...this.form.value,
    };

    // Convert the time string to a Date object
    const wakeUpTimeDate = this.convertTimeStringToDate(
      this.form.value.wake_up_date_time
    );
    if (!wakeUpTimeDate) {
      this.alertService.error('Invalid wake-up date time format.');
      this.loading = false;
      return;
    }

    const riseFromBedTimeDate = this.convertTimeStringToDate(
      this.form.value.rise_from_bed_date_time
    );

    if (!riseFromBedTimeDate) {
      this.alertService.error('Invalid rise_from_bed date time format.');
      this.loading = false;
      return;
    }

    const goToBedTimeDate = this.convertTimeStringToDate(
      this.form.value.go_to_bed_date_time
    );

    if (!goToBedTimeDate) {
      this.alertService.error('Invalid go_to_bed date time format.');
      this.loading = false;
      return;
    }

    const lightsOffTimeDate = this.convertTimeStringToDate(
      this.form.value.lights_off_date_time
    );

    if (!lightsOffTimeDate) {
      this.alertService.error('Invalid lights_off date time format.');
      this.loading = false;
      return;
    }
    const sleepAidDetails = this.form.value.sleep_aid_details?.trim();
    this.sleepAidUsed = !!sleepAidDetails;

    let request = {
      ...this.form.value,
      entry_date: moment(this.form.value.entry_date).format('YYYY-MM-DD'),
      wake_up_date_time: wakeUpTimeDate,
      rise_from_bed_date_time: riseFromBedTimeDate,
      go_to_bed_date_time: goToBedTimeDate,
      lights_off_date_time: lightsOffTimeDate,
      sleep_aid_used: this.sleepAidUsed,
    };

    this.accountService.checkJournalEntryExist(request).subscribe({
      next: (response) => {
        if (response && response.exists) {
          this.courseService.getUserProgress().subscribe((data) => {
            if (data && Object.keys(data).length > 0) {
              let weekExpectedUnlockTime;
              const currentWeek = data.current_week;
              if (currentWeek === 1) {
                weekExpectedUnlockTime = new Date(
                  data.week1_expected_unlock_time
                );
              } else if (currentWeek === 2) {
                weekExpectedUnlockTime = new Date(
                  data.week2_expected_unlock_time
                );
              } else if (currentWeek === 3) {
                weekExpectedUnlockTime = new Date(
                  data.week3_expected_unlock_time
                );
              } else if (currentWeek === 4) {
                weekExpectedUnlockTime = new Date(
                  data.week4_expected_unlock_time
                );
              } else if (currentWeek === 5) {
                weekExpectedUnlockTime = new Date(
                  data.week5_expected_unlock_time
                );
              }

              const startTime = this.datePipe.transform(
                weekExpectedUnlockTime,
                'yyyy-MM-dd'
              );
              const endDate = weekExpectedUnlockTime;
              endDate.setDate(weekExpectedUnlockTime.getDate() + 6);
              const endTime = this.datePipe.transform(endDate, 'yyyy-MM-dd');

              const entryDate = this.datePipe.transform(
                request.entry_date,
                'yyyy-MM-dd'
              );

              if (entryDate >= startTime && entryDate <= endTime) {
                const dialogRef1 = this.dialog.open(
                  JournalConfirmDialogComponent,
                  {
                    data: {
                      displayFlg: 1,
                    },
                    disableClose: true,
                  }
                );

                dialogRef1.afterClosed().subscribe((result) => {
                  if (result === 'confirm') {
                    this.accountService
                      .updateSleepJournal(request)
                      .pipe(first())
                      .subscribe({
                        next: () => {
                          this.alertService.success(
                            'Your entry has been successfully updated! Redirecting to your journal page…'
                          );
                          setTimeout(function () {
                            window.location.reload();
                          }, 2000);
                        },
                        error: (error) => {
                          this.alertService.error(error);
                          this.loading = false;
                        },
                      });
                  } else {
                    this.alertService.info('Operation cancelled.');
                  }
                });
              } else {
                const dialogRef2 = this.dialog.open(
                  JournalConfirmDialogComponent,
                  {
                    data: {
                      displayFlg: 2,
                      startDate: startTime,
                      endDate: endTime,
                    },
                    disableClose: true,
                  }
                );

                dialogRef2.afterClosed().subscribe((result) => {
                  if (result === 'edit') {
                    this.router.navigate(['/journal']);
                  } else if (result === 'progress') {
                    this.router.navigate(['/diary']);
                  }
                });
              }
            }
          });
          this.submitted = false;
        } else {
          this.accountService
            .journal(request)
            .pipe(first())
            .subscribe({
              next: () => {
                // this.form.get('entry_date')?.reset();
                this.submitted = false;
                this.alertService.success(
                  'Your entry has been saved successfully! Redirecting to your journal page…'
                );
                setTimeout(function () {
                  window.location.reload();
                }, 2000);
              },
              error: (error) => {
                this.alertService.error(error);
                this.submitted = false;
                this.loading = false;
              },
            });
        }
      },
      error: (error) => {
        this.alertService.error(
          'There was an error checking the journal entry.'
        );
        this.submitted = false;
        this.loading = false;
      },
    });
  }

  timeChangeHandler(data) {
    console.log('time changed to', data);
  }

  invalidInputHandler() {
    console.log('invalid input');
  }

  changeMaxValue() {
    const maxValue = new Date();
    maxValue.setHours(20);
    maxValue.setMinutes(10);
    this.maxValue = maxValue;
  }
  // pageTwo() {
  //   this.page = 2;
  // }
  // pageOne() {
  //   this.page = 1;
  // }

  // @Input() buttonType: string = 'complete';
}
