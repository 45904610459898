<div class="p-4 container-custom outer">
  <h1
    style="
      color: #012233;
      margin-bottom: 10px !important;
      font-size: 28px !important;
    "
  >
    <b>{{ "W5_PREVENTION" | translate }}</b>
  </h1>
  <h4 style="color: #002e46; font-size: 18px">
    <br />
    <p>
      {{ "W5_MANAGE_SLEEP" | translate }}
    </p>
    <p>
      <b> {{ "W5_RULES" | translate }}</b>
    </p>

    <div class="container">
      <!-- Block 1 -->
      <div class="block">
        <div class="number-block red">
          <span>01</span>
          <div class="triangle light-red"></div>
        </div>
        <div class="text-content">
          <h3>{{ "W5_REDUCE_BED_TIME_TITLE" | translate }}</h3>
          <p>
            {{ "W5_REDUCE_BED_TIME" | translate }}
          </p>
        </div>
      </div>

      <!-- Block 2 -->
      <div class="block">
        <div class="number-block orange">
          <span>02</span>
          <div class="triangle"></div>
        </div>
        <div class="text-content">
          <h3>{{ "W5_BED_ONLY_SLEEP_TITLE" | translate }}</h3>
          <p>
            {{ "W5_BED_ONLY_SLEEP" | translate }}
          </p>
        </div>
      </div>

      <!-- Block 3 -->
      <div class="block">
        <div class="number-block green">
          <span>03</span>
          <div class="triangle"></div>
        </div>
        <div class="text-content">
          <h3>{{ "W5_AWAKE_NOT_STAY_BED_TITLE" | translate }}</h3>
          <p>
            {{ "W5_AWAKE_NOT_STAY_BED" | translate }}
          </p>
        </div>
      </div>

      <!-- Block 4 -->
      <div class="block">
        <div class="number-block teal">
          <span>04</span>
          <div class="triangle"></div>
        </div>
        <div class="text-content">
          <h3>{{ "W5_REGULAR_SLEEP_TITLE" | translate }}</h3>
          <p>
            {{ "W5_REGULAR_SLEEP" | translate }}
          </p>
        </div>
      </div>
    </div>

    <!-- <div class="page-container">
      <div class="rule-card" *ngFor="let rule of rules">
        <div class="rule-header" [ngStyle]="{ 'background-color': rule.color }">
          <span class="rule-number">{{ rule.number }}</span>
          <span
            class="triangle"
            [ngStyle]="{ 'border-left-color': rule.lightColor }"
          ></span
          >>
        </div>
        <div class="rule-content">
          <h3>{{ rule.title }}</h3>
          <p>{{ rule.description }}</p>
        </div>
      </div>
    </div> -->

    <!-- <br /> -->
    <div class="form-group text-center">
      <button
        class="btn btn-primary field mx-auto col-3"
        (click)="onNextClick()"
      >
        {{ "NEXT" | translate }}
      </button>
    </div>
  </h4>
</div>
