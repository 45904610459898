<!-- <div class="choose-quantity">
    <mat-icon (click)="onRemove()" color="primary">minus_box</mat-icon>
    <div class="quantity">{{quantity}}{{txt}}</div>
    <mat-icon (click)="onAdd()" color="primary">add_box</mat-icon>
 </div> -->

<!-- <input
  matInput
  type="number"
  readonly
  class="valueInput"
  placeholder="Value"
  [(value)]="hours * 60 + mins"
  (click)="showSlider()"
/> -->
<mat-form-field style="width: 100%" appearance="outline" onclick="hidden=false">
  <mat-label> {{ hintMessage | translate }}</mat-label>
  <input
    matInput
    type="number"
    readonly
    class="valueInput"
    [value]="quantity !== null ? quantity : ''"
    (click)="showSlider()"
  />
  <div *ngIf="formControl?.errors?.required" class="text-danger">
    This field is required
  </div>
  <div class="duration-container" *ngIf="!hidden">
    <div class="row d-flex flex-row mt-3">
      <div class="col"></div>
      <div class="col clock-div">
        {{ hours | number : "2.0" }}
      </div>
      <div class="col colon-div">:</div>
      <div class="col clock-div">
        {{ mins }}
      </div>
      <div class="col"></div>
    </div>
    <div class="row d-flex flex-column pr-4 pl-4">
      <mat-slider
        min="0"
        max="12"
        step="1"
        [(value)]="hours"
        (change)="updatevalue()"
      ></mat-slider>
      <mat-label class="clock-label">HOURS</mat-label>
      <mat-slider
        min="0"
        max="60"
        step="1"
        [(value)]="mins"
        (change)="updatevalue()"
      ></mat-slider>
      <mat-label class="clock-label">MINUTES</mat-label>
    </div>
  </div>
</mat-form-field>
