import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CourseService } from '@app/_services/course.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-long-time-confirm-dialog',
  templateUrl: './long-time-confirm-dialog.component.html',
  styleUrls: ['./long-time-confirm-dialog.style.scss'],
})
export class LongTimeConfirmDialogComponent {
  safeHtmlContentContinue: SafeHtml;
  safeHtmlContentReset: SafeHtml;
  constructor(
    private courseService: CourseService,
    // private appComponent: AppComponent,
    public dialogRef: MatDialogRef<LongTimeConfirmDialogComponent>,
    private sanitizer: DomSanitizer,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.translate.get('DIALOG_CONTINUE_REMINDER').subscribe((res: string) => {
      this.safeHtmlContentContinue =
        this.sanitizer.bypassSecurityTrustHtml(res);
    });

    this.translate.get('DIALOG_RESET_REMINDER').subscribe((res: string) => {
      this.safeHtmlContentReset = this.sanitizer.bypassSecurityTrustHtml(res);
    });
  }

  onContinue() {
    // this.courseService.continueCourse(1);

    this.dialogRef.close('continue');
  }

  onReset() {
    // this.courseService.resetCourse();
    // this.courseService.updateProgress(1, 1);

    this.dialogRef.close('reset');
  }
}
