<div class="admin-dashboard container p-4">
    <h1 class="pb-4 pt-4">Admin Dashboard</h1>

    <div class="py-4 d-flex justify-content-between align-items-center">
        <button (click)="onAddPatient()" class="btn btn-primary px-5 patient-controls"> Add New Patient </button>
        <div class="d-inline-flex">
            <div class="has-search mr-2">
                <span class="fa fa-search form-control-feedback"></span>
                <input type="text" class="form-control mr-2 search-patient" placeholder="Search Patient"
                    (input)="onSearch($event.target.value)">
            </div>
            <div class="dropdown">
                <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                    Sort By
                </button>
                <div class="dropdown-menu">
                    <a class="dropdown-item" href="#" (click)="sortByName($event)">Name</a>
                    <a class="dropdown-item" href="#" (click)="sortByCreated($event)">Modified Date</a>
                </div>
            </div>
        </div>
    </div>

    <div>
        <div class="container pt-4 pb-2">
            <table class="table table-hover patient-details-table">
                <thead>
                    <tr>
                        <th><input type='checkbox' name='all-options' /></th>
                        <th>Name</th>
                        <th>Date Joined</th>
                        <th>Report</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody *ngIf="patientList?.length>0 else elseBlock">
                    <tr *ngFor="let item of patientList">

                        <td><input type='checkbox' name='all-options' /></td>
                        <td>{{item.first_name}} {{item.last_name}}</td>
                        <td>{{parseMomentDate(item.date_joined)}} </td>
                        <td>
                            <!--<i class="fa fa-file" aria-hidden="true" (click)="onViewPatientUpdate(item.id)"></i>-->
                            <i class="fa fa-file-text" aria-hidden="true"></i>
                        </td>
                        <td class='action-buttons'>
                            <i class="fa fa-pencil" aria-hidden="true" (click)="onViewPatient(item.id)"></i>
                            <i class="fa fa-trash-o" aria-hidden="true" (click)="clickMethodConfirm(item.id)"></i>
                        </td>
                    </tr>
                </tbody>
                <ng-template #elseBlock>
                    <tbody>
                        <tr colspan="5">
                            <p class="text-center">No data found</p>
                        </tr>
                    </tbody>
                </ng-template>
            </table>
        </div>

        <ul *ngIf="patientService.printPageList.length>0" class="pagination pagination-sm mt-4 justify-content-end">
            <li class="page-item" [ngClass]="[patientService.isPreviousPageDisable? 'disabled': '']">
                <a class="page-link" href="#" (click)="moveToPreviousPage($event)">Previous</a>
            </li>
            <li class="page-item" [ngClass]="[patientService.startPage == item ? 'active' : '']" *ngFor="let item of patientService.printPageList;">
                    <a class="page-link" href="#" (click)="goToPage($event,item)">{{item}}</a>
            </li>
            <li class="page-item" [ngClass]="[patientService.isNextPageDisable? 'disabled': '']"><a class="page-link"
                    href="#" (click)="moveToNextPage($event)">Next</a></li>
        </ul>

    </div>
	<ng-template #modalData let-modal>
	  <div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Angular Bootstrap Modal Popup</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
		  <span aria-hidden="true">×</span>
		</button>
	  </div>
	  <div class="modal-body">
		<p><strong>Are you sure you want to delete <span class="text-primary">"Agatha Harkness"</span> profile?</strong></p>
		
		<p>All data related to west view profile will be removed permanently.
		</p>
	  </div>
	  <div class="modal-footer">
		<button type="button" class="btn btn-danger" (click)="modal.close('Save click')">Close</button>
	  </div>
	</ng-template>
</div>
