import { Component, OnInit, Output,EventEmitter, Input } from '@angular/core';
import { MyService } from '../filter-service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.less']
})
export class FilterComponent implements OnInit {

  filter: string = 'daily';
  currentDate: Date = new Date();
  crtValue: string;
  sharedVar;
  constructor(private myService: MyService) { 
    this.filter = this.myService.crtValue;
}
  ngOnInit(): void {
  }
  change() {
    this.myService.setValue(this.filter);
  }
  
}
