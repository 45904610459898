<div class="container bg-light min-vh-100 p-4" style="margin-bottom: 3rem">
  <h1 class="pt-4 pb-4">{{ "CBTI_WHAT" | translate }}</h1>
  <div class="container vh-80 container-inner p-4">
    <div class="row p-4">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <video width="100%" height="auto" controls autoplay [src]="videoSrc">
          <!-- <source src="/assets/videos/what_is_cbti.mp4" type="video/mp4" />
          Your browser does not support the video tag. -->
        </video>
      </div>
      <div class="col-md-2"></div>
    </div>
    <div class="row p-4">
      <div class="col-md-6 content" style="font-family: Karla; font-size: 14px">
        <p style="color: #002e46 !important">
          {{ "CBTI_BEHAVIORAL_THERAPY" | translate }}
        </p>

        <p style="color: #002e46 !important">
          {{ "CBTI_COMPARE" | translate }}
        </p>

        <p style="color: #002e46 !important">
          {{ "CBTI_THERAPY_TYPES" | translate }}
        </p>

        <p style="color: #002e46 !important">
          {{ "CBTI_BBT" | translate }}
        </p>
      </div>
      <div class="col-md-6">
        <img src="/assets/cbti-1.png" alt="" width="100%" />
      </div>
    </div>
  </div>
</div>
