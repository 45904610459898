import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-intro-page2',
  templateUrl: 'intro-page2.component.html',
  styleUrls: ['intro-page2.style.scss'],
})
export class IntroPage2Component {
  constructor(private router: Router) {}
  onNextClick() {
    this.router.navigate(['recommended_time_line']); // navigate to intropage3
  }

  steps = [
    {
      number: '01',
      title: 'INTRO_P2_SELF_EVALUATION',
      description: 'INTRO_P2_SELF_EVALUATION_DETAILS',
      color: '#FFC107',
    },
    {
      number: '02',
      title: 'INTRO_P2_EDUCATION',
      description: 'INTRO_P2_EDUCATION_DETAILS',
      color: '#FF5722',
    },
    {
      number: '03',
      title: 'INTRO_P2_SELF_REFLECTION',
      description: 'INTRO_P2_SELF_REFLECTION_DETAILS',
      color: '#03A9F4',
    },
  ];
}
