import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '@app/_services';

@Component({
  selector: 'app-intro-page1',
  templateUrl: 'intro-page1.component.html',
  styleUrls: ['intro-page1.style.scss'],
})
export class IntroPage1Component {
  account = this.accountService.accountValue;

  constructor(private router: Router, private accountService: AccountService) {}

  onNextClick() {
    this.router.navigate(['introduction']); // navigate to intropage2
  }
}
