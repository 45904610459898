<div class="container p-4 container-custom outer">
  <h1
    style="
      color: #012233;
      margin-bottom: 10px !important;
      font-size: 28px !important;
    "
  >
    <b>{{ "W1_P1_TRACK_SLEEP" | translate }}</b>
  </h1>
  <h4 style="color: #002e46; font-size: 18px">
    <br />
    <p>
      {{ "W1_P1_TRACK_SLEEP_DETAILS" | translate }}
    </p>
    <p>
      {{ "W1_P1_DO_EVERYDAY" | translate }}
    </p>

    <div class="speech-bubble-container">
      <!-- <video
        class="person-video"
        width="200%"
        height="200px"
        autoplay
        muted
        (ended)="onVideoEnded()"
        [src]="videoSrc"
        #videoPlayer
      > -->
      <video
        class="person-video"
        autoplay
        muted
        (ended)="onVideoEnded()"
        [src]="videoSrc"
        #videoPlayer
      >
        <!-- 
       <source
          src="assets/videos/Introduction - Voice 2.mp4"
          type="video/mp4"
        />-->
      </video>
      <!-- <div class="speech-bubble">
        <p>
          {{ "W1_P1_DIARY_EVERYDAY" | translate }}
          <br /><br />
          {{ "W1_P1_DIARY_TODAY" | translate }}
        </p>
      </div> -->
    </div>
    <br />
    <div class="form-group text-center">
      <button
        class="sleep-journal-button btn btn-primary field mx-auto col-3"
        (click)="onNextClick()"
        [disabled]="!isVideoEnded"
      >
        {{ "NEXT" | translate }}
      </button>
    </div>
  </h4>
</div>
