import { Component, OnInit } from '@angular/core';
import { AlertService } from '@app/_services';
import { first } from 'rxjs/operators';
import { PatientService } from '@app/_services/patient.service';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent implements OnInit {
  patientId: Number;
  patientDetails: Object;
  showSpecificPatient: boolean = true;
  patientProfileDetails: Object;
  patientList: any[];

  constructor(
    private alertService: AlertService,
    public patientService: PatientService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.patientService.resetPaginationDetails()
    this.patientService.getPatientDetails()
      .pipe(first())
      .subscribe({
        next: (data) => {
          console.log("Patient data is ", data.results)
          this.patientList = data.results
        },
        error: (error) => {
          this.alertService.error(error);
          // this.loading = false;
        },
      });
  }

  onSearch(searchString): void {
    console.log("Data is ", searchString)
    if (searchString.length > 2 || searchString.length == 0) {
      this.patientService.getPatientDetailsFromSearch(searchString)
        .pipe(first())
        .subscribe({
          next: (data) => {
            console.log("Patient data is ", data.results)
            this.patientList = data.results
          },
          error: (error) => {
            this.alertService.error(error);
            // this.loading = false;
          },
        });
    }
  }

  onAddPatient(): void {
    this.router.navigateByUrl("add-patient");
  }

  onViewPatient(patientId: Number) {
    this.router.navigateByUrl('patient-profile/' + patientId)
  }

  onViewPatientUpdate(patientId: Number) {
    this.router.navigateByUrl('patient-profile-update/' + patientId)
  }
  
  parseMomentDate(modifiedDate: string) {
    if (modifiedDate == null) {
      return "-"
    }

    try {
      return moment(modifiedDate).format("YYYY-MM-DD")
    } catch (error) {
      return "-"
    }
  }

  moveToNextPage(event: any) {
    event.preventDefault();
    this.patientService.moveToNextPage()
      .subscribe({
        next: (data) => {
          this.patientList = data.results
        },
        error: (error) => {
          this.alertService.error(error);
        },
      });
  }

  moveToPreviousPage(event: any) {
    event.preventDefault();
    this.patientService.moveToPreviousPage()
      .subscribe({
        next: (data) => {
          this.patientList = data.results
        },
        error: (error) => {
          this.alertService.error(error);
        },
      });
  }

  goToPage(event: any,index:any) {
    event.preventDefault();
    this.patientService.goToPage(index)
      .subscribe({
        next: (data) => {
          this.patientList = data.results
        },
        error: (error) => {
          this.alertService.error(error);
        },
      });
  }
  
  sortByName(event) {
    event.preventDefault();
    this.patientService.sortByName()
      .subscribe({
        next: (data) => {
          this.patientList = data.results
        },
        error: (error) => {
          this.alertService.error(error);
        },
      });
  }

  sortByCreated(event) {
    event.preventDefault();
    this.patientService.sortByCreated()
      .subscribe({
        next: (data) => {
          this.patientList = data.results
        },
        error: (error) => {
          this.alertService.error(error);
        },
      });
  }
onDeletePatient(patientId: Number) {
    this.patientService.deletePatientDetailsById(patientId)
      .pipe(first())
      .subscribe({
        next: (data) => {
          data.initial_letter = data.first_name.charAt(0)
          this.patientDetails = data
		  window.location.reload();
        },
        error: (error) => {
          this.alertService.error(error);
          // this.loading = false;
        },
      });
  }
clickMethodConfirm(patientId: Number) {
  if(confirm("Are you sure to delete patient data?")) {
    this.onDeletePatient(patientId);
  }
}
}