<div class="container p-4">
  <h1 class="pl-4 pt-4">{{ "CONTACT_US" | translate }}</h1>
  <p class="p-4">{{ "CONTACT_TIME_RESPOND" | translate }}</p>
  <form [formGroup]="contactUsForm" (ngSubmit)="onSubmit()" class="p-4 m-4">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="first_name">{{ "CONTACT_FIRST_NAME" | translate }}</label>
          <label
            for="first_name"
            [ngClass]="{
              'text-danger':
                submitted && contactUsForm.get('first_name').invalid
            }"
            >{{ "CONTACT_FIRST_NAME" | translate }}</label
          >

          <input
            formControlName="first_name"
            id="first_name"
            #firstName
            type="text"
            class="form-control"
            [ngClass]="{
              'is-invalid': submitted && contactUsForm.get('first_name').invalid
            }"
          />
          <ng-container
            *ngIf="submitted && contactUsForm.get('first_name').invalid"
          >
            <small id="first_name" class="text-danger">
              First name is required.
            </small>
          </ng-container>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label
            for="last_name"
            [ngClass]="{
              'text-danger': submitted && contactUsForm.get('last_name').invalid
            }"
            >{{ "CONTACT_LAST_NAME" | translate }}</label
          >

          <input
            id="last_name"
            formControlName="last_name"
            cols="30"
            rows="10"
            [ngClass]="{
              'is-invalid': submitted && contactUsForm.get('last_name').invalid
            }"
            class="form-control"
          />
          <ng-container *ngIf="submitted">
            <small
              *ngIf="contactUsForm.get('last_name').errors?.required"
              class="text-danger"
              >Last Name is required
            </small>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="form-group">
          <label
            for="email"
            [ngClass]="{
              'text-danger': submitted && contactUsForm.get('email').invalid
            }"
            >{{ "CONTACT_EMAIL" | translate }}</label
          >

          <input
            formControlName="email"
            type="email"
            [ngClass]="{
              'is-invalid': submitted && contactUsForm.get('email').invalid
            }"
            class="form-control"
          />
          <ng-container *ngIf="submitted">
            <small
              *ngIf="contactUsForm.get('email').errors?.required"
              class="text-danger"
              >Email is required
            </small>
            <small
              *ngIf="contactUsForm.get('email').errors?.email"
              class="text-danger"
              >Please enter a valid email address</small
            >
          </ng-container>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label
            for="phone"
            [ngClass]="{
              'text-danger': submitted && contactUsForm.get('phone').invalid
            }"
            >{{ "CONTACT_NO" | translate }}</label
          >

          <input
            id="phone"
            formControlName="phone"
            type="tel"
            id="phone"
            name="phone"
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            required
            cols="30"
            rows="10"
            [ngClass]="{
              'is-invalid': submitted && contactUsForm.get('phone').invalid
            }"
            class="form-control"
          />
          <ng-container *ngIf="submitted">
            <small
              *ngIf="contactUsForm.get('phone').errors?.required"
              class="text-danger"
              >Please enter your phone number in correct format e.g.
              123-456-7891
            </small>
            <small
              *ngIf="contactUsForm.get('phone').errors?.pattern"
              class="text-danger"
              >Please provide a valid phone number e.g. 123-456-7891</small
            >
          </ng-container>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label
        for="message"
        [ngClass]="{
          'text-danger': submitted && contactUsForm.get('message').invalid
        }"
        >{{ "CONTACT_MESSAGE" | translate }}</label
      >
      <textarea
        name="message"
        id="message"
        formControlName="message"
        class="form-control"
        rows="10"
        [ngClass]="{
          'is-invalid': submitted && contactUsForm.get('message').invalid
        }"
      ></textarea>
      <ng-container *ngIf="submitted">
        <small
          *ngIf="contactUsForm.get('message').errors?.maxlength"
          class="text-danger"
          >You message exceed the maximum limit of 365 characters.</small
        >
      </ng-container>
    </div>
    <button class="btn btn-primary">{{ "CONTACT_SEND" | translate }}</button>
  </form>
</div>
