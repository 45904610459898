import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-time-picker-dialog',
  templateUrl: './time-picker-dialog.component.html',
  styleUrls: ['./time-picker-dialog.style.scss'],
})
export class TimePickerDialogComponent {
  hours: number[] = [];
  minutes: number[] = [];
  periods: string[] = ['AM', 'PM'];

  selectedHour: number = 12;
  selectedMinute: number = 0;
  selectedPeriod: string = 'AM';

  constructor(
    public dialogRef: MatDialogRef<TimePickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    for (let i = 1; i <= 12; i++) {
      this.hours.push(i);
    }

    for (let i = 0; i < 60; i += 5) {
      this.minutes.push(i);
    }
    if (data && data.time) {
      this.initializeSelectedTime(data.time);
    }
  }

  initializeSelectedTime(timeStr: string) {
    const [time, period] = timeStr.split(' ');
    const [hourStr, minuteStr] = time.split(':');
    this.selectedHour = parseInt(hourStr, 10);
    this.selectedMinute = parseInt(minuteStr, 10);
    this.selectedPeriod = period;
  }

  confirmTime(): void {
    const timeString = `${this.selectedHour}:${
      this.selectedMinute < 10 ? '0' + this.selectedMinute : this.selectedMinute
    } ${this.selectedPeriod}`;
    this.dialogRef.close(timeString);
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
