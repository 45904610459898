import {
  Component,
  ViewChild,
  AfterViewInit,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AccountService } from './_services';
import { Account, Role } from './_models';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { CourseService } from './_services/course.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.style.scss'],
})
export class AppComponent implements OnInit {
  Role: String;
  account: Account;
  userEnum = Role.User;
  adminEnum = Role.Admin;
  accountsbs;
  router;
  selectedLanguage: string = 'en';

  // Add source for menu
  selectedWeek: number = -1;

  toggleWeek(weekIndex: number): void {
    if (!this.courseService.getWeekLocked(weekIndex)) {
      this.selectedWeek = weekIndex;
    }
  }

  isExpanded(weekIndex: number): boolean {
    // return this.selectedWeek === weekIndex + 1;
    return (
      this.selectedWeek === weekIndex &&
      !this.courseService.getWeek1ContentLocked(0)
    );
  }

  constructor(
    private accountService: AccountService,
    private _router: Router,
    private translate: TranslateService,
    private courseService: CourseService,
    private actRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {
    this.accountService.account.subscribe((x) => (this.account = x));
    this.accountsbs = this.accountService.accountValue;

    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.router = event.url;
      }
    });

    // Translation setup code
    this.translate.addLangs(['en', 'sch', 'tch']);
    this.translate.setDefaultLang('en');
  }

  // Language switch function
  switchLanguage(language: string) {
    this.selectedLanguage = language;
    localStorage.setItem('selectedLanguage', language);
    this.translate.use(language);
  }

  ngOnInit() {
    this.accountService.fetchAccountDataFromStorage();
    this.Role = this.accountService.Role;

    const savedLang = localStorage.getItem('selectedLanguage');
    if (savedLang) {
      this.selectedLanguage = savedLang;
      this.translate.use(savedLang);
    } else {
      this.selectedLanguage = this.translate.defaultLang;
    }

    this.courseService.getUserProgress().subscribe({
      next: (progress: any) => {
        if (progress && Object.keys(progress).length > 0) {
          this.courseService.unlockWeekAndContent(
            progress.current_week,
            progress.current_page
          );
        }
      },
      error: (error) => {
        console.error('Error fetching user progress:', error);
      },
    });

    // this.courseService.menuUpdated.subscribe(() => {
    //   this.refreshMenu();
    // });
    // this.courseService.menuUpdated$.subscribe(() => {
    //   console.log('app.com detectChanges');
    //   this.cdr.detectChanges();
    // });
  }

  refreshMenu(): void {
    console.log('app.com detectChanges');
    this.courseService.unlockWeek(0);
    this.courseService.unlockWeek1Content(0);
    this.cdr.detectChanges();
  }

  logout() {
    this.accountService.logout();
  }

  /**
   * Check if the router url contains the specified route
   *
   * @param {string} route
   * @returns
   * @memberof MyComponent
   */
  hasRoute(route: string) {
    return this.router == '/' + route;
  }

  /**
   * Check if the router url starts wtih the specified route
   *
   * @param {string} route
   * @returns
   * @memberof MyComponent
   */
  startsWithRoute(route: string) {
    return this.router && this.router.startsWith('/' + route);
  }
}
