import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.less']
})
export class RangeSliderComponent implements OnInit {

  @Input()
  currentDate: Date = new Date();

  @Input()
  selectedDate: any = new Date().toJSON().slice(0, 10);

  @Input()
  selectedMonth: any = new Date();

  @Input()
  noTheme: boolean = false;
  constructor() { }

  @Input()
  type = 'daily';

  @Input()
  name = 'selectw';

  today = new Date;
  ngOnInit(): void {
    var curr = new Date;
    var firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()));
    this.currentDate = firstday
    this.selectedMonth = moment(this.currentDate).format("YYYY-MM")
  }
  @Output() onDatePicked = new EventEmitter<any>();
  @Output() onWeekShift = new EventEmitter<any>();

  addDays(date, days) {
    if (isFinite(date) && date instanceof Date) {

      //  find = new Date(Number(date));

    } else {
      date = new Date((date));

    }
    const find = new Date(Number(date));
    find.setDate(date.getDate() + days)
    return find
  }

  addMonths(date, months) {
    const find = new Date(Number(date))
    find.setMonth(find.getMonth() + months);
    return find
  }



  shift(attr) {
    if (this.type == "monthly") {
      if (attr == -1) {
        this.currentDate = this.addMonths(this.currentDate, -7);
        this.selectedDate = this.currentDate;
      } else {
        this.currentDate = this.addMonths(this.currentDate, 7);
        this.selectedDate = this.currentDate;
      }
      this.selectedMonth = moment(this.currentDate).format("YYYY-MM")
      this.onWeekShift.emit(this.currentDate);
    } else {
      if (attr == -1) {
        this.currentDate = this.addDays(this.currentDate, -7);
      } else {
        this.currentDate = this.addDays(this.currentDate, 7);
      }
      this.selectedDate = moment(this.currentDate).format("YYYY-MM-DD")
      this.onWeekShift.emit(this.currentDate);
    }

  }

  change() {
    if(this.type != 'monthly'){
      this.onDatePicked.emit(this.selectedDate);

    }else{
    this.onDatePicked.emit(this.selectedMonth);

    }
  }

}
