<div class="admin-dashboard container p-4">
  <h1 class="pb-4 pt-4">Contact Management</h1>
  <div class="py-4 d-flex justify-content-between align-items-center">
    <div class="d-inline-flex">
      <div class="d-flex align-items-center mb-3" style="width: 100%">
        <h4
          style="
            width: 20%;
            color: #002e46;
            font-size: 25px;
            margin-left: 5%;
            margin-top: 3%;
            margin-right: 2%;
          "
        >
          <label>Name:</label>
        </h4>
        <input
          class="form-control mr-2"
          type="text"
          placeholder="Search name"
          [(ngModel)]="searchName"
          (input)="onSearch()"
          style="width: 35%"
        />
        <h4
          style="
            width: 60%;
            color: #002e46;
            font-size: 25px;
            margin-left: 2%;
            margin-top: 3%;
          "
        >
          <label>Processed Status:</label>
        </h4>
        <select
          [(ngModel)]="filterProcessed"
          class="form-control"
          (change)="onSearch()"
          style="width: 40%"
        >
          <option value="">All</option>
          <option value="true">Processed</option>
          <option value="false">Not Processed</option>
        </select>
      </div>
    </div>
  </div>

  <div>
    <div class="container pt-4 pb-2">
      <h4 style="color: #002e46; font-size: 18px">
        <table class="table table-hover patient-details-table">
          <thead>
            <tr>
              <th (click)="sortData('first_name')">
                Name
                <i class="fa" [ngClass]="getSortIcon('first_name')"></i>
              </th>
              <th (click)="sortData('created')">
                Created Time<i
                  class="fa"
                  [ngClass]="getSortIcon('created')"
                ></i>
              </th>
              <th (click)="sortData('message')" style="width: 30%">
                Message
                <i class="fa" [ngClass]="getSortIcon('message')"></i>
              </th>
              <th (click)="sortData('is_processed')">
                Processed<i
                  class="fa"
                  [ngClass]="getSortIcon('is_processed')"
                ></i>
              </th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody *ngIf="contacts?.length > 0; else elseBlock">
            <tr *ngFor="let item of contacts">
              <td>{{ item.first_name }} {{ item.last_name }}</td>
              <td>
                {{ parseMomentDate(item.created) }}
              </td>
              <td>
                {{
                  item.message.length > 15
                    ? (item.message | slice : 0 : 15) + "..."
                    : item.message
                }}
              </td>
              <td>{{ item.is_processed ? "✅" : "❌" }}</td>

              <td>
                <i
                  class="fa fa-file-text"
                  aria-hidden="true"
                  (click)="goToContactDetails(item.id)"
                ></i>
              </td>
            </tr>
          </tbody>
          <ng-template #elseBlock>
            <tbody>
              <tr colspan="5">
                <p class="text-center">No data found</p>
              </tr>
            </tbody>
          </ng-template>
        </table>
        <div *ngIf="totalCount >= limit" class="pagination">
          <button
            class="btn btn-primary px-3 patient-controls page-btn"
            (click)="previousPage()"
            [disabled]="offset === 0"
          >
            Previous
          </button>
          <span
            >Page {{ currentPage }} of {{ totalPages }} (Total:{{
              totalCount
            }})</span
          >
          <button
            class="btn btn-primary px-3 patient-controls page-btn"
            (click)="nextPage()"
            [disabled]="!hasMoreData"
          >
            Next
          </button>
        </div>
      </h4>
    </div>
  </div>
</div>
