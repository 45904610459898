import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';

import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { DiaryRoutingModule } from './diary-routing.module';
import { LayoutComponent } from './layout.component';
import { DiaryComponent } from './diary.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatTimepickerModule } from 'mat-timepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSliderModule } from '@angular/material/slider';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { PieComponent } from './piechart/piechart.compoment';
import { DiaryJournalComponent } from './journal/diaryjournal.component';
import { DiaryNotesComponent } from './notes/notes.component';
import { BarchartComponent } from './barchart/barchart.component';
import { DonutchartComponent} from './donutchart/donutchart.component';
import { AnimatedDigitComponent } from './animated-digit/animated-digit.component';
import { FilterComponent } from './filter/filter.component';
import { DayComponent } from './day/day.component';
import { WeekComponent } from './week/week.component';
import { MonthComponent } from './month/month.component';
import { DaySliderComponent } from './day-slider/day-slider.component';
import { RangeSliderComponent } from './range-slider/range-slider.component';
import { HeaderComponent } from './header/header.component';
import { FormatTimePipe } from './format-time.pipe';
import { StrToTimePipe } from './str-to-time.pipe';
import { AddPatientComponent } from '@app/dashboard/patient/patient.component';
import { DashboardComponent } from '@app/dashboard/dashboard.component';
import { PatientProfileComponent } from '@app/dashboard/patient-profile/patient-profile.component';
import { AdministratorComponent } from '@app/dashboard/administrator/administrator.component';
import { AddAdminComponent } from '@app/dashboard/add-admin/add-admin.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DiaryRoutingModule,
    //BrowserAnimationsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatTimepickerModule,
    FormsModule,
    MatIconModule,
    NgxSliderModule,
    MatExpansionModule,
    MatSliderModule,
    NgxChartsModule,
  ],
  declarations: [
    LayoutComponent,
    DiaryComponent,
    PieComponent,
    DiaryJournalComponent,
    DiaryNotesComponent,
    BarchartComponent,
    DonutchartComponent,
    AnimatedDigitComponent,
    FilterComponent,
    DayComponent,
    WeekComponent,
    MonthComponent,
    RangeSliderComponent,
    HeaderComponent,
    FormatTimePipe,
    StrToTimePipe,
    AddPatientComponent,
    DashboardComponent,
    PatientProfileComponent,
    AdministratorComponent,
    AddAdminComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class DiaryModule {}

