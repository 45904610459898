<div class="charts">

    <ngx-charts-advanced-pie-chart [view]="view" [scheme]="colorScheme" [results]="single" [gradient]="gradient"
        (select)="onSelect($event)" (activate)="onActivate($event)" (deactivate)="onDeactivate($event)">
    </ngx-charts-advanced-pie-chart>
    <div style="margin-left: -30%">
        <p>
            <mat-icon> mode_night</mat-icon>
            Time Asleep
            {{single[0].value}}
        </p>

        <p>
            <mat-icon>alarm</mat-icon>
            Total Time in Bed
            {{single[0].value + single[1].value}}
        </p>
    </div>
</div>