import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Account, Role } from '@app/_models';
import { Journal } from '@app/_models/journal';
import { DatePipe } from '@angular/common';
import { Contactus } from '@app/_models/contactus';
import { PatientService } from '@app/_services/patient.service';

const hostUrl = `${environment.apiUrl}`;

const baseUrl = `${environment.apiUrl}/api`;
const baseUrlJournal = `${environment.apiUrl}/journal`;
@Injectable({ providedIn: 'root' })
export class AccountService {
  private accountSubject: BehaviorSubject<Account>;
  public account: Observable<Account>;
  private role: Role;

  constructor(
    private router: Router,
    private http: HttpClient,
    private datepipe: DatePipe,
    private patientService: PatientService
  ) {
    this.accountSubject = new BehaviorSubject<Account>(null);
    this.account = this.accountSubject.asObservable();
  }

  public get accountValue(): Account {
    let tkn = localStorage.getItem('token');
    return JSON.parse(tkn);
    // return this.accountSubject.value;
  }

  public fetchAccountDataFromStorage(): void {
    let tkn = localStorage.getItem('token');
    let userRole = JSON.parse(tkn).user.get_role_type_label;

    if (userRole === 'Doctor') {
      this.Role = Role.Admin;
    }

    if (userRole === 'Patient') {
      this.Role = Role.User;
    }
    console.log('userRole' + userRole);
    console.log('Role:' + this.Role);
  }

  isLoginSubject = new BehaviorSubject<boolean>(this.hasToken());

  /**
   * if we have token the user is loggedIn
   * @returns {boolean}
   */
  private hasToken(): boolean {
    return !localStorage.getItem('token');
  }

  login(email: string, password: string) {
    return this.http
      .post<any>(
        `${baseUrl}/token/`,
        { email, password },
        { withCredentials: true }
      )
      .pipe(
        map((account) => {
          localStorage.setItem('token', JSON.stringify(account));
          this.accountSubject.next(account);
          this.startRefreshTokenTimer();
          return account;
        })
      );
  }

  logout() {
    localStorage.removeItem('token');
    this.http
      .post<any>(`${baseUrl}/revoke-token`, {}, { withCredentials: true })
      .subscribe();
    this.stopRefreshTokenTimer();
    this.accountSubject.next(null);
    this.router.navigate(['/account/login']);
  }

  public get Role(): Role {
    return this.role;
  }
  public set Role(value: Role) {
    this.role = value;
  }

  /**
   *
   * @returns {Observable<T>}
   */
  isLoggedIn(): Observable<Account> {
    let tkn = localStorage.getItem('token');
    return JSON.parse(tkn);
  }

  refreshToken() {
    return this.http
      .post<any>(`${baseUrl}/refresh-token/`, {}, { withCredentials: true })
      .pipe(
        map((account) => {
          this.accountSubject.next(account);
          this.startRefreshTokenTimer();
          return account;
        })
      );
  }

  register(account: Account) {
    return this.http.post(`${baseUrl}/register/`, account);
  }

  journal(journal: Journal) {
    journal.entry_date = this.datepipe.transform(
      journal.entry_date,
      'yyyy-MM-dd'
    );
    journal.wake_up_date_time = this.datepipe.transform(
      journal.wake_up_date_time,
      'yyyy-MM-dd HH:mm:ss'
    );
    journal.rise_from_bed_date_time = this.datepipe.transform(
      journal.rise_from_bed_date_time,
      'yyyy-MM-dd HH:mm:ss'
    );
    journal.go_to_bed_date_time = this.datepipe.transform(
      journal.go_to_bed_date_time,
      'yyyy-MM-dd HH:mm:ss'
    );
    journal.lights_off_date_time = this.datepipe.transform(
      journal.lights_off_date_time,
      'yyyy-MM-dd HH:mm:ss'
    );
    // journal.wake_up_time = this.datepipe.transform(
    //   journal.wake_up_time,
    //   'HH:mm:ssZZZZZ'
    // );
    // journal.rise_from_bed = this.datepipe.transform(
    //   journal.rise_from_bed,
    //   'HH:mm:ssZZZZZ'
    // );
    // journal.go_to_bed = this.datepipe.transform(
    //   journal.go_to_bed,
    //   'HH:mm:ssZZZZZ'
    // );
    // journal.lights_off_time = this.datepipe.transform(
    //   journal.lights_off_time,
    //   'HH:mm:ssZZZZZ'
    // );
    return this.http.post(`${baseUrlJournal}/journal/`, journal);
  }

  updateJournal(updateJournal: Journal) {
    let JournalId = updateJournal['id'];
    updateJournal.entry_date = this.datepipe.transform(
      updateJournal.entry_date,
      'yyyy-MM-dd'
    );
    updateJournal.wake_up_date_time = this.datepipe.transform(
      updateJournal.wake_up_date_time,
      'yyyy-MM-dd HH:mm:ss'
    );
    updateJournal.rise_from_bed_date_time = this.datepipe.transform(
      updateJournal.rise_from_bed_date_time,
      'yyyy-MM-dd HH:mm:ss'
    );
    updateJournal.go_to_bed_date_time = this.datepipe.transform(
      updateJournal.go_to_bed_date_time,
      'yyyy-MM-dd HH:mm:ss'
    );
    updateJournal.lights_off_date_time = this.datepipe.transform(
      updateJournal.lights_off_date_time,
      'yyyy-MM-dd HH:mm:ss'
    );
    // updateJournal.wake_up_time = this.datepipe.transform(
    //   updateJournal.wake_up_time,
    //   'HH:mm:ssZZZZZ'
    // );
    // updateJournal.rise_from_bed = this.datepipe.transform(
    //   updateJournal.rise_from_bed,
    //   'HH:mm:ssZZZZZ'
    // );
    // updateJournal.go_to_bed = this.datepipe.transform(
    //   updateJournal.go_to_bed,
    //   'HH:mm:ssZZZZZ'
    // );
    // updateJournal.lights_off_time = this.datepipe.transform(
    //   updateJournal.lights_off_time,
    //   'HH:mm:ssZZZZZ'
    // );
    return this.http.put(
      `${baseUrlJournal}/journal/updatejournal/${JournalId}/`,
      updateJournal
    );
  }
  updateSleepJournal(updateJournal: Journal) {
    let JournalId = JSON.parse(localStorage.getItem('token')).user.id;
    updateJournal.entry_date = this.datepipe.transform(
      updateJournal.entry_date,
      'yyyy-MM-dd'
    );
    updateJournal.wake_up_date_time = this.datepipe.transform(
      updateJournal.wake_up_date_time,
      'yyyy-MM-dd HH:mm:ss'
    );
    updateJournal.rise_from_bed_date_time = this.datepipe.transform(
      updateJournal.rise_from_bed_date_time,
      'yyyy-MM-dd HH:mm:ss'
    );
    updateJournal.go_to_bed_date_time = this.datepipe.transform(
      updateJournal.go_to_bed_date_time,
      'yyyy-MM-dd HH:mm:ss'
    );
    updateJournal.lights_off_date_time = this.datepipe.transform(
      updateJournal.lights_off_date_time,
      'yyyy-MM-dd HH:mm:ss'
    );
    // updateJournal.wake_up_time = this.datepipe.transform(
    //   updateJournal.wake_up_time,
    //   'HH:mm:ssZZZZZ'
    // );
    // updateJournal.rise_from_bed = this.datepipe.transform(
    //   updateJournal.rise_from_bed,
    //   'HH:mm:ssZZZZZ'
    // );
    // updateJournal.go_to_bed = this.datepipe.transform(
    //   updateJournal.go_to_bed,
    //   'HH:mm:ssZZZZZ'
    // );
    // updateJournal.lights_off_time = this.datepipe.transform(
    //   updateJournal.lights_off_time,
    //   'HH:mm:ssZZZZZ'
    // );
    return this.http.put(
      `${baseUrlJournal}/journal/updatesleepjournal/${JournalId}/`,
      updateJournal
    );
  }

  deleteJournalDetailsById(id: Number) {
    return this.http
      .get<any>(`${baseUrlJournal}/journal/journaldelete/${id}/`)
      .pipe(map((journalDetails) => {}));
  }

  contact(contact: Contactus) {
    // journal.entry_date = this.datepipe.transform(journal.entry_date, 'yyyy-MM-dd');
    // journal.wake_up_time = this.datepipe.transform(journal.wake_up_time, 'HH:mm:ssZZZZZ');
    // journal.rise_from_bed = this.datepipe.transform(journal.rise_from_bed, 'HH:mm:ssZZZZZ');
    // journal.go_to_bed = this.datepipe.transform(journal.go_to_bed, 'HH:mm:ssZZZZZ');
    // journal.lights_off_time = this.datepipe.transform(journal.lights_off_time, 'HH:mm:ssZZZZZ');
    return this.http.post(`${hostUrl}/contact/contact/`, contact);
  }
  getDairyData(start_date = null, type = 'daily') {
    let params = new HttpParams();
    // let start_date_minus_1:any = new Date;
    if (start_date == null && type == 'weekly') {
      var currentDay = new Date();
      start_date = new Date(
        currentDay.setDate(currentDay.getDate() - currentDay.getDay())
      );
    }

    if (start_date == null) {
      start_date = new Date();
    }
    let end_date: any = new Date(start_date.getTime());
    let start_date_cp: any = new Date(start_date.getTime());

    if (type == 'daily') {
      end_date.setDate(start_date.getDate() + 6);
      start_date_cp.setDate(start_date_cp.getDate() - 1);
    }

    if (type == 'weekly') {
      end_date.setDate(start_date.getDate() + 6);
      start_date_cp.setDate(start_date_cp.getDate());
    }

    if (type == 'monthly') {
      end_date = new Date(end_date.getFullYear(), end_date.getMonth() + 1, 0);
      start_date_cp = new Date(
        start_date_cp.getFullYear(),
        start_date_cp.getMonth(),
        1
      );
    }

    if (type == 'half-yearly') {
      end_date = new Date(end_date.getFullYear(), end_date.getMonth() + 7, 0);
      start_date_cp = new Date(
        start_date_cp.getFullYear(),
        start_date_cp.getMonth(),
        1
      );
    }

    start_date_cp = this.datepipe.transform(start_date_cp, 'yyyy-MM-dd');
    end_date = this.datepipe.transform(end_date, 'yyyy-MM-dd');

    params = params.append('start_date', start_date_cp);
    params = params.append('end_date', end_date);

    if (this.role === Role.Admin) {
      let patientId = this.patientService.patientData.id;
      return this.http.get<Journal[]>(
        `${baseUrlJournal}/journal/patient/${patientId}/`,
        { params: params }
      );
    } else {
      return this.http.get<Journal[]>(
        `${baseUrlJournal}/journal/fetchDataForDate/`,
        { params: params }
      );
    }
  }
  verifyEmail(token: string) {
    return this.http.post(`${baseUrl}/verify-email`, { token });
  }

  forgotPassword(email: string) {
    return this.http.post(`${baseUrl}/forgot-password`, { email });
  }

  validateResetToken(token: string) {
    return this.http.post(`${baseUrl}/validate-reset-token`, { token });
  }

  resetPassword(token: string, password: string, confirmPassword: string) {
    return this.http.post(`${baseUrl}/reset-password`, {
      token,
      password,
      confirmPassword,
    });
  }

  getAll() {
    return this.http.get<Account[]>(baseUrl);
  }

  getById(id: string) {
    return this.http.get<Account>(`${baseUrl}/${id}`);
  }

  create(params) {
    return this.http.post(baseUrl, params);
  }

  update(id, params) {
    return this.http.put(`${baseUrl}/${id}`, params).pipe(
      map((account: any) => {
        // update the current account if it was updated
        if (account.id === this.accountValue.id) {
          // publish updated account to subscribers
          account = { ...this.accountValue, ...account };
          this.accountSubject.next(account);
        }
        return account;
      })
    );
  }

  delete(id: string) {
    return this.http.delete(`${baseUrl}/${id}`).pipe(
      finalize(() => {
        // auto logout if the logged in account was deleted
        if (id === this.accountValue.id) this.logout();
      })
    );
  }

  fetchLastEntryData() {
    return this.http.get(
      `${hostUrl}/journal/journal/patient-details/last-entry/`
    );
  }

  checkJournalEntryExist(data: any): Observable<JournalEntryResponse> {
    return this.http.post<JournalEntryResponse>(
      `${baseUrl}/check-entry-exist/`,
      data
    );
  }

  // checkJournalEntryExist(data) {
  //   return this.http.post(`${baseUrl}/check-entry-exist/`, data);
  // }

  // helper methods

  private refreshTokenTimeout;

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(this.accountValue.jwtToken.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    this.refreshTokenTimeout = setTimeout(
      () => this.refreshToken().subscribe(),
      timeout
    );
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
}
export interface JournalEntryResponse {
  exists: boolean;
}
