import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-hour-minuter-picker-dialog',
  templateUrl: './hour-minuter-picker-dialog.component.html',
  styleUrls: ['./hour-minuter-picker-dialog.style.scss'],
})
export class HourMinuterPickerDialogComponent {
  hours: number[] = [];
  minutes: number[] = [];
  totalMinutes: number = 0;

  selectedHour: number = 0;
  selectedMinute: number = 0;

  constructor(
    public dialogRef: MatDialogRef<HourMinuterPickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    for (let i = 0; i <= 12; i++) {
      this.hours.push(i);
    }

    for (let i = 0; i < 60; i += 5) {
      this.minutes.push(i);
    }
    if (data && data.durationMinutes !== undefined) {
      this.initializeSelectedTime(data.durationMinutes);
    }
  }

  initializeSelectedTime(totalMinutes: number) {
    this.selectedHour = Math.floor(totalMinutes / 60);
    this.selectedMinute = totalMinutes % 60;
    this.calculateTotalMinutes();
  }
  calculateTotalMinutes() {
    this.totalMinutes =
      Number(this.selectedHour * 60) + Number(this.selectedMinute);
  }

  confirmTime(): void {
    this.dialogRef.close(this.totalMinutes);
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
