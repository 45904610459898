export var initData = [
    {
        name: "Day 1",
        series: [
            {
                name: 'Sleep Time',
                value: 0,
                tooltipText: ''
            },
            {
                name: 'Time in Bed',
                value: 0,
                tooltipText: ''
            },
        ],
    },

    {
        name: "Day 2",
        series: [
            {
                name: 'Sleep Time',
                value: 0,
                tooltipText: ''
            },
            {
                name: 'Time in Bed',
                value: 0,
                tooltipText: ''
            },
        ],
    },

    {
        name: "Day 3",
        series: [
            {
                name: 'Sleep Time',
                value: 0,
                tooltipText: ''
            },
            {
                name: 'Time in Bed',
                value: 0,
                tooltipText: ''
            },
        ],
    },
    {
        name: "Day 4",
        series: [
            {
                name: 'Sleep Time',
                value: 0,
                tooltipText: ''
            },
            {
                name: 'Time in Bed',
                value: 0,
                tooltipText: ''
            },
        ],
    },
    {
        name: "Day 5",
        series: [
            {
                name: 'Sleep Time',
                value: 0,
                tooltipText: ''
            },
            {
                name: 'Time in Bed',
                value: 0,
                tooltipText: ''
            },
        ],
    },
    {
        name: "Day 6",
        series: [
            {
                name: 'Sleep Time',
                value: 0,
                tooltipText: ''
            },
            {
                name: 'Time in Bed',
                value: 0,
                tooltipText: ''
            },
        ],
    },
    {
        name: "Day 7",
        series: [
            {
                name: 'Sleep Time',
                value: 0,
                tooltipText: ''
            },
            {
                name: 'Time in Bed',
                value: 0,
                tooltipText: ''
            },
        ],
    },
];

export var sleepEfficiencyInit = [
    {
      "name": "Day 1",
      "value": 0
    },
    {
      "name": "Day 2",
      "value": 0
    },
    {
      "name": "Day 3",
      "value": 0
    },
    {
      "name": "Day 4",
      "value": 0
    },
    {
      "name": "Day 5",
      "value": 0
    },
    {
      "name": "Day 6",
      "value": 0
    },
    {
      "name": "Day 7",
      "value": 0
    }
  ];