import { Component, Injectable } from "@angular/core";
import { Subject } from 'rxjs';

// https://stackblitz.com/edit/angular-hrtwqk?file=src%2Fapp%2Fgrandchild.component.ts
@Injectable({
  providedIn: "root"
})
export class MyService {

  private subj = new Subject();
  public subj$ = this.subj.asObservable();
    public crtValue = "weekly";
  constructor() {}

  setValue(value) {
    this.subj.next(value);
    this.crtValue = value;
  }


}