<div class="container p-4 container-custom outer">
  <h1
    style="
      color: #012233;
      margin-bottom: 10px !important;
      font-size: 28px !important;
    "
  >
    <b>{{ "W5_REVIEW_SLEEP" | translate }}</b>
  </h1>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <h4 style="color: #002e46; font-size: 18px">
      <div *ngIf="this.role !== 1">
        <br />
        <p>
          {{ "W3_JOURNAL_DETAILS" | translate }}
        </p>
      </div>
      <div class="desktop">
        <div class="sleep-journal-container">
          <div class="entry-row empty-row"></div>
          <div
            *ngFor="
              let entry of journalEntries;
              let first = first;
              let last = last
            "
          >
            <div class="entry-row">
              <div class="timeline-wrapper">
                <div *ngIf="first">
                  <div
                    class="entry-row first-hidden-timeline"
                    *ngIf="!hasFirstPreviousData"
                  ></div>
                </div>

                <div class="timeline">
                  <div class="marker marker-1"></div>
                  <div class="marker marker-6"></div>
                  <div class="marker marker-12"></div>
                  <div class="marker marker-18"></div>
                  <div class="marker marker-24"></div>
                  <!-- Go to Bed to Lights Off -->
                  <div *ngIf="entry.bedToLightsOffChkFlg === 0">
                    <div
                      class="segment go-to-bed"
                      [ngStyle]="{
                        width:
                          calculateWidth(
                            entry.go_to_bed_date_time,
                            entry.lights_off_date_time
                          ) + '%',
                        left:
                          calculateLeftOffset(entry.go_to_bed_date_time) + '%'
                      }"
                      (click)="showPopup($event, entry, 'bedToLights')"
                    ></div>

                    <div
                      *ngIf="visiblePopup === 'bedToLights'"
                      class="popup"
                      [ngStyle]="{
                        top: popupPosition.top + 'px',
                        left: popupPosition.left + 'px'
                      }"
                      (click)="$event.stopPropagation()"
                    >
                      <strong>Go to Bed:</strong>
                      {{ parseMomentDateTime(selectedEntry.go_to_bed_date_time)
                      }}<br />
                      <strong>Lights Off:</strong>
                      {{
                        parseMomentDateTime(selectedEntry.lights_off_date_time)
                      }}
                    </div>
                  </div>
                  <div *ngIf="entry.previousBedToLightsOffChkFlg === 0">
                    <div
                      *ngIf="calculateSecondSegment(entry)"
                      class="segment go-to-bed"
                      [ngStyle]="{
                        width: calculateSecondSegment(entry)?.width,
                        left: calculateSecondSegment(entry)?.left
                      }"
                    ></div>
                  </div>
                  <div *ngIf="entry.previousBedToLightsOffChkFlg === 1">
                    <div
                      *ngIf="calculatePreviousFirstSegment(entry)"
                      class="segment go-to-bed"
                      [ngStyle]="{
                        width: calculatePreviousFirstSegment(entry)?.width,
                        left: calculatePreviousFirstSegment(entry)?.left
                      }"
                    ></div>
                  </div>

                  <!-- Lights Off to Sleep -->
                  <div *ngIf="entry.lightsOffToSleepChkFlg === 0">
                    <div
                      class="segment lights-off"
                      [ngStyle]="{
                        width:
                          calculateWidth(
                            entry.lights_off_date_time,
                            entry.go_to_sleep_date_time
                          ) + '%',
                        left:
                          calculateLeftOffset(entry.lights_off_date_time) + '%'
                      }"
                      (click)="showPopup($event, entry, 'lightsToSleep')"
                    ></div>

                    <div
                      *ngIf="visiblePopup === 'lightsToSleep'"
                      class="popup"
                      [ngStyle]="{
                        top: popupPosition.top + 'px',
                        left: popupPosition.left + 'px'
                      }"
                      (click)="$event.stopPropagation()"
                    >
                      <strong>Lights Off:</strong>
                      {{
                        parseMomentDateTime(selectedEntry.lights_off_date_time)
                      }}<br />
                      <strong>Go to Sleep:</strong>
                      {{
                        parseMomentDateTime(selectedEntry.go_to_sleep_date_time)
                      }}
                    </div>
                  </div>

                  <div *ngIf="entry.previousLightsOffToSleepChkFlg === 0">
                    <div
                      *ngIf="calculateSecondSegment(entry)"
                      class="segment lights-off"
                      [ngStyle]="{
                        width: calculateSecondSegment(entry)?.width,
                        left: calculateSecondSegment(entry)?.left
                      }"
                    ></div>
                  </div>
                  <div *ngIf="entry.previousLightsOffToSleepChkFlg === 1">
                    <div
                      class="segment go-to-bed"
                      [ngStyle]="{
                        width:
                          calculateWidth(
                            entry.previous_go_to_bed_date_time,
                            entry.previous_lights_off_date_time
                          ) + '%',
                        left:
                          calculateLeftOffset(
                            entry.previous_go_to_bed_date_time
                          ) + '%'
                      }"
                      (click)="showPopup($event, entry, 'preBedToLights')"
                    ></div>

                    <div
                      *ngIf="visiblePopup === 'preBedToLights'"
                      class="popup"
                      [ngStyle]="{
                        top: popupPosition.top + 'px',
                        left: popupPosition.left + 'px'
                      }"
                      (click)="$event.stopPropagation()"
                    >
                      <strong>Go to Bed:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_go_to_bed_date_time
                        )
                      }}<br />
                      <strong>Lights Off:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_lights_off_date_time
                        )
                      }}
                    </div>
                    <div
                      *ngIf="calculatePreviousFirstSegment(entry)"
                      class="segment lights-off"
                      [ngStyle]="{
                        width: calculatePreviousFirstSegment(entry)?.width,
                        left: calculatePreviousFirstSegment(entry)?.left
                      }"
                    ></div>
                  </div>
                  <div *ngIf="entry.lastLightsOffToSleepChkFlg === 0">
                    <div
                      *ngIf="calculateFirstSegment(entry)"
                      class="segment lights-off"
                      [ngStyle]="{
                        width: calculateFirstSegment(entry)?.width,
                        left: calculateFirstSegment(entry)?.left
                      }"
                    ></div>
                  </div>

                  <div *ngIf="entry.lastLightsOffToSleepChkFlg === 1">
                    <div
                      *ngIf="calculateNextSecondSegment(entry)"
                      class="segment lights-off"
                      [ngStyle]="{
                        width: calculateNextSecondSegment(entry)?.width,
                        left: calculateNextSecondSegment(entry)?.left
                      }"
                    ></div>
                    <div
                      class="segment go-to-sleep"
                      [ngStyle]="{
                        width:
                          calculateWidth(
                            entry.last_go_to_sleep_date_time,
                            entry.last_wake_up_date_time
                          ) + '%',
                        left:
                          calculateLeftOffset(
                            entry.last_go_to_sleep_date_time
                          ) + '%'
                      }"
                      (click)="showPopup($event, entry, 'lastSleepToWake')"
                    ></div>

                    <div
                      *ngIf="visiblePopup === 'lastSleepToWake'"
                      class="popup"
                      [ngStyle]="{
                        top: popupPosition.top + 'px',
                        left: popupPosition.left + 'px'
                      }"
                      (click)="$event.stopPropagation()"
                    >
                      <strong>Go to Sleep:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.last_go_to_sleep_date_time
                        )
                      }}<br />
                      <strong>Wake Up:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.last_wake_up_date_time
                        )
                      }}
                    </div>
                    <div
                      class="segment wake-up"
                      [ngStyle]="{
                        width:
                          calculateWidth(
                            entry.last_wake_up_date_time,
                            entry.last_rise_from_bed_date_time
                          ) + '%',
                        left:
                          calculateLeftOffset(entry.last_wake_up_date_time) +
                          '%'
                      }"
                      (click)="showPopup($event, entry, 'lastWakeToRise')"
                    ></div>

                    <div
                      *ngIf="visiblePopup === 'lastWakeToRise'"
                      class="popup"
                      [ngStyle]="{
                        top: popupPosition.top + 'px',
                        left: popupPosition.left + 'px'
                      }"
                      (click)="$event.stopPropagation()"
                    >
                      <strong>Wake Up:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.last_wake_up_date_time
                        )
                      }}<br />
                      <strong>Rise from Bed:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.last_rise_from_bed_date_time
                        )
                      }}
                    </div>
                  </div>

                  <!-- Sleep to Wake -->
                  <div *ngIf="entry.sleepToWakeChkFlg === 0">
                    <div
                      class="segment go-to-sleep"
                      [ngStyle]="{
                        width:
                          calculateWidth(
                            entry.go_to_sleep_date_time,
                            entry.wake_up_date_time
                          ) + '%',
                        left:
                          calculateLeftOffset(entry.go_to_sleep_date_time) + '%'
                      }"
                      (click)="showPopup($event, entry, 'sleepToWake')"
                    ></div>

                    <div
                      *ngIf="visiblePopup === 'sleepToWake'"
                      class="popup"
                      [ngStyle]="{
                        top: popupPosition.top + 'px',
                        left: popupPosition.left + 'px'
                      }"
                      (click)="$event.stopPropagation()"
                    >
                      <strong>Go to Sleep:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.go_to_sleep_date_time
                        )
                      }}<br />
                      <strong>Wake Up:</strong>
                      {{ parseMomentDateTime(selectedEntry.wake_up_date_time) }}
                    </div>
                  </div>

                  <div *ngIf="entry.previousSleepToWakeChkFlg === 0">
                    <div
                      *ngIf="calculateSecondSegment(entry)"
                      class="segment go-to-sleep"
                      [ngStyle]="{
                        width: calculateSecondSegment(entry)?.width,
                        left: calculateSecondSegment(entry)?.left
                      }"
                    ></div>
                  </div>
                  <div *ngIf="entry.previousSleepToWakeChkFlg === 1">
                    <div
                      class="segment go-to-bed"
                      [ngStyle]="{
                        width:
                          calculateWidth(
                            entry.previous_go_to_bed_date_time,
                            entry.previous_lights_off_date_time
                          ) + '%',
                        left:
                          calculateLeftOffset(
                            entry.previous_go_to_bed_date_time
                          ) + '%'
                      }"
                      (click)="showPopup($event, entry, 'preBedToLights')"
                    ></div>

                    <div
                      *ngIf="visiblePopup === 'preBedToLights'"
                      class="popup"
                      [ngStyle]="{
                        top: popupPosition.top + 'px',
                        left: popupPosition.left + 'px'
                      }"
                      (click)="$event.stopPropagation()"
                    >
                      <strong>Go to Bed:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_go_to_bed_date_time
                        )
                      }}<br />
                      <strong>Lights Off:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_lights_off_date_time
                        )
                      }}
                    </div>
                    <div
                      class="segment lights-off"
                      [ngStyle]="{
                        width:
                          calculateWidth(
                            entry.previous_lights_off_date_time,
                            entry.previous_go_to_sleep_date_time
                          ) + '%',
                        left:
                          calculateLeftOffset(
                            entry.previous_lights_off_date_time
                          ) + '%'
                      }"
                      (click)="showPopup($event, entry, 'preLightsToSleep')"
                    ></div>

                    <div
                      *ngIf="visiblePopup === 'preLightsToSleep'"
                      class="popup"
                      [ngStyle]="{
                        top: popupPosition.top + 'px',
                        left: popupPosition.left + 'px'
                      }"
                      (click)="$event.stopPropagation()"
                    >
                      <strong>Lights Off:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_lights_off_date_time
                        )
                      }}<br />
                      <strong>Go to Sleep:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_go_to_sleep_date_time
                        )
                      }}
                    </div>
                    <div
                      *ngIf="calculatePreviousFirstSegment(entry)"
                      class="segment go-to-sleep"
                      [ngStyle]="{
                        width: calculatePreviousFirstSegment(entry)?.width,
                        left: calculatePreviousFirstSegment(entry)?.left
                      }"
                    ></div>
                  </div>
                  <div *ngIf="entry.lastSleepToWakeChkFlg === 0">
                    <div
                      *ngIf="calculateFirstSegment(entry)"
                      class="segment go-to-sleep"
                      [ngStyle]="{
                        width: calculateFirstSegment(entry)?.width,
                        left: calculateFirstSegment(entry)?.left
                      }"
                    ></div>
                  </div>

                  <div *ngIf="entry.lastSleepToWakeChkFlg === 1">
                    <div
                      *ngIf="calculateNextSecondSegment(entry)"
                      class="segment go-to-sleep"
                      [ngStyle]="{
                        width: calculateNextSecondSegment(entry)?.width,
                        left: calculateNextSecondSegment(entry)?.left
                      }"
                    ></div>
                    <div
                      class="segment wake-up"
                      [ngStyle]="{
                        width:
                          calculateWidth(
                            entry.last_wake_up_date_time,
                            entry.last_rise_from_bed_date_time
                          ) + '%',
                        left:
                          calculateLeftOffset(entry.last_wake_up_date_time) +
                          '%'
                      }"
                      (click)="showPopup($event, entry, 'lastWakeToRise')"
                    ></div>

                    <div
                      *ngIf="visiblePopup === 'lastWakeToRise'"
                      class="popup"
                      [ngStyle]="{
                        top: popupPosition.top + 'px',
                        left: popupPosition.left + 'px'
                      }"
                      (click)="$event.stopPropagation()"
                    >
                      <strong>Wake Up:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.last_wake_up_date_time
                        )
                      }}<br />
                      <strong>Rise from Bed:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.last_rise_from_bed_date_time
                        )
                      }}
                    </div>
                  </div>

                  <!-- Wake to Rise -->
                  <div *ngIf="entry.wakeToRiseChkFlg === 0">
                    <div
                      class="segment wake-up"
                      [ngStyle]="{
                        width:
                          calculateWidth(
                            entry.wake_up_date_time,
                            entry.rise_from_bed_date_time
                          ) + '%',
                        left: calculateLeftOffset(entry.wake_up_date_time) + '%'
                      }"
                      (click)="showPopup($event, entry, 'wakeToRise')"
                    ></div>

                    <div
                      *ngIf="visiblePopup === 'wakeToRise'"
                      class="popup"
                      [ngStyle]="{
                        top: popupPosition.top + 'px',
                        left: popupPosition.left + 'px'
                      }"
                      (click)="$event.stopPropagation()"
                    >
                      <strong>Wake Up:</strong>
                      {{ parseMomentDateTime(selectedEntry.wake_up_date_time)
                      }}<br />
                      <strong>Rise from Bed:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.rise_from_bed_date_time
                        )
                      }}
                    </div>
                  </div>

                  <div *ngIf="entry.previousWakeToRiseChkFlg === 0">
                    <div
                      *ngIf="calculateSecondSegment(entry)"
                      class="segment wake-up"
                      [ngStyle]="{
                        width: calculateSecondSegment(entry)?.width,
                        left: calculateSecondSegment(entry)?.left
                      }"
                    ></div>
                  </div>
                  <div *ngIf="entry.previousWakeToRiseChkFlg === 1">
                    <div
                      class="segment go-to-bed"
                      [ngStyle]="{
                        width:
                          calculateWidth(
                            entry.previous_go_to_bed_date_time,
                            entry.previous_lights_off_date_time
                          ) + '%',
                        left:
                          calculateLeftOffset(
                            entry.previous_go_to_bed_date_time
                          ) + '%'
                      }"
                      (click)="showPopup($event, entry, 'preBedToLights')"
                    ></div>

                    <div
                      *ngIf="visiblePopup === 'preBedToLights'"
                      class="popup"
                      [ngStyle]="{
                        top: popupPosition.top + 'px',
                        left: popupPosition.left + 'px'
                      }"
                      (click)="$event.stopPropagation()"
                    >
                      <strong>Go to Bed:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_go_to_bed_date_time
                        )
                      }}<br />
                      <strong>Lights Off:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_lights_off_date_time
                        )
                      }}
                    </div>
                    <div
                      class="segment lights-off"
                      [ngStyle]="{
                        width:
                          calculateWidth(
                            entry.previous_lights_off_date_time,
                            entry.previous_go_to_sleep_date_time
                          ) + '%',
                        left:
                          calculateLeftOffset(
                            entry.previous_lights_off_date_time
                          ) + '%'
                      }"
                      (click)="showPopup($event, entry, 'preLightsToSleep')"
                    ></div>

                    <div
                      *ngIf="visiblePopup === 'preLightsToSleep'"
                      class="popup"
                      [ngStyle]="{
                        top: popupPosition.top + 'px',
                        left: popupPosition.left + 'px'
                      }"
                      (click)="$event.stopPropagation()"
                    >
                      <strong>Lights Off:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_lights_off_date_time
                        )
                      }}<br />
                      <strong>Go to Sleep:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_go_to_sleep_date_time
                        )
                      }}
                    </div>
                    <div
                      class="segment go-to-sleep"
                      [ngStyle]="{
                        width:
                          calculateWidth(
                            entry.previous_go_to_sleep_date_time,
                            entry.previous_wake_up_date_time
                          ) + '%',
                        left:
                          calculateLeftOffset(
                            entry.previous_go_to_sleep_date_time
                          ) + '%'
                      }"
                      (click)="showPopup($event, entry, 'preSleepToWake')"
                    ></div>

                    <div
                      *ngIf="visiblePopup === 'preSleepToWake'"
                      class="popup"
                      [ngStyle]="{
                        top: popupPosition.top + 'px',
                        left: popupPosition.left + 'px'
                      }"
                      (click)="$event.stopPropagation()"
                    >
                      <strong>Go to Sleep:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_go_to_sleep_date_time
                        )
                      }}<br />
                      <strong>Wake Up:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_wake_up_date_time
                        )
                      }}
                    </div>
                    <div
                      *ngIf="calculatePreviousFirstSegment(entry)"
                      class="segment wake-up"
                      [ngStyle]="{
                        width: calculatePreviousFirstSegment(entry)?.width,
                        left: calculatePreviousFirstSegment(entry)?.left
                      }"
                    ></div>
                  </div>
                  <div *ngIf="entry.lastWakeToRiseChkFlg === 0">
                    <div
                      *ngIf="calculateFirstSegment(entry)"
                      class="segment wake-up"
                      [ngStyle]="{
                        width: calculateFirstSegment(entry)?.width,
                        left: calculateFirstSegment(entry)?.left
                      }"
                    ></div>
                  </div>
                  <div *ngIf="entry.lastWakeToRiseChkFlg === 1">
                    <div
                      *ngIf="calculateNextSecondSegment(entry)"
                      class="segment wake-up"
                      [ngStyle]="{
                        width: calculateNextSecondSegment(entry)?.width,
                        left: calculateNextSecondSegment(entry)?.left
                      }"
                    ></div>
                  </div>

                  <div *ngIf="entry.previousRiseChkFlg === 1">
                    <div
                      class="segment go-to-bed"
                      [ngStyle]="{
                        width:
                          calculateWidth(
                            entry.previous_go_to_bed_date_time,
                            entry.previous_lights_off_date_time
                          ) + '%',
                        left:
                          calculateLeftOffset(
                            entry.previous_go_to_bed_date_time
                          ) + '%'
                      }"
                      (click)="showPopup($event, entry, 'preBedToLights')"
                    ></div>

                    <div
                      *ngIf="visiblePopup === 'preBedToLights'"
                      class="popup"
                      [ngStyle]="{
                        top: popupPosition.top + 'px',
                        left: popupPosition.left + 'px'
                      }"
                      (click)="$event.stopPropagation()"
                    >
                      <strong>Go to Bed:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_go_to_bed_date_time
                        )
                      }}<br />
                      <strong>Lights Off:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_lights_off_date_time
                        )
                      }}
                    </div>
                    <div
                      class="segment lights-off"
                      [ngStyle]="{
                        width:
                          calculateWidth(
                            entry.previous_lights_off_date_time,
                            entry.previous_go_to_sleep_date_time
                          ) + '%',
                        left:
                          calculateLeftOffset(
                            entry.previous_lights_off_date_time
                          ) + '%'
                      }"
                      (click)="showPopup($event, entry, 'preLightsToSleep')"
                    ></div>

                    <div
                      *ngIf="visiblePopup === 'preLightsToSleep'"
                      class="popup"
                      [ngStyle]="{
                        top: popupPosition.top + 'px',
                        left: popupPosition.left + 'px'
                      }"
                      (click)="$event.stopPropagation()"
                    >
                      <strong>Lights Off:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_lights_off_date_time
                        )
                      }}<br />
                      <strong>Go to Sleep:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_go_to_sleep_date_time
                        )
                      }}
                    </div>
                    <div
                      class="segment go-to-sleep"
                      [ngStyle]="{
                        width:
                          calculateWidth(
                            entry.previous_go_to_sleep_date_time,
                            entry.previous_wake_up_date_time
                          ) + '%',
                        left:
                          calculateLeftOffset(
                            entry.previous_go_to_sleep_date_time
                          ) + '%'
                      }"
                      (click)="showPopup($event, entry, 'preSleepToWake')"
                    ></div>

                    <div
                      *ngIf="visiblePopup === 'preSleepToWake'"
                      class="popup"
                      [ngStyle]="{
                        top: popupPosition.top + 'px',
                        left: popupPosition.left + 'px'
                      }"
                      (click)="$event.stopPropagation()"
                    >
                      <strong>Go to Sleep:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_go_to_sleep_date_time
                        )
                      }}<br />
                      <strong>Wake Up:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_wake_up_date_time
                        )
                      }}
                    </div>
                    <div
                      class="segment wake-up"
                      [ngStyle]="{
                        width:
                          calculateWidth(
                            entry.previous_wake_up_date_time,
                            entry.previous_rise_from_bed_date_time
                          ) + '%',
                        left:
                          calculateLeftOffset(
                            entry.previous_wake_up_date_time
                          ) + '%'
                      }"
                      (click)="showPopup($event, entry, 'preWakeToRise')"
                    ></div>

                    <div
                      *ngIf="visiblePopup === 'preWakeToRise'"
                      class="popup"
                      [ngStyle]="{
                        top: popupPosition.top + 'px',
                        left: popupPosition.left + 'px'
                      }"
                      (click)="$event.stopPropagation()"
                    >
                      <strong>Wake Up:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_wake_up_date_time
                        )
                      }}<br />
                      <strong>Rise from Bed:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_rise_from_bed_date_time
                        )
                      }}
                    </div>
                  </div>
                </div>

                <div class="time-labels">
                  <span>{{ entry.date | date : "MM-dd" }} 12 PM</span>
                  <span class="align-6pm">6 PM</span>
                  <span class="align-middle">{{
                    entry.next_date | date : "MM-dd"
                  }}</span>
                  <span class="align-6am">6 AM</span>
                  <span>11:59 AM</span>
                </div>

                <div *ngIf="last">
                  <div
                    class="entry-row last-hidden-timeline"
                    *ngIf="!hasLastNextData"
                  ></div>
                </div>
              </div>
              <!-- </div> -->
              <!-- <div class="sleetp-table"> -->
              <div class="minutes-awake-table-wrapper">
                <table class="minutes-awake-table">
                  <tr *ngIf="first">
                    <td><strong>DATE</strong></td>
                    <td>
                      <strong>NTW</strong>
                      <span class="header-note"
                        >({{ "W2_TIMES" | translate }})</span
                      >
                    </td>
                    <td>
                      <strong>WASO</strong>
                      <span class="header-note"
                        >({{ "W2_MIN" | translate }})</span
                      >
                    </td>
                    <td>
                      <strong>TIB</strong>
                      <span class="header-note"
                        >({{ "W2_MIN" | translate }})</span
                      >
                    </td>
                    <td>
                      <strong>ST</strong
                      ><span class="header-note"
                        >({{ "W2_MIN" | translate }})</span
                      >
                    </td>
                    <td>
                      <strong>SE</strong><span class="header-note">(%)</span>
                    </td>
                  </tr>

                  <div *ngIf="first">
                    <div
                      *ngIf="!hasFirstPreviousData"
                      class="first-empty-row"
                    ></div>
                  </div>

                  <tr
                    *ngIf="
                      entry.awake_number ||
                      entry.awake_minutes ||
                      entry.time_in_bed ||
                      entry.sleep_time ||
                      entry.sleep_efficiency
                    "
                  >
                    <td>
                      {{ entry.entry_date | date : "MM-dd" }}
                    </td>
                    <td>{{ entry.awake_number }}</td>
                    <td>{{ entry.awake_minutes }}</td>
                    <td>{{ entry.time_in_bed }}</td>
                    <td>{{ entry.sleep_time }}</td>
                    <td>{{ entry.sleep_efficiency }}</td>
                  </tr>
                  <div *ngIf="last">
                    <div *ngIf="!hasLastNextData" class="first-empty-row"></div>
                  </div>
                  <tr *ngIf="last">
                    <td>
                      <b>{{ "W2_TOTAL" | translate }}</b>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{{ form.get("sleepEfficiency")?.value }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="explanation-section">
          <div class="definitions" style="width: 55%">
            <strong>{{ "W2_LEGEND" | translate }}</strong>
            <div class="legend-row">
              <p>
                <span class="color-box red"></span
                >{{ "W2_BED_TO_LIGHTSOFF" | translate }}
              </p>
              <p>
                <span class="color-box purple"></span
                >{{ "W2_LIGHTSOFF_TO_SLEEP" | translate }}
              </p>
            </div>
            <div class="legend-row">
              <p>
                <span class="color-box blue"></span
                >{{ "W2_SLEEP_TO_WAKE_UP" | translate }}
              </p>

              <p>
                <span class="color-box green"></span
                >{{ "W2_WAKE_UP_TO_RISE" | translate }}
              </p>
            </div>
          </div>

          <div class="definitions" style="width: 50%">
            <strong>{{ "W2_DEFINITIONS" | translate }}</strong>
            <div class="definitions-row">
              <p><strong>DATE:</strong>{{ "W2_SLEEP_DATE" | translate }}</p>
              <p>
                <strong>NTW:</strong> {{ "W2_NIGHT_TIMES_WAKINGS" | translate }}
              </p>
            </div>
            <div class="definitions-row">
              <p>
                <strong>WASO:</strong> {{ "W2_WAKE_AFTER_SLEEP" | translate }}
              </p>
              <p><strong>TIB:</strong> {{ "W2_TIME_IN_BED" | translate }}</p>
            </div>

            <div class="definitions-row">
              <p><strong>ST:</strong> {{ "W2_SLEEP_TIME" | translate }}</p>
              <p><strong>SE:</strong> {{ "W2_EFFICIENCY" | translate }}</p>
            </div>
          </div>
        </div>
        <div class="entry-row" *ngIf="sleepAidUsed">
          <div class="minutes-awake-table-wrapper">
            <table class="minutes-awake-table">
              <tr>
                <td style="width: 10%"><strong>DATE</strong></td>
                <td style="width: 90%">
                  <strong>{{ "SLEEP_AID_DETAILS" | translate }}</strong>
                </td>
              </tr>
              <ng-container *ngFor="let item of journalEntries">
                <tr *ngIf="item.sleep_aid_used">
                  <td>
                    {{ item.entry_date | date : "MM-dd" }}
                  </td>
                  <td>{{ item.sleep_aid_details }}</td>
                </tr>
              </ng-container>
            </table>
          </div>
        </div>
      </div>
      <div class="mobile">
        <div class="sleep-journal-container">
          <div class="entry-row empty-row"></div>
          <div
            *ngFor="
              let entry of journalEntries;
              let first = first;
              let last = last
            "
          >
            <div class="entry-row">
              <div class="timeline-wrapper">
                <!-- <div *ngIf="first">
                  <div
                    class="entry-row first-hidden-timeline"
                    *ngIf="!hasFirstPreviousData"
                  ></div>
                </div> -->

                <div class="timeline">
                  <div class="marker marker-1"></div>
                  <div class="marker marker-6"></div>
                  <div class="marker marker-12"></div>
                  <div class="marker marker-18"></div>
                  <div class="marker marker-24"></div>
                  <!-- Go to Bed to Lights Off -->
                  <div *ngIf="entry.bedToLightsOffChkFlg === 0">
                    <div
                      class="segment go-to-bed"
                      [ngStyle]="{
                        width:
                          calculateWidth(
                            entry.go_to_bed_date_time,
                            entry.lights_off_date_time
                          ) + '%',
                        left:
                          calculateLeftOffset(entry.go_to_bed_date_time) + '%'
                      }"
                      (click)="showPopup($event, entry, 'bedToLights')"
                    ></div>

                    <div
                      *ngIf="visiblePopup === 'bedToLights'"
                      class="popup"
                      [ngStyle]="{
                        top: popupPosition.top + 'px',
                        left: popupPosition.left + 'px'
                      }"
                      (click)="$event.stopPropagation()"
                    >
                      <strong>Go to Bed:</strong>
                      {{ parseMomentDateTime(selectedEntry.go_to_bed_date_time)
                      }}<br />
                      <strong>Lights Off:</strong>
                      {{
                        parseMomentDateTime(selectedEntry.lights_off_date_time)
                      }}
                    </div>
                  </div>
                  <div *ngIf="entry.previousBedToLightsOffChkFlg === 0">
                    <div
                      *ngIf="calculateSecondSegment(entry)"
                      class="segment go-to-bed"
                      [ngStyle]="{
                        width: calculateSecondSegment(entry)?.width,
                        left: calculateSecondSegment(entry)?.left
                      }"
                    ></div>
                  </div>
                  <div *ngIf="entry.previousBedToLightsOffChkFlg === 1">
                    <div
                      *ngIf="calculatePreviousFirstSegment(entry)"
                      class="segment go-to-bed"
                      [ngStyle]="{
                        width: calculatePreviousFirstSegment(entry)?.width,
                        left: calculatePreviousFirstSegment(entry)?.left
                      }"
                    ></div>
                  </div>

                  <!-- Lights Off to Sleep -->
                  <div *ngIf="entry.lightsOffToSleepChkFlg === 0">
                    <div
                      class="segment lights-off"
                      [ngStyle]="{
                        width:
                          calculateWidth(
                            entry.lights_off_date_time,
                            entry.go_to_sleep_date_time
                          ) + '%',
                        left:
                          calculateLeftOffset(entry.lights_off_date_time) + '%'
                      }"
                      (click)="showPopup($event, entry, 'lightsToSleep')"
                    ></div>

                    <div
                      *ngIf="visiblePopup === 'lightsToSleep'"
                      class="popup"
                      [ngStyle]="{
                        top: popupPosition.top + 'px',
                        left: popupPosition.left + 'px'
                      }"
                      (click)="$event.stopPropagation()"
                    >
                      <strong>Lights Off:</strong>
                      {{
                        parseMomentDateTime(selectedEntry.lights_off_date_time)
                      }}<br />
                      <strong>Go to Sleep:</strong>
                      {{
                        parseMomentDateTime(selectedEntry.go_to_sleep_date_time)
                      }}
                    </div>
                  </div>

                  <div *ngIf="entry.previousLightsOffToSleepChkFlg === 0">
                    <div
                      *ngIf="calculateSecondSegment(entry)"
                      class="segment lights-off"
                      [ngStyle]="{
                        width: calculateSecondSegment(entry)?.width,
                        left: calculateSecondSegment(entry)?.left
                      }"
                    ></div>
                  </div>
                  <div *ngIf="entry.previousLightsOffToSleepChkFlg === 1">
                    <div
                      class="segment go-to-bed"
                      [ngStyle]="{
                        width:
                          calculateWidth(
                            entry.previous_go_to_bed_date_time,
                            entry.previous_lights_off_date_time
                          ) + '%',
                        left:
                          calculateLeftOffset(
                            entry.previous_go_to_bed_date_time
                          ) + '%'
                      }"
                      (click)="showPopup($event, entry, 'preBedToLights')"
                    ></div>

                    <div
                      *ngIf="visiblePopup === 'preBedToLights'"
                      class="popup"
                      [ngStyle]="{
                        top: popupPosition.top + 'px',
                        left: popupPosition.left + 'px'
                      }"
                      (click)="$event.stopPropagation()"
                    >
                      <strong>Go to Bed:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_go_to_bed_date_time
                        )
                      }}<br />
                      <strong>Lights Off:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_lights_off_date_time
                        )
                      }}
                    </div>
                    <div
                      *ngIf="calculatePreviousFirstSegment(entry)"
                      class="segment lights-off"
                      [ngStyle]="{
                        width: calculatePreviousFirstSegment(entry)?.width,
                        left: calculatePreviousFirstSegment(entry)?.left
                      }"
                    ></div>
                  </div>
                  <div *ngIf="entry.lastLightsOffToSleepChkFlg === 0">
                    <div
                      *ngIf="calculateFirstSegment(entry)"
                      class="segment lights-off"
                      [ngStyle]="{
                        width: calculateFirstSegment(entry)?.width,
                        left: calculateFirstSegment(entry)?.left
                      }"
                    ></div>
                  </div>

                  <div *ngIf="entry.lastLightsOffToSleepChkFlg === 1">
                    <div
                      *ngIf="calculateNextSecondSegment(entry)"
                      class="segment lights-off"
                      [ngStyle]="{
                        width: calculateNextSecondSegment(entry)?.width,
                        left: calculateNextSecondSegment(entry)?.left
                      }"
                    ></div>
                    <div
                      class="segment go-to-sleep"
                      [ngStyle]="{
                        width:
                          calculateWidth(
                            entry.last_go_to_sleep_date_time,
                            entry.last_wake_up_date_time
                          ) + '%',
                        left:
                          calculateLeftOffset(
                            entry.last_go_to_sleep_date_time
                          ) + '%'
                      }"
                      (click)="showPopup($event, entry, 'lastSleepToWake')"
                    ></div>

                    <div
                      *ngIf="visiblePopup === 'lastSleepToWake'"
                      class="popup"
                      [ngStyle]="{
                        top: popupPosition.top + 'px',
                        left: popupPosition.left + 'px'
                      }"
                      (click)="$event.stopPropagation()"
                    >
                      <strong>Go to Sleep:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.last_go_to_sleep_date_time
                        )
                      }}<br />
                      <strong>Wake Up:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.last_wake_up_date_time
                        )
                      }}
                    </div>
                    <div
                      class="segment wake-up"
                      [ngStyle]="{
                        width:
                          calculateWidth(
                            entry.last_wake_up_date_time,
                            entry.last_rise_from_bed_date_time
                          ) + '%',
                        left:
                          calculateLeftOffset(entry.last_wake_up_date_time) +
                          '%'
                      }"
                      (click)="showPopup($event, entry, 'lastWakeToRise')"
                    ></div>

                    <div
                      *ngIf="visiblePopup === 'lastWakeToRise'"
                      class="popup"
                      [ngStyle]="{
                        top: popupPosition.top + 'px',
                        left: popupPosition.left + 'px'
                      }"
                      (click)="$event.stopPropagation()"
                    >
                      <strong>Wake Up:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.last_wake_up_date_time
                        )
                      }}<br />
                      <strong>Rise from Bed:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.last_rise_from_bed_date_time
                        )
                      }}
                    </div>
                  </div>

                  <!-- Sleep to Wake -->
                  <div *ngIf="entry.sleepToWakeChkFlg === 0">
                    <div
                      class="segment go-to-sleep"
                      [ngStyle]="{
                        width:
                          calculateWidth(
                            entry.go_to_sleep_date_time,
                            entry.wake_up_date_time
                          ) + '%',
                        left:
                          calculateLeftOffset(entry.go_to_sleep_date_time) + '%'
                      }"
                      (click)="showPopup($event, entry, 'sleepToWake')"
                    ></div>

                    <div
                      *ngIf="visiblePopup === 'sleepToWake'"
                      class="popup"
                      [ngStyle]="{
                        top: popupPosition.top + 'px',
                        left: popupPosition.left + 'px'
                      }"
                      (click)="$event.stopPropagation()"
                    >
                      <strong>Go to Sleep:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.go_to_sleep_date_time
                        )
                      }}<br />
                      <strong>Wake Up:</strong>
                      {{ parseMomentDateTime(selectedEntry.wake_up_date_time) }}
                    </div>
                  </div>

                  <div *ngIf="entry.previousSleepToWakeChkFlg === 0">
                    <div
                      *ngIf="calculateSecondSegment(entry)"
                      class="segment go-to-sleep"
                      [ngStyle]="{
                        width: calculateSecondSegment(entry)?.width,
                        left: calculateSecondSegment(entry)?.left
                      }"
                    ></div>
                  </div>
                  <div *ngIf="entry.previousSleepToWakeChkFlg === 1">
                    <div
                      class="segment go-to-bed"
                      [ngStyle]="{
                        width:
                          calculateWidth(
                            entry.previous_go_to_bed_date_time,
                            entry.previous_lights_off_date_time
                          ) + '%',
                        left:
                          calculateLeftOffset(
                            entry.previous_go_to_bed_date_time
                          ) + '%'
                      }"
                      (click)="showPopup($event, entry, 'preBedToLights')"
                    ></div>

                    <div
                      *ngIf="visiblePopup === 'preBedToLights'"
                      class="popup"
                      [ngStyle]="{
                        top: popupPosition.top + 'px',
                        left: popupPosition.left + 'px'
                      }"
                      (click)="$event.stopPropagation()"
                    >
                      <strong>Go to Bed:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_go_to_bed_date_time
                        )
                      }}<br />
                      <strong>Lights Off:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_lights_off_date_time
                        )
                      }}
                    </div>
                    <div
                      class="segment lights-off"
                      [ngStyle]="{
                        width:
                          calculateWidth(
                            entry.previous_lights_off_date_time,
                            entry.previous_go_to_sleep_date_time
                          ) + '%',
                        left:
                          calculateLeftOffset(
                            entry.previous_lights_off_date_time
                          ) + '%'
                      }"
                      (click)="showPopup($event, entry, 'preLightsToSleep')"
                    ></div>

                    <div
                      *ngIf="visiblePopup === 'preLightsToSleep'"
                      class="popup"
                      [ngStyle]="{
                        top: popupPosition.top + 'px',
                        left: popupPosition.left + 'px'
                      }"
                      (click)="$event.stopPropagation()"
                    >
                      <strong>Lights Off:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_lights_off_date_time
                        )
                      }}<br />
                      <strong>Go to Sleep:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_go_to_sleep_date_time
                        )
                      }}
                    </div>
                    <div
                      *ngIf="calculatePreviousFirstSegment(entry)"
                      class="segment go-to-sleep"
                      [ngStyle]="{
                        width: calculatePreviousFirstSegment(entry)?.width,
                        left: calculatePreviousFirstSegment(entry)?.left
                      }"
                    ></div>
                  </div>
                  <div *ngIf="entry.lastSleepToWakeChkFlg === 0">
                    <div
                      *ngIf="calculateFirstSegment(entry)"
                      class="segment go-to-sleep"
                      [ngStyle]="{
                        width: calculateFirstSegment(entry)?.width,
                        left: calculateFirstSegment(entry)?.left
                      }"
                    ></div>
                  </div>

                  <div *ngIf="entry.lastSleepToWakeChkFlg === 1">
                    <div
                      *ngIf="calculateNextSecondSegment(entry)"
                      class="segment go-to-sleep"
                      [ngStyle]="{
                        width: calculateNextSecondSegment(entry)?.width,
                        left: calculateNextSecondSegment(entry)?.left
                      }"
                    ></div>
                    <div
                      class="segment wake-up"
                      [ngStyle]="{
                        width:
                          calculateWidth(
                            entry.last_wake_up_date_time,
                            entry.last_rise_from_bed_date_time
                          ) + '%',
                        left:
                          calculateLeftOffset(entry.last_wake_up_date_time) +
                          '%'
                      }"
                      (click)="showPopup($event, entry, 'lastWakeToRise')"
                    ></div>

                    <div
                      *ngIf="visiblePopup === 'lastWakeToRise'"
                      class="popup"
                      [ngStyle]="{
                        top: popupPosition.top + 'px',
                        left: popupPosition.left + 'px'
                      }"
                      (click)="$event.stopPropagation()"
                    >
                      <strong>Wake Up:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.last_wake_up_date_time
                        )
                      }}<br />
                      <strong>Rise from Bed:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.last_rise_from_bed_date_time
                        )
                      }}
                    </div>
                  </div>

                  <!-- Wake to Rise -->
                  <div *ngIf="entry.wakeToRiseChkFlg === 0">
                    <div
                      class="segment wake-up"
                      [ngStyle]="{
                        width:
                          calculateWidth(
                            entry.wake_up_date_time,
                            entry.rise_from_bed_date_time
                          ) + '%',
                        left: calculateLeftOffset(entry.wake_up_date_time) + '%'
                      }"
                      (click)="showPopup($event, entry, 'wakeToRise')"
                    ></div>

                    <div
                      *ngIf="visiblePopup === 'wakeToRise'"
                      class="popup"
                      [ngStyle]="{
                        top: popupPosition.top + 'px',
                        left: popupPosition.left + 'px'
                      }"
                      (click)="$event.stopPropagation()"
                    >
                      <strong>Wake Up:</strong>
                      {{ parseMomentDateTime(selectedEntry.wake_up_date_time)
                      }}<br />
                      <strong>Rise from Bed:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.rise_from_bed_date_time
                        )
                      }}
                    </div>
                  </div>

                  <div *ngIf="entry.previousWakeToRiseChkFlg === 0">
                    <div
                      *ngIf="calculateSecondSegment(entry)"
                      class="segment wake-up"
                      [ngStyle]="{
                        width: calculateSecondSegment(entry)?.width,
                        left: calculateSecondSegment(entry)?.left
                      }"
                    ></div>
                  </div>
                  <div *ngIf="entry.previousWakeToRiseChkFlg === 1">
                    <div
                      class="segment go-to-bed"
                      [ngStyle]="{
                        width:
                          calculateWidth(
                            entry.previous_go_to_bed_date_time,
                            entry.previous_lights_off_date_time
                          ) + '%',
                        left:
                          calculateLeftOffset(
                            entry.previous_go_to_bed_date_time
                          ) + '%'
                      }"
                      (click)="showPopup($event, entry, 'preBedToLights')"
                    ></div>

                    <div
                      *ngIf="visiblePopup === 'preBedToLights'"
                      class="popup"
                      [ngStyle]="{
                        top: popupPosition.top + 'px',
                        left: popupPosition.left + 'px'
                      }"
                      (click)="$event.stopPropagation()"
                    >
                      <strong>Go to Bed:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_go_to_bed_date_time
                        )
                      }}<br />
                      <strong>Lights Off:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_lights_off_date_time
                        )
                      }}
                    </div>
                    <div
                      class="segment lights-off"
                      [ngStyle]="{
                        width:
                          calculateWidth(
                            entry.previous_lights_off_date_time,
                            entry.previous_go_to_sleep_date_time
                          ) + '%',
                        left:
                          calculateLeftOffset(
                            entry.previous_lights_off_date_time
                          ) + '%'
                      }"
                      (click)="showPopup($event, entry, 'preLightsToSleep')"
                    ></div>

                    <div
                      *ngIf="visiblePopup === 'preLightsToSleep'"
                      class="popup"
                      [ngStyle]="{
                        top: popupPosition.top + 'px',
                        left: popupPosition.left + 'px'
                      }"
                      (click)="$event.stopPropagation()"
                    >
                      <strong>Lights Off:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_lights_off_date_time
                        )
                      }}<br />
                      <strong>Go to Sleep:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_go_to_sleep_date_time
                        )
                      }}
                    </div>
                    <div
                      class="segment go-to-sleep"
                      [ngStyle]="{
                        width:
                          calculateWidth(
                            entry.previous_go_to_sleep_date_time,
                            entry.previous_wake_up_date_time
                          ) + '%',
                        left:
                          calculateLeftOffset(
                            entry.previous_go_to_sleep_date_time
                          ) + '%'
                      }"
                      (click)="showPopup($event, entry, 'preSleepToWake')"
                    ></div>

                    <div
                      *ngIf="visiblePopup === 'preSleepToWake'"
                      class="popup"
                      [ngStyle]="{
                        top: popupPosition.top + 'px',
                        left: popupPosition.left + 'px'
                      }"
                      (click)="$event.stopPropagation()"
                    >
                      <strong>Go to Sleep:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_go_to_sleep_date_time
                        )
                      }}<br />
                      <strong>Wake Up:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_wake_up_date_time
                        )
                      }}
                    </div>
                    <div
                      *ngIf="calculatePreviousFirstSegment(entry)"
                      class="segment wake-up"
                      [ngStyle]="{
                        width: calculatePreviousFirstSegment(entry)?.width,
                        left: calculatePreviousFirstSegment(entry)?.left
                      }"
                    ></div>
                  </div>
                  <div *ngIf="entry.lastWakeToRiseChkFlg === 0">
                    <div
                      *ngIf="calculateFirstSegment(entry)"
                      class="segment wake-up"
                      [ngStyle]="{
                        width: calculateFirstSegment(entry)?.width,
                        left: calculateFirstSegment(entry)?.left
                      }"
                    ></div>
                  </div>
                  <div *ngIf="entry.lastWakeToRiseChkFlg === 1">
                    <div
                      *ngIf="calculateNextSecondSegment(entry)"
                      class="segment wake-up"
                      [ngStyle]="{
                        width: calculateNextSecondSegment(entry)?.width,
                        left: calculateNextSecondSegment(entry)?.left
                      }"
                    ></div>
                  </div>

                  <div *ngIf="entry.previousRiseChkFlg === 1">
                    <div
                      class="segment go-to-bed"
                      [ngStyle]="{
                        width:
                          calculateWidth(
                            entry.previous_go_to_bed_date_time,
                            entry.previous_lights_off_date_time
                          ) + '%',
                        left:
                          calculateLeftOffset(
                            entry.previous_go_to_bed_date_time
                          ) + '%'
                      }"
                      (click)="showPopup($event, entry, 'preBedToLights')"
                    ></div>

                    <div
                      *ngIf="visiblePopup === 'preBedToLights'"
                      class="popup"
                      [ngStyle]="{
                        top: popupPosition.top + 'px',
                        left: popupPosition.left + 'px'
                      }"
                      (click)="$event.stopPropagation()"
                    >
                      <strong>Go to Bed:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_go_to_bed_date_time
                        )
                      }}<br />
                      <strong>Lights Off:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_lights_off_date_time
                        )
                      }}
                    </div>
                    <div
                      class="segment lights-off"
                      [ngStyle]="{
                        width:
                          calculateWidth(
                            entry.previous_lights_off_date_time,
                            entry.previous_go_to_sleep_date_time
                          ) + '%',
                        left:
                          calculateLeftOffset(
                            entry.previous_lights_off_date_time
                          ) + '%'
                      }"
                      (click)="showPopup($event, entry, 'preLightsToSleep')"
                    ></div>

                    <div
                      *ngIf="visiblePopup === 'preLightsToSleep'"
                      class="popup"
                      [ngStyle]="{
                        top: popupPosition.top + 'px',
                        left: popupPosition.left + 'px'
                      }"
                      (click)="$event.stopPropagation()"
                    >
                      <strong>Lights Off:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_lights_off_date_time
                        )
                      }}<br />
                      <strong>Go to Sleep:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_go_to_sleep_date_time
                        )
                      }}
                    </div>
                    <div
                      class="segment go-to-sleep"
                      [ngStyle]="{
                        width:
                          calculateWidth(
                            entry.previous_go_to_sleep_date_time,
                            entry.previous_wake_up_date_time
                          ) + '%',
                        left:
                          calculateLeftOffset(
                            entry.previous_go_to_sleep_date_time
                          ) + '%'
                      }"
                      (click)="showPopup($event, entry, 'preSleepToWake')"
                    ></div>

                    <div
                      *ngIf="visiblePopup === 'preSleepToWake'"
                      class="popup"
                      [ngStyle]="{
                        top: popupPosition.top + 'px',
                        left: popupPosition.left + 'px'
                      }"
                      (click)="$event.stopPropagation()"
                    >
                      <strong>Go to Sleep:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_go_to_sleep_date_time
                        )
                      }}<br />
                      <strong>Wake Up:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_wake_up_date_time
                        )
                      }}
                    </div>
                    <div
                      class="segment wake-up"
                      [ngStyle]="{
                        width:
                          calculateWidth(
                            entry.previous_wake_up_date_time,
                            entry.previous_rise_from_bed_date_time
                          ) + '%',
                        left:
                          calculateLeftOffset(
                            entry.previous_wake_up_date_time
                          ) + '%'
                      }"
                      (click)="showPopup($event, entry, 'preWakeToRise')"
                    ></div>

                    <div
                      *ngIf="visiblePopup === 'preWakeToRise'"
                      class="popup"
                      [ngStyle]="{
                        top: popupPosition.top + 'px',
                        left: popupPosition.left + 'px'
                      }"
                      (click)="$event.stopPropagation()"
                    >
                      <strong>Wake Up:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_wake_up_date_time
                        )
                      }}<br />
                      <strong>Rise from Bed:</strong>
                      {{
                        parseMomentDateTime(
                          selectedEntry.previous_rise_from_bed_date_time
                        )
                      }}
                    </div>
                  </div>
                </div>

                <div class="time-labels">
                  <span>{{ entry.date | date : "MM-dd" }} 12 PM</span>
                  <span class="align-6pm">6 PM</span>
                  <span class="align-middle">{{
                    entry.next_date | date : "MM-dd"
                  }}</span>
                  <span class="align-6am">6 AM</span>
                  <span>11:59 AM</span>
                </div>

                <!-- <div *ngIf="last">
                  <div
                    class="entry-row last-hidden-timeline"
                    *ngIf="!hasLastNextData"
                  ></div>
                </div> -->
              </div>
            </div>
          </div>
          <!-- <div class="explanation-section"> -->
          <div class="definitions" style="width: 55%">
            <strong>{{ "W2_LEGEND" | translate }}</strong>
            <div class="legend-row">
              <p>
                <span class="color-box red"></span
                >{{ "W2_BED_TO_LIGHTSOFF" | translate }}
              </p>
              <p>
                <span class="color-box purple"></span
                >{{ "W2_LIGHTSOFF_TO_SLEEP" | translate }}
              </p>
            </div>
            <div class="legend-row">
              <p>
                <span class="color-box blue"></span
                >{{ "W2_SLEEP_TO_WAKE_UP" | translate }}
              </p>

              <p>
                <span class="color-box green"></span
                >{{ "W2_WAKE_UP_TO_RISE" | translate }}
              </p>
            </div>
          </div>
          <div class="first-empty-row-mobile"></div>
          <div
            *ngFor="
              let entry of journalEntries;
              let first = first;
              let last = last
            "
          >
            <div class="minutes-awake-table-wrapper">
              <table class="minutes-awake-table">
                <tr *ngIf="first">
                  <td><strong>DATE</strong></td>
                  <td>
                    <strong>NTW</strong>
                    <span class="header-note"
                      >({{ "W2_TIMES" | translate }})</span
                    >
                  </td>
                  <td>
                    <strong>WASO</strong>
                    <span class="header-note"
                      >({{ "W2_MIN" | translate }})</span
                    >
                  </td>
                  <td>
                    <strong>TIB</strong>
                    <span class="header-note"
                      >({{ "W2_MIN" | translate }})</span
                    >
                  </td>
                  <td>
                    <strong>ST</strong
                    ><span class="header-note"
                      >({{ "W2_MIN" | translate }})</span
                    >
                  </td>
                  <td>
                    <strong>SE</strong><span class="header-note">(%)</span>
                  </td>
                </tr>

                <div *ngIf="first">
                  <div
                    *ngIf="!hasFirstPreviousData"
                    class="first-empty-row-mobile"
                  ></div>
                </div>

                <tr
                  *ngIf="
                    entry.awake_number ||
                    entry.awake_minutes ||
                    entry.time_in_bed ||
                    entry.sleep_time ||
                    entry.sleep_efficiency
                  "
                >
                  <td>
                    {{ entry.date | date : "M-d" }}
                  </td>
                  <td>{{ entry.awake_number }}</td>
                  <td>{{ entry.awake_minutes }}</td>
                  <td>{{ entry.time_in_bed }}</td>
                  <td>{{ entry.sleep_time }}</td>
                  <td>{{ entry.sleep_efficiency }}</td>
                </tr>
                <div *ngIf="last">
                  <div
                    *ngIf="!hasLastNextData"
                    class="first-empty-row-mobile"
                  ></div>
                </div>
                <tr *ngIf="last">
                  <td>
                    <b>{{ "W2_TOTAL" | translate }}</b>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{{ form.get("sleepEfficiency")?.value }}</td>
                </tr>
              </table>
            </div>
          </div>
          <!-- <div class="explanation-section"> -->
          <div class="definitions">
            <strong>{{ "W2_DEFINITIONS" | translate }}</strong>
            <div class="definitions-row">
              <p><strong>DATE:</strong>{{ "W2_SLEEP_DATE" | translate }}</p>
              <p>
                <strong>NTW:</strong>
                {{ "W2_NIGHT_TIMES_WAKINGS" | translate }}
              </p>
            </div>
            <div class="definitions-row">
              <p>
                <strong>WASO:</strong>
                {{ "W2_WAKE_AFTER_SLEEP" | translate }}
              </p>
              <p><strong>TIB:</strong> {{ "W2_TIME_IN_BED" | translate }}</p>
            </div>

            <div class="definitions-row">
              <p><strong>ST:</strong> {{ "W2_SLEEP_TIME" | translate }}</p>
              <p><strong>SE:</strong> {{ "W2_EFFICIENCY" | translate }}</p>
            </div>
            <!-- </div> -->
          </div>
          <div class="entry-row" *ngIf="sleepAidUsed">
            <div class="minutes-awake-table-wrapper">
              <table class="minutes-awake-table">
                <tr>
                  <td style="width: 20%"><strong>DATE</strong></td>
                  <td style="width: 80%">
                    <strong>{{ "SLEEP_AID_DETAILS" | translate }}</strong>
                  </td>
                </tr>
                <ng-container *ngFor="let item of journalEntries">
                  <tr *ngIf="item.sleep_aid_used">
                    <td>
                      {{ item.entry_date | date : "MM-dd" }}
                    </td>
                    <td>{{ item.sleep_aid_details }}</td>
                  </tr>
                </ng-container>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="this.role !== 1">
        <br />
        <div class="form-group text-center">
          <button class="btn btn-primary field mx-auto col-3" type="submit">
            {{ "NEXT" | translate }}
          </button>
        </div>
      </div>
    </h4>
  </form>
</div>
