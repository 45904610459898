import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { CbtiComponent } from './cbti/cbti.component';
import { ContactusComponent } from './contactus/contactus.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AddPatientComponent } from './dashboard/patient/patient.component';
import { PatientProfileComponent } from './dashboard/patient-profile/patient-profile.component';
import { PatientProfileUpdateComponent } from './dashboard/patient-profile-update/patient-profile-update.component';
import { AdministratorComponent } from './dashboard/administrator/administrator.component';

import { HomeComponent } from './home';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { AuthGuard } from './_helpers';
import { Role } from './_models';
import { AddAdminComponent } from './dashboard/add-admin/add-admin.component';
import { DiaryComponent } from './diary/diary.component';

const accountModule = () =>
  import('./account/account.module').then((x) => x.AccountModule);
const adminModule = () =>
  import('./admin/admin.module').then((x) => x.AdminModule);
const profileModule = () =>
  import('./profile/profile.module').then((x) => x.ProfileModule);
const journalModule = () =>
  import('./journal/journal.module').then((x) => x.JournalModule);

const diaryModule = () =>
  import('./diary/diary.module').then((x) => x.DiaryModule);

const educateModule = () =>
  import('./educate/educate.module').then((x) => x.EducateModule);
  
const calculatorModule = () =>
  import('./calculator/calculator.module').then((x) => x.CalculatorModule);
  
const homeModule = () =>
  import('./home/home.module').then((x) => x.HomeModule);

const landingModule = () =>
  import('./landing/landing.module').then((x) => x.LandingModule);
const planModule = () => import('./plan/plan.module').then((x) => x.PlanModule);


// const addPatientModule = () =>
//   import('./dashboard/dashboard.module').then((x) => x.DashboardModule);

const routes: Routes = [
  { path: '', loadChildren: homeModule, canActivate: [AuthGuard] },
  { path: 'account', loadChildren: accountModule },
  { path: 'account/login', loadChildren: accountModule },
  { path: 'profile', loadChildren: profileModule, canActivate: [AuthGuard] },
  { path: 'calculator', loadChildren: calculatorModule, canActivate: [AuthGuard] },
  { path: 'journal', loadChildren: journalModule, canActivate: [AuthGuard] },
  { path: 'diary', loadChildren: diaryModule, canActivate: [AuthGuard] },
  { path: 'contact', component: ContactusComponent },
  { path: 'cbti', component: CbtiComponent, canActivate: [AuthGuard] }, 

  { path: 'onboarding', component: OnboardingComponent, canActivate: [AuthGuard] },
  { path: 'educate', loadChildren: educateModule, canActivate: [AuthGuard] },
  { path: 'plan', loadChildren: planModule, canActivate: [AuthGuard] },
  {
    path: 'admin',
    loadChildren: adminModule,
    canActivate: [AuthGuard],
    data: { roles: [Role.User] },
  },
  {
    path: 'dashboard',
    // loadChildren: diaryModule,
    // children: [ 
    //   { 
    //       path: 'api-dashboard', 
    //       component: DiaryComponent
    //   }] ,
    component: DashboardComponent,
    canActivate: [AuthGuard],
	data: { roles: [Role.Admin] },
  },
  {
    path: 'add-patient',
    // loadChildren: diaryModule,
    //  children: [ 
    //   { 
    //       path: 'api-dashboard', 
    //       component: DiaryComponent
    //   }] ,
    component: AddPatientComponent,
    canActivate: [AuthGuard],
	data: { roles: [Role.Admin] },
  },
  {
    path: 'patient-profile/:id',
    // loadChildren: diaryModule,
    //  children: [ 
    //   { 
    //       path: 'api-dashboard', 
    //       component: DiaryComponent
    //   }] ,
    component: PatientProfileComponent,
    canActivate: [AuthGuard],
	data: { roles: [Role.Admin] },
  },
  {
    path: 'patient-profile-update/:id',
    // loadChildren: diaryModule,
    //  children: [ 
    //   { 
    //       path: 'api-dashboard', 
    //       component: DiaryComponent
    //   }] ,
    component: PatientProfileUpdateComponent,
    canActivate: [AuthGuard],
	data: { roles: [Role.Admin] },
  },
  {
    path: 'administrator',
    // loadChildren: diaryModule,
    //  children: [ 
    //   { 
    //       path: 'api-dashboard', 
    //       component: DiaryComponent
    //   }] ,
    component: AdministratorComponent,
    canActivate: [AuthGuard],
	data: { roles: [Role.Admin] },
  },
  {
    path: 'add-admin',
    // loadChildren: diaryModule,
    //  children: [ 
    //   { 
    //       path: 'api-dashboard', 
    //       component: DiaryComponent
    //   }] ,
    component: AddAdminComponent,
    canActivate: [AuthGuard],
	data: { roles: [Role.Admin] },
  },
  // otherwise redirect to home
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, 
    {preloadingStrategy: PreloadAllModules}
    // { relativeLinkResolution: 'legacy' }
    )
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}