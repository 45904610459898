import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AccountService } from '@app/_services';

@Component({
  templateUrl: 'layout.component.html',
  styleUrls: ['layout.style.scss'],
})
export class LayoutComponent {
  // constructor(private router: Router, private accountService: AccountService) {
  //   // redirect to home if already logged in
  //   if (this.accountService.accountValue) {
  //     this.router.navigate(['/']);
  //   }
  // }
}
