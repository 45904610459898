import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatTime' })
export class FormatTimePipe implements PipeTransform {
  transform(time: number) {
    try{
      let hours:any = Math.floor(time / 60);
      let mins:any = time % 60;
      if(isNaN(hours)){
        hours = '..'
      }
      if(isNaN(mins)){
        mins = '..'
      }
      return hours + "h " + mins + 'min'
    }
    catch{
      return ''
    }
  }
}
