import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';

import { JournalRoutingModule } from './journal-routing.module';
import { LayoutComponent } from './layout.component';
import { JournalComponent } from './journal.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatTimepickerModule } from 'mat-timepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSliderModule } from '@angular/material/slider';
import { DurationComponent } from './duration/duration.component';
import { TestComponent } from './test/test.component';
import { ChooseQuantityComponent } from './choose-quatity.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    JournalRoutingModule,
    //BrowserAnimationsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatTimepickerModule,
    FormsModule,
    MatIconModule,
    NgxSliderModule,
    MatExpansionModule,
    MatSliderModule,
    TranslateModule.forChild(),
  ],
  declarations: [
    LayoutComponent,
    JournalComponent,
    DurationComponent,
    TestComponent,
    ChooseQuantityComponent,
    // [JournalComponent],

    // RegisterComponent,
    // VerifyEmailComponent,
    // ForgotPasswordComponent,
    // ResetPasswordComponent
  ],

  exports: [JournalComponent],
})
export class JournalModule {}
