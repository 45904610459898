<ng-container [ngSwitch]="state" style="background-color: white;">
  <div class="text-center vh-100 d-flex flex-column justify-content-around">
    <app-step1 *ngSwitchCase="stateEnum.step1"></app-step1>
    <app-step2 *ngSwitchCase="stateEnum.step2"></app-step2>
    <app-step3 *ngSwitchCase="stateEnum.step3"></app-step3>
    <div class="text-center ml-auto mr-auto w-10">
      <ng-container *ngIf="state == stateEnum.step3; then lastStep; else otherStep">
      </ng-container>
      <!-- <button type="button" class="btn btn-info btn-lg btn-block" (click)="next()">Next</button> -->
      <!-- <button type="button" class="btn btn-outline-info btn-lg btn-block" [routerLink]='["/"]' >Skip</button> -->
    </div>

    <div class="text-center ml-auto mr-auto w-10 align-bottom align-self-baseline">
      <div class="step" [ngClass]="{'active': state == stateEnum.step1}">&nbsp;</div>
      <div class="step" [ngClass]="{'active': state == stateEnum.step2}">&nbsp;</div>
      <div class="step" [ngClass]="{'active': state == stateEnum.step3}">&nbsp;</div>
    </div>
  </div>
</ng-container>


<ng-template #lastStep>
  <button type="button" class="btn btn-primary btn-lg btn-block" [routerLink]='["/"]'>Get Started</button>
</ng-template>
<ng-template #otherStep>
  <button type="button" class="btn btn-info btn-lg btn-block" (click)="next()">Next</button>
  <button type="button" class="btn btn-outline-info btn-lg btn-block" [routerLink]='["/"]'>Skip</button>
</ng-template>
