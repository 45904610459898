<div class="container p-4 container-custom outer">
  <h1
    style="
      color: #012233;
      margin-bottom: 10px !important;
      font-size: 28px !important;
    "
  >
    <b>{{ "MENU_PLAN_WEEK" | translate }}</b>
  </h1>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <h4 style="color: #002e46; font-size: 18px">
      <br />
      <p>
        <!-- {{ "W2_DATA_WEEKLY_JOURNAL" | translate }} -->
        {{ "W3_REVIEW_JOURNAL" | translate }}
      </p>

      <!-- Calculated Results Section -->
      <div class="journal-section">
        <!-- <div class="sleep-input">
          <label for="totalTimeInBed"
            >{{ "W2_TOTAL_TIME_IN_BED" | translate
            }}{{ "W2_IN_MINUTES" | translate }}</label
          >
          <input
            formControlName="totalTimeInBed"
            type="text"
            readonly
            [(ngModel)]="totalTimeInBed"
          />
        </div>
        <div class="sleep-input">
          <label for="totalSleepTime"
            >{{ "W2_TOTAL_SLEEP_TIME" | translate
            }}{{ "W2_IN_MINUTES" | translate }}</label
          >
          <input
            formControlName="totalSleepTime"
            type="text"
            readonly
            [(ngModel)]="totalSleepTime"
          />
        </div> -->
        <div class="sleep-input">
          <label for="sleepEfficiency"
            >{{ "W2_SLEEP_EFFICIENCY" | translate }}({{
              f.startDate?.value | date : "MM/dd"
            }}
            - {{ f.endDate?.value | date : "MM/dd" }})</label
          >
          <input
            formControlName="sleepEfficiency"
            type="text"
            readonly
            [(ngModel)]="sleepEfficiency"
          />
        </div>
        <div class="sleep-input">
          <label for="targetSleepEfficiency">{{
            "W2_TARGET_SLEEP_EFFICIENCY" | translate
          }}</label>
          <input type="text" readonly value="80%" />
        </div>
        <div class="sleep-input">
          <label for="dailySleepTime">{{ "W2_TST" | translate }}</label>
          <input
            formControlName="dailySleepTime"
            type="text"
            readonly
            [(ngModel)]="dailySleepTime"
          />
        </div>
      </div>
      <!-- <p>
        {{ "W2_TIME_BED_RESTRICTIONS" | translate }}
      </p> -->
      <!-- <div class="journal-section">
        <div class="sleep-input">
          <label for="dailySleepTime">{{ "W2_TST" | translate }}</label>
          <input
            formControlName="dailySleepTime"
            type="text"
            readonly
            [(ngModel)]="dailySleepTime"
          />
        </div>
      </div> -->

      <p>
        {{ "W2_CALCULATE_BEDTIME_DETIALS" | translate }}
      </p>

      <div class="journal-section">
        <div class="sleep-input">
          <label for="desiredGoToBedTime">{{
            "W2_BEDTIME_TIB" | translate
          }}</label>
          <input
            formControlName="desiredGoToBedTime"
            placeholder="Please select time..."
            (mousedown)="openGoToBedTimePicker($event)"
            readonly
            [(ngModel)]="desiredGoToBedTime"
            [ngClass]="{
              'input-error':
                f.desiredGoToBedTime?.invalid && f.desiredGoToBedTime?.touched
            }"
          />
        </div>
        <!-- <div
            class="text-danger align-message"
            *ngIf="f.desiredGoToBedTime.errors?.error"
          >
            {{ f.desiredGoToBedTime.errors?.error | translate }}
          </div> -->
        <div class="sleep-input">
          <label for="desiredWakeUpTime">{{
            "W2_WAKEUP_TIB" | translate
          }}</label>
          <input
            formControlName="desiredWakeUpTime"
            placeholder="Please select time..."
            (mousedown)="openWakeUpTimePicker($event)"
            readonly
            [(ngModel)]="desiredWakeUpTime"
            [ngClass]="{
              'input-error':
                f.desiredWakeUpTime?.invalid && f.desiredWakeUpTime?.touched
            }"
          />
        </div>
        <!-- <div
            class="text-danger align-message"
            *ngIf="f.desiredWakeUpTime.errors?.error"
          >
            {{ f.desiredWakeUpTime.errors?.error | translate }}
          </div> -->
        <div
          class="text-danger align-message"
          *ngIf="f.desiredWakeUpTime.errors?.desiredDadilySleepTimeChkError"
        >
          <span
            [innerHTML]="
              f.desiredWakeUpTime.errors?.desiredDadilySleepTimeChkError
                | translate
                  : {
                      dailySleepTime: f.desiredWakeUpTime.errors?.dailySleepTime
                    }
            "
          ></span>
        </div>
      </div>
      <h2>{{ "W3_KEY_POINTS" | translate }}</h2>
      <div class="journal-section">
        <p>{{ "W3_SUGESST_SLEEP" | translate }}</p>

        <p>{{ "W3_CONTINUE_LOG" | translate }}</p>
      </div>
      <br />
      <p>
        {{ "W2_KEEP_RECORD_JOURNAL" | translate }}
      </p>

      <br />
      <div class="form-group text-center">
        <button
          class="btn btn-primary field mx-auto col-3"
          type="submit"
          [disabled]="form.invalid"
        >
          {{ "NEXT" | translate }}
        </button>
      </div>
    </h4>
  </form>
</div>
