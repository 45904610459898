import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CourseService } from '@app/_services/course.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TimePickerDialogComponent } from '@app/dialog/time-picker-dialog/time-picker-dialog.component';
import { utcToZonedTime } from 'date-fns-tz';
import { AlertService } from '@app/_services';
import * as moment from 'moment';
import Validation from './validation';

@Component({
  selector: 'app-week3-page5',
  templateUrl: './week3-page5.component.html',
  styleUrls: ['./week3-page5.style.scss'],
})
export class Week3Page5Component implements OnInit {
  form: FormGroup;
  formError = false;
  // Form fields for sleep journal data
  goToBedTime: string = '';
  goToSleepTime: string = '';
  minutesAwake: string = '';
  wakeUpTime: string = '';
  riseFromBedTime: string = '';

  // Calculated results
  totalTimeInBed: string = '';
  totalSleepTime: string = '';
  sleepEfficiency: string = '';

  dailySleepTime: string = '';

  desiredGoToBedTime: string = '';
  desiredWakeUpTime: string = '';

  entry_date_noon: Date;
  next_entry_date_noon: Date;
  go_to_bed_date_time: Date;
  lights_off_date_time: Date;
  go_to_sleep_date_time: Date;
  wake_up_date_time: Date;
  rise_from_bed_date_time: Date;

  // customErrorStateMatcher = new CustomErrorStateMatcher();

  constructor(
    private router: Router,
    private translate: TranslateService,
    private courseService: CourseService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {
        week_number: ['3'],
        // totalTimeInBed: [''],
        // totalSleepTime: [''],
        startDate: [''],
        endDate: [''],
        sleepEfficiency: [''],
        dailySleepTime: [''],
        desiredGoToBedTime: ['', Validators.required],
        desiredWakeUpTime: ['', Validators.required],
      },
      {
        validator: Validation.dateShouldBeLessThan(
          'desiredWakeUpTime',
          'desiredGoToBedTime',
          'dailySleepTime'
        ),
      }
    );

    this.form.valueChanges.subscribe(() => {
      if (this.form.valid) {
        this.formError = false; // Reset the global error if the form becomes valid
      }
    });

    this.translate.onTranslationChange.subscribe((event: LangChangeEvent) => {
      this.form.updateValueAndValidity();
    });

    const savedLang = localStorage.getItem('selectedLanguage');
    if (savedLang) {
      this.translate.use(savedLang);
    } else {
      this.translate.use(this.translate.defaultLang);
    }

    //  Parameter (2): Indicates the number of the previous week
    this.courseService
      .getLastWeekSleepJournalCalculateTotal(2)
      .subscribe((data) => {
        let totalAwakeNumber = 0;
        let totalAwakeMinutes = 0;
        let totalTimeInBed = 0;
        let totalSleepTime = 0;
        let startDate;
        let endDate;

        if (data && Object.keys(data).length > 0) {
          totalAwakeNumber = data.total_awake_number ?? 0;
          totalAwakeMinutes = data.total_awake_minutes ?? 0;
          totalTimeInBed = data.total_time_in_bed ?? 0;
          totalSleepTime = data.total_sleep_time ?? 0;
          startDate = data.start_date ?? 0;
          endDate = data.end_date ?? 0;

          this.form.patchValue({
            totalTimeInBed: totalTimeInBed,
            totalSleepTime: totalSleepTime,
            sleepEfficiency: data.sleep_efficiency ?? 0,
            startDate: startDate,
            endDate: endDate,
            dailySleepTime: this.getDailySleepTime(totalSleepTime),
          });
        } else {
          //  Parameter (2): Indicates the number of the previous week
          this.courseService
            .getLastWeekSleepJournalCalculateEntries(2)
            .subscribe({
              next: (response) => {
                if (response && response.length > 0) {
                  if (response.length === 7) {
                    for (const [index, entry] of response.entries()) {
                      const awakeMinutes = entry.minutes_awake || 0;
                      const awakeNumber = entry.number_awakening || 0;
                      const timeInBed = entry.time_in_bed || 0;
                      const sleepTime = entry.sleep_time || 0;
                      if (index === 0) {
                        startDate = entry.entry_date ?? 0;
                      }
                      if (index === 6) {
                        endDate = entry.entry_date ?? 0;
                      }
                      totalAwakeNumber += awakeNumber;
                      totalAwakeMinutes += awakeMinutes;
                      totalTimeInBed += timeInBed;
                      totalSleepTime += sleepTime;
                    }
                    const totalSleepEfficiency = (
                      (totalSleepTime / totalTimeInBed) *
                      100
                    ).toFixed(0); // Format as percentage
                    this.form.patchValue({
                      totalTimeInBed: totalTimeInBed,
                      totalSleepTime: totalSleepTime,
                      sleepEfficiency: totalSleepEfficiency + '%',
                      startDate: startDate,
                      endDate: endDate,
                      dailySleepTime: this.getDailySleepTime(totalSleepTime),
                    });
                    let request = {
                      week_number: 2,
                      start_date: moment(startDate).format('YYYY-MM-DD'),
                      end_date: moment(endDate).format('YYYY-MM-DD'),
                      total_awake_number: totalAwakeNumber,
                      total_awake_minutes: totalAwakeMinutes,
                      total_time_in_bed: totalTimeInBed,
                      total_sleep_time: totalSleepTime,
                      sleep_efficiency: totalSleepEfficiency + '%',
                    };
                    this.courseService.saveLastWeekSleepJournalCalculateTotal(
                      request
                    );
                  } else {
                    console.error(
                      'Error: Insufficient sleep journal entries. There should be exactly 7 entries.'
                    );
                  }
                } else {
                  // Parameter (2): Indicates the number of the previous week
                  this.courseService.getLastWeekJournalEntries(2).subscribe({
                    next: (response) => {
                      // Step 1: Check if the number of records is exactly 7
                      if (response && response.length > 0) {
                        if (response.length === 7) {
                          let totalAwakeNumber = 0;
                          let totalAwakeMinutes = 0;
                          let totalTimeInBed = 0;
                          let totalSleepTime = 0;
                          let startDate;
                          let endDate;
                          for (const [index, entry] of response.entries()) {
                            this.initialSleepTime(entry);
                            this.setTableValue(entry);

                            totalAwakeNumber += entry.awake_number;
                            totalAwakeMinutes += entry.awake_minutes;
                            totalTimeInBed += entry.time_in_bed;
                            totalSleepTime += entry.sleep_time;
                            if (index === 0) {
                              startDate = entry.entry_date;
                            }
                            if (index === 6) {
                              endDate = entry.entry_date;
                            }

                            this.courseService.saveLastWeekSleepJournalCalculate(
                              entry,
                              2
                            );
                          }
                          const totalSleepEfficiency = (
                            (totalSleepTime / totalTimeInBed) *
                            100
                          ).toFixed(0); // Format as percentage
                          this.form.patchValue({
                            startDate: startDate,
                            endDate: endDate,
                            totalTimeInBed: totalTimeInBed,
                            totalSleepTime: totalSleepTime,
                            sleepEfficiency: totalSleepEfficiency + '%',
                            dailySleepTime:
                              this.getDailySleepTime(totalSleepTime),
                          });
                          let request = {
                            week_number: 2,
                            start_date: moment(startDate).format('YYYY-MM-DD'),
                            end_date: moment(endDate).format('YYYY-MM-DD'),
                            total_awake_number: totalAwakeNumber,
                            total_awake_minutes: totalAwakeMinutes,
                            total_time_in_bed: totalTimeInBed,
                            total_sleep_time: totalSleepTime,
                            sleep_efficiency: totalSleepEfficiency + '%',
                          };
                          this.courseService.saveLastWeekSleepJournalCalculateTotal(
                            request
                          );
                        } else {
                          console.error(
                            'Error: Insufficient sleep journal entries. There should be exactly 7 entries.'
                          );
                          this.alertService.error(
                            'Error: Insufficient sleep journal entries. There should be exactly 7 entries.'
                          );
                          return;
                        }
                      } else {
                        console.error('No data retrieved from sleep journal');
                      }
                    },
                  });
                }
              },
            });
        }
      });

    // Parameter (3): Indicates the number of the current week.
    this.courseService.getDesiredSleepTime(3).subscribe(
      (data) => {
        if (data && Object.keys(data).length > 0) {
          const formatData = this.formatData(data);
          this.form.patchValue(formatData);
        }
      },
      (error) => {
        console.log('Error fetching desired sleep time:', error);
      }
    );

    this.translate.onLangChange.subscribe((event) => {
      localStorage.setItem('selectedLanguage', event.lang);
    });
  }

  initialSleepTime(entry) {
    this.entry_date_noon = new Date(`${entry.entry_date}T12:00:00`);
    this.next_entry_date_noon = new Date(`${entry.entry_date}T12:00:00`);
    this.next_entry_date_noon.setDate(this.next_entry_date_noon.getDate() + 1);

    this.go_to_bed_date_time = new Date(entry.go_to_bed_date_time);
    this.lights_off_date_time = new Date(entry.lights_off_date_time);

    entry.go_to_sleep_date_time = this.calculateGoToSleepTime(
      entry.lights_off_date_time,
      entry.duration_falling_sleep
    );
    this.go_to_sleep_date_time = new Date(entry.go_to_sleep_date_time);

    this.wake_up_date_time = new Date(entry.wake_up_date_time);
    this.rise_from_bed_date_time = new Date(entry.rise_from_bed_date_time);
  }

  setTableValue(entry: any) {
    entry.awake_number = entry.number_awakening || 0;
    entry.awake_minutes = entry.minutes_awake || 0;

    const bedDifferenceInMs =
      this.rise_from_bed_date_time.getTime() -
      this.go_to_bed_date_time.getTime();
    const bedDifferenceInMinutes = Math.floor(bedDifferenceInMs / (1000 * 60));
    entry.time_in_bed = bedDifferenceInMinutes;

    const sleepDifferenceInMs =
      this.wake_up_date_time.getTime() - this.go_to_sleep_date_time.getTime();
    const sleepDifferenceInMinutes = Math.floor(
      (sleepDifferenceInMs - entry.awake_minutes) / (1000 * 60)
    );
    entry.sleep_time = sleepDifferenceInMinutes;
  }

  calculateGoToSleepTime(lightsOffTime: string, minutesFallingSleep: number) {
    const lightsoff_time = new Date(lightsOffTime);

    const durationInMs = minutesFallingSleep * 60 * 1000;
    const gotosleeptime = new Date(lightsoff_time.getTime() + durationInMs);

    const year = gotosleeptime.getFullYear();
    const month = String(gotosleeptime.getMonth() + 1).padStart(2, '0');
    const day = String(gotosleeptime.getDate()).padStart(2, '0');

    const date = `${year}-${month}-${day}`;

    let hours = gotosleeptime.getHours();
    const minutes = gotosleeptime.getMinutes();
    const seconds = gotosleeptime.getSeconds();

    hours = hours % 24;

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    return `${date}T${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  formatData(data) {
    return {
      desiredGoToBedTime: data.desired_go_to_bed_time
        ? this.formatHM(data.desired_go_to_bed_time)
        : '',
      desiredWakeUpTime: data.desired_wake_up_time
        ? this.formatHM(data.desired_wake_up_time)
        : '',
    };
  }

  getDailySleepTime(totalSleepTime): string {
    const totalSleepInMinutes = Number(totalSleepTime);

    const dailyHours = Math.floor(totalSleepInMinutes / 7 / 60);
    const dailyMinutes = Math.round((totalSleepInMinutes / 7) % 60);

    return dailyMinutes === 0
      ? `${dailyHours}h`
      : `${dailyHours}h ${dailyMinutes}min`;
  }

  formatHM(timeStr: string): string {
    const [hours, minutes, seconds] = timeStr.split(':').map(Number);

    const period = hours >= 12 ? 'PM' : 'AM';

    let changeHours = hours === 0 ? 12 : hours > 12 ? hours - 12 : hours;

    return `${this.padZero(changeHours)}:${this.padZero(minutes)} ${period}`;
  }

  parseTimeHM(time: string): { hours: number; minutes: number } {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return { hours, minutes };
  }

  getMinutesSinceNoon(time: { hours: number; minutes: number }): number {
    let totalMinutes = time.hours * 60 + time.minutes;

    if (time.hours >= 12) {
      totalMinutes -= 12 * 60;
    } else {
      totalMinutes += 12 * 60;
    }

    return totalMinutes;
  }

  parseTimeMinutes(time: string): number {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return hours * 60 + minutes;
  }

  calculateFirstSegmentWidth(startTime: string): number {
    const startMinutes = this.parseTimeMinutes(startTime);
    const noonMinutes = 12 * 60;
    if (startMinutes < noonMinutes) {
      return ((noonMinutes - startMinutes) / 1440) * 100;
    }
    return 0;
  }

  calculateSecondSegmentWidth(endTime: string): number {
    const endMinutes = this.parseTimeMinutes(endTime);
    const noonMinutes = 12 * 60;
    if (endMinutes >= noonMinutes) {
      return ((endMinutes - noonMinutes) / 1440) * 100;
    }

    return 0;
  }

  calculateLeftOffset(time: string): number {
    const parsedTime = this.parseTimeHM(time);
    const minutesSinceNoon = this.getMinutesSinceNoon(parsedTime);
    return (minutesSinceNoon / 1440) * 100;
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }

  // Method to calculate results based on user input
  calculateSleepEfficiency(): void {
    const totalMinutesInBed = this.timeDifference(
      this.goToBedTime,
      this.riseFromBedTime
    );
    const totalSleepMinutes =
      totalMinutesInBed -
      parseInt(this.goToSleepTime) -
      parseInt(this.minutesAwake);

    this.totalTimeInBed = this.formatMinutes(totalMinutesInBed);
    this.totalSleepTime = this.formatMinutes(totalSleepMinutes);
    this.sleepEfficiency =
      ((totalSleepMinutes / totalMinutesInBed) * 100).toFixed(2) + '%';
  }

  // Utility function to calculate time difference in minutes
  timeDifference(startTime: string, endTime: string): number {
    const start = this.parseTime(startTime);
    const end = this.parseTime(endTime);
    return (end - start + 1440) % 1440; // Adjust for overnight times
  }

  // Utility function to parse time in "HH:mm" format to minutes since midnight
  parseTime(time: string): number {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  }

  // Format minutes to "HH:mm" format
  formatMinutes(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${this.padZero(hours)}:${this.padZero(mins)}`;
  }

  // Pad single digit number with leading zero
  padZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  openWakeUpTimePicker(event: Event): void {
    // openTimePicker(controlName: string): void {
    event.preventDefault();

    const wakeUpTime = this.form.get('desiredWakeUpTime')?.value;

    const initalTime = wakeUpTime ? wakeUpTime : '6:00 AM';

    const dialogRef = this.dialog.open(TimePickerDialogComponent, {
      width: 'auto',
      data: { time: initalTime },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.form.get('desiredWakeUpTime')?.setValue(result);
        this.form.get('desiredWakeUpTime')?.markAsTouched();
        this.form.get('desiredWakeUpTime')?.updateValueAndValidity();
      }
    });
  }

  openGoToBedTimePicker(event: Event): void {
    event.preventDefault();

    const goToBedTime = this.form.get('desiredGoToBedTime')?.value;

    const initalTime = goToBedTime ? goToBedTime : '11:00 PM';

    const dialogRef = this.dialog.open(TimePickerDialogComponent, {
      width: 'auto',
      data: { time: initalTime },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.form.get('desiredGoToBedTime')?.setValue(result);
        this.form.get('desiredGoToBedTime')?.markAsTouched();
        this.form.get('desiredGoToBedTime')?.updateValueAndValidity();
      }
    });
  }

  convertTimeStringToDate(timeString: string): Date | null {
    const [time, period] = timeString.split(' ');
    const [hours, minutes] = time.split(':').map(Number);

    if (!time || !period || isNaN(hours) || isNaN(minutes)) {
      return null; // Return null if the time string is invalid
    }

    // Create a new Date object for today and set the hours, minutes, and AM/PM
    const date = new Date();
    let hour = period === 'PM' && hours < 12 ? hours + 12 : hours;
    if (period === 'AM' && hour === 12) {
      hour = 0; // Adjust for midnight
    }

    date.setHours(hour);
    date.setMinutes(minutes);
    date.setSeconds(0);
    date.setMilliseconds(0);

    return date;
  }

  saveDesiredSleepTimeData() {
    // Convert the time string to a Date object
    const desiredGoToBedTimeData = this.convertTimeStringToDate(
      this.form.value.desiredGoToBedTime
    );
    if (!desiredGoToBedTimeData) {
      this.alertService.error('Invalid wake-up time format.');
      return;
    }

    const desiredWakeUpTimeData = this.convertTimeStringToDate(
      this.form.value.desiredWakeUpTime
    );
    if (!desiredWakeUpTimeData) {
      this.alertService.error('Invalid wake-up time format.');
      return;
    }

    let desiredGoToBedTime = moment(
      this.form.value.desiredGoToBedTime,
      'hh:mm A'
    );
    let desiredWakeUpTime = moment(
      this.form.value.desiredWakeUpTime,
      'hh:mm A'
    );

    if (
      desiredGoToBedTime.format('A') === 'PM' &&
      desiredWakeUpTime.format('A') === 'AM'
    ) {
      desiredWakeUpTime.add(1, 'days');
    }

    // const hoursDifference = desiredWakeUpTime.diff(
    //   desiredGoToBedTime,
    //   'hours',
    //   true
    // );

    const sleepTime = desiredWakeUpTime.diff(
      desiredGoToBedTime,
      'minutes',
      true
    );

    let request = {
      week_number: 3,
      desired_go_to_bed_time: desiredGoToBedTimeData,
      desired_wake_up_time: desiredWakeUpTimeData,
      desired_sleep_time: sleepTime,
    };

    this.courseService.saveDesiredSleepTime(request).subscribe(
      (response) => {
        console.log(
          "The calculated total result of last week's sleep journal saved sucessfully",
          response
        );
      },
      (error) => {
        console.log(
          "The calculated total result of last week's sleep journal saved failure",
          error
        );
      }
    );
  }

  // Event handler for Next button
  onSubmit(): void {
    this.calculateSleepEfficiency();
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.formError = true;
      return;
    }
    this.formError = false;

    this.saveDesiredSleepTimeData();

    this.courseService.getCourseTimes().then(
      (response) => {
        // 3: Indicates the number of the current week
        if (response.currentWeek === 3) {
          const timeZone = 'America/Vancouver';
          const lastUpdateTimeVancouver = utcToZonedTime(
            response.lastUpdateTime,
            timeZone
          );
          const dayDifference = this.courseService.getDayDuration(
            new Date(),
            lastUpdateTimeVancouver,
            timeZone
          );
          // Paremeter(3): Indicates the number of the current week
          this.courseService.checkUserProgress(3, dayDifference, true);
        } else {
          this.router.navigate(['week4/weekly_check_in']);
        }
      },
      (error) => {
        console.log('Userprogress not found!');
      }
    );
  }
}
