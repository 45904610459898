import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-date-time-picker-dialog',
  templateUrl: './date-time-picker-dialog.component.html',
  styleUrls: ['./date-time-picker-dialog.style.scss'],
})
export class DateTimePickerDialogComponent {
  years: number[] = [];
  months: number[] = Array.from({ length: 12 }, (_, i) => i + 1);
  days: number[] = [];
  hours: number[] = [];
  minutes: number[] = [];
  periods: string[] = ['AM', 'PM'];

  selectedYear!: number;
  selectedMonth!: number;
  selectedDay!: number;
  selectedHour: number = 12;
  selectedMinute: number = 0;
  selectedPeriod: string = 'AM';

  constructor(
    public dialogRef: MatDialogRef<DateTimePickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.initializeYears();

    // if (data && data.date) {
    //   this.initializeSelectedDate(data.date);
    // }

    for (let i = 1; i <= 12; i++) {
      this.hours.push(i);
    }

    for (let i = 0; i < 60; i += 5) {
      this.minutes.push(i);
    }

    if (data && data.time) {
      this.initializeSelectedTime(data.time);
    }
  }

  initializeYears(): void {
    const currentYear = new Date().getFullYear();
    this.years = Array.from({ length: 10 }, (_, i) => currentYear - 5 + i); // Last 5 years to next 4 years
  }

  updateDays(): void {
    const daysInMonth = new Date(
      this.selectedYear,
      this.selectedMonth,
      0
    ).getDate();
    this.days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
    if (this.selectedDay > daysInMonth) {
      this.selectedDay = daysInMonth; // Adjust if the day exceeds days in month
    }
  }

  initializeSelectedDate(dateStr: string) {
    const [month, day, year] = dateStr.split('/');
    this.selectedYear = parseInt(year);
    this.selectedMonth = parseInt(month, 10);
    this.selectedDay = parseInt(day, 10);
    this.updateDays();
  }

  initializeSelectedTime(time: string) {
    const [dateStr, timeStr, period] = time.split(' ');
    const [month, day, year] = dateStr.split('/');
    this.selectedYear = parseInt(year);
    this.selectedMonth = parseInt(month, 10);
    this.selectedDay = parseInt(day, 10);
    this.updateDays();
    // const [time, period] = timeStr.split(' ');
    const [hourStr, minuteStr] = timeStr.split(':');
    this.selectedHour = parseInt(hourStr, 10);
    this.selectedMinute = parseInt(minuteStr, 10);
    this.selectedPeriod = period;
  }

  confirmTime(): void {
    const timeString = `${
      this.selectedMonth < 10 ? '0' + this.selectedMonth : this.selectedMonth
    }/${this.selectedDay < 10 ? '0' + this.selectedDay : this.selectedDay}/${
      this.selectedYear
    } ${this.selectedHour}:${
      this.selectedMinute < 10 ? '0' + this.selectedMinute : this.selectedMinute
    } ${this.selectedPeriod}`;
    this.dialogRef.close(timeString);
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
