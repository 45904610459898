<div class="custom-dialog">
  <h2>{{ "DIALOG_CONFIRMATION" | translate }}</h2>
  <div *ngIf="data.displayFlg == 1">
    <h3>
      <p>
        {{ "DIALOG_JOURNAL_EXIST" | translate }}
      </p>
    </h3>
    <div class="form-group text-center">
      <button
        class="btn btn-primary field mx-auto col-3 me-2"
        (click)="onConfirm()"
        style="min-width: 190px"
      >
        {{ "DIALOG_REVISE_JOURNAL" | translate }}
      </button>
      <button
        class="btn btn-primary field mx-auto col-3 ms-2"
        (click)="onCancel()"
        style="min-width: 190px"
      >
        {{ "DIALOG_CANCEL" | translate }}
      </button>
    </div>
  </div>
  <div *ngIf="data.displayFlg == 2">
    <h3>
      <p>
        <!-- A Sleep Journal entry already exists for this date. You can only update
        data for the current week (from {{ data.startDate }} to
        {{ data.endDate }}). Updating data for previous weeks is not allowed. -->
        {{
          "DIALOG_JOURNAL_EXIST_PREVIOUS"
            | translate : { startDate: data.startDate, endDate: data.endDate }
        }}
      </p>
    </h3>
    <div class="form-group text-center">
      <button
        class="btn btn-primary field mx-auto col-3 me-2"
        (click)="onEdit()"
        style="min-width: 200px"
      >
        {{ "DIALOG_BACK_JOURNAL" | translate }}
      </button>
      <button
        class="btn btn-primary field mx-auto col-3 me-2"
        (click)="viewProgress()"
        style="min-width: 200px"
      >
        {{ "DIALOG_VIEW_PROGRESS" | translate }}
      </button>
    </div>
  </div>
</div>
