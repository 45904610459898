import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from '@app/_services';
import { PatientService } from '@app/_services/patient.service';
import { first } from 'rxjs/operators';
import Validation from './validation';

@Component({
  selector: 'add-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.less']
})
export class AddPatientComponent implements OnInit {

  form: FormGroup;
  submitted = false;
  constructor(
    private formBuilder: FormBuilder,
    private patientService: PatientService,
    private alertService: AlertService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        sex: ['', Validators.required],
        dob: ['', Validators.required],
        email: ['', [Validators.email, Validators.required]],
        height: ['', Validators.required],
        weight: ['', Validators.required],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(40)
          ]
        ],
        confirmPassword: ['', Validators.required],
      },
      {
        validators: [Validation.match('password', 'confirmPassword'), Validation.dateLessThan('dob')]
      }
    );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    console.log(JSON.stringify(this.form.value, null, 2));
    this.patientService.savePatientData(this.form.value)
      .pipe(first())
      .subscribe({
        next: (data) => {
          console.log("Patient data is ", data)
          this.alertService.info("Patient Added successfully")
          this.router.navigate(['/dashboard'])
        },
        error: (error) => {
          this.alertService.error(error);
        },
      });
  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
  }

}