import { Component, OnInit } from '@angular/core';
import { AlertService } from '@app/_services';
import { PatientService, SortOrderEnum } from '@app/_services/patient.service';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.style.scss'],
})
export class CourseComponent implements OnInit {
  patientId: Number;
  patientDetails: Object;
  showSpecificPatient: boolean = true;
  patientProfileDetails: Object;
  patientList: any[];

  searchString = '';
  activeSort = '';
  sortOrder: 'asc' | 'desc' = 'asc';

  limit = 20;
  offset = 0;
  totalCount: number = 0;
  totalPages: number = 0;
  hasMoreData: boolean = false;
  currentPage: number = 1;

  constructor(
    private alertService: AlertService,
    public patientService: PatientService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.patientService.resetPaginationDetails();
    this.fetchData();
  }

  fetchData(): void {
    this.patientService
      .getPatientCourseDetails(
        this.searchString,
        this.limit,
        this.offset,
        this.activeSort || 'first_name',
        this.sortOrder
      )
      .subscribe((data) => {
        this.patientList = data.results;
        this.totalCount = data.count;
        this.totalPages = Math.ceil(this.totalCount / this.limit);
        this.hasMoreData = data.results.length === this.limit;
        this.currentPage = Math.floor(this.offset / this.limit) + 1;
      });
  }

  onSearch(): void {
    this.offset = 0; // Reset pagination
    this.fetchData();
  }

  sortData(column: string): void {
    if (this.activeSort === column) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.activeSort = column;
      this.sortOrder = 'asc';
    }

    this.patientService
      .getPatientCourseDetails(
        this.searchString,
        this.limit,
        this.offset,
        this.activeSort,
        this.sortOrder
      )
      .subscribe((data) => {
        this.patientList = data.results;
      });
  }

  getSortIcon(column: string): string {
    if (this.activeSort === column) {
      return this.sortOrder === 'asc' ? 'fa-sort-asc' : 'fa-sort-desc';
    }
    return 'fa-sort';
  }

  parseMomentDate(modifiedDate: string) {
    if (modifiedDate == null) {
      return '-';
    }

    try {
      return moment(modifiedDate).format('M-D-YYYY h:mm A');
    } catch (error) {
      return '-';
    }
  }

  previousPage(): void {
    if (this.offset > 0) {
      this.offset -= this.limit;
      this.fetchData();
    }
  }

  nextPage(): void {
    this.offset += this.limit;
    this.fetchData();
  }

  goToCourseDetails(id: number) {
    this.router.navigate([`/course-details/${id}`]);
  }

  goToCourseEdit(id: number) {
    this.router.navigate([`/course-edit/${id}`]);
  }
}
