import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: 'piechart.component.html',
  selector: 'piechart',
  styleUrls: ['piechart.style.scss'],
})
export class PieComponent implements OnInit {
  //chart stuff:
  view: any[] = [700, 300];

  // options
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;

  colorScheme = {
    domain: ['#002642;', '#ACD1E5'],
  };
  single = [
    {
      name: 'Time Asleep',
      value: 360,
    },
    {
      name: 'Time Awake',
      value: 120,
    },
  ];


  constructor() {}

  ngOnInit() {}

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
}
