export var sleep = [
  {
    "name": "Germany",
    "value": 9940000
  },
  {
    "name": "USA",
    "value": 6000000
  },
  {
    "name": "France",
    "value": 8200000
  }
];

//   export var labels = [
//       [
//     {
//       "name": "laksjdf",
//       "value": 9940000
//     },
//     {
//         "name": "laksjdf",
//         "value": 9940000
//       }
// ],[
//     {
//       "name": "UalskdjfSA",
//       "value": 6000000
//     },  {
//         "name": "laksjdf",
//         "value": 9940000
//       }],
//     [{
//       "name": "Fraalskdjfnce",
//       "value": 8200000
//     },  {
//         "name": "laksjdf",
//         "value": 9940000
//       }],
//   ];
// export var labels = [{'val':1},{'val':2},{'val':3},{'val':4},{'val':5},{'val':6},{'val':7}]

export var labels = { 'val':[1,2,3,4,5,6,7]}

export var bed = [
  {
    "name": "Germany",
    "value": 8940000
  },
  {
    "name": "USA",
    "value": 5000000
  },
  {
    "name": "France",
    "value": 7200000
  }
];
