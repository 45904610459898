import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { CourseService } from '@app/_services/course.service';

@Component({
  selector: 'app-intro-page3',
  templateUrl: 'intro-page3.component.html',
  styleUrls: ['intro-page3.style.scss'],
})
export class IntroPage3Component implements OnInit {
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private courseService: CourseService
  ) {}
  chkFlg: string = '';

  ngOnInit(): void {
    this.courseService.isFirstLogin().then((result) => {
      if (result) {
        this.chkFlg = '1';
      } else {
        this.chkFlg = '2';
      }
    });
  }

  async onNextClick() {
    this.courseService.isFirstLogin().then((result) => {
      if (result) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          disableClose: true,
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result === 'confirm') {
            this.router.navigate(['/week1/track_your_sleep']); // navigate to week1
          }
        });
      } else {
        this.router.navigate(['/week1/track_your_sleep']);
      }
    });
  }
}
