import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CourseService } from '@app/_services/course.service';
import { AccountService, AlertService } from '@app/_services';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: 'journal-confirm-dialog.component.html',
  styleUrls: ['journal-confirm-dialog.style.scss'],
})
export class JournalConfirmDialogComponent {
  private currentWeek: number;
  private currentPage: number;
  constructor(
    public dialogRef: MatDialogRef<JournalConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onConfirm() {
    this.dialogRef.close('confirm');
  }

  viewProgress() {
    this.dialogRef.close('progress');
  }

  onEdit() {
    this.dialogRef.close('edit');
  }

  onCancel() {
    this.dialogRef.close();
  }
}
