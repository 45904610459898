import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
// used to create fake backend
import { fakeBackendProvider } from './_helpers';

import { AppRoutingModule } from './app-routing.module';
import { JwtInterceptor, ErrorInterceptor, appInitializer } from './_helpers';
import { AccountService } from './_services';
import { AppComponent } from './app.component';
import { AlertComponent } from './_components';

import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatTimepickerModule } from 'mat-timepicker';
import { MatSliderModule } from '@angular/material/slider';

import { MatIconModule } from '@angular/material/icon';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { Step1Component } from './onboarding/step1/step1.component';
import { Step2Component } from './onboarding/step2/step2.component';
import { Step3Component } from './onboarding/step3/step3.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { DatePipe } from '@angular/common';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ContactusComponent } from './contactus/contactus.component';
import { CbtiComponent } from './cbti/cbti.component';
import { DiaryModule } from './diary/diary.module';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { CourseService } from './_services/course.service';
import { IntroComponent } from './intro/intro.component';
import { Week1Component } from './week1/week1.component';
import { IntroPage1Component } from './intro/intro-page1/intro-page1.component';
import { IntroPage2Component } from './intro/intro-page2/intro-page2.component';
import { IntroPage3Component } from './intro/intro-page3/intro-page3.component';
import { ConfirmDialogComponent } from './intro/intro-page3/confirm-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { Week1Page1Component } from './week1/week1-page1/week1-page1.component';
import { Week1Page2Component } from './week1/week1-page2/week1-page2.component';
import { JournalModule } from './journal/journal.module';
import { Week1Page3Component } from './week1/week1-page3/week1-page3.component';

import { Week1Page4Component } from './week1/week1-page4/week1-page4.component';
import { Week1Page5Component } from './week1/week1-page5/week1-page5.component';
import { Week2Component } from './week2/week2.component';
import { Week2Page1Component } from './week2/week2-page1/week2-page1.component';
import { Week2Page2Component } from './week2/week2-page2/week2-page2.component';
import { Week2Page3Component } from './week2/week2-page3/week2-page3.component';
import { Week2Page4Component } from './week2/week2-page4/week2-page4.component';
import { Week2Page5Component } from './week2/week2-page5/week2-page5.component';
import { Week2Page6Component } from './week2/week2-page6/week2-page6.component';
import { Week2Page7Component } from './week2/week2-page7/week2-page7.component';
import { Week2Page8Component } from './week2/week2-page8/week2-page8.component';
import { DialogComponent } from './dialog/dialog.component';
import { MissingJournalDialogComponent } from './dialog/missing-journal-dialog/missing-journal-dialog.component';
import { LongTimeConfirmDialogComponent } from './dialog/long-time-confirm-dialog/long-time-confirm-dialog.component';
import { TimePickerDialogComponent } from './dialog/time-picker-dialog/time-picker-dialog.component';
import { DateTimePickerDialogComponent } from './dialog/date-time-picker-dialog/date-time-picker-dialog.component';
import { HourMinuterPickerDialogComponent } from './dialog/hour-minuter-picker-dialog/hour-minuter-picker-dialog.component';
import { JournalConfirmDialogComponent } from './journal/journal-confirm-dialog.component';
import { Week3Component } from './week3/week3.component';
import { Week3Page1Component } from './week3/week3-page1/week3-page1.component';
import { Week3Page2Component } from './week3/week3-page2/week3-page2.component';
import { Week3Page3Component } from './week3/week3-page3/week3-page3.component';
import { Week3Page4Component } from './week3/week3-page4/week3-page4.component';
import { Week3Page5Component } from './week3/week3-page5/week3-page5.component';
import { Week4Component } from './week4/week4.component';
import { Week4Page1Component } from './week4/week4-page1/week4-page1.component';
import { Week4Page2Component } from './week4/week4-page2/week4-page2.component';
import { Week4Page3Component } from './week4/week4-page3/week4-page3.component';
import { Week4Page4Component } from './week4/week4-page4/week4-page4.component';
import { Week4Page5Component } from './week4/week4-page5/week4-page5.component';
import { Week5Component } from './week5/week5.component';
import { Week5Page1Component } from './week5/week5-page1/week5-page1.component';
import { Week5Page2Component } from './week5/week5-page2/week5-page2.component';
import { Week5Page3Component } from './week5/week5-page3/week5-page3.component';
import { Week5Page4Component } from './week5/week5-page4/week5-page4.component';
import { Week5Page5Component } from './week5/week5-page5/week5-page5.component';
import { Week5Page6Component } from './week5/week5-page6/week5-page6.component';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    BrowserModule,
    TourMatMenuModule.forRoot(),
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    RouterModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatTimepickerModule,
    MatIconModule,
    NgxSliderModule,
    MatExpansionModule,
    MatSliderModule,
    NgScrollbarModule,
    // DiaryModule,
    FormsModule,
    MatDialogModule,
    JournalModule,
    AngularEditorModule,

    // ngx-translate
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],

  declarations: [
    AppComponent,
    AlertComponent,
    OnboardingComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    ContactusComponent,
    CbtiComponent,
    Week1Component,
    IntroComponent,
    IntroPage1Component,
    IntroPage2Component,
    IntroPage3Component,
    ConfirmDialogComponent,
    Week1Page1Component,
    Week1Page2Component,
    Week1Page3Component,
    Week1Page4Component,
    Week1Page5Component,
    Week2Component,
    Week2Page1Component,
    Week2Page2Component,
    Week2Page3Component,
    Week2Page4Component,
    Week2Page5Component,
    Week2Page6Component,
    Week2Page7Component,
    Week2Page8Component,
    DialogComponent,
    MissingJournalDialogComponent,
    LongTimeConfirmDialogComponent,
    TimePickerDialogComponent,
    DateTimePickerDialogComponent,
    HourMinuterPickerDialogComponent,
    JournalConfirmDialogComponent,
    Week3Component,
    Week3Page1Component,
    Week3Page2Component,
    Week3Page3Component,
    Week3Page4Component,
    Week3Page5Component,
    Week4Component,
    Week4Page1Component,
    Week4Page2Component,
    Week4Page3Component,
    Week4Page4Component,
    Week4Page5Component,
    Week5Component,
    Week5Page1Component,
    Week5Page2Component,
    Week5Page3Component,

    Week5Page4Component,
    Week5Page5Component,
    Week5Page6Component,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AccountService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // provider used to create fake backend
    fakeBackendProvider,
    DatePipe,
    CourseService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
