import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from '@app/_services';
import { AdministratorService } from '@app/_services/administrator.service';
import { PatientService } from '@app/_services/patient.service';
import { first } from 'rxjs/operators';
import Validation from '../patient/validation';

@Component({
  selector: 'administrator',
  templateUrl: './add-admin.component.html',
  styleUrls: ['./add-admin.component.less']
})
export class AddAdminComponent implements OnInit {

  form: FormGroup;
  submitted = false;

  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private router: Router,
    private patientService: PatientService,
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: ['', [Validators.email, Validators.required]],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(40)
          ]
        ],
        confirmPassword: ['', Validators.required],
      },
      {
        validators: [Validation.match('password', 'confirmPassword'),]
      }
    );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    this.patientService.saveAdminData(this.form.value)
      .pipe(first())
      .subscribe({
        next: (data) => {
          this.router.navigate(['/administrator'])
          this.alertService.info("Admin Added successfully")
        },
        error: (error) => {
          this.alertService.error(error);
        },
      });
  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
  }

}