import {
  Component,
  ElementRef,
  forwardRef,
  HostListener,
  Input,
  Optional,
  Self,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';

@Component({
  selector: 'choose-quantity',
  templateUrl: './choose-quantity.component.html',
  styleUrls: ['./duration/duration.component.less'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: ChooseQuantityComponent,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ChooseQuantityComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ChooseQuantityComponent,
    },
  ],
})
export class ChooseQuantityComponent
  implements ControlValueAccessor, Validator
{
  quantity: number | null = null;
  hours = 0;
  mins = 0;
  formControl: AbstractControl | null = null;

  @Input() hintMessage: string = '';

  // @Input()
  // increment: number;

  @Input()
  hidden: boolean = true;

  onChange = (quantity) => {};

  onTouched = () => {};

  touched = false;

  disabled = false;
  shouldLabelFloat: boolean = true;
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.eRef.nativeElement.contains(event.target)) {
      this.hidden = false;
    } else {
      this.hidden = true;
    }
  }

  constructor(
    private eRef: ElementRef
  ) // @Optional() @Self() private ngControl: NgControl
  {
    // if (this.ngControl) {
    //   this.ngControl.valueAccessor = this;
    // }
    // if (this.ngControl) {
    //   this.ngControl.valueAccessor = this;
    // }
  }

  updatevalue() {
    this.markAsTouched();
    if (!this.disabled) {
      this.quantity =
        this.hours !== null && this.mins !== null
          ? this.hours * 60 + this.mins
          : null;
      this.onChange(this.quantity);
    }
  }

  // onRemove() {
  //   this.markAsTouched();
  //   if (!this.disabled) {
  //     this.quantity-= this.increment;
  //     this.onChange(this.quantity);
  //   }
  // }

  writeValue(quantity: number | null) {
    this.quantity = quantity;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  showSlider() {
    this.hidden = !this.hidden;
  }
  hideSlider() {
    this.hidden = true;
  }
  validate(control: AbstractControl): ValidationErrors | null {
    const quantity = control.value;
    if (quantity === null || quantity === undefined || quantity <= 0) {
      return {
        required: true,
      };
    }
    if (quantity < 0) {
      return {
        mustBePositive: {
          quantity,
        },
      };
    }
  }
}
