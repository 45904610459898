import { Time } from '@angular/common';
import { DatePipe } from '@angular/common';
import { OnInit } from '@angular/core';
export class Journal {
  constructor(/*public datepipe: DatePipe*/) {
    // this.entry_date=this.datepipe.transform(this.entry_date, 'yyyy-MM-dd');
    // this.wake_up_time=this.datepipe.transform(this.wake_up_time, 'HH:mm:ssZZZZZ');
    // this.rise_from_bed=this.datepipe.transform(this.rise_from_bed, 'HH:mm:ssZZZZZ');
    // this.go_to_bed=this.datepipe.transform(this.go_to_bed, 'HH:mm:ssZZZZZ');
    // this.lights_off_time=this.datepipe.transform(this.lights_off_time, 'HH:mm:ssZZZZZ');
  }

  OnInit() {
    // this.entry_date=this.datepipe.transform(this.entry_date, 'yyyy-MM-dd');
    // this.wake_up_time=this.datepipe.transform(this.wake_up_time, 'HH:mm:ssZZZZZ');
    // this.rise_from_bed=this.datepipe.transform(this.rise_from_bed, 'HH:mm:ssZZZZZ');
    // this.go_to_bed=this.datepipe.transform(this.go_to_bed, 'HH:mm:ssZZZZZ');
    // this.lights_off_time=this.datepipe.transform(this.lights_off_time, 'HH:mm:ssZZZZZ');
  }

  entry_date;
  wake_up_date_time;
  rise_from_bed_date_time;
  go_to_bed_date_time;
  lights_off_date_time;
  // wake_up_time;
  // rise_from_bed;
  // go_to_bed;
  // lights_off_time;
  duration_falling_sleep: number;
  number_awakening: number;
  minutes_awake: number;
  list_stimulants: string;
  list_sleep_medications: string;
  rate_rest: string;
  rate_sleep_quality: string;

  // Addtional fields
  total_asleep: any;
  sleep_efficiency: any;
  total_time_in_bed: any;
}
