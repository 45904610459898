<section class="weeks mt-4" id="range-slider">
  <button mat-icon-button class="button" (click)="shift(-1)">
    <mat-icon> chevron_left</mat-icon>
  </button>
  <div class="screen" *ngFor="let i of [0,1,2,3,4,5,6]">
    <ng-container *ngIf='type=="monthly"'>
      <input type="radio" [attr.id]="addMonths(currentDate, i)|date:'YYYY-MM'" [name]="selectw" (change)="change()"
        [(ngModel)]="selectedMonth" [value]="addMonths(currentDate, i)|date:'YYYY-MM'" class="inputw"
        [disabled]="addMonths(currentDate, i).setHours(0,0,0,0) > today.setHours(0,0,0,0) " 
        >
      <label style="padding-top: 0.7em;padding-bottom: 0.5em" [attr.for]="addMonths(currentDate, i)|date:'YYYY-MM'" [ngClass]="{'no-theme' : noTheme}" class="labelw">
        <div>
          <p class="date-month increase-month-font mb-1">
            {{addMonths(currentDate, i)|date:'MMM'}}
          </p>
          <p class="date-year">
            {{addMonths(currentDate, i)|date:'YYYY'}}
          </p>
        </div>

      </label>
    </ng-container>
    <ng-container *ngIf='type=="daily"'>
      <input type="radio" [attr.id]="addDays(currentDate, i)|date:'short'" [disabled]="addDays(currentDate, i).setHours(0,0,0,0) > today.setHours(0,0,0,0) " [name]="selectw" (change)="change()"
        [(ngModel)]="selectedDate" [value]="addDays(currentDate, i)|date:'YYYY-MM-dd'" class="inputw">
      <label [attr.for]="addDays(currentDate, i)|date:'short'" [ngClass]="{'no-theme' : noTheme}" class="labelw">
        <div style="padding: 5px;">
          <p class="date-day">
            {{addDays(currentDate, i)|date:'E'}}
          </p>
          <p class="date-month">
            {{addDays(currentDate, i)|date:'MMM'}}
          </p>
          <p class="date-date">
            {{addDays(currentDate, i)|date:'d'}}
          </p>
        </div>

      </label>
    </ng-container>
  </div>
  <div class="mobile" *ngFor="let i of [0,1,2]">
    <ng-container *ngIf='type=="monthly"'>
      <input type="radio" [attr.id]="addMonths(currentDate, i)|date:'YYYY-MM'" [name]="selectw" (change)="change()"
        [(ngModel)]="selectedMonth" [value]="addMonths(currentDate, i)|date:'YYYY-MM'" class="inputw"
        [disabled]="addMonths(currentDate, i).setHours(0,0,0,0) > today.setHours(0,0,0,0) " 
        >
      <label style="padding-top: 0.7em;padding-bottom: 0.5em" [attr.for]="addMonths(currentDate, i)|date:'YYYY-MM'" [ngClass]="{'no-theme' : noTheme}" class="labelw">
        <div>
          <p class="date-month increase-month-font mb-1">
            {{addMonths(currentDate, i)|date:'MMM'}}
          </p>
          <p class="date-year">
            {{addMonths(currentDate, i)|date:'YYYY'}}
          </p>
        </div>

      </label>
    </ng-container>
    <ng-container *ngIf='type=="daily"'>
      <input type="radio" [attr.id]="addDays(currentDate, i)|date:'short'" [disabled]="addDays(currentDate, i).setHours(0,0,0,0) > today.setHours(0,0,0,0) " [name]="selectw" (change)="change()"
        [(ngModel)]="selectedDate" [value]="addDays(currentDate, i)|date:'YYYY-MM-dd'" class="inputw">
      <label [attr.for]="addDays(currentDate, i)|date:'short'" [ngClass]="{'no-theme' : noTheme}" class="labelw">
        <div style="padding: 5px;">
          <p class="date-day">
            {{addDays(currentDate, i)|date:'E'}}
          </p>
          <p class="date-month">
            {{addDays(currentDate, i)|date:'MMM'}}
          </p>
          <p class="date-date">
            {{addDays(currentDate, i)|date:'d'}}
          </p>
        </div>

      </label>
    </ng-container>
  </div>

  <button mat-icon-button class="button" (click)="shift(1)">
    <mat-icon> chevron_right</mat-icon>
  </button>
</section>
