import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Patient } from '@app/_models/patient';

const baseUrl = `${environment.apiUrl}/api`;

enum SortOrderEnum {
    ASC = '',
    DESC = '-',
    EMPTY = ''
}

enum SortValueEnum {
    NAME = 'first_name',
    MODIFIED_DATE = 'modified_date',
    EMPTY = ''
}

@Injectable({ providedIn: 'root' })
export class PatientService {
    private patientSubject: BehaviorSubject<Patient>;
    public account: Observable<Patient>;
    public patientData: any;
    public patientList: any[];

    searchString: string = ""
    limit: number = 300
    offset: number = 0
    count: number = 0

    startPage: number = 1
    printPageList: any

    activeSort: SortValueEnum = SortValueEnum.MODIFIED_DATE
    sortOrder: SortOrderEnum = SortOrderEnum.DESC

    constructor(
        private http: HttpClient
    ) {
        this.patientSubject = new BehaviorSubject<Patient>(null);
        this.account = this.patientSubject.asObservable();
    }

    gePagiantion() {
        this.printPageList = []
        let startPoint
        let endPoint;

        let lastPage = Math.floor(this.count/this.limit);
        let startPage = this.startPage

        if (startPage == lastPage) {
            startPoint = startPage - 2
            endPoint = lastPage
        } else if (startPage == 1) {
            startPoint = 1
            endPoint = startPoint + 2
        }else{
            startPoint = startPage - 1
            endPoint = startPage + 1
        }

        if(startPoint<=0){
            startPoint = 1
        }

        if(endPoint>lastPage){
            endPoint = lastPage
        }

        for(let i= startPoint;i<=endPoint; i++){
            this.printPageList.push(i)
        }

    }

    getPatientDetails() {
        let { searchString, limit, offset, activeSort, sortOrder } = this

        let orderState;

        if (this.sortOrder === SortOrderEnum.DESC) {
            orderState = '-'
        } else {
            orderState = ''
        }

        let ordering = orderState + activeSort
        return this.http.get<any>(`${baseUrl}/patient/?search=${searchString}&limit=${limit}&offset=${offset}&ordering=${ordering}`,)
            .pipe(map(patientDetails => {
                this.patientSubject.next(patientDetails);
                this.patientList = patientDetails
                this.count = patientDetails.count
                this.gePagiantion()
                return patientDetails;
            }));
    }

    getPatientDetailsById(id: Number) {
        return this.http.get<any>(`${baseUrl}/patient/${id}/`,)
            .pipe(map(patientDetails => {
                this.patientSubject.next(patientDetails);
                this.patientData = patientDetails
                return patientDetails;
            }));
    }
	
	deletePatientDetailsById(id: Number) {
        return this.http.get<any>(`${baseUrl}/patientdelete/${id}/`,)
            .pipe(map(patientDetails => {
                this.patientSubject.next(patientDetails);
                this.patientData = patientDetails
                return patientDetails;
            }));
    }

    getPatientDetailsFromSearch(searchString: string) {
        this.searchString = searchString
        return this.getPatientDetails()
    }

    moveToNextPage() {
        this.startPage = this.startPage + 1
        this.offset = ((this.startPage-1) * this.limit)
        return this.getPatientDetails()
    }

    moveToPreviousPage() {
        this.startPage = this.startPage - 1
        let offset = ((this.startPage-1) * this.limit)
        if (offset < 0) {
            offset = 0
        }

        this.offset = offset
        return this.getPatientDetails()
    }

    goToPage(index) {
        this.startPage = index
        let offset = ((this.startPage-1) * this.limit)
        if (offset < 0) {
            offset = 0
        }

        this.offset = offset
        return this.getPatientDetails()
    }

    sortByName() {
        this.sortValues(SortValueEnum.NAME)
        return this.getPatientDetails()
    }

    sortByCreated() {
        this.sortValues(SortValueEnum.MODIFIED_DATE)
        return this.getPatientDetails()
    }

    sortValues(sortValue: SortValueEnum) {
        let { activeSort } = this

        if (activeSort !== sortValue) {
            this.resetSortValues()
            this.activeSort = sortValue
            this.sortOrder = SortOrderEnum.DESC
        } else {
            this.toggleSortOrder()
        }
    }

    toggleSortOrder() {
        if (this.sortOrder === SortOrderEnum.DESC) {
            this.sortOrder = SortOrderEnum.ASC
        } else {
            this.sortOrder = SortOrderEnum.DESC
        }
    }

    resetSortValues() {
        this.activeSort = SortValueEnum.EMPTY
        this.sortOrder = SortOrderEnum.EMPTY
    }

    get isPreviousPageDisable() {
        return ((this.offset - this.limit) < 0)
    }

    get isNextPageDisable() {
        let { limit, offset, count } = this
        return (limit * offset) + limit >= count
    }

    savePatientData(data: any) {

        let sexData = data.sex
        let sex = "2";
        if (sexData === 'Male') {
            sex = "1"
        }

        const requestData = {
            "first_name": data.firstName,
            "last_name": data.lastName,
            "email": data.email,
            "mobile": "9999999999",
            "password": data.password,
            "profile_details": {
                "sex": sex,
                "height": data.height,
                "weight": data.weight,
                "dob": data.dob,
            }
        }
        return this.http.post<any>(`${baseUrl}/patient/`, requestData)
            .pipe(map(patientDetails => {
                return patientDetails;
            }));
    }

    savePatientNote(notes: string, notesWrittenBy: string) {
        let patientData = this.patientData;
        let id = patientData.id

        patientData['password'] = 'we'
        patientData['profile_details']['notes'] = notes
        patientData['profile_details']['notes_written_by'] = notesWrittenBy

        let requestData = {
            ...patientData
        }

        return this.http.put<any>(`${baseUrl}/patient/${id}/`, requestData)
            .pipe(map(patientDetails => {
                this.patientSubject.next(patientDetails);
                this.patientData = patientDetails
                return patientDetails;
            }));
    }

    saveAdminData(data: any) {
        const requestData = {
            "first_name": data.firstName,
            "last_name": data.lastName,
            "email": data.email,
            "mobile": "9999999999",
            "password": data.password,
        }
        return this.http.post<any>(`${baseUrl}/admin/`, requestData)
            .pipe(map(patientDetails => {
                return patientDetails;
            }));
    }

    savePatientPlanData(data: any) {
        return this.http.post<any>(`${baseUrl}/action-plan/`, data)
            .pipe(map(apiResponse => {
                return apiResponse;
            }));
    }

    fetchPatientPlanData() {
        return this.http.get<any>(`${baseUrl}/action-plan/`)
    }

    resetPaginationDetails() {
        this.offset = 0
        this.count = 0
    }

}