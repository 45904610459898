import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout.component';
import { JournalComponent } from './journal.component';
import { TestComponent } from './test/test.component';



const routes: Routes = [
    {
        path: '', component: LayoutComponent,
        children: [
            { path: '', component: JournalComponent },
            { path: 'test', component: TestComponent },

        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class JournalRoutingModule { }