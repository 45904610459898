<section class="section2 row pl-4 pr-4 pt-2 align-items-baseline">
    <div class="col-md-4" id="current-date">{{currentDate|date}}</div>
    <div class="col-md-2" id="current-value">{{crtValue}}</div>
    <div class="col-md-2" id="filter-day">
      <input type="radio" id="control_01" name="tsiselect"  value="daily" (change)="change()" class="input2"
      [(ngModel)]="filter">
      <label for="control_01" class="label2">
        Day
      </label>
    </div>
    <div class="col-md-2" id="filter-week">
      <input type="radio" id="control_02" name="tsiselect" value="weekly" (change)="change()" class="input2" [(ngModel)]="filter">
      <label for="control_02" class="label2">
        Week
      </label>
    </div>
    <div class="col-md-2" id="filter-month">
      <input type="radio" id="control_03" name="tsiselect" value="monthly" (change)="change()" class="input2" [(ngModel)]="filter">
      <label for="control_03" class="label2">
        Month
      </label>
    </div>
    <!-- {{crtValue}} -->
  </section>